// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
  Menu,
} from 'antd';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpTab,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getFilterOperators from 'utils/getFilterOperators';

import {
  ratesActions,
  issuerrateActions,
  issuerActions,
  filterParamsActions,
  notificationActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
} from 'appconstants';

import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import RBAC from 'components/rbac';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import AddRate from './components/AddRate';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const RATES_FILTERS_TYPES = FILTERS_TYPES.ISSUERRATE;

type Props = {
  rates: Array<Object>,
  issuer: Array<Object>,
  category: Array<Object>,
  transferType: Array<Object>,
  fetchIssuerRates: Function,
  fetchIssuer: Function,
  fetchRateCategory: Function,
  fetchTransferType: Function,
  addIssuerRate: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  setFilterData: Function,
  preserveFilters: Object,
  history: {
    push: Function,
  },
};

const IssuerRatesList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    rates,
    issuer,
    category,
    transferType,
    loading,
    addIssuerRate,
    fetchIssuerRates,
    fetchIssuer,
    fetchRateCategory,
    fetchTransferType,
    totalElements,
    submitting,
    validator,
    test,
    history,
    setFilterData,
    preserveFilters,
  } = props;

  const [pagination, setPagination] = useState({});
  const [addRateModal, setAddRateModal] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const { id } = (selectedAccount && selectedAccount.currency);
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(RATES_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterRates, setFilterRates] = useState((preserveFilters && preserveFilters.filterRates)
    ? (preserveFilters && preserveFilters.filterRates)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [current, setCurrent] = useState('issuerrate');
  const currencyType = selectedAccount && selectedAccount.currency;
  const DEFAULT_DECIMALS = 2;
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterRates: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        checked: true,
      }],
    });
  const exportData = rates ? rates.map((values) => ({
    name: values.issuer && values.issuer.name,
    paymentType: values.issuerRate && values.issuerRate.transferType && values.issuerRate.transferType.value,
    categoryName: values.issuerRate && values.issuerRate.category && values.issuerRate.category.name,
    rate: `${formatNumber((values.accountRate / 100)
      .toFixed((values.issuerRate && values.issuerRate.currency && values.issuerRate.currency.toFixed) || DEFAULT_DECIMALS), currencyType)} %`,
    fee: `${values.issuerRate && values.issuerRate.currency && values.issuerRate.currency.prefix}
    ${formatNumber((values.accountFee / 100)
      .toFixed((values.issuerRate && values.issuerRate.currency && values.issuerRate.currency.toFixed) || DEFAULT_DECIMALS), currencyType)}`,
    tax: `${formatNumber((values.accountTax / 100)
      .toFixed((values.issuerRate && values.issuerRate.currency && values.issuerRate.currency.toFixed) || DEFAULT_DECIMALS), currencyType)} %`,
    createdAt: values.createdAt
      ? moment
        .utc(values.createdAt)
        .tz(selectedAccount.timezone)
        .format('MMM DD, YYYY, hh:mm a') : '',
  })) : '';

  const exportHeaders = [
    { label: 'NAME', key: 'name' },
    { label: 'TRANSFER TYPE', key: 'paymentType' },
    { label: 'CATEGORY', key: 'categoryName' },
    { label: 'RATE', key: 'rate' },
    { label: 'FEE', key: 'fee' },
    { label: 'TAX', key: 'tax' },
    { label: 'CREATED ON', key: 'createdAt' },
  ];

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    const categorysortParams = '&sortBy=updatedAt&direction=DESC';
    fetchIssuerRates({ currentPage, sortParams, filterParams });
    /* Category and Acquirer for Add Rate component */
    fetchIssuer({ categorysortParams });
    fetchRateCategory({ categorysortParams });
    fetchTransferType({ categorysortParams });
  };

  useEffect(() => {
    getData(location.search);
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, []);

  useEffect(() => {
    getData();
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setAddRateModal(false);
    }
  }, [validator]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [rates]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addNewRate = (rateInfo: Object) => {
    const {
      issuerId,
      processingMin,
      processingMax,
      rate,
      tax,
      fee,
      salesPerson,
      rateGid,
      countryId,
    } = rateInfo;
    const min = processingMin.replace(/[^\d]/g, '');
    const max = processingMax.replace(/[^\d]/g, '');
    const payloadRate = rate.replace(/[^\d]/g, '');
    const payloadTax = tax.replace(/[^\d]/g, '');
    const payloadFee = fee.replace(/[^\d]/g, '');
    addIssuerRate({
      issuerId,
      processingMin: Number(min),
      processingMax: Number(max),
      accountRate: Number(payloadRate),
      accountTax: Number(payloadTax),
      accountFee: Number(payloadFee),
      currencyCode: countryId === 1 ? 'USD' : 'INR',
      countryCode: countryId === 1 ? 'US' : 'IN',
      salesPerson,
      issuerRateGid: rateGid,
    });
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.ISSUER_RATE,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.ISSUER_RATE,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const selectedRecord = (record) => {
    const ratesGid = record.gid;
    history.push({
      pathname: `${PAGES.RATES}/${ratesGid}/issuer`,
      state: location.search,
    });
  };
  const onCheck = (e, index) => {
    const list = [...filterRates];
    if (filterRates.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = RATES_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterRates(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterRates];
    list[index].filterType = 'String';
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = RATES_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterRates(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterRates];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterRates(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterRates(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterRates];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterRates(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterRates(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterRates[index].filterName === 'createdAt') {
      if (filterRates[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterRates[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterRates[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterRates[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterRates];
    list[index].filterValue = selectedValue;
    setFilterRates(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterRates];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterRates[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterRates(list);
  };

  const handleAddClick = () => {
    const list = [...filterRates];
    setFilterRates([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterRates([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(RATES_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.ISSUER_RATE);
  };

  const applyFilters = () => {
    const ratessFilters = filterRates.filter((item) => item.checked);
    for (let i = 0; i < ratessFilters.length; i += 1) {
      if (!ratessFilters[i].filterName || !ratessFilters[i].filterOperator || !ratessFilters[i].filterValue) {
        return;
      }
    }
    if (ratessFilters[0].filterValue || (ratessFilters[0].filterValueFrom && ratessFilters[0].filterValueTo)) {
      setCount(ratessFilters && ratessFilters.length);
      for (let i = 0; i < ratessFilters.length; i += 1) {
        if (ratessFilters[i].checked) {
          if (ratessFilters[i].filterName === 'createdAt') {
            ratessFilters[i] = ratessFilters[i].filterValue;
          } else if (ratessFilters[i].filterName === 'amount' && ratessFilters[i].filterOperator === 'BT') {
            const name = ratessFilters[i].filterName;
            const from = ratessFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = ratessFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            ratessFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (ratessFilters[i].filterName === 'amount' && ratessFilters[i].filterOperator !== 'BT') {
            const value = ratessFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            ratessFilters[i] = `${ratessFilters[i].filterName}.${ratessFilters[i].filterOperator}=${value}`;
          } else {
            ratessFilters[i] = `${ratessFilters[i].filterName}.${ratessFilters[i].filterOperator}=${ratessFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(ratessFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterRates,
        });
        history.push({
          pathname: PAGES.ISSUER_RATE,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterRates,
        });
        history.push({
          pathname: PAGES.ISSUER_RATE,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };


  const columns = [
    {
      title: 'NAME',
      dataIndex: 'issuer.name',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'TRANSFER TYPE',
      dataIndex: 'issuerRate.transferType.value',
      width: '18%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (issuerType) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {issuerType.replace(/_/g, ' ') || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'CATEGORY',
      dataIndex: 'issuerRate.category.name',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (categoryname) => (
        <Tooltip placement="top" title="Click here to view details">
          <span style={{ cursor: 'pointer' }}>
            {categoryname || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'RATE',
      dataIndex: 'accountRate',
      width: '10%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (rate, record) => {
        const total = (rate / 100).toFixed(record.issuerRate && record.issuerRate.currency && record.issuerRate.currency.toFixed);
        return (
          <span> {`${formatNumber(total, currencyType)} %`}</span>
        );
      },
    },
    {
      title: 'FEE',
      dataIndex: 'accountFee',
      width: '10%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (fee, record) => {
        const total = (fee / 100).toFixed(record.issuerRate && record.issuerRate.currency && record.issuerRate.currency.toFixed);
        return (
          <span>
            {`${(record.issuerRate && record.issuerRate.currency && record.issuerRate.currency.prefix)} ${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'TAX',
      dataIndex: 'accountTax',
      width: '10%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (tax, record) => {
        const total = (tax / 100).toFixed(record.currency && record.currency.toFixed);
        return (
          <span> {`${formatNumber(total, currencyType)} %`}</span>
        );
      },
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
        <Menu.Item key="acquirer">
          <SpTab
            to="/rate"
            selected={current === 'acquirer'}
          >
            Acquirer Rates
          </SpTab>
        </Menu.Item>
        <Menu.Item key="issuerrate">
          <SpTab
            to="/rate/issuerrate"
            selected={current === 'issuerrate'}
          >
            Issuer Rates
          </SpTab>
        </Menu.Item>
        <Menu.Item key="exchangerate">
          <SpTab
            to="/rate/exchangerate"
            selected={current === 'exchangerate'}
          >
            Exchange Rates
          </SpTab>
        </Menu.Item>
      </Menu>
      <Card>
        <Row className="my-2">
          <div className="d-flex justify-content-end">
            <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
              <Col>
                <SpButton
                  type="secondary"
                  shape="round"
                  ghost
                  className="mr-3"
                  onClick={() => setAddRateModal(true)}
                >
                  {'\u002B'}&nbsp;Add Rate
                </SpButton>
                <span>
                  {
                    exportData !== null
                      ? (
                        <Tooltip placement="left" title="Click here to export Rates">
                          <CSVLink
                            target="_blank"
                            className="mr-3"
                            filename="Rates.csv"
                            data={exportData}
                            headers={exportHeaders}
                            onMouseEnter={() => setFillColor(true)}
                            onMouseLeave={() => setFillColor(false)}
                          >
                            <ExportIcon
                              fill={fillColor
                                ? '#279dfe'
                                : '#C0C0C0'}
                            />
                          </CSVLink>
                        </Tooltip>
                      ) : null
                  }
                </span>
                <span ref={myRef}>
                  <span
                    onClick={() => setOpen(!open)}
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={() => setFilterColor(true)}
                    onMouseLeave={() => setFilterColor(false)}
                  >
                    {open && (
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    )}
                    {!open && (
                      <Tooltip placement="left" title="Click here to filter Rates">
                        <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                          <FilterIcon
                            fill={filterColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </Badge>
                      </Tooltip>
                    )}
                  </span>
                  {open && (
                    <FilterWrapper>
                      <Filters
                        filtersGrid={filterRates}
                        filterTypes={filterTypes}
                        handleChange={handleChange}
                        onCheck={onCheck}
                        fiterOperators={fiterOperators}
                        handleOperator={handleOperator}
                        handleValue={handleValue}
                        startDate={startDate}
                        endDate={endDate}
                        currentDate={currentDate}
                        handleFromValue={handleFromValue}
                        handleToValue={handleToValue}
                      />
                      <div className="px-4 mt-2">
                        {
                          filterRates.length < RATES_FILTERS_TYPES.length && (
                            <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                              <SpText
                                fontWeight="600"
                                color="#279dfe"
                                onClick={handleAddClick}
                              >
                                +&nbsp; Add another filter
                              </SpText>
                            </div>
                          )
                        }
                      </div>
                      <div className="d-flex mb-2 justify-content-end mr-3">
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-2"
                          ghost
                          onClick={resetFilters}
                        >
                          Clear
                        </SpButton>
                        <SpButton
                          type="primary"
                          shape="round"
                          onClick={applyFilters}
                        >
                          Save
                        </SpButton>
                      </div>
                    </FilterWrapper>
                  )}
                </span>
              </Col>
            </RBAC>
          </div>
        </Row>

        {/* ADD RATE MODAL */}
        {addRateModal && (
          <AddRate
            visible={addRateModal}
            submitting={submitting}
            phoneCode={phoneCode}
            issuer={issuer}
            category={category}
            transferType={transferType}
            id={id}
            selectedAccount={selectedAccount}
            close={() => setAddRateModal(false)}
            submit={addNewRate}
          />
        )}
        <RBAC
          fallback={(
            <Table
              onRow={(record) => ({
                onClick: () => {
                  selectedRecord(record);
                },
              })}
              columns={columns}
              rowKey={(record) => record.gid}
              dataSource={rates}
              pagination={pagination}
              onChange={handleTableChange}
            />
          )}
          permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
        >
          <Table
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            rowClassName={() => 'ant-table-clickable-row'}
            columns={[...columns]}
            rowKey={(record) => record.gid}
            dataSource={rates}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </RBAC>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  rates: state.issuerrate.content,
  issuer: state.issuer.content,
  category: state.rates.category,
  transferType: state.issuerrate.transferType.content,
  totalElements: state.issuerrate.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
});

const mapDispatchToProps = (dispatch) => ({
  fetchIssuerRates: param => dispatch(issuerrateActions.fetchIssuerRates({
    payload: param,
  })),
  fetchIssuer: param => dispatch(issuerActions.fetchIssuer({
    payload: param,
  })),
  fetchRateCategory: param => dispatch(ratesActions.fetchRateCategory({
    payload: param,
  })),
  fetchTransferType: param => dispatch(issuerrateActions.fetchTransferType({
    payload: param,
  })),
  addIssuerRate: param => dispatch(issuerrateActions.addIssuerRate({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(IssuerRatesList);
