import { EVENT_PAGE_DETAILS } from '../actionTypes';

export const fetchEventPageDetails = (action) => ({
  type: EVENT_PAGE_DETAILS.FETCH,
  payload: action.payload,
});

export const fetchEventPageProductItemDetails = (action) => ({
  type: EVENT_PAGE_DETAILS.PRODUCT_ITEM_FETCH,
  payload: action.payload,
});

export const fetchEventPageOrderPaymentData = (action) => ({
  type: EVENT_PAGE_DETAILS.PAYMENT_FETCH,
  payload: action.payload,
});

export const fetchEventPageCustomTemplates = (action) => ({
  type: EVENT_PAGE_DETAILS.CUSTOM_TEMPLATE_FETCH,
  payload: action.payload,
});

export const removeCustomTemplateEventPage = (action) => ({
  type: EVENT_PAGE_DETAILS.CUSTOM_TEMPLATE_DELETE,
  payload: action.payload,
});

export const updateEventPage = (action) => ({
  type: EVENT_PAGE_DETAILS.UPDATE,
  payload: action.payload,
});

export const deactivateEventPage = (action) => ({
  type: EVENT_PAGE_DETAILS.DEACTIVE,
  payload: action.payload,
});

export const soldOutEventPage = (action) => ({
  type: EVENT_PAGE_DETAILS.SOLDOUT,
  payload: action.payload,
});

export const resumeSalesNon = (action) => ({
  type: EVENT_PAGE_DETAILS.RESUME_SALES,
  payload: action.payload,
});

export const offlinePaymentsNon = (action) => ({
  type: EVENT_PAGE_DETAILS.OFFLINE_PAYMENTS,
  payload: action.payload,
});

export const fetchEventPagesSlug = (action) => ({
  type: EVENT_PAGE_DETAILS.EVENT_PAGE_SLUG_FETCH,
  payload: action.payload,
});

export const fetchOfflineEventPagesPayments = (action) => ({
  type: EVENT_PAGE_DETAILS.FETCH_OFFLINE_PAYMENTS,
  payload: action.payload,
});

export const clearEventPageDetails = (action) => ({
  type: EVENT_PAGE_DETAILS.CLEAR_EVENT_DETAILS,
  payload: action.payload,
});

export const notifyNonSeatedPageDetails = (action) => ({
  type: EVENT_PAGE_DETAILS.PAGE_NOTIFY,
  payload: action.payload,
});

export const notifyNonSeatedPage = (action) => ({
  type: EVENT_PAGE_DETAILS.SEATED_NOTIFY,
  payload: action.payload,
});

export const fetchPromosByEventPage = (action) => ({
  type: EVENT_PAGE_DETAILS.EVENT_PAGE_PROMOS,
  payload: action.payload,
});

export const fetchAllPromosByEventPage = (action) => ({
  type: EVENT_PAGE_DETAILS.EVENT_PAGE_ALL_PROMOS,
  payload: action.payload,
});

export const addPromosByEventPage = (action) => ({
  type: EVENT_PAGE_DETAILS.EVENT_PAGE_PROMO_ADD,
  payload: action.payload,
});

export const updatePromosByEventPage = (action) => ({
  type: EVENT_PAGE_DETAILS.EVENT_PAGE_PROMO_UPDATE,
  payload: action.payload,
});

export const deletePromosByEventPage = (action) => ({
  type: EVENT_PAGE_DETAILS.EVENT_PAGE_PROMO_DELETE,
  payload: action.payload,
});

export const fetchEventPageSecureDetails = (action) => ({
  type: EVENT_PAGE_DETAILS.EVENT_PAGE_OFFLINE_DETAILS_FETCH,
  payload: action.payload,
});

export const fetchEventPageSplitUp = (action) => ({
  type: EVENT_PAGE_DETAILS.EVENT_PAGE_SPLIT_UP_FETCH,
  payload: action.payload,
});

export const createOrderForOfflinePayment = (action) => ({
  type: EVENT_PAGE_DETAILS.EVENT_PAGE_OFFLINE_CREATE_ORDER,
  payload: action.payload,
});
