import React, { useEffect, useState, useRef } from 'react';
import OtpInput from 'react-otp-input';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import { NOTIFICATION } from 'store/actionTypes';
import {
  Input,
  Row,
  Col,
  Tabs,
  Select,
  DatePicker,
  AutoComplete,
  Modal,
  Icon,
  Tooltip,
} from 'antd';
import ReactSelect from 'react-select';
import {
  manageAccountActions,
  fundingSourceActions,
  transferSettingsActions,
  notificationActions,
  transfersActions,
  exchangeRateActions,
} from 'store/actions';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import {
  SpError,
  SpText,
  SpButton,
  SpH5,
  SpForm,
  SpTextMute,
} from 'components/DesignKit';
import Loading from 'components/Loading';
import {
  COUNTRIES,
  MAX_AMOUNT,
  MESSAGES,
  CURRENCY,
  PAGES,
} from 'appconstants';
import Lock from 'assets/imgs/lock.svg';
// import Verified from 'assets/imgs/Verified.svg';
// import VerifiedUser from 'assets/imgs/Verified_User.svg';
import IndividualIcon from 'assets/imgs/Individual_icon.png';
import BusinessIcon from 'assets/imgs/Business_icon.png';
import AccountVerifiedIcon from 'assets/imgs/account-verified-badge.png';
import ExchangeIcon from 'components/NavIcons/exchangeIcon';
import EnabledBadge from 'components/NavIcons/enabledBadge';
import IndiaIcon from 'imgs/India.png';
import UsaIcon from 'imgs/USA.png';
import { informationIcon } from 'components/NavIcons';
import ConfirmCrossBorderModal from './Components/ConfirmCrossBorderModal';

const { TabPane } = Tabs;
const { Option } = Select;

type Props = {
  history: {
    push: Function,
    replace: Function,
  },
  getExchangeRatesList: Function,
  getExchangeRateForAmount: Function,
  fetchSelfAccounts: Function,
  selectedAccount: Object,
  test: boolean,
  manageAccountsList: Array<Object>,
  fetchFundingSourceList: Function,
  fundingSource: Array<Object>,
  fetchTransferSetting: Function,
  transferSettings: Object,
  setNotification: Function,
  confirmTransferOtp: Function,
  issueTransfer: Function,
  issuerGid: Object,
  fetchTransfers: Function,
  validator: Boolean,
  getContactList: Function,
  contactList: Array,
  loading: Boolean,
  exchangeRate: Object,
  exchangeAmount: Object,
};

const SendMoney = (props: Props) => {
  const {
    // history,
    fetchSelfAccounts,
    selectedAccount,
    test,
    manageAccountsList,
    fetchFundingSourceList,
    fundingSource,
    fetchTransferSetting,
    transferSettings,
    setNotification,
    confirmTransferOtp,
    issueTransfer,
    issuerGid,
    fetchTransfers,
    validator,
    getContactList,
    exchangeRate,
    loading,
    history,
    getExchangeRatesList,
    getExchangeRateForAmount,
    exchangeAmount,
  } = props;
  const [manageAccountInfo, setManageAccountInfo] = useState(manageAccountsList);
  const [manageContactInfo, setManageContactInfo] = useState(fundingSource);
  const [manageAccountInfoSelf, setManageAccountInfoSelf] = useState(manageAccountsList);
  const [manageContactInfoSelf, setManageContactInfoSelf] = useState(manageAccountsList);
  const location = useLocation();
  const [, forceUpdate] = useState();
  // eslint-disable-next-line
  const [transfers, setTransfers] = useState(1);
  const [error, setError] = useState(false);
  const [contactName, setContactName] = useState('');
  const [contactCountry, setContactCountry] = useState('');
  const [contactGid, setContactGid] = useState('');
  const [benficiaryName, setBenficiaryName] = useState('');
  const [benficiaryCountry, setBenficiaryCountry] = useState('');
  const [benficiaryGid, setBenficiaryGid] = useState('');
  const [sendMoneyStep, setSendMoneyStep] = useState(0);
  const [transferAmount, setTransferAmount] = useState();
  const [transferDate, setTransferDate] = useState();
  const [transferApiDate, setTransferApiDate] = useState();
  const [transferMode, setTransferMode] = useState('');
  const [remarks, setRemarks] = useState(selectedAccount?.usBusiness?.statementDescriptor ?? '');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [UniqueId, setUniqueId] = useState('');
  const [otp, setOtp] = useState();
  const [accontTransferStep, setAccountTransferStep] = useState(0);
  const debouncedFetchSelfAccounts = debounce(fetchSelfAccounts, 2000, { leading: true });
  const debouncedFetchFundingSourceList = debounce(fetchFundingSourceList, 2000, { leading: true });
  const debouncedFetchTransfers = debounce(fetchTransfers, 2000, { leading: true });
  const currencyType = selectedAccount && selectedAccount.currency;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const disableDate = transferDate;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const supportsSchedule = (selectedAccount.issuer && selectedAccount.issuer.supportsSchedule);
  const { id } = selectedAccount && selectedAccount.currency;
  const minLimit = (transferSettings && transferSettings.minLimit / 100);
  const maxLimit = (transferSettings && transferSettings.maxLimit / 100);
  const [otpConfirm, setOtpConfirm] = useState(false);

  const [contactNameSelf, setContactNameSelf] = useState('');
  const [contactGidSelf, setContactGidSelf] = useState('');
  const [benficiaryContactGid, setBenficiaryContactGid] = useState('');
  const [benficiaryNameSelf, setBenficiaryNameSelf] = useState('');
  const [benficiaryGidSelf, setBenficiaryGidSelf] = useState('');
  const [benficiaryContactGidSelf, setBenficiaryContactGidSelf] = useState('');
  const [transferAmountSelf, setTransferAmountSelf] = useState();
  const [transferDateSelf, setTransferDateSelf] = useState();
  const [transferApiDateSelf, setTransferApiDateSelf] = useState();
  const [transferModeSelf, setTransferModeSelf] = useState('');
  const [remarksSelf, setRemarksSelf] = useState(selectedAccount?.usBusiness?.statementDescriptor ?? '');
  const [crossBorder, setCrossBorder] = useState(false);
  const [confirmCrossBorderModal, setConfirmCrossBorderModal] = useState(false);
  // eslint-disable-next-line
  const [acceptTermsSelf, setAcceptTermsSelf] = useState(false);
  const [UniqueIdSelf, setUniqueIdSelf] = useState('');
  const [otpSelf, setOtpSelf] = useState();
  const [otpConfirmSelf, setOtpConfirmSelf] = useState(false);
  const disableDateSelf = transferDateSelf;
  const [transferTypeLIst, setTransferTypeList] = useState(selectedAccount?.supportedTransferTypes ? selectedAccount?.supportedTransferTypes : []);
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [confirmationSms, setConfirmationSms] = useState('');
  const [confirmationEmailSelf, setConfirmationEmailSelf] = useState('');
  const [confirmationSmsSelf, setConfirmationSmsSelf] = useState('');
  const [noExchangeModal, setNoExchangeModal] = useState(true);
  const exchangeFee = typeof exchangeAmount?.feeAmount === 'number' && exchangeAmount.feeAmount / 100;
  const tax = typeof exchangeAmount?.feeTax === 'number' && exchangeAmount.feeTax / 100;
  const lowVolumeFee = typeof exchangeAmount?.lowVolumeFee === 'number' && exchangeAmount.lowVolumeFee / 100;

  useEffect(() => {
    if (manageAccountsList && manageAccountsList.length > 0) {
      setManageAccountInfo(manageAccountsList);
      setManageAccountInfoSelf(manageAccountsList);
      setManageContactInfoSelf(manageAccountsList);
    }
  }, [manageAccountsList]);

  useEffect(() => {
    if (fundingSource && fundingSource.length > 0) {
      setManageContactInfo(fundingSource);
    }
  }, [fundingSource]);


  useEffect(() => {
    if (transferSettings?.defaultTransferTypeCode) {
      setTransferMode(transferSettings?.defaultTransferTypeCode);
      setTransferModeSelf(transferSettings?.defaultTransferTypeCode);
    }
  }, [transferSettings, validator, otpConfirm, otpConfirmSelf]);
  useEffect(() => {
    if (crossBorder && exchangeRate && exchangeRate?.length === 0) {
      setNoExchangeModal(true);
    } else {
      setNoExchangeModal(false);
    }
  }, [crossBorder, exchangeRate]);

  const debouncedFetchExchangeAmount = debounce(getExchangeRateForAmount, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const size = 1000;
    debouncedFetchSelfAccounts({ currentPage, sortParams, filterParam });
    debouncedFetchFundingSourceList({
      currentPage, sortParams, filterParam, size,
    });
    fetchTransferSetting({ sortParams });
    debouncedFetchTransfers({
      currentPage,
      sortParams,
      filterParam,
    });
    getContactList();
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (exchangeRate[0]?.gid && transferAmount > 0 && transferAmount && crossBorder) {
      const amount = transferAmount * 100;
      const params = `amount=${amount}&transferTypeCode=ACH`;
      const gid = exchangeRate[0]?.gid;
      debouncedFetchExchangeAmount({ gid, params });
    }
  }, [transferAmount, crossBorder, exchangeRate]);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      imps: {
        message: MAX_AMOUNT.MESSAGE_IMPS,
        rule: (val) => (
          MAX_AMOUNT.IMPS_MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.IMPS_MAX_AMOUNT),
      },
      rtgs: {
        message: MAX_AMOUNT.MESSAGE_RTGS,
        rule: (val) => (
          MAX_AMOUNT.RTGS_MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.RTGS_MAX_AMOUNT),
      },
      neft: {
        message: MAX_AMOUNT.MESSAGE_NEFT,
        rule: (val) => (
          MAX_AMOUNT.NEFT_MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.NEFT_MAX_AMOUNT),
      },
      minmaxAmount: {
        rule: (val, params) => {
          if (params && params[0] && val) {
            return Number(params[0].split('&')[0]) <= Number(val)
              && Number(val) <= Number(params[0].split('&')[1]);
          }
          return false;
        },
      },
      remarks: {
        message: 'Special characters are not allowed in Remarks when transfer mode is ACH.',
        rule: (val, params) => {
          if (params[0] === 'ACH') {
            return /^[a-zA-Z0-9\s]*$/.test(val);
          }
          return true;
        },
      },
    },
  }));
  simpleValidator.current.purgeFields();

  useEffect(() => {
    simpleValidator.current.purgeFields();
    if (!validator && otpConfirm) {
      setSendMoneyStep(3);
      setContactName('');
      setContactCountry('');
      setContactGid('');
      setBenficiaryName('');
      setBenficiaryCountry('');
      setBenficiaryGid('');
      setTransferAmount();
      setTransferDate();
      // setTransferApiDate();
      // setTransferInitialDates('TransferApiDate');
      // setTransferMode('');
      setRemarks(selectedAccount?.usBusiness?.statementDescriptor ?? '');
      setAcceptTerms(false);
      setUniqueId('');
      setOtp();
      setCrossBorder(false);
    }
    if (!validator && otpConfirmSelf) {
      setAccountTransferStep(3);
      setContactNameSelf('');
      setContactGidSelf('');
      setBenficiaryNameSelf('');
      setBenficiaryGidSelf('');
      setTransferAmountSelf();
      // setTransferDateSelf();
      // setTransferApiDateSelf();
      // setTransferInitialDates('TransferDateSelf');
      // setTransferModeSelf('');
      setRemarksSelf(selectedAccount?.usBusiness?.statementDescriptor ?? '');
      setAcceptTermsSelf(false);
      setUniqueIdSelf('');
      setOtpSelf();
      setError(false);
    }
  }, [validator]);

  const sendMoney = (
    <div style={{ textAlign: 'left', padding: '0px 10px 5px' }}>
      <span style={{ fontSize: '14px', fontWeight: '600' }}>Send Money</span>
      <div>
        <span style={{ fontSize: '10px', fontWeight: '400' }}>Transfer funds to third parties</span>
      </div>
    </div>
  );

  const accountTransfer = (
    <div style={{ textAlign: 'left', padding: '0px 13px 5px' }}>
      <span style={{ fontSize: '14px', fontWeight: '600' }}>Account Transfers</span>
      <div>
        <span style={{ fontSize: '10px', fontWeight: '400' }}>Between your accounts</span>
      </div>
    </div>
  );

  const updateContactSelf = (value) => {
    const results = manageAccountsList.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setContactNameSelf(`${results[0].issuerBank.bankName} **** ${results[0].issuerBank.lastFour}`);
      setContactGidSelf(results[0].gid);
    }
  };

  const updateBenficiarySelf = (value) => {
    const results = manageAccountsList.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setBenficiaryNameSelf(`${results[0].issuerBank.bankName} **** ${results[0].issuerBank.lastFour}`);
      setBenficiaryGidSelf(results[0].gid);
      setBenficiaryContactGidSelf(results[0].contact.gid);
      setConfirmationEmailSelf(results[0].contact.email);
      setConfirmationSmsSelf(results[0].contact.phoneNumber);
    }
  };

  const updateContact = (value) => {
    const results = manageAccountsList.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setContactName(`${results[0].issuerBank.bankName} **** ${results[0].issuerBank.lastFour}`);
      setContactCountry(results[0]?.issuerBank?.country?.alpha2); // value will be US & IN respectivily
      setContactGid(results[0].gid);
      if (results[0]?.issuerBank?.country?.alpha2 !== benficiaryCountry && benficiaryCountry && !crossBorder) {
        setConfirmCrossBorderModal(true);
      }
    }
  };

  const updateBenficiary = (value) => {
    const results = fundingSource.filter((item) => (item.gid === value?.value));
    if (results && results[0].gid) {
      setBenficiaryName(value);
      setBenficiaryCountry(results[0]?.issuerBank?.country?.alpha2); // value will be US & IN respectivily
      setBenficiaryGid(results[0].gid);
      setConfirmationEmail(results[0].contact.email);
      setConfirmationSms(results[0].contact.phoneNumber);
      setBenficiaryContactGid(results[0].contact.gid);
      if (contactCountry && contactCountry !== results[0]?.issuerBank?.country?.alpha2 && !crossBorder) {
        setConfirmCrossBorderModal(true);
      }
    }
  };

  const onDateChangeSelf = (date, dateString) => {
    setTransferDateSelf(dateString);
    // eslint-disable-next-line
    // const Date = moment(date._d).format(MOMENT_FORMAT);
    // const selectedDate = moment.tz(Date, 'UTC');
    // const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    const utcDate = moment(date).utc().format(MOMENT_FORMAT);
    setTransferApiDateSelf(utcDate);
  };

  const onDateChange = (date, dateString) => {
    setTransferDate(dateString);
    // eslint-disable-next-line
    // const Date = moment(date._d).format(MOMENT_FORMAT);
    // const selectedDate = moment.tz(Date, 'UTC');
    // const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
    const utcDate = moment(date).utc().format(MOMENT_FORMAT);
    setTransferApiDate(utcDate);
  };

  const getOtpSelf = async () => {
    try {
      const { data: { entity: { uniqueId } } } = await axiosAuthInstance.post(API_END_POINTS.TRANSFER_OTP);
      setUniqueIdSelf(uniqueId);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  };

  const addNewTransferSelf = () => {
    const amount = transferAmountSelf.replace(/[^\d]/g, '');
    const approveLimt = (transferSettings && transferSettings.minApprovalLimit);
    if ((Number(amount) > approveLimt) && !selectedAccount.issuer.requiresOtp) {
      getOtpSelf();
      setAccountTransferStep(accontTransferStep + 1);
    } else {
      issueTransfer({
        amount: Number(amount),
        currencyCode: CURRENCY(id),
        fundingSourceGid: benficiaryGidSelf,
        contactGid: benficiaryContactGidSelf,
        payerFundingSourceGid: contactGidSelf,
        description: remarksSelf,
        transferTypeCode: transferModeSelf,
        scheduleDate: supportsSchedule ? transferApiDateSelf : null,
        confirmationEmail: confirmationEmailSelf,
        confirmationSms: confirmationSmsSelf,
        transferMethod: supportsSchedule ? 'AUTOMATIC' : 'MANUAL',
      });
      setOtpConfirmSelf(true);
    }
  };

  const otpTransferSelf = async () => {
    const params = { otpCode: otpSelf };
    if (selectedAccount.issuer.requiresOtp && !test) {
      confirmTransferOtp({
        gid: issuerGid.gid,
        params,
      });
      setOtpConfirmSelf(true);
    } else {
      setOtpConfirmSelf(true);
      const amount = transferAmountSelf.replace(/[^\d]/g, '');
      issueTransfer({
        amount: Number(amount),
        currencyCode: CURRENCY(id),
        fundingSourceGid: benficiaryGidSelf,
        payerFundingSourceGid: contactGidSelf,
        contactGid: benficiaryContactGidSelf,
        description: remarksSelf,
        transferTypeCode: transferModeSelf,
        scheduleDate: supportsSchedule ? transferApiDateSelf : null,
        confirmationEmail: confirmationEmailSelf,
        confirmationSms: confirmationSmsSelf,
        transferMethod: supportsSchedule ? 'AUTOMATIC' : 'MANUAL',
        swirepayOtpGid: UniqueIdSelf,
        otpCode: otpSelf,
      });
    }
  };

  const getOtp = async () => {
    try {
      const { data: { entity: { uniqueId } } } = await axiosAuthInstance.post(API_END_POINTS.TRANSFER_OTP);
      setUniqueId(uniqueId);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  };

  const addNewTransfer = () => {
    const amount = transferAmount.replace(/[^\d]/g, '');
    const approveLimt = (transferSettings && transferSettings.minApprovalLimit);
    if ((Number(amount) > approveLimt) && !selectedAccount.issuer.requiresOtp) {
      getOtp();
      setSendMoneyStep(sendMoneyStep + 1);
      simpleValidator.current.purgeFields();
    } else {
      const CurrencyCode = crossBorder ? (benficiaryCountry === 'IN' ? 'INR' : 'USD') : null;
      issueTransfer({
        amount: Number(amount),
        currencyCode: CURRENCY(id),
        contactGid: benficiaryContactGid,
        payerFundingSourceGid: contactGid,
        fundingSourceGid: benficiaryGid,
        description: remarks,
        transferTypeCode: crossBorder ? 'ACH' : transferMode,
        scheduleDate: supportsSchedule ? transferApiDate : null,
        confirmationEmail,
        confirmationSms,
        transferMethod: supportsSchedule ? 'AUTOMATIC' : 'MANUAL',
        exchangeRateGid: crossBorder ? exchangeRate[0]?.gid : null,
        convertedCurrencyCode: CurrencyCode,
      });
      setOtpConfirm(true);
    }
  };

  const otpTransfer = async () => {
    const params = { otpCode: otp };
    if (selectedAccount.issuer.requiresOtp && !test) {
      confirmTransferOtp({
        gid: issuerGid.gid,
        params,
      });
      setOtpConfirm(true);
    } else {
      setOtpConfirm(true);
      simpleValidator.current.purgeFields();
      const amount = transferAmount.replace(/[^\d]/g, '');
      const CurrencyCode = crossBorder ? (benficiaryCountry === 'IN' ? 'INR' : 'USD') : null;
      issueTransfer({
        amount: Number(amount),
        currencyCode: CURRENCY(id),
        contactGid: benficiaryContactGid,
        payerFundingSourceGid: contactGid,
        fundingSourceGid: benficiaryGid,
        description: remarks,
        transferTypeCode: crossBorder ? 'ACH' : transferMode,
        scheduleDate: supportsSchedule ? transferApiDate : null,
        confirmationEmail,
        confirmationSms,
        transferMethod: supportsSchedule ? 'AUTOMATIC' : 'MANUAL',
        swirepayOtpGid: UniqueId,
        otpCode: otp,
        exchangeRateGid: crossBorder ? exchangeRate[0]?.gid : null,
        convertedCurrencyCode: CurrencyCode,
      });
    }
  };

  const transferFunds = (event) => {
    if (sendMoneyStep === 0) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setSendMoneyStep(sendMoneyStep + 1);
    } else if (sendMoneyStep === 1) {
      addNewTransfer();
    } else if (sendMoneyStep === 2) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      otpTransfer();
    }
  };

  const transferFundsSelf = () => {
    if (accontTransferStep === 0) {
      if (contactNameSelf && benficiaryNameSelf && transferAmountSelf && transferDateSelf && remarksSelf && transferModeSelf) {
        setAccountTransferStep(accontTransferStep + 1);
        setError(false);
      } else {
        setError(true);
      }
    } else if (accontTransferStep === 1) {
      addNewTransferSelf();
    } else if (accontTransferStep === 2) {
      if (otpSelf) {
        otpTransferSelf();
      } else {
        setError(true);
      }
    }
  };

  const onChange = (key) => {
    setTransfers(key);
    if (key === '2') {
      setSendMoneyStep(0);
      if (!crossBorder) {
        setContactName('');
        setContactGid('');
        setBenficiaryName('');
        setBenficiaryGid('');
        setTransferAmount();
        setRemarks(selectedAccount?.usBusiness?.statementDescriptor ?? '');
      }
      // setTransferDate();
      // setTransferApiDate();
      // setTransferInitialDates('TransferApiDate');
      crossBorder ? setTransferMode('ACH') : setTransferMode(transferSettings?.defaultTransferTypeCode);
      setAcceptTerms(false);
      setUniqueId('');
      setOtp();
    }
    if (key === '1') {
      setAccountTransferStep(0);
      setContactNameSelf('');
      setContactGidSelf('');
      setBenficiaryNameSelf('');
      setBenficiaryGidSelf('');
      setTransferAmountSelf();
      // setTransferDateSelf();
      // setTransferApiDateSelf();
      // setTransferInitialDates('TransferDateSelf');
      setTransferModeSelf(transferSettings?.defaultTransferTypeCode);
      setRemarksSelf(selectedAccount?.usBusiness?.statementDescriptor ?? '');
      setAcceptTermsSelf(false);
      setUniqueIdSelf('');
      setOtpSelf();
      setError(false);
    }
  };

  const searchContact = (val) => {
    if (val) {
      const testArray = manageAccountsList.filter((item) => (item.issuerBank.bankName.toLowerCase()).includes(val.toLowerCase()));
      setManageAccountInfo(testArray);
    } else {
      setManageAccountInfo(manageAccountsList);
    }
  };

  const searchContactList = (val) => {
    if (val) {
      const testArray = fundingSource.filter((item) => (item.contact.name.toLowerCase()).includes(val.toLowerCase()));
      setManageContactInfo(testArray);
    } else {
      setManageContactInfo(fundingSource);
    }
  };

  const searchContactSelf = (val) => {
    if (val) {
      const testArray = manageAccountsList.filter((item) => (item.issuerBank.bankName.toLowerCase()).includes(val.toLowerCase()));
      setManageAccountInfoSelf(testArray);
    } else {
      setManageAccountInfoSelf(manageAccountsList);
    }
  };

  const searchContactListSelf = (val) => {
    if (val) {
      const testArray = manageAccountsList.filter((item) => (item.issuerBank.bankName.toLowerCase()).includes(val.toLowerCase()));
      setManageContactInfoSelf(testArray);
    } else {
      setManageContactInfoSelf(manageAccountsList);
    }
  };


  const enableCrossBorder = async () => {
    // CrossBorder is only supported for ACH Payment
    if (!transferTypeLIst.includes('ACH') && !selectedAccount?.allowCrossBorderTransfer) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Enable ACH/CrossBorder in Settings',
      });
      setConfirmCrossBorderModal(false);
      history.push(PAGES.SENDMONEY);
      return;
    }
    // When cross-border functionality is enabled, the "To" contact list should only include contacts from the specified beneficiary country.
    const offshoreContacts = fundingSource.filter((contact) => contact?.issuerBank?.country?.alpha2 === benficiaryCountry);
    setManageContactInfo(offshoreContacts);
    setConfirmCrossBorderModal(false);
    // For fetching the exchange rate, the date should always be sent in UTC format to ensure consistency
    // and avoid any timezone-related discrepancies for Admin level users.
    // However, for transferring funds, the date must be sent in the selectedAccount's timezone,
    // as the transfer process relies on the account's local time zone settings.
    const today = moment();
    setTransferDate(today.format(MOMENT_FORMAT));
    const Date = moment(today.toDate()).utc().format(MOMENT_FORMAT);
    const DateApi = moment(today.toDate()).format(MOMENT_FORMAT);
    const selectedDate = moment.tz(DateApi, selectedAccount.timezone);
    const utcDateAPI = selectedDate.utc().format(MOMENT_FORMAT);
    const selectedDateForExchange = moment.tz(Date, 'UTC');
    const utcExchangeRate = selectedDateForExchange.utc().format(MOMENT_FORMAT);
    setTransferApiDate(utcExchangeRate);
    const getCurrency = (country) => (country === 'IN' ? 'INR' : 'USD');
    const fromCurrency = getCurrency(contactCountry);
    const toCurrency = getCurrency(benficiaryCountry);
    const exchangeRateParams = `date=${utcExchangeRate}&fromCurrency.EQ=${fromCurrency}&toCurrency.EQ=${toCurrency}`;
    getExchangeRatesList({ exchangeRateParams });
    setTransferMode('ACH');
    setCrossBorder(true);
  };

  const resetForNoExchange = () => {
    setCrossBorder(false);
    setTransferMode(transferSettings?.defaultTransferTypeCode);
  };

  const resetTransferSession = () => {
    setSendMoneyStep(0);
    setCrossBorder(false);
    setManageContactInfo(fundingSource);
  };

  const getCurrencySuffix = (country) => {
    if (country === 'IN') {
      return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={IndiaIcon}
            alt="india"
            style={{ width: 16, height: 16, marginRight: 4 }}
          />
          INR
        </span>
      );
    } if (country === 'US') {
      return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={UsaIcon}
            alt="usa"
            style={{ width: 16, height: 16, marginRight: 4 }}
          />
          USD
        </span>
      );
    }
    // Default case, can return null or some other currency
    return null;
  };
  const getCurrencyPrefix = (country) => {
    if (country === 'IN') {
      return '₹';
    }
    if (country === 'US') {
      return '$';
    }
    return null;
  };
  const getConvertedCurrency = (country) => {
    if (country === 'IN') {
      return 'INR';
    }
    if (country === 'US') {
      return 'USD';
    }
    return null;
  };

  if (loading) {
    return <Loading />;
  }

  const reactOptions = manageContactInfo.map((item, key) => {
    const { contact, issuerBank } = item;
    const bankName = issuerBank?.bankName || '';
    return {
      label: `${contact?.name} | ${bankName} **** ${issuerBank?.lastFour} ${issuerBank?.isVerified ? ' (VERIFIED)' : ''}`,
      value: item?.gid,
      issuerBank,
    };
  });

  const CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props;
    const { issuerBank } = data;
    const icon = issuerBank?.businessType === 'INDIVIDUAL'
      ? <img src={IndividualIcon} alt="individual" style={{ width: '15px', height: '15px', marginLeft: '5px' }} />
      : <img src={BusinessIcon} alt="business" style={{ width: '15px', height: '15px', marginLeft: '5px' }} />;
    const verifiedIcon = issuerBank?.status === 'VERIFIED'
      ? <img src={AccountVerifiedIcon} alt="individual" style={{ width: '15px', height: '15px', marginLeft: '5px' }} />
      : null;
    const rtpSupportedIcon = (issuerBank?.rtpsupported !== null && issuerBank?.rtpsupported)
      ? <EnabledBadge style={{ marginLeft: '5px' }} />
      : null;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: 'flex', alignItems: 'center', padding: '10px', maxHeight: '50px',
        }}
      >
        {data.label} {icon} {verifiedIcon} {rtpSupportedIcon}
      </div>
    );
  };

  const CustomSingleValue = (props: any) => {
    const { data } = props;
    const { issuerBank } = data;
    const icon = issuerBank?.businessType === 'INDIVIDUAL'
      ? <img src={IndividualIcon} alt="individual" style={{ width: '15px', height: '15px', marginLeft: '5px' }} />
      : <img src={BusinessIcon} alt="business" style={{ width: '15px', height: '15px', marginLeft: '5px' }} />;
    const verifiedIcon = issuerBank?.status === 'VERIFIED'
      ? <img src={AccountVerifiedIcon} alt="individual" style={{ width: '15px', height: '15px', marginLeft: '5px' }} />
      : null;
    const rtpSupportedIcon = (issuerBank?.rtpsupported !== null && issuerBank?.rtpsupported)
      ? <EnabledBadge style={{ marginLeft: '5px' }} />
      : null;
    return (
      <div style={{ display: 'flex', alignItems: 'center', maxHeight: '50px' }}>
        {data.label} {icon} {verifiedIcon} {rtpSupportedIcon}
      </div>
    );
  };

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      backgroundColor: 'white',
      cursor: 'pointer',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#bfbfbf',
    }),
  };

  return (
    <div
      className="mx-auto mt-5 py-3 px-3"
      style={{ width: 800, height: 'auto' }}
      bodyStyle={{ height: '100%' }}
    >
      <Tabs defaultActiveKey="1" tabPosition="top" onChange={onChange} className="tab-header">
        <TabPane tab={sendMoney} key="1" style={{ backgroundColor: '#FFF', padding: '15px', marginTop: '-15px' }}>
          <>
            {
              sendMoneyStep === 0 && (
                <>
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>From</SpH5></label>
                    <AutoComplete
                      placeholder="Select Account"
                      showSearch
                      className="w-100"
                      onSearch={searchContact}
                      dataSource={manageAccountInfo.map((item, key) => {
                        const bankInfo = `${item.issuerBank.bankName} **** ${item.issuerBank.lastFour}`;
                        const displayDetails = item?.contact?.primary ? `${bankInfo} (Primary)` : bankInfo;
                        return (
                          <Option key={key} value={item.gid} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {displayDetails}
                          </Option>
                        );
                      })}
                      disabled={crossBorder}
                      style={{ height: '40px' }}
                      defaultValue={contactName}
                      onSelect={e => updateContact(e)}
                    />
                    <SpError>
                      {simpleValidator.current.message('account', contactName, 'required')}
                    </SpError>

                  </div>
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>To</SpH5></label>
                    <ReactSelect
                      options={reactOptions}
                      className="w-100"
                      maxMenuHeight={250}
                      value={benficiaryName}
                      onChange={updateBenficiary}
                      styles={customStyles}
                      components={{ Option: CustomOption, SingleValue: CustomSingleValue, DropdownIndicator: () => null }}
                      placeholder="Select Contact"
                    />
                    <SpError>
                      {simpleValidator.current.message('contact', benficiaryName, 'required')}
                    </SpError>
                  </div>
                  {/* -------------------------CROSS BORDER------------------- */}
                  {crossBorder
                    && exchangeRate?.length > 0 && (
                      <>
                        <div className="d-flex">
                          <div className="w-50 mr-3">
                            <label className="d-block"><SpH5>Amount you send</SpH5></label>
                            <div>
                              <Input
                                value={transferAmount}
                                prefix={getCurrencyPrefix(contactCountry)}
                                suffix={getCurrencySuffix(contactCountry)}
                                placeholder="0.00"
                                onChange={(e) => {
                                  const regex = /^\d*\.?\d*$/;
                                  const { value } = e.currentTarget;
                                  if (regex.test(value) || value === '') {
                                    setTransferAmount(e.currentTarget.value);
                                  } else if (!regex.test(value)) {
                                    setTransferAmount(0.00);
                                  }
                                }}
                                onKeyPress={e => {
                                  const keyCode = e.charCode || e.which;
                                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                    e.preventDefault();
                                  }
                                }}
                                onBlur={e => {
                                  if (e.currentTarget.value) {
                                    const value = formatAmount(e.currentTarget.value, currencyType);
                                    setTransferAmount(value);
                                  }
                                }}
                              />
                              {
                                transferSettings && Object.keys(transferSettings).length ? (
                                  <SpTextMute>
                                    Minimum send limit: {prefix} {minLimit}, & Maximum send limit: {prefix} {maxLimit}
                                  </SpTextMute>
                                ) : (
                                  <></>
                                )
                              }
                              {
                                transferSettings && Object.keys(transferSettings).length ? (
                                  <SpError>
                                    {simpleValidator.current.message('amount', transferAmount, 'required|amount')}
                                  </SpError>
                                  // <SpError>
                                  //   {simpleValidator.current.message('amount',
                                  //     transferAmount, `required|minmaxAmount:${minLimit}&${maxLimit}`,
                                  //     { messages: { required: `Amount should be between ${prefix}${minLimit} - ${prefix}${maxLimit}` } })}
                                  // </SpError>
                                ) : (
                                  <SpError>
                                    {simpleValidator.current.message('amount', transferAmount, 'required|amount')}
                                  </SpError>
                                )
                              }
                            </div>
                          </div>
                          <div style={{ marginTop: '12px', marginRight: '8px' }}>
                            <ExchangeIcon />
                          </div>
                          <div className="w-50">
                            <label className="d-block"><SpH5>Amount recipient gets</SpH5></label>
                            <Input
                              value={((exchangeAmount?.convertedAmount ?? 0) / 100).toFixed(2)}
                              prefix={getCurrencyPrefix(benficiaryCountry)}
                              suffix={getCurrencySuffix(benficiaryCountry)}
                              placeholder="0.00"
                              readOnly
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            border: '1px solid #BF671E',
                            marginTop: '10px',
                            marginBottom: '8px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '6px',
                            borderRadius: '6px',
                            backgroundColor: '#faf1df',
                          }}
                        >
                          <SpH5>1 {exchangeRate[0]?.fromCurrency?.name} = {exchangeRate[0]?.rate} {exchangeRate[0]?.toCurrency?.name} </SpH5>
                          <SpTextMute style={{ marginLeft: '3px' }}> Indicative Exchange Rate</SpTextMute>
                        </div>
                        <div
                          style={{
                            border: '1px solid #CBCBCB',
                            marginTop: '10px',
                            marginBottom: '25px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'stretch',
                            padding: '10px',
                          }}
                        >
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <SpTextMute>Bank account (ACH) fee</SpTextMute>
                            <SpTextMute>
                              {(exchangeFee || tax) ? `${getCurrencyPrefix(contactCountry)} ${(exchangeFee + tax)}` : <>&#8211;</>}
                            </SpTextMute>
                          </div>

                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <SpTextMute>Low volume fee
                              <Tooltip placement="right" title={<span style={{ fontSize: '11px' }}>A fee charged if your transaction volume falls below the minimum threshold set by the platform.</span>}>
                                <Icon
                                  style={{
                                    fontSize: '12px',
                                    marginLeft: '3px',
                                    marginBottom: '3px',
                                    cursor: 'pointer',
                                  }}
                                  component={informationIcon}
                                />
                              </Tooltip>
                            </SpTextMute>
                            <SpTextMute>
                              {(typeof lowVolumeFee === 'number' && lowVolumeFee >= 0) ? `${getCurrencyPrefix(contactCountry)} ${lowVolumeFee}` : <>&#8211;</>}
                            </SpTextMute>
                          </div>

                          <hr style={{ margin: '10px 0', border: '0.5px solid #ccc' }} />

                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <SpH5>Total fees</SpH5>
                            <SpH5>
                              {(exchangeFee || tax || lowVolumeFee) && getCurrencyPrefix(contactCountry)} {
                                // Calculate total fees by adding all individual fees, with null/undefined checks.
                                (exchangeFee + tax + lowVolumeFee) || <>&#8211;</>
                              }
                            </SpH5>
                          </div>
                        </div>
                      </>
                  )}

                  {/* noExchangeModal MODAL */}
                  {noExchangeModal && exchangeRate?.length === 0 && (
                    <Modal
                      centered
                      closable={false}
                      visible={noExchangeModal}
                      bodyStyle={{ borderRadius: '10px' }}
                      footer={[
                        <SpButton
                          onClick={() => {
                            setNoExchangeModal(false);
                            resetForNoExchange();
                          }}
                          type="danger"
                        >
                          Close
                        </SpButton>,
                      ]}
                    >
                      <div className="px-4" style={{ display: 'flex', justifyContent: 'center' }}>
                        <SpH5 className="mt-1">Exchange Rate not available for the Date</SpH5>
                      </div>
                    </Modal>
                  )}
                  {/* confirmCrossBorderModal MODAL */}
                  {confirmCrossBorderModal && (
                    <ConfirmCrossBorderModal
                      visible={confirmCrossBorderModal}
                      close={() => { setConfirmCrossBorderModal(false); setBenficiaryName(''); }}
                      submit={enableCrossBorder}
                    />
                  )}
                  {/* -------------------------CROSS BORDER ENDS------------------- */}

                  {!crossBorder
                    && (
                      <div className="mb-3">
                        <label htmlFor=""><SpH5>Amount</SpH5></label>
                        <Input
                          value={transferAmount}
                          prefix={prefix}
                          placeholder="0.00"
                          onChange={(e) => {
                            const regex = /^\d*\.?\d*$/;
                            const { value } = e.currentTarget;
                            if (regex.test(value) || value === '') {
                              setTransferAmount(e.currentTarget.value);
                            } else if (!regex.test(value)) {
                              setTransferAmount(0.00);
                            }
                          }}
                          onKeyPress={e => {
                            const keyCode = e.charCode || e.which;
                            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                              e.preventDefault();
                            }
                          }}
                          onBlur={e => {
                            if (e.currentTarget.value) {
                              const value = formatAmount(e.currentTarget.value, currencyType);
                              setTransferAmount(value);
                            }
                          }}
                        />
                        {
                          transferSettings && Object.keys(transferSettings).length ? (
                            <SpTextMute>
                              Minimum send limit: {prefix} {minLimit}, & Maximum send limit: {prefix} {maxLimit}
                            </SpTextMute>
                          ) : (
                            <></>
                          )
                        }
                        {
                          transferSettings && Object.keys(transferSettings).length ? (
                            <SpError>
                              {simpleValidator.current.message('amount', transferAmount, 'required|amount')}
                            </SpError>
                            // <SpError>
                            //   {simpleValidator.current.message('amount',
                            //     transferAmount, `required|minmaxAmount:${minLimit}&${maxLimit}`,
                            //     { messages: { required: `Amount should be between ${prefix}${minLimit} - ${prefix}${maxLimit}` } })}
                            // </SpError>
                          ) : (
                            <SpError>
                              {simpleValidator.current.message('amount', transferAmount, 'required|amount')}
                            </SpError>
                          )
                        }

                      </div>
                    )}
                  <div className="mb-3">
                    <label htmlFor="">
                      <SpH5>Transfer mode
                        <Tooltip
                          placement="right"
                          title={(
                            <span style={{ fontSize: '11px' }}>1.The Real Time Payment (RTP) network is operated by The Clearing House and is available 24/7 has a maximum transaction limit of $1 million.<br />
                              2.An ACH payment, or Automated Clearing House payment, is an electronic transfer of money between financial institutions that uses the ACH network. ACH Cut off is 3 pm ET
                            </span>
                          )}
                        >
                          <Icon
                            style={{
                              fontSize: '12px',
                              marginLeft: '3px',
                              marginBottom: '3px',
                              cursor: 'pointer',
                            }}
                            component={informationIcon}
                          />
                        </Tooltip>
                      </SpH5>
                    </label>
                    {crossBorder
                      ? (
                        <div className="mr-3" style={{ width: '33%' }}>
                          <SpButton
                            type="primary"
                            style={{ width: '100%' }}
                          >
                            ACH
                          </SpButton>
                        </div>
                      )
                      : (
                        <div className="d-flex">
                          {
                            transferTypeLIst.map((item) => (
                              <div className="mr-3" style={{ width: '33%' }}>
                                <SpButton
                                  type={transferMode === item ? 'primary' : 'secondary'}
                                  style={{ width: '100%' }}
                                  onClick={() => setTransferMode(item)}
                                >
                                  {item === 'RTP' ? 'INSTANT PAYMENT' : item.replace(/_/g, ' ')}
                                </SpButton>
                              </div>
                            ))
                          }
                        </div>
                      )}
                    <SpError>
                      {simpleValidator.current.message('transfer mode', transferMode, 'required')}
                    </SpError>
                  </div>
                  <div className="pb-3">
                    <div className="d-flex">
                      <div className="w-50 mr-3">
                        <label className="d-block"><SpH5>Date</SpH5></label>
                        <div>
                          {crossBorder ? (
                            <DatePicker
                              format={dateFormat}
                              allowClear={false}
                              value={moment(transferDate)}
                              disabled
                            />
                          ) : (
                            <DatePicker
                              format={dateFormat}
                              allowClear={false}
                              onChange={onDateChange}
                              disabledDate={current => current && current < moment().subtract(1, 'days')}
                              value={transferDate ? moment(transferDate) : undefined}
                            />
                          )}
                          <SpError>
                            {simpleValidator.current.message('Transfer Date', transferDate, 'required')}
                          </SpError>
                        </div>
                      </div>
                      <div className="w-50">
                        <label className="d-block"><SpH5>Remarks</SpH5></label>
                        <Input
                          placeholder="Remarks"
                          onChange={(e) => setRemarks(e.currentTarget.value)}
                          value={remarks}
                        />
                        <SpError>
                          {simpleValidator.current.message('remarks', remarks, `required|remarks:${transferMode}`)}
                        </SpError>

                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%', display: 'flex', justifyContent: 'space-between' }}>
                      {crossBorder && exchangeRate.length > 0
                        && (
                          <SpButton
                            type="secondary"
                            style={{ width: '100%', fontSize: '18px', height: '40px' }}
                            onClick={() => window.location.reload()}
                          >
                            Cancel
                          </SpButton>
                        )}
                      <SpButton
                        type="primary"
                        className="ml-2"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={(e) => transferFunds(e)}
                      >
                        Continue
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
            {
              sendMoneyStep === 1 && (
                <>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>From</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{contactName}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>To</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{benficiaryName?.label}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>Amount</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{transferAmount}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>Transfer Mode</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{transferMode === 'RTP' ? 'INSTANT PAYMENT' : transferMode?.replace(/_/g, ' ') || ''}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>Date</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{transferDate}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>Remarks</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{remarks}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  {/* <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={24}>
                      <SpH5><Checkbox
                        className="mr-2"
                        defaultChecked={acceptTerms}
                        onChange={({ target: { checked } }) => {
                          setAcceptTerms(checked);
                        }}
                      /> I accept the <span style={{ color: '#2B5EAD' }}>Terms and Conditions</span>
                      </SpH5>
                    </Col>
                  </Row>
                </SpForm> */}
                  <div className="d-flex mb-3">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="primary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={(e) => transferFunds(e)}
                      >
                        Confirm
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="secondary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={() => setSendMoneyStep(0)}
                      >
                        Back
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
            {
              sendMoneyStep === 2 && (
                <>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <img src={Lock} alt="Lock" />
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px" fontWeight="600">Enter code</SpText>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px">Enter the code sent to {transferSettings && transferSettings.approvalEmail}</SpText>
                      </Col>
                    </Row>
                  </SpForm>

                  {/* {
                    selectedAccount.issuer.requiresOtp ? (
                      <SpForm>
                        <Row type="flex" justify="center" align="middle">
                          <Col span={12}>
                            <SpText fontSize="18px">Enter the code sent to {phoneCode} ******{lastFour}</SpText>
                          </Col>
                        </Row>
                      </SpForm>
                    ) : (!selectedAccount.issuer.requiresOtp && (transferSettings && transferSettings.approvalEmail)) ? (
                      <SpForm>
                        <Row type="flex" justify="center" align="middle">
                          <Col span={12}>
                            <SpText fontSize="18px">Enter the code sent to {transferSettings && transferSettings.approvalEmail}</SpText>
                          </Col>
                        </Row>
                      </SpForm>
                    ) : ''
                  } */}
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <div style={{ marginLeft: '25%' }}>
                          <OtpInput
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span style={{ width: '8px' }} />}
                            inputType="text"
                            inputStyle={{
                              border: '1px solid',
                              borderRadius: '8px',
                              width: '54px',
                              height: '54px',
                              fontSize: '12px',
                              color: '#000',
                              fontWeight: '400',
                              caretColor: 'blue',
                            }}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>

                        {/* <Input
                          type="password"
                          style={{ width: '50%' }}
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.currentTarget.value)}
                        /> */}
                        <SpError>
                          {simpleValidator.current.message('otp', otp, 'required')}
                        </SpError>

                      </Col>
                    </Row>
                  </SpForm>
                  <div className="d-flex mb-3">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="primary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={(e) => transferFunds(e)}
                      >
                        Verify
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
            {
              sendMoneyStep === 3 && (
                <>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px" fontWeight="600">You have successfully completed the transfer</SpText>
                      </Col>
                    </Row>
                  </SpForm>
                  <div className="d-flex mb-3">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="primary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={() => resetTransferSession()}
                      >
                        Make another transaction
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="secondary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={() => resetTransferSession()}
                      >
                        Home
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
          </>
        </TabPane>
        <TabPane tab={accountTransfer} key="2" style={{ backgroundColor: '#FFF', padding: '15px', marginTop: '-15px' }}>
          <>
            {
              accontTransferStep === 0 && (
                <>
                  <div className="mb-3">
                    {
                      error && (
                        <div>
                          <SpError>
                            Please provide the required information
                          </SpError>
                        </div>
                      )
                    }
                    <label htmlFor=""><SpH5>From</SpH5></label>
                    <AutoComplete
                      placeholder="Select Account"
                      showSearch
                      className="w-100"
                      onSearch={searchContactSelf}
                      dataSource={manageAccountInfoSelf.map((item, key) => {
                        const bankInfo = `${item.issuerBank.bankName} **** ${item.issuerBank.lastFour}`;
                        const displayDetails = item?.contact?.primary ? `${bankInfo} (Primary)` : bankInfo;
                        return (
                          <Option key={key} value={item.gid} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {displayDetails}
                          </Option>
                        );
                      })}
                      style={{ height: '40px' }}
                      defaultValue={contactNameSelf}
                      onSelect={e => updateContactSelf(e)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>To</SpH5></label>
                    <AutoComplete
                      placeholder="Select Account"
                      showSearch
                      className="w-100"
                      onSearch={searchContactListSelf}
                      dataSource={manageContactInfoSelf.map((item, key) => {
                        const bankInfo = `${item.issuerBank.bankName} **** ${item.issuerBank.lastFour}`;
                        const displayDetails = item?.contact?.primary ? `${bankInfo} (Primary)` : bankInfo;
                        return (
                          <Option key={key} value={item.gid} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {displayDetails}
                          </Option>
                        );
                      })}
                      style={{ height: '40px' }}
                      defaultValue={benficiaryNameSelf}
                      onSelect={e => updateBenficiarySelf(e)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor=""><SpH5>Amount</SpH5></label>
                    <Input
                      value={transferAmountSelf}
                      prefix={prefix}
                      placeholder="0.00"
                      onChange={(e) => {
                        const regex = /^\d*\.?\d*$/;
                        const { value } = e.currentTarget;
                        if (regex.test(value) || value === '') {
                          setTransferAmountSelf(e.currentTarget.value);
                        } else if (!regex.test(value)) {
                          setTransferAmountSelf(0.00);
                        }
                      }}
                      onKeyPress={e => {
                        const keyCode = e.charCode || e.which;
                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={e => {
                        if (e.currentTarget.value) {
                          const value = formatAmount(e.currentTarget.value, currencyType);
                          setTransferAmountSelf(value);
                        }
                      }}
                    />
                    {
                      transferSettings && Object.keys(transferSettings).length ? (
                        <SpTextMute>
                          Minimum send limit: {prefix} {minLimit}, & Maximum send limit: {prefix} {maxLimit}
                        </SpTextMute>
                      ) : (
                        <></>
                      )
                    }
                    {/* {
                      transferSettings && Object.keys(transferSettings).length ? (
                        <SpError>
                          {simpleValidator.current.message('amount',
                            transferAmountSelf, `required|minmaxAmount:${minLimit}&${maxLimit}`,
                            { messages: { required: `Amount should be between ${prefix}${minLimit} - ${prefix}${maxLimit}` } })}
                        </SpError>
                      ) : (
                        <SpError>
                          {simpleValidator.current.message('amount', transferAmountSelf, 'required|amount')}
                        </SpError>
                      )
                    } */}

                  </div>
                  <div className="mb-3">
                    <label htmlFor="">
                      <SpH5>Transfer mode
                        <Tooltip
                          placement="right"
                          title={(
                            <span style={{ fontSize: '11px' }}>1.The Real Time Payment (RTP) network is operated by The Clearing House and is available 24/7 has a maximum transaction limit of $1 million.<br />
                              2.An ACH payment, or Automated Clearing House payment, is an electronic transfer of money between financial institutions that uses the ACH network. ACH Cut off is 3 pm ET
                            </span>
                          )}
                        >
                          <Icon
                            style={{
                              fontSize: '12px',
                              marginLeft: '3px',
                              marginBottom: '3px',
                              cursor: 'pointer',
                            }}
                            component={informationIcon}
                          />
                        </Tooltip>
                      </SpH5>
                    </label>
                    <div className="d-flex">
                      {
                        transferTypeLIst.map((item) => (
                          <div className="mr-3" style={{ width: '33%' }}>
                            <SpButton
                              type={transferModeSelf === item ? 'primary' : 'secondary'}
                              style={{ width: '100%' }}
                              onClick={() => setTransferModeSelf(item)}
                            >
                              {item === 'RTP' ? 'INSTANT PAYMENT' : item?.replace(/_/g, ' ')}
                            </SpButton>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                  <div className="pb-3">
                    <div className="d-flex">
                      <div className="w-50 mr-3">
                        <label className="d-block"><SpH5>Date</SpH5></label>
                        <div>
                          <DatePicker
                            // value={transferDateSelf ? moment(transferDateSelf) : ''}
                            format={dateFormat}
                            allowClear={false}
                            onChange={onDateChangeSelf}
                            disabledDate={current => current && current < moment().subtract(1, 'days')}
                          />
                          {/* <SpError>
                          {simpleValidator.current.message('Transfer Date', transferDateSelf, 'required')}
                        </SpError> */}

                        </div>
                      </div>
                      <div className="w-50">
                        <label className="d-block"><SpH5>Remarks</SpH5></label>
                        <Input
                          placeholder="Remarks"
                          onChange={(e) => setRemarksSelf(e.currentTarget.value)}
                          value={remarksSelf}
                        />
                        {/* <SpError>
                          {simpleValidator.current.message('remarks', remarksSelf, 'required')}
                        </SpError> */}

                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="primary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={(e) => transferFundsSelf(e)}
                      >
                        Continue
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
            {
              accontTransferStep === 1 && (
                <>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>From</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{contactNameSelf}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>To</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{benficiaryNameSelf}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>Amount</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{transferAmountSelf}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>Transfer Mode</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{transferModeSelf === 'RTP' ? 'INSTANT PAYMENT' : transferModeSelf?.replace(/_/g, ' ') || ''}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>Date</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{transferDateSelf}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={6}>
                        <SpH5>Remarks</SpH5>
                      </Col>
                      <Col span={6}>
                        <SpH5>{remarksSelf}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  {/* <SpForm>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={24}>
                      <SpH5><Checkbox
                        className="mr-2"
                        defaultChecked={acceptTerms}
                        onChange={({ target: { checked } }) => {
                          setAcceptTerms(checked);
                        }}
                      /> I accept the <span style={{ color: '#2B5EAD' }}>Terms and Conditions</span>
                      </SpH5>
                    </Col>
                  </Row>
                </SpForm> */}
                  <div className="d-flex mb-3">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="primary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={(e) => transferFundsSelf(e)}
                      >
                        Confirm
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="secondary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={() => setAccountTransferStep(0)}
                      >
                        Back
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
            {
              accontTransferStep === 2 && (
                <>
                  {
                    error && (
                      <div>
                        <SpError>
                          Plese provide the OTP
                        </SpError>
                      </div>
                    )
                  }
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <img src={Lock} alt="Lock" />
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px" fontWeight="600">Enter code</SpText>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px">Enter the code sent to {transferSettings && transferSettings.approvalEmail}</SpText>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <div style={{ marginLeft: '25%' }}>
                          <OtpInput
                            value={otpSelf}
                            onChange={setOtpSelf}
                            numInputs={6}
                            renderSeparator={<span style={{ width: '8px' }} />}
                            inputType="text"
                            inputStyle={{
                              border: '1px solid',
                              borderRadius: '8px',
                              width: '54px',
                              height: '54px',
                              fontSize: '12px',
                              color: '#000',
                              fontWeight: '400',
                              caretColor: 'blue',
                            }}
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                      </Col>
                    </Row>
                  </SpForm>
                  <div className="d-flex mb-3">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="primary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={(e) => transferFundsSelf(e)}
                      >
                        Verify
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
            {
              accontTransferStep === 3 && (
                <>
                  <SpForm>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={24} style={{ textAlign: 'center' }}>
                        <SpText fontSize="18px" fontWeight="600">You have successfully completed the transfer</SpText>
                      </Col>
                    </Row>
                  </SpForm>
                  <div className="d-flex mb-3">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="primary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={() => setAccountTransferStep(0)}
                      >
                        Make another transaction
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                  <div className="d-flex">
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                    <div style={{ width: '33%' }}>
                      <SpButton
                        type="secondary"
                        style={{ width: '100%', fontSize: '18px', height: '40px' }}
                        onClick={() => setAccountTransferStep(0)}
                      >
                        Home
                      </SpButton>
                    </div>
                    <div style={{ width: '33%' }}>
                      &nbsp;
                    </div>
                  </div>
                </>
              )
            }
          </>
        </TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  manageAccountsList: state.manageAccounts.content,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  fundingSource: state.fundingSource.content,
  transferSettings: state.transferSettings.content,
  issuerGid: state.transfers.issuerGid,
  contactList: state.transfers.contactList,
  loading: state.loading.loading,
  exchangeRate: state.exchangeRate.content,
  exchangeAmount: state.exchangeRate.amount,

});

const mapDispatchToProps = (dispatch) => ({
  fetchSelfAccounts: param => dispatch(manageAccountActions.fetchSelfAccounts({
    payload: param,
  })),
  fetchFundingSourceList: param => dispatch(fundingSourceActions.fetchFundingSourceList({
    payload: param,
  })),
  fetchTransferSetting: gid => dispatch(transferSettingsActions.fetchTransferSetting({
    payload: gid,
  })),
  issueTransfer: param => dispatch(transfersActions.issueTransfer({
    payload: param,
  })),
  confirmTransferOtp: param => dispatch(transfersActions.confirmTransferOtp({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchTransfers: param => dispatch(transfersActions.fetchTransfers({
    payload: param,
  })),
  getContactList: param => dispatch(transfersActions.getContactList({
    payload: param,
  })),
  getExchangeRatesList: param => dispatch(exchangeRateActions.getExchangeRatesList({
    payload: param,
  })),
  getExchangeRateForAmount: param => dispatch(exchangeRateActions.getExchangeRateForAmount({
    payload: param,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(SendMoney);
