import React, { useState, useRef } from 'react';
import {
  Row,
  Col,
  Input,
  Switch,
  Radio,
  Tooltip,
  Icon,
} from 'antd';
import {
  SpError,
  SpButton,
  SpH5,
  SpText,
} from 'components/DesignKit';

import SimpleReactValidator from 'simple-react-validator';
import {
  MAX_AMOUNT,
} from 'appconstants';
import { validationRegex } from 'utils/validationMessages';
import { informationIcon } from 'components/NavIcons';

const RadioGroup = Radio.Group;

type Props = {
  updateSetting: Function,
  transferSettings: Object,
  isIndia: Boolean,
  prefix: String,
  transferTypes: Array,
};

const Edit = (props: Props) => {
  const {
    updateSetting,
    isIndia,
    transferSettings,
    prefix,
    transferTypes,
  } = props;
  const [, forceUpdate] = useState();
  const [email, setEmail] = useState(transferSettings ? (transferSettings.approvalEmail) : '');
  const [minLimt, setMinLimt] = useState(transferSettings ? (transferSettings.minLimit / 100).toFixed(2) : '');
  const [maxLimt, setMaxLimt] = useState(transferSettings ? (transferSettings.maxLimit / 100).toFixed(2) : '');
  const [approveLimt, setApproveLimt] = useState(transferSettings ? (transferSettings.minApprovalLimit / 100).toFixed(2) : '');
  const [enableSmartRouting, setEnableSmartRouting] = useState(transferSettings?.enableSmartRouting ?? false);
  const [showActiveInvoice, setShowActiveInvoice] = useState(transferSettings?.showActiveInvoice ?? true);
  const [defaultTransferTypeCode, setDefaultTransferTypeCode] = useState(transferSettings?.defaultTransferTypeCode ?? []);


  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const formatMinAmount = (e) => {
    const { value } = e.currentTarget;
    const amount = parseFloat(value);
    if (Number(amount)) {
      setMinLimt(parseInt(amount, 10).toFixed(2));
    }
  };

  const updateMinAmount = (e) => {
    const { value } = e.currentTarget;
    if (validationRegex.amountRegex.test(value) || value === '') {
      setMinLimt(value);
    } else if (!validationRegex.amountRegex.test(value)) {
      setMinLimt(0);
    }
  };

  const updateMaxAmount = (e) => {
    const { value } = e.currentTarget;
    if (validationRegex.amountRegex.test(value) || value === '') {
      setMaxLimt(value);
    } else if (!validationRegex.amountRegex.test(value)) {
      setMaxLimt(0);
    }
  };

  const formatMaxAmount = (e) => {
    const { value } = e.currentTarget;
    const amount = parseFloat(value);
    if (Number(amount)) {
      setMaxLimt(parseInt(amount, 10).toFixed(2));
    }
  };

  const formatApproveAmount = (e) => {
    const { value } = e.currentTarget;
    const amount = parseFloat(value);
    if (Number(amount)) {
      setApproveLimt(parseInt(amount, 10).toFixed(2));
    }
  };

  const updateApproveAmount = (e) => {
    const { value } = e.currentTarget;
    if (validationRegex.amountRegex.test(value) || value === '') {
      setApproveLimt(value);
    } else if (!validationRegex.amountRegex.test(value)) {
      setApproveLimt(0);
    }
  };


  const updateTransferSettings = () => {
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    updateSetting({
      email,
      minLimt,
      maxLimt,
      approveLimt,
      defaultTransferTypeCode,
      enableSmartRouting,
      showActiveInvoice,
    });
  };

  const isSmartRoute = (value) => {
    setEnableSmartRouting(value);
  };
  const isShowActiveInvoice = (value) => {
    setShowActiveInvoice(value);
  };

  const onRadioChange = (e) => {
    setDefaultTransferTypeCode(e.target.value);
    if (e.target.value === 'RTP') {
      setEnableSmartRouting(true);
    } else {
      setEnableSmartRouting(transferSettings?.enableSmartRouting ?? false);
    }
  };

  return (
    <>
      <Row className="my-2 mb-4 mt-4">
        <Col>
          <SpText className="text-uppercase" fontSize="14px" fontWeight="700" color="#999999">
            LIVE TRANSFER SETTINGS
          </SpText>
        </Col>
      </Row>
      <>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Minimum Limit</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="0.00"
              prefix={prefix}
              value={minLimt && minLimt}
              onBlur={formatMinAmount}
              onChange={updateMinAmount}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('min limit', minLimt, 'required|amount',
                { messages: { required: 'The min limit field is required.' } })}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" className="mb-4 mt-1" justify="start" align="middle">
          <Col span={4}>
            <SpH5>&nbsp;</SpH5>
          </Col>
          <Col span={8}>
            Minimum limit should be {prefix}1 and above
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Maximum Limit</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="0.00"
              prefix={prefix}
              value={maxLimt && maxLimt}
              onBlur={formatMaxAmount}
              onChange={updateMaxAmount}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('max limit', maxLimt, 'required|amount',
                { messages: { required: 'The max limit field is required.' } })}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" className="mb-4 mt-1" justify="start" align="middle">
          <Col span={4}>
            <SpH5>&nbsp;</SpH5>
          </Col>
          <Col span={8}>
            Maximum limit should be under {prefix}100,000
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Minimum Approval Limit</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="0.00"
              prefix={prefix}
              value={approveLimt && approveLimt}
              onBlur={formatApproveAmount}
              onChange={updateApproveAmount}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('min approve limit', approveLimt, 'required|amount',
                { messages: { required: 'The min approve limit field is required.' } })}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" className="mb-4 mt-1" justify="start" align="middle">
          <Col span={4}>
            <SpH5>&nbsp;</SpH5>
          </Col>
          <Col span={8}>
            Approval limit should be {prefix}1 and above
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Approval Email</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Enter Email"
              defaultValue={email}
              onChange={e => setEmail(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Email', email, 'required|email|checkLowerCase')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" className="mb-4 mt-1" justify="start" align="middle">
          <Col span={4}>
            <SpH5>&nbsp;</SpH5>
          </Col>
          <Col span={16}>
            Email to send approval links (Administrators can always approve via Dashboard).
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle" className="my-3">
          <Col span={4}>
            <SpH5>Display Active Invoice</SpH5>
          </Col>
          <Col span={6}>
            <Switch
              className="mr-2 ml-2"
              checked={showActiveInvoice}
              onChange={isShowActiveInvoice}
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Enable Smart Routing</SpH5>
            <Tooltip
              placement="right"
              title={(
                <span style={{ fontSize: '11px' }}>If routing number is not eligible for instant payment it would automatically
                  route to Next Day or Standard ACH and any applicable fees will be recalculated accordingly.
                </span>
)}
            >
              <Icon
                style={{
                  fontSize: '12px',
                  marginLeft: '5px',
                  marginBottom: '3px',
                  cursor: 'pointer',
                }}
                component={informationIcon}
              />
            </Tooltip>
          </Col>
          <Col span={6}>
            <Switch
              className="mr-2 ml-2"
              checked={enableSmartRouting}
              onChange={isSmartRoute}
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle" className="mt-3">
          <Col span={4}>
            <SpH5>Default Transfer Type</SpH5>
          </Col>
          <Col span={20}>
            <RadioGroup value={defaultTransferTypeCode} onChange={onRadioChange}>
              {transferTypes.map((item) => (
                <Radio value={item}>
                  {item === 'RTP' ? 'INSTANT PAYMENT' : item.replace(/_/g, ' ')}
                </Radio>
              ))}
            </RadioGroup>
          </Col>
        </Row>
        <SpError>
          {simpleValidator.current.message('Default Transfer Type', defaultTransferTypeCode, 'required')}
        </SpError>
      </>
      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={updateTransferSettings}
          >
            Update
          </SpButton>
        </Col>
      </Row>
    </>
  );
};

export default React.memo(Edit);
