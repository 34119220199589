import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Input,
  Select,
  Tabs,
} from 'antd';
import {
  SpForm,
  SpError,
  SpButton,
  SpText,
} from 'components/DesignKit';
import { notificationActions } from 'store/actions';
import { useLocation } from 'react-router-dom';
import getStateList from 'utils/stateList';
import formatAmount from 'utils/formatAmount';
import Loading from 'components/Loading';
import { validators, validationRegex } from 'utils/validationMessages';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { API_END_POINTS, axiosAuthInstance } from 'api';
import SimpleReactValidator from 'simple-react-validator';
import {
  COUNTRY_CODES,
  MESSAGES,
} from 'appconstants';
import { NOTIFICATION } from 'store/actionTypes';

const { TabPane } = Tabs;
const { Option } = Select;

type Props = {
    test: boolean,
    selectedAccount: Object,
    setNotification: Function,
};

const Virtualterminal = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    test,
    setNotification,
  } = props;
  const [, forceUpdate] = useState();
  const [cardNumber, setCardNumber] = useState('');
  const [cardType, setCardType] = useState('');
  const [cardLength, setCardLength] = useState(16);
  const [hasCardErrors, setHasCardErrors] = useState(false);
  const [expiry, setExpiry] = useState('');
  const [hasExpiryErrors, setHasExpiryErrors] = useState(false);
  const [cvv, setCvv] = useState('');
  const [hasCvvError, setCvvHasError] = useState(false);
  const [name, setName] = useState('');
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const countryName = selectedAccount && selectedAccount.country && selectedAccount.country.name;
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [eventAmount, setAmount] = useState(null);
  const countryId = selectedAccount && selectedAccount.country.alpha2;
  const postalCodeRegex = countryId === 'US' ? /^[0-9][0-9]{4}$/ : /^[1-9][0-9]{5}$/;
  const prefix = selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix;
  const currencyType = selectedAccount && selectedAccount.currency;
  const [loading, setLoading] = useState(false);
  const [transferType, setTransferType] = useState('card');
  const [accountType, setAccountType] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [confirmAccountNumber, setConfirmAccountNumber] = useState();
  const [routingNumber, setRoutingNumber] = useState('');
  const [bankName, setBankName] = useState('');
  const accountRegex = /[0-9]{6,17}/;
  const routingRegex = /[0-9]{9}/;
  const upiRegex = /^\w.+@\w+$/;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      confirmAccount: {
        message: MESSAGES.BANK_DETAILS.MATCH,
        rule: (val, params) => val === params[0],
      },
      accountNumber: {
        message: MESSAGES.BANK_DETAILS.VALID,
        rule: val => accountRegex.test(val),
      },
      bankRoutingNumber: {
        message: MESSAGES.BANK_DETAILS.ROUTING,
        rule: val => routingRegex.test(val),
      },
      bankName: {
        message: validators.bussiness.validBankName,
        rule: val => validationRegex.nameRegex.test(val),
      },
      stringLength: {
        message: 'please enter a valid bank name',
        rule: val => val.length >= 4,
      },
      upiNumber: {
        message: MESSAGES.BANK_DETAILS.UPI,
        rule: val => upiRegex.test(val),
      },
    },
  }));

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
    }
  }, [location, test]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
    }
  }, [selectedAccount]);

  // useEffect(() => {
  //     if (!validator) {
  //         setEditSettings(false);
  //     }
  // }, [validator]);

  const validateCardType = () => {
    const maxLength = cardNumber.substring(0, cardLength);
    const visaCard = ['4'];
    const masterCard = ['51', '52', '53', '54', '55'];
    const dinersCard = ['36', '38', '54', '55'];
    const cartCard = ['300', '301', '302', '303', '304', '305'];
    const amexCard = ['34', '37'];
    const discoverCard = ['60', '62', '64', '65'];
    const jcbCard = ['35'];
    const enRouteCard = ['2014', '2149'];
    const soloCard = ['6334', '6767'];
    const masteroCard = [
      '5018',
      '5020',
      '5038',
      '6304',
      '6759',
      '6761',
      '6762',
      '6763',
    ];
    const visaElectronCard = ['4026', '417500', '4508', '4844', '4913', '4917'];
    const laserCard = ['6304', '6706', '6771', '6709'];

    if (
      (maxLength.length === 13 || maxLength.length === 16)
          && visaCard.includes(maxLength.substring(0, 1))
    ) {
      setCardType('VISA');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
          && masterCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('MASTER');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 14 || maxLength.length === 16)
          && dinersCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('DINERS CLUB');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 14
          && cartCard.includes(maxLength.substring(0, 3))
    ) {
      setCardType('CARTE BLANCHE');
      setCardLength(14);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 15
          && amexCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('AMEX');
      setCardLength(15);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
          && discoverCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('DISCOVER');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
          && jcbCard.includes(maxLength.substring(0, 2))
    ) {
      setCardType('JCB');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 15
          && enRouteCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('ENROUTE');
      setCardLength(15);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 16
            || maxLength.length === 18
            || maxLength.length === 19)
          && soloCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('SOLO');
      setCardLength(19);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 12
            || maxLength.length === 13
            || maxLength.length === 14
            || maxLength.length === 15
            || maxLength.length === 16
            || maxLength.length === 18
            || maxLength.length === 19)
          && masteroCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('MAESTRO');
      setCardLength(19);
      setHasCardErrors(false);
    } else if (
      maxLength.length === 16
          && visaElectronCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('VISA ELECTRON');
      setCardLength(16);
      setHasCardErrors(false);
    } else if (
      (maxLength.length === 16
            || maxLength.length === 18
            || maxLength.length === 19)
          && laserCard.includes(maxLength.substring(0, 4))
    ) {
      setCardType('LASER CARD');
      setCardLength(19);
      setHasCardErrors(false);
    } else if (maxLength.length > 1) {
      setCardType('');
      setHasCardErrors(true);
    }
  };

  const validateCard = (text) => {
    const sample = text.replace(/[^\d]/g, '');
    const maxLength = sample.substring(0, cardLength);
    if (maxLength.length > 0) {
      const joy = maxLength.match(/.{1,4}/g);
      setCardNumber(joy.join(' '));
    } else {
      setCardNumber('');
      setCardType('');
      setHasCardErrors(false);
    }
  };

  const formatCardDate = input => {
    const text = input
      .replace(
        /[^0-9]/g,
        '', // To allow only numbers
      )
      .replace(
        /^([2-9])$/g,
        '0$1', // To handle 3 > 03
      )
      .replace(
        /^(1{1})([3-9]{1})$/g,
        '0$1/$2', // 13 > 01/3
      )
      .replace(
        /^0{1,}/g,
        '0', // To handle 00 > 0
      )
      .replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/g,
        '$1/$2', // To handle 113 > 11/3
      );
    setExpiry(text);
    if (text.length === 5) {
      setHasExpiryErrors(false);
    } else if (text.length === 7) {
      setHasExpiryErrors(false);
    } else if (text.length !== 5) {
      setHasExpiryErrors(true);
    }
  };

  const validateCvv = text => {
    const sample = text.replace(/[^\d]/g, '');
    const maxLength = sample.substring(0, 4);
    setCvv(maxLength);
    if (maxLength.length === 3 || maxLength.length === 4) {
      setCvvHasError(false);
    } else if (maxLength.length !== 3 || maxLength.length !== 4) {
      setCvvHasError(true);
    }
  };

  const addSupportSettings = async (event) => {
    event.preventDefault();
    if (transferType === 'card') {
      simpleValidator.current.fields.Name = true;
      simpleValidator.current.fields['account holder name'] = true;
      simpleValidator.current.fields['account number'] = true;
      simpleValidator.current.fields['account type'] = true;
      simpleValidator.current.fields['bank name'] = true;
      simpleValidator.current.fields['routing number'] = true;
    }
    if (transferType === 'ach') {
      simpleValidator.current.fields.Name = true;
      simpleValidator.current.fields['card number'] = true;
      simpleValidator.current.fields['cvv number'] = true;
      simpleValidator.current.fields['expiry date'] = true;
    }
    const formValid = simpleValidator.current.allValid();
    if (transferType === 'card') {
      if (!formValid || hasCardErrors || hasCvvError || hasExpiryErrors) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
    }
    if (transferType === 'ach') {
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
    }
    let paymentInfo = {};
    if (transferType === 'card') {
      const yearOfExpiry = expiry.split('/')[1];
      let expiryYear = '';
      if (yearOfExpiry.length === 2) {
        expiryYear = `20${yearOfExpiry}`;
      } else if (yearOfExpiry.length === 4) {
        expiryYear = yearOfExpiry;
      }
      paymentInfo = {
        type: 'CARD',
        postalCode: zipCode,
        card: {
          cvv,
          name,
          number: cardNumber.replaceAll(' ', ''),
          expiryMonth: expiry.split('/')[0],
          expiryYear,
        },
        paymentMethodBillingAddress: {
          street: stateName,
          city: cityName,
          state: stateName,
          countryCode: countryId,
        },
      };
    }
    if (transferType === 'ach') {
      paymentInfo = {
        type: 'ACH_LEGACY',
        postalCode: zipCode,
        bank: {
          accountType,
          routingNumber,
          accountNumber,
          bankName,
          name: accountHolderName,
        },
        paymentMethodBillingAddress: {
          street: stateName,
          city: cityName,
          state: stateName,
          countryCode: countryId,
        },
      };
    }
    const paymentData = {
      amount: eventAmount.replace(/[^\d]/g, ''),
      currency: currencyType?.name,
      paymentMethod: paymentInfo,
    };
    setLoading(true);
    try {
      const { data: { entity } } = await axiosAuthInstance.post(`${API_END_POINTS.VIRTUAL_TERMINAL}`, paymentData);
      if (entity?.paymentSession?.status === 'SUCCEEDED') {
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: 'Payment added successfully.',
        });
      } else if (entity?.paymentSession?.status !== 'SUCCEEDED') {
        setNotification({
          type: NOTIFICATION.ERROR,
          payload: entity?.paymentSession?.errorDescription,
        });
      }
      setCardNumber('');
      setName('');
      setExpiry('');
      setAmount(null);
      setCardType('');
      setCardLength(16);
      setHasCardErrors(false);
      setHasExpiryErrors(false);
      setCvvHasError(false);
      setName('');
      setStreetName('');
      setCityName('');
      setStateName('');
      setZipCode('');
      setCvv('');
      setAccountType('');
      setAccountHolderName('');
      setAccountNumber('');
      setRoutingNumber('');
      setBankName('');
      setConfirmAccountNumber('');
      simpleValidator.current.purgeFields();
      simpleValidator.current.hideMessages();
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: e.response.data && e.response.data.message,
      });
      simpleValidator.current.purgeFields();
      simpleValidator.current.hideMessages();
    } finally {
      setLoading(false);
    }
  };

  const onChange = (key) => {
    if (key === '1') {
      setTransferType('card');
    }
    if (key === '2') {
      setTransferType('ach');
    }
    setAccountType('');
    setAccountHolderName('');
    setAccountNumber('');
    setRoutingNumber('');
    setBankName('');
    setCardNumber('');
    setName('');
    setExpiry('');
    setName('');
    setCvv('');
    setConfirmAccountNumber('');
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card className="mb-2">
      <Tabs defaultActiveKey="1" tabPosition="top" onChange={onChange} className="tab-header">
        <TabPane tab="CARD INFORMATION" key="1">
          <Row className="my-2 mb-3">
            <Col>
              <SpText className="text-uppercase" fontSize="20px">
                Card Details
              </SpText>
            </Col>
          </Row>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Name on Card</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Name on card"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
                {
                  transferType === 'card' ? (
                    <SpError>
                      {simpleValidator.current.message('Name', name, 'required|alpha_space')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Card Information</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Enter Your Card Number"
                  onChange={(e) => validateCard(e.currentTarget.value)}
                  value={cardNumber}
                  suffix={cardType}
                  onBlur={validateCardType}
                />
                {
                  transferType === 'card' ? (
                    <>
                      {
                        hasCardErrors ? (
                          <SpError>
                            Invalid Card Number
                          </SpError>
                        ) : (
                          <SpError>
                            {simpleValidator.current.message('card number', cardNumber, 'required')}
                          </SpError>
                        )
                      }
                    </>
                  ) : (
                    <></>
                  )
                }
                <div className="d-flex mt-3">
                  <Input
                    placeholder="MM/YYYY"
                    onChange={(e) => formatCardDate(e.currentTarget.value)}
                    value={expiry}
                    autocomplete="false"
                  />
                  <Input
                    placeholder="Cvv"
                    onChange={(e) => validateCvv(e.currentTarget.value)}
                    value={cvv}
                    autoComplete="new-password"
                    style={{ webkitTextSecurity: 'disc' }}
                  />
                </div>
                {
                  transferType === 'card' ? (
                    <>
                      <div>
                        {
                          hasExpiryErrors ? (
                            <SpError>
                              Invalid Expiry Date
                            </SpError>
                          ) : (
                            <SpError>
                              {simpleValidator.current.message('expiry date', expiry, 'required')}
                            </SpError>
                          )
                        }
                      </div>
                      <div>
                        {
                          hasCvvError ? (
                            <SpError>
                              Invalid Cvv
                            </SpError>
                          ) : (
                            <SpError>
                              {simpleValidator.current.message('cvv number', cvv, 'required')}
                            </SpError>
                          )
                        }
                      </div>
                    </>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
          </SpForm>
        </TabPane>
        <TabPane tab="BANK INFORMATION" key="2">
          <Row className="my-2 mb-3">
            <Col>
              <SpText className="text-uppercase" fontSize="20px">
                BANK Details
              </SpText>
            </Col>
          </Row>
          <SpForm>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Account Type</span>
              </Col>
              <Col span={6}>
                {
                countryId === 'US' ? (
                  <Select
                    className="w-100"
                    showSearch
                    value={accountType || 'Account Type'}
                    onChange={(value) => {
                      setAccountType(value);
                    }}
                  >
                    <Option key={1} value="CHECKING">CHECKING</Option>
                    <Option key={2} value="SAVINGS">SAVINGS</Option>
                  </Select>
                ) : (
                  <Select
                    className="w-100"
                    showSearch
                    value={accountType || 'Account Type'}
                    onChange={(value) => {
                      setAccountType(value);
                    }}
                  >
                    <Option key={1} value="CURRENT">CURRENT ACCOUNT</Option>
                    <Option key={2} value="SAVINGS">SAVINGS</Option>
                  </Select>
                )
              }
                {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('account type', accountType, 'required')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Account Holder Name</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Name"
                  value={accountHolderName}
                  onChange={(e) => setAccountHolderName(e.currentTarget.value)}
                />
                {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('account holder name', accountHolderName, 'required|alpha_space')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Account Number</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Account Number"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.currentTarget.value)}
                />
                {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('account number', accountNumber, 'required|accountNumber')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Retype Account Number</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Confirm Account Number"
                  value={confirmAccountNumber}
                  onChange={(e) => setConfirmAccountNumber(e.currentTarget.value)}
                />
                {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('account number', confirmAccountNumber, `required|confirmAccount:${accountNumber}`)}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
            {
              countryId === 'US' ? (
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Routing Number</span>
                  </Col>
                  <Col span={6}>
                    <Input
                      placeholder="Routing Number"
                      value={routingNumber}
                      onChange={(e) => setRoutingNumber(e.currentTarget.value)}
                    />
                    {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('routing number', routingNumber, 'required|bankRoutingNumber')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
                  </Col>
                </Row>
              ) : (
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>IFSC Code</span>
                  </Col>
                  <Col span={6}>
                    <Input
                      placeholder="IFSC Code"
                      value={routingNumber}
                      onChange={(e) => setRoutingNumber((e.currentTarget.value).toUpperCase())}
                    />
                    {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('ifsc code', routingNumber, 'required')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
                  </Col>
                </Row>
              )
            }
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Bank Name</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Bank Name"
                  value={bankName}
                  onChange={(e) => setBankName(e.currentTarget.value)}
                />
                {
                  transferType === 'ach' ? (
                    <SpError>
                      {simpleValidator.current.message('bank name', bankName, 'required|bankName|stringLength')}
                    </SpError>
                  ) : (
                    <></>
                  )
                }
              </Col>
            </Row>
          </SpForm>
        </TabPane>
      </Tabs>
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Billing Address
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Street</span>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Street Address"
              value={streetName}
              onChange={(e) => setStreetName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('street', streetName, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>City</span>
          </Col>
          <Col span={6}>
            <Input
              placeholder="City"
              value={cityName}
              onChange={(e) => setCityName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('city', cityName, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>State</span>
          </Col>
          <Col span={6}>
            <Select
              className="w-100"
              placeholder="State"
              showSearch
              optionFilterProp="children"
              value={stateName || 'State'}
              onChange={(e) => setStateName(e)}
            >
              {
                statesInCountry.map(s => (
                  <Option
                    key={s.id}
                    value={s.id}
                    className="text-capitalize"
                  >
                    {s.name}
                  </Option>
                ))
              }
            </Select>
            <SpError>
              {simpleValidator.current.message('state', stateName, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Country</span>
          </Col>
          <Col span={6}>
            <Select
              defaultValue={countryName}
              style={{ width: '100%' }}
              disabled
            >
              {COUNTRY_CODES.map((item) => (
                <Option key={item.ID} value={item.ALPHA2}>
                  {item.NAME}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Postal Code</span>
          </Col>
          <Col span={6}>
            <Input
              placeholder="Postal Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('postal code', zipCode, 'required|postalcode')}
            </SpError>
          </Col>
        </Row>
      </SpForm>
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Transaction Details
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Col span={4}>
          <span>Amount</span>
        </Col>
        <Col span={6}>
          <Input
            placeholder="0.00"
            value={eventAmount}
            prefix={prefix}
            onChange={(e) => setAmount(e.currentTarget.value)}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
            onBlur={e => {
              if (e.currentTarget.value) {
                const value = formatAmount(e.currentTarget.value, currencyType);
                setAmount(value);
              }
            }}
          />
          <p className="text-muted" style={{ fontSize: '10px', textAlign: 'end' }}>
            Minimum amount {prefix} 10.00
          </p>
          <SpError>
            {simpleValidator.current.message('amount', eventAmount, 'required')}
          </SpError>
        </Col>

      </SpForm>
      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={(e) => addSupportSettings(e)}
          >
            Save
          </SpButton>
        </Col>
      </Row>
    </Card>
  );
};

const mapStateToProps = state => ({
  selectedAccount: state.account.selectedAccount,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  validator: state.loading.validator,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Virtualterminal);
