// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  DatePicker,
  Input,
  Modal,
  Select,
} from 'antd';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError, SpButton } from 'components/DesignKit';

const { Option } = Select;

type Props = {
  close: Function,
  submit: Function,
  visible: boolean,
  selectedTransfer: Object,
  submitting: boolean,
};

const UpdatePayment = (props: Props) => {
  const {
    visible,
    close,
    submit,
    selectedTransfer,
    submitting,
  } = props;

  const [, forceUpdate] = useState();
  const gid = selectedTransfer && selectedTransfer.gid;
  const [status, setStatus] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [errorCode, setErrorCode] = useState('');
  const [errorDescription, setErrorDescription] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator());
  const [beneficiaryEtaDisplay, setBeneficiaryEtaDisplay] = useState();
  const [beneficiaryEta, setBeneficiaryEta] = useState();
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DD';


  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      gid,
      status,
      referenceId,
      errorCode,
      errorDescription,
      beneficiaryEta,
    });
  };
  const onDateChange = (date, dateString) => {
    setBeneficiaryEtaDisplay(dateString);
    const utcDate = moment(date).utc().format(MOMENT_FORMAT);
    setBeneficiaryEta(utcDate);
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Update</SpButton>,
      ]}
      title="Update Transfer"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label><SpH5>Transfer Status</SpH5></label>
          <Select
            className="w-100"
            placeholder="Select Tranfer Status"
            onChange={(e) => setStatus(e)}
          >
            <Option key={1} value="SUCCEEDED">Succeeded</Option>
            <Option key={2} value="DECLINED">Declined</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('status', status, 'required')}
          </SpError>
        </div>
        <div className={status === 'DECLINED' ? 'mb-3' : ''}>
          <label htmlFor=""><SpH5>Reference ID</SpH5></label>
          <Input
            placeholder="Enter Reference ID"
            value={referenceId}
            onChange={(e) => setReferenceId(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('reference id', referenceId, 'required')}
          </SpError>
        </div>
        <div className="mt-2">
          <label><SpH5>Beneficiary ETA</SpH5></label>
          <DatePicker
            format={dateFormat}
            allowClear={false}
            onChange={onDateChange}
            // disabledDate={current => current && current < moment().subtract(1, 'days')}
            value={beneficiaryEtaDisplay ? moment(beneficiaryEtaDisplay) : undefined}
          />
        </div>
        {
          status === 'DECLINED' && (
            <>
              <div className="mb-3">
                <label htmlFor=""><SpH5>Error Code</SpH5></label>
                <Input
                  placeholder="Enter Error Code"
                  value={errorCode}
                  onChange={(e) => setErrorCode(e.currentTarget.value)}
                />
              </div>
              <div>
                <label htmlFor=""><SpH5>Error Description</SpH5></label>
                <Input
                  placeholder="Enter Error Description"
                  value={errorDescription}
                  onChange={(e) => setErrorDescription(e.currentTarget.value)}
                />
              </div>
            </>
          )
        }
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(UpdatePayment);
