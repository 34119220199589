import { COUPONDETAILS } from '../actionTypes';

export const fetchCouponDetails = (action) => ({
  type: COUPONDETAILS.FETCH,
  payload: action.payload,
});

export const deleteCoupon = (action) => ({
  type: COUPONDETAILS.DELETE,
  payload: action.payload,
});

export const updateCoupon = (action) => ({
  type: COUPONDETAILS.UPDATE,
  payload: action.payload,
});

export const addPromoCode = (action) => ({
  type: COUPONDETAILS.ADD_PROMOTIONS,
  payload: action.payload,
});

export const deletePromoCode = (action) => ({
  type: COUPONDETAILS.DELETE_PROMOTIONS,
  payload: action.payload,
});

export const fetchRedemptionsDetails = (action) => ({
  type: COUPONDETAILS.FETCH_REDEMPTIONS,
  payload: action.payload,
});

export const fetchCouponShops = (action) => ({
  type: COUPONDETAILS.FETCH_COUPON_SHOPS,
  payload: action.payload,
});

export const fetchCouponMenus = (action) => ({
  type: COUPONDETAILS.FETCH_COUPONS_MENUS,
  payload: action.payload,
});

export const fetchCouponCategories = (action) => ({
  type: COUPONDETAILS.FETCH_COUPON_CATEGORIES,
  payload: action.payload,
});

export const fetchCouponShop = (action) => ({
  type: COUPONDETAILS.FETCH_COUPON_SHOP,
  payload: action.payload,
});

export const fetchCouponItems = (action) => ({
  type: COUPONDETAILS.FETCH_COUPON_ITEMS,
  payload: action.payload,
});

export const updateCouponShop = (action) => ({
  type: COUPONDETAILS.UPDATE_COUPON_SHOP,
  payload: action.payload,
});

export const fetchAllEventPages = (action) => ({
  type: COUPONDETAILS.FETCH_ALL_EVENT_PAGES,
  payload: action.payload,
});

export const fetchEventPageItems = (action) => ({
  type: COUPONDETAILS.FETCH_EVENT_PAGE_ITEMS,
  payload: action.payload,
});

export const fetchEventPageCategories = (action) => ({
  type: COUPONDETAILS.FETCH_EVENT_PAGE_CATEGORIES,
  payload: action.payload,
});

export const fetchCouponEventPage = (action) => ({
  type: COUPONDETAILS.FETCH_COUPON_EVENT_PAGE,
  payload: action.payload,
});


export const updateCouponEventPage = (action) => ({
  type: COUPONDETAILS.UPDATE_COUPON_EVENT_PAGE,
  payload: action.payload,
});
