import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  API_END_POINTS,
  axiosAuthInstance2,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  COUPONDETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchCouponDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.COUPONS}/${gid}`);

const fetchRedemptionsDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.REDEMPTIONS}/${gid}`);

const patchCoupon = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.COUPONS}/${gid}`, params);

const deleteCoupons = async (gid) => axiosAuthInstance.patch(`${API_END_POINTS.COUPONS}/${gid}/deactivate`);

const addPromotionalCode = async (payload) => axiosAuthInstance.post(API_END_POINTS.PROMOTIONAL_CODES, payload);

const deletePromos = async (gid) => axiosAuthInstance.patch(`${API_END_POINTS.PROMOTIONAL_CODES}/${gid}/deactivate`);

const fetchCouponShops = async () => axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_SHOPS}?size=1000`);

const fetchCouponMenus = async (gid) => axiosAuthInstance.get(`${API_END_POINTS.GET_MENUS}/shop/${gid}?size=1000`);

const fetchCouponCategories = async (gid) => axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_CATEGORY}/shop/${gid}?size=1000`);

const fetchCouponItems = async (gid) => axiosAuthInstance.get(`${API_END_POINTS.GET_INVENTORY_ITEM}/shop/${gid}`);

const fetchCouponShop = async (gid) => axiosAuthInstance2.get(`${API_END_POINTS.COUPON_SHOP}?couponGid.EQ=${gid}`);

const fetchCouponMenu = async ({ gid, val }) => axiosAuthInstance2.get(`${API_END_POINTS.COUPON_SHOP}/${gid}?shopGid=${val}`);

const fetchCouponCategory = async (gid) => axiosAuthInstance.get(`${API_END_POINTS.COUPONS_CATEGORY}/${gid}`);

// eslint-disable-next-line max-len
const patchCouponShop = async ({ gid, params, shopGid }) => axiosAuthInstance2.patch(`${API_END_POINTS.COUPON_SHOP}/${gid}?shopGid=${shopGid}`, params);

const patchCouponMenu = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.COUPONS_MENU}/${gid}`, params);

const patchCouponCategory = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.COUPONS_CATEGORY}/${gid}`, params);

const fetchEventPages = async () => axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT_PAGE}?size=1000&status.NE=DEACTIVATED`);

const fetchEventPageItems = async (gid) => axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT_PAGE}/${gid}/items`);

const fetchEventPageCategories = async (gid) => axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT_PAGE}/${gid}/categories`);

const fetchCouponEventPage = async (gid) => axiosAuthInstance.get(`${API_END_POINTS.COUPON_EVENT_PAGE}?couponGid.EQ=${gid}`);

// eslint-disable-next-line max-len
const patchCouponEventPage = async ({ gid, params, eventPageGid }) => axiosAuthInstance.patch(`${API_END_POINTS.COUPON_EVENT_PAGE}/${gid}?eventPageGid=${eventPageGid}`, params);


export function* getCouponDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchCouponDetails, action.payload);
    yield put({ type: COUPONDETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getRedemptions(action) {
  try {
    const { data: { entity } } = yield call(fetchRedemptionsDetails, action.payload);
    yield put({ type: COUPONDETAILS.REDEMPTIONS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  }
}

export function* updateCoupons(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCoupon, action.payload);
    const { data: { entity } } = yield call(fetchCouponDetails, action.payload.gid);
    yield put({ type: COUPONDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.COUPONS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteCoupon(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deleteCoupons, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.COUPONS.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addPromoCode(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(addPromotionalCode, action.payload.payload);
    const { data: { entity } } = yield call(fetchCouponDetails, action.payload.gid);
    yield put({ type: COUPONDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.COUPONS.PROMOTION_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deletePromoCode(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(deletePromos, action.payload.promoGid);
    const { data: { entity } } = yield call(fetchCouponDetails, action.payload.gid);
    yield put({ type: COUPONDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.COUPONS.PROMAOTION_DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getShopDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchCouponShops, action.payload);
    yield put({ type: COUPONDETAILS.COUPON_SHOPS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getMenus(action) {
  try {
    const { data: { entity } } = yield call(fetchCouponMenus, action.payload);
    yield put({ type: COUPONDETAILS.COUPON_MENUS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getCategories(action) {
  try {
    const { data: { entity } } = yield call(fetchCouponCategories, action.payload);
    yield put({ type: COUPONDETAILS.COUPON_CATEGORIES_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getItems(action) {
  try {
    const { data: { entity } } = yield call(fetchCouponItems, action.payload);
    yield put({ type: COUPONDETAILS.COUPON_ITEMS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getCouponShop(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchCouponShop, action.payload);
    yield put({ type: COUPONDETAILS.COUPON_SHOP_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getCouponMenu(action) {
  try {
    const { data: { entity } } = yield call(fetchCouponMenu, action.payload);
    yield put({ type: COUPONDETAILS.COUPON_MENU_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  }
}

export function* getCouponCategory(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchCouponCategory, action.payload);
    yield put({ type: COUPONDETAILS.COUPON_CATEGORY_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateCouponShop(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCouponShop, action.payload);
    // const { data: { entity } } = yield call(fetchCouponShop, action.payload.gid);
    // yield put({ type: COUPONDETAILS.COUPON_SHOP_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.COUPONS.DISCOUNT_ASSIGNED,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* updateCouponMenu(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCouponMenu, action.payload);
    const { data: { entity } } = yield call(fetchCouponMenu, action.payload.gid);
    yield put({ type: COUPONDETAILS.COUPON_MENU_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.COUPONS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateCouponCategory(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCouponCategory, action.payload);
    const { data: { entity } } = yield call(fetchCouponCategory, action.payload.gid);
    yield put({ type: COUPONDETAILS.COUPON_CATEGORY_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.COUPONS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getAllEventPages(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchEventPages, action.payload);
    yield put({ type: COUPONDETAILS.EVENT_PAGES_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getEventPageItems(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchEventPageItems, action.payload);
    yield put({ type: COUPONDETAILS.EVENT_PAGE_ITEMS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getEventPageCategories(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchEventPageCategories, action.payload);
    yield put({ type: COUPONDETAILS.EVENT_PAGE_CATEGORIES_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getCouponEventPage(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchCouponEventPage, action.payload);
    yield put({ type: COUPONDETAILS.COUPON_EVENT_PAGE_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: COUPONDETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* updateCouponEventPage(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield call(patchCouponEventPage, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.COUPONS.DISCOUNT_ASSIGNED,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
