import { call, put } from 'redux-saga/effects';

import generateDetailsQuery from 'utils/generateDetailsQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { MESSAGES } from 'appconstants';

import {
  EVENT_PAGE_DETAILS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchEventPageDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT_PAGE}/${gid}`);

const fetchEventPageProductItemDetails = async payload => axiosAuthInstance.post(`${API_END_POINTS.GET_EVENT_PAGE}/items`, payload);

const fetchEventPageOrderPaymentData = async (payload) => {
  const { gid } = payload;
  const { currentPage } = payload;
  const { onlinePayment } = payload;
  let onlinePaymentQuery = '';
  if (onlinePayment !== '') {
    onlinePaymentQuery = `&onlinePayment=${onlinePayment}`;
  }
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  const query = generateDetailsQuery(currentPage);
  return axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT_PAGE_ORDER_PAYMENTS}?${query}${onlinePaymentQuery}&eventPageGid=${gid}${sorting}`);
};

const fetchEventPageCustomTemplates = async ({ gid }) => axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT_PAGE}/${gid}/custom-template`);

const deleteCustomTemplateEventPage = async (payload) => {
  const { gid } = payload;
  const { customTemplateType } = payload;
  let customTemplateTypeQuery = '';
  if (customTemplateType !== '') {
    customTemplateTypeQuery = `?emailTemplateType=${customTemplateType}`;
  }
  return axiosAuthInstance.delete(`${API_END_POINTS.GET_EVENT_PAGE}/${gid}/custom-template${customTemplateTypeQuery}`);
};

const patchEventPage = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_EVENT_PAGE}/${gid}`, params);

const eventDeactivate = async gid => axiosAuthInstance.patch(`${API_END_POINTS.GET_EVENT_PAGE}/${gid}/deactivate`);

const eventSoldOut = async gid => axiosAuthInstance.patch(`${API_END_POINTS.GET_EVENT_PAGE}/${gid}/sold-out`);

const salesResume = async gid => axiosAuthInstance.patch(`${API_END_POINTS.GET_EVENT_PAGE}/${gid}/resume-sale`);

const offlinePayment = async ({ gid, params }) => axiosAuthInstance.post(`${API_END_POINTS.OFFLINE_SEATED_VENUE}/${gid}/offline-payment`, params);

const fetchEventPagesSlug = async gid => axiosAuthInstance.get(`${API_END_POINTS.EVENT_SLUG_API}/${gid}`);

const notifyNonSeatedPageDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_NOTIFY}/${gid}`);

const notifyNonSeatedPage = async payload => axiosAuthInstance.post(`${API_END_POINTS.GET_NOTIFY}/notify`, payload);

const fetchOfflineEventPagesPayments = async (payload) => {
  const defaultPage = 1;
  const defaultSize = 10;
  let query;
  if (payload.currentPage) {
    query = `&page=${payload.currentPage}&size=${defaultSize}`;
  } else {
    query = `&page=${defaultPage}&size=${defaultSize}`;
  }
  const queryParam = `?seatPage.seatPageMetaGid.EQ=${payload.gid}`;
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  const offline = '&seatPage.offlinePaymentType.NULL=false';
  return axiosAuthInstance.get(`${API_END_POINTS.EVENT_RECEIPTS}${queryParam}${offline}${sorting}${query}`);
};

const fetchEventPageSecureDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_EVENT_PAGE}/${gid}/secure`);

const postEventPageOrder = async payload => axiosAuthInstance.post(`${API_END_POINTS.GET_EVENT_PAGE}/order`, payload);

const getSplitUp = async payload => axiosAuthInstance.post(`${API_END_POINTS.GET_EVENT_PAGE}/order/split-up`, payload);

const fetchPromosByEventPage = async (payload) => {
  const apiURL = `/event-page/${payload.gid}/event-page-promo`;
  const defaultPage = 1;
  const defaultSize = 10;
  let query;
  if (payload.currentPage) {
    query = `?page=${payload.currentPage}&size=${defaultSize}`;
  } else {
    query = `?page=${defaultPage}&size=${defaultSize}`;
  }
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${apiURL}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${apiURL}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${apiURL}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${apiURL}${query}${sorting}`);
};

const fetchAllPromosByEventPage = async (gid) => {
  const apiURL = `/event-page/${gid}/event-page-promo`;
  const queryParam = '?page=1&size=1000';
  const sorting = '&sortBy=createdAt&direction=ASC';
  return axiosAuthInstance.get(`${apiURL}${queryParam}${sorting}`);
};

const postEventPagePromo = async ({ gid, params }) => axiosAuthInstance.post(`/event-page/${gid}/event-page-promo`, params);

const removeEventPagePromo = async ({ gid, promoGid }) => axiosAuthInstance.delete(`/event-page/${gid}/event-page-promo/${promoGid}`);

const patchEventPagePromo = async ({ gid, promoGid, params }) => axiosAuthInstance.patch(`/event-page/${gid}/event-page-promo/${promoGid}`, params);

export function* getEventPageDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchEventPageDetails, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENT_PAGE_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getEventPageProductItemDetails(action) {
  try {
    const { data: { entity } } = yield call(fetchEventPageProductItemDetails, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.PRODUCT_ITEM_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENT_PAGE_DETAILS.FAILED, payload: e.response.data });
  }
}

export function* getEventPageOrderPaymentData(action) {
  try {
    const { data: { entity } } = yield call(fetchEventPageOrderPaymentData, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.PAYMENT_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENT_PAGE_DETAILS.FAILED, payload: e.response.data });
  }
}

export function* getEventPageCustomTemplates(action) {
  try {
    const { data: { entity } } = yield call(fetchEventPageCustomTemplates, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.CUSTOM_TEMPLATE_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENT_PAGE_DETAILS.FAILED, payload: e.response.data });
  }
}

export function* removeCustomTemplateEventPage(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(deleteCustomTemplateEventPage, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Custom Template Deleted successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateEventPage(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchEventPage, action.payload);
    const { data: { entity } } = yield call(fetchEventPageDetails, action.payload.gid);
    yield put({ type: EVENT_PAGE_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.CUSTOMERS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deactivateEventPage(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(eventDeactivate, action.payload);
    const { data: { entity } } = yield call(fetchEventPageDetails, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.DEACTIVATED,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* soldOutEventPage(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(eventSoldOut, action.payload);
    const { data: { entity } } = yield call(fetchEventPageDetails, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.SOLDOUT,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* resumeSalesNon(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(salesResume, action.payload);
    const { data: { entity } } = yield call(fetchEventPageDetails, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.SOLDOUT,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* offlinePaymentsNon(action) {
  try {
    yield put({ type: LOADING.SET });
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(offlinePayment, action.payload);
    const { data: { entity } } = yield call(fetchEventPageDetails, action.payload.params.seatPageMetaGid);
    yield put({ type: EVENT_PAGE_DETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.EVENTS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
    yield put({ type: LOADING.UNSET });
  }
}

export function* getEventPagesSlug(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchEventPagesSlug, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.EVENT_PAGE_SLUG_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENT_PAGE_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getOfflineEventPagesPaymentsList(action) {
  try {
    const { data: { entity } } = yield call(fetchOfflineEventPagesPayments, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.SUCCESS_OFFLINE_PAYMENTS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENT_PAGE_DETAILS.FAILED, payload: e.response.data });
  }
}

export function* getNotifyNonSeatedPageDetails(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(notifyNonSeatedPageDetails, action.payload);
      yield put({ type: EVENT_PAGE_DETAILS.SUCCESS, payload: entity });
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.NOTIFY.SUCCESS,
      });
    } else {
      const { data: { entity } } = yield call(notifyNonSeatedPageDetails, action);
      yield put({ type: EVENT_PAGE_DETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* getNotifyNonSeatedPage(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(notifyNonSeatedPage, action.payload);
    yield put({ type: LOADING.VALIDATED });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.NOTIFY.SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getPromosByEventPage(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchPromosByEventPage, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.EVENT_PAGE_PROMO_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENT_PAGE_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getAllPromosByEventPage(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchAllPromosByEventPage, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.EVENT_PAGE_ALL_PROMO_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENT_PAGE_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* addPromosByEventPage(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postEventPagePromo, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Promo added successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updatePromosByEventPage(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchEventPagePromo, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Promo updated successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deletePromosByEventPage(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(removeEventPagePromo, action.payload);
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Promo deleted successfully.',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getEventPageSecureDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchEventPageSecureDetails, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.EVENT_PAGE_OFFLINE_DETAILS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENT_PAGE_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getEventPageSplitUp(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(getSplitUp, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.EVENT_PAGE_SPLIT_UP_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENT_PAGE_DETAILS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* createEventPageOfflineOrder(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(postEventPageOrder, action.payload);
    yield put({ type: EVENT_PAGE_DETAILS.EVENT_PAGE_OFFLINE_ORDER_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: EVENT_PAGE_DETAILS.EVENT_PAGE_OFFLINE_ORDER_FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}
