import { VENDORINVOICEDETAILS } from '../actionTypes';

const initialState = {
  invoiceDetailsStatus: undefined,
  invoiceDetailsError: undefined,
  customersList: [],
  newCustomer: {},
  invoice: {},
  isOpenPayModal: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case VENDORINVOICEDETAILS.FETCH:
      return {
        ...state,
        invoiceDetailsStatus: 'STARTED',
      };
    case VENDORINVOICEDETAILS.SUCCESS:
      return {
        ...state,
        invoice: action.payload,
        invoiceDetailsStatus: 'SUCCESS',
      };
    case VENDORINVOICEDETAILS.FAILED:
      return {
        ...state,
        invoiceDetailsError: action.payload,
        invoiceDetailsStatus: 'FAILED',
      };
    case VENDORINVOICEDETAILS.CONTACT_SUCCESS:
      return {
        ...state,
        customersList: action.payload,
      };
    case VENDORINVOICEDETAILS.CONTACT_ADD_SUCCESS:
      return {
        ...state,
        newCustomer: action.payload,
      };
    case VENDORINVOICEDETAILS.OPEN_PAY_MODAL:
      return {
        ...state,
        isOpenPayModal: action.payload,
      };
    default:
      return state;
  }
};
