import React from 'react';
import { Col, Row } from 'antd';
import {
  SpH5,
  SpForm,
} from 'components/DesignKit';
import moment from 'moment-timezone';

import formatNumber from 'utils/formatNumber';


type Props = {
  transferDetails: Array<Object>,
  transferTypeDetails: Object,
  currencyType: Object,
  selectedAccount: Object,
}
const CardType = (props: Props) => {
  const {
    transferDetails,
    transferTypeDetails,
    currencyType,
    selectedAccount,
  } = props;

  function getTotalAmount(amount, fee, tax) {
    let total;
    if (amount && fee && tax) {
      total = amount + fee + tax;
    } else if (amount && fee) {
      total = amount + fee;
    } else if (amount && tax) {
      total = amount + tax;
    } else total = amount;
    const totalAmount = total
      ? `${transferDetails.currency.prefix} ${formatNumber((total / 100).toFixed(transferDetails.currency.toFixed), currencyType)}`
      : <>&#8211;</>;
    return totalAmount;
  }

  if (transferTypeDetails) {
    return (
      <SpForm>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Amount</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {transferTypeDetails.amount
                ? `${transferDetails.currency.prefix} 
                      ${formatNumber((transferTypeDetails.amount / 100).toFixed(transferDetails.currency.toFixed), currencyType)}`
                : <>&#8211;</>}
            </SpH5>
          </Col>
          <Col span={3}>
            <span>ID</span>
          </Col>
          <Col span={8}>
            <SpH5 color="#7f7f7f">
              {transferTypeDetails.referenceId || <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Fee</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {transferDetails.feeAmount
                ? `${transferDetails.currency.prefix} 
                      ${formatNumber((transferDetails.feeAmount / 100).toFixed(transferDetails.currency.toFixed), currencyType)}`
                : <>&#8211;</>}
            </SpH5>
          </Col>
          <Col span={3}>
            <span>Transfer Date</span>
          </Col>
          <Col span={8}>
            <SpH5>
              {
                transferTypeDetails.transferDate && selectedAccount && selectedAccount.timezone
                  ? (
                    moment
                      .utc(transferTypeDetails.transferDate)
                      .tz(selectedAccount.timezone)
                      .format('MMM DD, YYYY')
                  )
                  : <>&#8211;</>
              }
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Tax</span>
          </Col>
          <Col span={9}>
            {
              (transferDetails.feeTax) ? (
                <SpH5>
                  {
                    transferDetails.feeTax
                      ? `${transferDetails.currency.prefix} 
        ${formatNumber((transferDetails.feeTax / 100).toFixed(transferDetails.currency.toFixed), currencyType)}`
                      : <>&#8211;</>
                  }
                </SpH5>
              ) : (
                transferTypeDetails.gst
                  ? `${transferDetails.currency.prefix} 
      ${formatNumber((transferTypeDetails.gst / 100).toFixed(transferDetails.currency.toFixed), currencyType)}`
                  : transferTypeDetails.tds ? `${transferDetails.currency.prefix} 
                  ${formatNumber((transferTypeDetails.gst / 100).toFixed(transferDetails.currency.toFixed), currencyType)}` : <>&#8211;</>

              )
            }
          </Col>
          <Col span={3}>
            <span>Beneficiary ETA</span>
          </Col>
          <Col span={8}>
            <SpH5>
              {
                transferDetails.beneficiaryEta && selectedAccount && selectedAccount.timezone
                  ? (
                    moment
                      .utc(transferDetails.beneficiaryEta)
                      .tz(selectedAccount.timezone)
                      .format('MMM DD, YYYY')
                  )
                  : <>&#8211;</>
              }
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start">
          <Col span={4}>
            <span>Total</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {
                (transferDetails.feeAmount && transferDetails.feeTax)
                  ? getTotalAmount(transferTypeDetails.amount, transferDetails.feeAmount, transferDetails.feeTax)
                  : (transferTypeDetails && transferTypeDetails.gst)
                    ? getTotalAmount(transferTypeDetails.amount, transferDetails.feeAmount, transferTypeDetails.gst)
                    : (transferTypeDetails && transferTypeDetails.tds)
                      ? getTotalAmount(transferTypeDetails.amount, transferDetails.feeAmount, transferTypeDetails.tds)
                      : getTotalAmount(transferTypeDetails.amount, transferDetails.feeAmount)
              }
            </SpH5>
          </Col>
        </Row>
      </SpForm>
    );
  } return null;
};

export default CardType;
