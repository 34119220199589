// @flow
import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import {
  Input,
  Row,
  Col,
  Icon,
  Upload,
  message,
  Select,
  Checkbox,
  Switch,
  DatePicker,
  TimePicker,
} from 'antd';

// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';
import {
  MESSAGES,
  COUNTRY_PHONE_CODE,
} from 'appconstants';

import formatAmount from 'utils/formatAmount';
import { NOTIFICATION } from 'store/actionTypes';
import {
  notificationActions,
} from 'store/actions';
import {
  validators,
  validationRegex,
  countryCodeValidation,
} from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';

import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5,
  SpError,
  SpText,
  SpButton,
} from 'components/DesignKit';

import Previewicon from 'components/NavIcons/previewIcon';
import Uploadicon from 'components/NavIcons/uploadIcon';
import ReactQuill from 'react-quill';
import MapLocation from './MapLocation';

import 'react-quill/dist/quill.snow.css';

const { Option } = Select;

const { TextArea } = Input;

const MOMENT_FORMAT = 'YYYY-MM-DD';

type Props = {
  pageData: Object,
  selectedAccount: Object,
  setNotification: Function,
  isIndia: boolean,
  updateLayout: Function,
  isGid: String,
  venueName1: String,
};

const PageDetails = forwardRef((props: Props, ref) => {
  const {
    pageData,
    selectedAccount,
    setNotification,
    isIndia,
    updateLayout,
    isGid,
    venueName1,
  } = props;
  const [, forceUpdate] = useState();
  const GOOGLE_MAPS_API_KEY = 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds';
  const currencyType = selectedAccount && selectedAccount.currency;
  const [title, setTitle] = useState(pageData && pageData.title);
  const [content, setContent] = useState(pageData && pageData.content);
  const [logo, setLogo] = useState(pageData && pageData.logo);
  const [preview, setPreview] = useState(pageData && pageData.preview);
  const [fileName, setFileName] = useState(pageData && pageData.fileName);
  const [loader, setLoading] = useState(false);
  const [termsAndConditions, setTerms] = useState(pageData && pageData.termsAndConditions);
  const [contactUsEmail, setEmail] = useState(pageData && pageData.contactUsEmail);
  const [contactPhone, setContactPhone] = useState(pageData && pageData.contactPhone);
  const [phoneNumberCode, setPhoneCode] = useState(pageData && pageData.phoneNumberCode
    ? pageData && pageData.phoneNumberCode
    : getCountryPhoneCode((selectedAccount && selectedAccount.country && selectedAccount.country.id)));
  const [phoneFormate, setPhoneFormate] = useState(pageData && pageData.phoneFormate ? pageData && pageData.phoneFormate : '');
  const [venueAddress, setAddress] = useState(pageData && pageData.venueAddress);
  const [receiptNote, setReceiptNote] = useState(pageData && pageData.receiptNote);
  const [coordinate, setCoordinates] = useState(pageData && pageData.coordinate);
  // const [location, setLocation] = useState(pageData && pageData.location);
  const [serviceFeeEnabled, setServiceFeeEnabled] = useState(pageData && pageData.serviceFeeEnabled);
  // eslint-disable-next-line max-len
  const [serviceFeeRate, setServiceFeeRate] = useState(pageData && pageData.serviceFeeRate ? formatAmount((pageData && pageData.serviceFeeRate) / 100, currencyType) : null);
  // eslint-disable-next-line max-len
  const [taxPercentage, setTaxPercentage] = useState(pageData && pageData.taxPercentage ? formatAmount((pageData && pageData.taxPercentage) / 100, currencyType) : null);
  // eslint-disable-next-line max-len
  const [serviceFee, setServiceFee] = useState(pageData && pageData.serviceFee ? formatAmount((pageData && pageData.serviceFee) / 100, currencyType) : null);
  const [supportOfflinePayment, setSupportOfflinePayment] = useState(pageData && pageData.supportOfflinePayment);
  const prefix = selectedAccount && selectedAccount.currency && selectedAccount.currency.prefix;
  const youtubeRegex = /^(?:https?:\/\/)?(?:w ww\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  const [venueDetails, setVenueDetails] = useState();
  const [selectedVenue, setSelectedVenu] = useState('');
  const [eventDate, setEventDate] = useState(pageData && pageData.eventDate);
  const [startTime, setStartTime] = useState(pageData && pageData.startTime);
  const [endTime, setEndTime] = useState(pageData && pageData.endTime);
  const [duration, setDuration] = useState(pageData && pageData.duration);
  const [layout, setLayout] = useState(pageData && pageData.layout);
  const [venueName, setVenueName] = useState(pageData && pageData.venueName);
  const [startT, setStartT] = useState(false);
  const [endT, setEndT] = useState(false);
  const [durationDisplay, setDurationDisplay] = useState(null);
  const [showLayoutError, setShowLayoutError] = useState(false);

  const getDurationStart = (data) => {
    if (data && endTime) {
      const diff = endTime - data;
      const hours = Math.floor(diff / 60);
      const minutes = diff % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours === 24) {
        const hr = hours - 12;
        return `${hr}:${min}`;
      }
      if (hours === 0) {
        return `00:${min}`;
      }
      return `${hours}:${min}`;
    } if (data === 0 && endTime) {
      const diff = endTime - data;
      const hours = Math.floor(diff / 60);
      const minutes = diff % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours === 24) {
        const hr = hours - 12;
        return `${hr}:${min}`;
      }
      if (hours === 0) {
        return `00:${min}`;
      }
      return `${hours}:${min}`;
    }
    return null;
  };

  const getDuration = (data) => {
    if (data && startTime) {
      const diff = data - startTime;
      const hours = Math.floor(diff / 60);
      const minutes = diff % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours === 24) {
        const hr = hours - 12;
        return `${hr}:${min}`;
      }
      if (hours === 0) {
        return `00:${min}`;
      }
      return `${hours}:${min}`;
    } if (data && startTime === 0) {
      const diff = data - startTime;
      const hours = Math.floor(diff / 60);
      const minutes = diff % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours === 24) {
        const hr = hours - 12;
        return `${hr}:${min}`;
      }
      if (hours === 0) {
        return `00:${min}`;
      }
      return `${hours}:${min}`;
    }
    return null;
  };

  const selectStartTime = (e) => {
    if (e) {
      setStartT(false);
      setEndT(false);
      const time = e.format('hh:mm a');
      const timeStamp = time.split(' ')[1];
      const actualTime = time.split(' ')[0];
      const a = actualTime.split(':');
      let minutes;
      if (a[0] === '12' && timeStamp === 'am') {
        minutes = +a[1];
      } else if (a[0] === '12' && timeStamp === 'pm') {
        minutes = (+a[0]) * 60 + (+a[1]);
      } else {
        minutes = (+a[0] + (timeStamp === 'pm' ? 12 : 0)) * 60 + (+a[1]);
      }
      if (endTime && endTime < minutes) {
        setStartT(true);
        setStartTime(minutes);
      } else if (endTime && endTime === minutes) {
        setStartT(true);
        setStartTime(minutes);
      } else if (!endTime) {
        setStartTime(minutes);
      } else if (endTime && endTime > minutes) {
        setStartTime(minutes);
        setDurationDisplay(getDurationStart(minutes));
      }
    } else {
      setDurationDisplay(null);
    }
  };

  const selectEndTime = (e) => {
    if (e) {
      setEndT(false);
      setStartT(false);
      const time = e.format('hh:mm a');
      const timeStamp = time.split(' ')[1];
      const actualTime = time.split(' ')[0];
      const a = actualTime.split(':');
      let minutes;
      if (a[0] === '12' && timeStamp === 'am') {
        minutes = +a[1];
      } else if (a[0] === '12' && timeStamp === 'pm') {
        minutes = (+a[0]) * 60 + (+a[1]);
      } else {
        minutes = (+a[0] + (timeStamp === 'pm' ? 12 : 0)) * 60 + (+a[1]);
      }
      if (startTime > minutes) {
        setEndTime(minutes);
        setDurationDisplay(null);
        setEndT(true);
      } else if (startTime === minutes) {
        setEndTime(minutes);
        setDurationDisplay(null);
        setEndT(true);
      } else if (startTime === minutes) {
        setDurationDisplay(null);
        setEndT(true);
      } else if (startTime < minutes) {
        setEndTime(minutes);
        setDurationDisplay(getDuration(minutes));
      } else if (!startTime && minutes) {
        setEndTime(minutes);
        setDurationDisplay(getDuration(minutes));
      }
    } else {
      setDurationDisplay(null);
    }
  };

  useEffect(() => {
    if (pageData && pageData.title) {
      setTitle(pageData.title);
      setContent(pageData.content);
      setLogo(pageData.logo);
      setPreview(pageData.preview);
      setFileName(pageData.fileName);
      setTerms(pageData.termsAndConditions);
      setEmail(pageData.contactUsEmail);
      setContactPhone(pageData.contactPhone);
      setPhoneCode(pageData.phoneNumberCode
        ? pageData.phoneNumberCode
        : getCountryPhoneCode((selectedAccount && selectedAccount.country && selectedAccount.country.id)));
      setPhoneFormate(pageData.phoneFormate);
      setAddress(pageData.venueAddress);
      setReceiptNote(pageData.receiptNote);
      setCoordinates(pageData.coordinate);
      setServiceFeeEnabled(pageData.serviceFeeEnabled);
      setServiceFeeRate(pageData.serviceFeeRate ? formatAmount(pageData.serviceFeeRate / 100, currencyType) : null);
      setServiceFee(pageData.serviceFee ? formatAmount(pageData.serviceFee / 100, currencyType) : null);
      setTaxPercentage(pageData.taxPercentage ? formatAmount(pageData.taxPercentage / 100, currencyType) : null);
      setSupportOfflinePayment(pageData.supportOfflinePayment);
      setEventDate(pageData.eventDate);
      setStartTime(pageData.startTime);
      setEndTime(pageData.endTime);
      setDuration(pageData.duration);
      setSelectedVenu(pageData.selectedVenue);
      if (pageData.startTime && pageData.endTime) {
        setDurationDisplay(getDuration(pageData.endTime));
      }
    }
  }, [pageData]);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.new_email.test(val),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      feeAmount: {
        message: 'fee should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      rateAmount: {
        message: 'rate should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      taxAmount: {
        message: 'tax should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) > 0,
      },
      youtubeValidation: {
        message: 'please enter a valid youtube url',
        rule: val => (youtubeRegex.test(val)),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      noEmojiValidation: {
        message: 'Emojis are not allowed in the page description.',
        rule: val => {
          const emojiRegex = /[\uD800-\uDFFF]/;
          return !emojiRegex.test(val);
        },
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setContactPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    setPhoneCode(value);
    const data = formatNumber(`${value}${contactPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const getPlaceDetails = async (place) => {
    // const locations = place.value.description;
    setAddress(place);
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(place)}&key=${GOOGLE_MAPS_API_KEY}`)
      .then(response => response.json())
      .then(data => {
        const { lat, lng } = data.results[0].geometry.location;
        setCoordinates({
          x: lat,
          y: lng,
        });
      })
      .catch(error => console.error(error));
  };

  const getVenueList = async () => {
    try {
      setLoading(true);
      const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.SEATED_VENUE}?size=1000`);
      setVenueDetails(entity.content);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Failed to load venue details',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getVenueList();
  }, []);

  const getVenueAddrees = (evnt) => {
    const address = `${evnt.street}, ${evnt.city}, ${evnt.state}, ${evnt.country.name}, ${evnt.postalCode},`;
    return address;
  };

  useEffect(() => {
    if (venueDetails && pageData.layout) {
      const item = venueDetails.filter((data) => data.gid === pageData.layout);
      if (item && item.length > 0) {
        setSelectedVenu(item[0]);
        setVenueName(item[0].venueName);
      }
      if (pageData && !pageData.address && !pageData.coordinate) {
        setCoordinates(item[0].coordinate);
        setAddress(getVenueAddrees(item[0].venueAddress));
      }
    } else if (!pageData.layout) {
      setSelectedVenu('');
      setCoordinates('');
      setAddress('');
      setLayout('');
      setVenueName('noLayout');
      setShowLayoutError(false);
      updateLayout('noLayout');
    }
  }, [venueDetails]);

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (startT || endT) {
        return false;
      }
      if (showLayoutError) {
        return false;
      }
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      title,
      content,
      logo,
      preview,
      fileName,
      contactUsEmail,
      contactPhone,
      phoneFormate,
      phoneNumberCode,
      eventDate,
      startTime,
      endTime,
      duration,
      termsAndConditions,
      coordinate,
      venueAddress,
      serviceFeeEnabled,
      serviceFeeRate,
      serviceFee,
      taxPercentage,
      supportOfflinePayment,
      receiptNote,
      layout,
      venueName,
    }),
  }));

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <Uploadicon />}
      <div className="ant-upload-text mt-2">Click or drag the file here to upload</div>
      <div className="mt-2"><SpText color="rgba(0, 0, 0, 0.45)">Support extension：.pdf .jpg .png</SpText></div>
    </div>
  );

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    const PDF = 'application/pdf';
    const IMAGE = 'image/*';
    const pattern = file.type.match(IMAGE) || file.type.match(PDF);
    if (!pattern) {
      message.error('File is not image or pdf');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 5MB!');
      return false;
    }
    return true;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const isFeeChange = (value) => {
    const { target: { checked } } = value;
    if (checked === true) {
      setServiceFeeRate('2.90');
      setServiceFee('0.30');
    }
    setServiceFeeEnabled(checked);
  };

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPLOAD_PAYMENTPAGE}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.UPLOAD_PAYMENTPAGE}`, { fileUrl: entity });
        setLogo(fileUrl);
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview(<img src={imageUrl} alt="..." style={{ width: '115px' }} />);
          });
        } else {
          setPreview(<Previewicon />);
        }
        setFileName(info.file.name);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const selectVenue = (item) => {
    if (item !== 'noLayout') {
      const selectedItem = venueDetails.filter((venue) => venue.gid === item);
      setSelectedVenu(selectedItem[0]);
      setCoordinates(selectedItem[0].coordinate);
      setAddress(getVenueAddrees(selectedItem[0].venueAddress));
      setLayout(selectedItem[0].gid);
      setVenueName(selectedItem[0].venueName);
      if (selectedItem[0] && selectedItem[0].seatedVenueCategories && selectedItem[0].seatedVenueCategories.length === 0) {
        setShowLayoutError(true);
      } else {
        setShowLayoutError(false);
      }
    }
    if (item === 'noLayout') {
      setSelectedVenu('');
      setCoordinates('');
      setAddress('');
      setLayout('');
      setVenueName(item);
      setShowLayoutError(false);
    }
    updateLayout(item);
  };

  const handleDate = (date) => {
    // eslint-disable-next-line
    const Date = moment(date._d).format(MOMENT_FORMAT);
    setEventDate(Date);
  };

  const getTimeVal = (time) => {
    const fromTime = time;
    if (fromTime !== 0) {
      const hours = Math.floor(fromTime / 60);
      const minutes = fromTime % 60;
      if (hours === 12) {
        const hr = 0;
        return moment(`${hr}:${minutes}`, 'hh:mm a');
      }
      if (hours === 24) {
        const hr = 12;
        return moment(`${hr}:${minutes}`, 'hh:mm a');
      }
      return moment(`${hours}:${minutes}`, 'hh:mm a');
    }
    return moment(`${24}:${0}`, 'hh:mm a');
  };

  const requestNewLayout = () => {
    const url = 'https://share.hsforms.com/1dCGR2iPvQZ-QzIzLApmy9Qnlqc3';
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <>
      <div>
        <Row>
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Page Title</SpH5></label>
            <Input
              placeholder="Enter the page title"
              value={title}
              maxlength="100"
              onChange={(e) => setTitle(e.currentTarget.value)}
            />
            <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
            <SpError>
              {simpleValidator.current.message('page title', title, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Page Description</SpH5></label>
            <ReactQuill
              theme="snow"
              rows={10}
              placeholder="Add the page description you want your customer to see"
              value={content}
              maxlength="1000"
              onChange={setContent}
              className="richTextFormatting"
            />
            <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 1000 Characters</SpText>
            <SpError>
              {simpleValidator.current.message('page description', content, 'required|noEmojiValidation')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Email</SpH5></label>
            <Input
              placeholder="Enter the email"
              value={contactUsEmail}
              maxlength="100"
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('email', contactUsEmail, 'required|email')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Phone Number</SpH5></label>
            <div className="d-flex">
              <div
                style={{ width: '20%' }}
                onKeyPress={e => countryCodeValidation(e)}
              >
                <Select
                  className="w-100"
                  showSearch
                  value={phoneNumberCode}
                  onChange={onCountryCodeChange}
                >
                  {COUNTRY_PHONE_CODE.map((item) => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
              <div style={{ width: '80%' }}>
                <Input
                  className="InputnumFormate"
                  placeholder="Phone Number"
                  value={phoneFormate}
                  onChange={onPhoneNumberChange}
                  onBlur={e => {
                    if (e.currentTarget.value) {
                      setContactPhone(e.currentTarget.value);
                      const value = formatNumber(`${phoneNumberCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                      const formater = value.substr(value.indexOf(' ') + 1);
                      setPhoneFormate(formater);
                    }
                  }}
                />
                <SpError>
                  {simpleValidator.current.message('phone number', contactPhone, `required|phoneNumValidation:${phoneNumberCode}${contactPhone}`)}
                </SpError>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Add Image</SpH5></label>
            <Upload
              name="avatar"
              listType="picture-card upload-card"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={fileUpload}
            >
              {fileName ? preview : uploadButton}
            </Upload>
            <SpError>
              {simpleValidator.current.message('logo', logo, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={4} push={4}>
            <label htmlFor=""><SpH5>Date</SpH5></label>
            <div className="d-flex">
              <DatePicker
                defaultValue={eventDate ? moment(eventDate, MOMENT_FORMAT) : ''}
                format={MOMENT_FORMAT}
                style={{ width: '111px' }}
                allowClear={false}
                onChange={handleDate}
                disabledDate={current => current && current < moment().subtract(1, 'days')}
              />
            </div>
            <SpError>
              {simpleValidator.current.message('Date', eventDate, 'required')}
            </SpError>
          </Col>
          <Col span={4} push={4}>
            <label htmlFor=""><SpH5>Start Time</SpH5></label>
            <div className="d-flex">
              <TimePicker
                style={{ width: '111px' }}
                use12Hours
                format="h:mm a"
                onChange={selectStartTime}
                defaultValue={startTime ? getTimeVal(startTime) : ''}
              />
            </div>
            {
              startT && (
                <SpError>
                  Start Time should be less than End Time
                </SpError>
              )
            }
            <SpError>
              {simpleValidator.current.message('start time', startTime, 'required')}
            </SpError>
          </Col>
          <Col span={4} push={4}>
            <label htmlFor=""><SpH5>End Time <SpText color="rgba(0, 0, 0, 0.5)">(Opt)</SpText></SpH5></label>
            <div className="d-flex">
              <TimePicker
                style={{ width: '111px' }}
                use12Hours
                format="h:mm a"
                onChange={selectEndTime}
                defaultValue={endTime ? getTimeVal(endTime) : ''}
              />
            </div>
            {
              endT && (
                <SpError>
                  End Time should be greater than Start Time
                </SpError>
              )
            }
          </Col>
          <Col span={4} push={4}>
            <label htmlFor=""><SpH5>Duration <SpText color="rgba(0, 0, 0, 0.5)">(Opt)</SpText></SpH5></label>
            <div className="d-flex">
              <Input
                style={{ width: '111px' }}
                placeholder="Duration"
                onChange={(e) => setDuration(e.currentTarget.value)}
                value={durationDisplay ? `${durationDisplay} hrs` : ''}
                readOnly
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={7} push={4} className="mr-2">
            <label htmlFor=""><SpH5>Choose layout</SpH5></label>
            <div>
              <Select
                className="w-100 mr-2"
                showSearch
                onChange={selectVenue}
                // eslint-disable-next-line no-unneeded-ternary
                disabled={isGid && (venueName1 === 'noLayout') ? true : false}
                placeholder="Type / Pick from the list"
                value={venueName}
              >
                <Option key={0} value="noLayout">No layout</Option>
                {/* {
                  !isGid && (
                    <Option key={0} value="noLayout">No layout</Option>
                  )
                } */}
                {venueDetails && venueDetails.map((item) => (
                  <Option key={item.gid} value={item.gid}>
                    {item.venueName}
                  </Option>
                ))}
              </Select>
              {
                showLayoutError && (
                  <SpError>
                    Please change the layout this layout has no categories.
                  </SpError>
                )
              }
              {
                venueName !== 'noLayout' && (
                  <SpError>
                    {simpleValidator.current.message('layout', layout, 'required')}
                  </SpError>
                )
              }
            </div>
            {
              venueName !== 'noLayout' && (
                <SpButton
                  type="primary"
                  shape="round"
                  className="mr-3 mt-5"
                  onClick={requestNewLayout}
                >
                  Request new Layout
                </SpButton>
              )
            }
          </Col>
          <Col span={9} push={4}>
            {
              selectedVenue && (
                venueName !== 'noLayout' ? (
                  <img src={selectedVenue.venueImageUrl} alt="venuImage" width="250" />
                ) : null
              )
            }
          </Col>
        </Row>
        {
          venueName !== 'noLayout' && (
            <>
              <Row className="mt-4">
                <Col span={16} push={4}>
                  <label htmlFor=""><SpH5>Venue name / Location</SpH5></label>
                  <div>
                    <Input
                      value={venueAddress}
                      onChange={(e) => setAddress(e.currentTarget.value)}
                      onBlur={(e) => getPlaceDetails(e.currentTarget.value)}
                      placeholder="Enter Location or pick from Map"
                    />
                  </div>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col span={16} push={4}>
                  <MapLocation selectedVenue={selectedVenue} coordinate={coordinate} />
                </Col>
              </Row>
            </>
          )
        }

        {
          !isIndia && (
            <>
              <Row className="mt-4">
                <Col span={16} push={4}>
                  <Checkbox
                    className="mr-2"
                    checked={serviceFeeEnabled}
                    onChange={isFeeChange}
                  />
                  <SpH5>Apply Service Rate & Fees</SpH5>
                </Col>
              </Row>
              {
                serviceFeeEnabled && (
                  <>
                    <Row className="mt-4">
                      <Col span={16} push={4}>
                        <div className="d-flex">
                          <SpH5>Rate</SpH5>
                          <div style={{ width: '20%' }} />
                          <SpH5>Fees</SpH5>
                          <div style={{ width: '20%' }} />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={16} push={4}>
                        <div className="d-flex">
                          <div style={{ width: '20%' }}>
                            <Input
                              value={serviceFeeRate}
                              placeholder="0.0"
                              suffix="%"
                              onChange={(e) => {
                                const regex = /^\d*\.?\d*$/;
                                const { value } = e.currentTarget;
                                if (regex.test(value) || value === '') {
                                  setServiceFeeRate(e.currentTarget.value);
                                } else if (!regex.test(value)) {
                                  setServiceFeeRate(0.00);
                                }
                              }}
                              onKeyPress={e => {
                                const keyCode = e.charCode || e.which;
                                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                  e.preventDefault();
                                }
                              }}
                              onBlur={e => {
                                if (e.currentTarget.value) {
                                  const value = formatAmount(e.currentTarget.value, currencyType);
                                  setServiceFeeRate(value);
                                }
                              }}
                            />
                            {
                              serviceFee ? (
                                <SpError>
                                  {simpleValidator.current.message('rate', serviceFeeRate, 'rateAmount')}
                                </SpError>
                              ) : (
                                <SpError>
                                  {simpleValidator.current.message('rate', serviceFeeRate, 'required|rateAmount')}
                                </SpError>
                              )
                            }
                          </div>
                          <div style={{ width: '60%' }} className="ml-4">
                            <div className="d-flex">
                              <div style={{ width: '35%' }}>
                                <Input
                                  placeholder="0.00"
                                  prefix={prefix}
                                  value={serviceFee}
                                  onChange={(e) => {
                                    const regex = /^\d*\.?\d*$/;
                                    const { value } = e.currentTarget;
                                    if (regex.test(value) || value === '') {
                                      setServiceFee(e.currentTarget.value);
                                    } else if (!regex.test(value)) {
                                      setServiceFee(0.00);
                                    }
                                  }}
                                  onKeyPress={e => {
                                    const keyCode = e.charCode || e.which;
                                    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                      e.preventDefault();
                                    }
                                  }}
                                  onBlur={e => {
                                    if (e.currentTarget.value) {
                                      const value = formatAmount(e.currentTarget.value, currencyType);
                                      setServiceFee(value);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            {
                              serviceFeeRate ? (
                                <SpError>
                                  {simpleValidator.current.message('fee', serviceFee, 'feeAmount')}
                                </SpError>
                              ) : (
                                <SpError>
                                  {simpleValidator.current.message('fee', serviceFee, 'required|feeAmount')}
                                </SpError>
                              )
                            }
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )
              }
            </>
          )
        }
        <Row className="mt-4">
          <Col span={5} push={4}>
            <label htmlFor=""><SpH5>Sales Tax</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <Input
              value={taxPercentage}
              placeholder="0.0"
              suffix="%"
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                const { value } = e.currentTarget;
                if (regex.test(value) || value === '') {
                  setTaxPercentage(e.currentTarget.value);
                } else if (!regex.test(value)) {
                  setTaxPercentage(0.00);
                }
              }}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
              onBlur={e => {
                if (e.currentTarget.value) {
                  const value = formatAmount(e.currentTarget.value, currencyType);
                  setTaxPercentage(value);
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('tax', taxPercentage, 'taxAmount')}
            </SpError>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <Switch
              defaultChecked={supportOfflinePayment}
              onChange={val => setSupportOfflinePayment(val)}
            /> <SpH5> Support Offline Payment</SpH5>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Terms & Conditions</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <TextArea
              rows={5}
              placeholder="Enter terms and conditions"
              value={termsAndConditions}
              maxlength="1000"
              onChange={(e) => setTerms(e.currentTarget.value)}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col span={16} push={4}>
            <label htmlFor=""><SpH5>Receipt Note</SpH5> <SpText color="rgba(0, 0, 0, 0.5)">(Optional)</SpText></label>
            <Input
              placeholder="Enter the receipt note"
              value={receiptNote}
              onChange={(e) => setReceiptNote(e.currentTarget.value)}
            />
          </Col>
        </Row>
      </div>
    </>
  );
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(PageDetails);
