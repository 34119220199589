import { EVENT_PAGE_DETAILS } from '../actionTypes';

const initialState = {
  cardDetails: [],
  offlineEventPayments: [],
  eventPagePromosList: [],
  eventPageOrderPaymentDetails: {},
  eventPageCustomTemplateDetails: [],
  eventPageProductItems: [],
  allEventPagePromosList: [],
  eventDetailsStatus: undefined,
  eventPageOrderDetailsStatus: undefined,
  eventDetailsError: undefined,
  eventPageSlug: [],
  eventPageOfflineDetails: undefined,
  eventPageOrderDetails: undefined,
  addSeatedEvent: false,
  eventPageOrderSplitUp: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EVENT_PAGE_DETAILS.FETCH:
      return {
        ...state,
        eventDetailsStatus: 'STARTED',
        eventPageOrderDetails: undefined,
        eventPageOrderDetailsStatus: undefined,
      };
    case EVENT_PAGE_DETAILS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        eventDetailsStatus: 'SUCCESS',
      };
    case EVENT_PAGE_DETAILS.FAILED:
      return {
        ...state,
        eventDetailsError: action.payload,
        eventDetailsStatus: 'FAILED',
      };
    case EVENT_PAGE_DETAILS.PRODUCT_ITEM_SUCCESS:
      return {
        ...state,
        eventPageProductItems: action.payload,
        eventDetailsStatus: 'SUCCESS',
      };
    case EVENT_PAGE_DETAILS.PAYMENT_SUCCESS:
      return {
        ...state,
        eventPageOrderPaymentDetails: action.payload,
        eventDetailsStatus: 'SUCCESS',
      };
    case EVENT_PAGE_DETAILS.CUSTOM_TEMPLATE_SUCCESS:
      return {
        ...state,
        eventPageCustomTemplateDetails: action.payload,
        eventDetailsStatus: 'SUCCESS',
      };
    case EVENT_PAGE_DETAILS.EVENT_PAGE_SLUG_SUCCESS:
      return {
        ...state,
        eventPageSlug: action.payload,
      };
    case EVENT_PAGE_DETAILS.EVENT_PAGE_OFFLINE_DETAILS_SUCCESS:
      return {
        ...state,
        eventPageOfflineDetails: action.payload,
      };
    case EVENT_PAGE_DETAILS.EVENT_PAGE_OFFLINE_CREATE_ORDER:
      return {
        ...state,
        eventPageOrderDetails: undefined,
        eventPageOrderDetailsStatus: undefined,
      };
    case EVENT_PAGE_DETAILS.EVENT_PAGE_OFFLINE_ORDER_SUCCESS:
      return {
        ...state,
        eventPageOrderDetails: action.payload,
        eventPageOrderDetailsStatus: 'SUCCESS',
      };
    case EVENT_PAGE_DETAILS.EVENT_PAGE_OFFLINE_ORDER_FAILED:
      return {
        ...state,
        eventPageOrderDetailsStatus: 'FAILED',
      };
    case EVENT_PAGE_DETAILS.EVENT_PAGE_SPLIT_UP_SUCCESS:
      return {
        ...state,
        eventPageOrderSplitUp: action.payload,
      };
    case EVENT_PAGE_DETAILS.CLEAR_EVENT_DETAILS:
      return {
        ...state,
        addSeatedEvent: action.payload,
      };
    case EVENT_PAGE_DETAILS.EVENT_PAGE_PROMO_SUCCESS:
      return {
        ...state,
        eventPagePromosList: action.payload,
        eventDetailsStatus: 'SUCCESS',
      };
    case EVENT_PAGE_DETAILS.EVENT_PAGE_ALL_PROMO_SUCCESS:
      return {
        ...state,
        allEventPagePromosList: action.payload,
        eventDetailsStatus: 'SUCCESS',
      };
    default:
      return state;
  }
};
