import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import VisaIcon from './assets/imgs/visa.png';
import MasterIcon from './assets/imgs/master.png';
import AmexIcon from './assets/imgs/ae.png';
import DiscoverIcon from './assets/imgs/discover.png';
import DefaultCardIcon from './assets/imgs/default-card.svg';
import RupayIcon from './assets/imgs/rupay.png';
import AchIcon from './assets/imgs/ach.svg';


const LayoutMixin = css`
  width: ${props => (props.width
    ? props.width
    : 'auto'
  )};
  height: ${props => (props.height
    ? props.height
    : 'auto'
  )};
  padding-top: ${props => (props.paddingTop
    ? props.paddingTop
    : 0
  )};
  padding-right: ${props => (props.paddingRight
    ? props.paddingRight
    : 0
  )};
  padding-bottom: ${props => (props.paddingBottom
    ? props.paddingBottom
    : 0
  )};
  padding-left: ${props => (props.paddingLeft
    ? props.paddingLeft
    : 0
  )};
  margin-top: ${props => (props.marginTop
    ? props.marginTop
    : 0
  )};
  margin-right: ${props => (props.marginRight
    ? props.marginRight
    : 0
  )};
  margin-bottom: ${props => (props.marginBottom
    ? props.marginBottom
    : 0
  )};
  margin-left: ${props => (props.marginLeft
    ? props.marginLeft
    : 0
  )};
  border: ${props => (props.border
    ? props.border
    : 0
  )};
  border-top: ${props => (props.borderTop
    ? props.borderTop
    : 0
  )};
  border-right: ${props => (props.borderRight
    ? props.borderRight
    : 0
  )};
  border-bottom: ${props => (props.borderDown
    ? props.borderDown
    : 0
  )};
  border-left: ${props => (props.borderLeft
    ? props.borderLeft
    : 0
  )};
  border-radius: ${props => (props.borderRadius
    ? props.borderRadius
    : 0
  )};
  backdrop-filter: ${props => props.backdropFilter || 'unset'};
  opacity: ${props => props.opacity || 1};
  box-shadow: ${props => (props.boxShadow ? props.boxShadow : 'none')};
`;

const colorMixin = css`
  background-color: ${props => props.backgroundColor || 'transparent'};
  color: ${props => props.color || '#000'};
  :hover {
    background-color: ${props => props.hoverBackgroundColor || props.backgroundColor || 'inherit'};
  }
`;

const textMixin = css`
  font-size: ${props => props.fontSize || '14px'};
  font-weight: ${props => props.fontWeight || 400};
  cursor: ${props => props.cursor || 'auto'};
`;

export const DefaultTheme = {
  colors: {
    brand: 'rgba(41, 177, 204, 0.3)',
    highlight: '#29b1cc',
    gray500: '#F7F7F7',
    text: 'black',
    black: 'black',
    white: 'white',
  },
  font: {
    fontFamily: 'OpenSans',
  },
};


export const Page = styled.div`
  min-width: 100vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;
export const Loader = styled.div`
  &:before, &:after{
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #4A56FE;
}

&:before{
  z-index: 100;
  animation: spin 1s infinite;
}

&:after{
  border: 10px solid #ccc;
}

@keyframes spin{
  0%{
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%{
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
`;

export const FlexContainer = withTheme(styled.div`
  display: ${props => props.display};
  width: ${props => props.width};
  height: ${props => props.height};
  min-width: ${props => props.minWidth};
  min-height: ${props => props.minHeight};
  flex-direction: ${props => props.flexDirection};
  flex-wrap: ${props => props.flexWrap};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  align-content: ${props => props.alignContent};
  border-radius: ${props => props.borderRadius};
  ${LayoutMixin}
  ${colorMixin}
`);

FlexContainer.defaultProps = {
  flexDirection: 'row',
  width: 'auto',
  height: 'auto',
  minWidth: 'auto',
  minHeight: 'auto',
  backgroundColor: 'inherit',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
  display: 'flex',
};

export const Text = withTheme(styled.p`
  text-align: ${props => props.textAlign};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => props.textTransform};
  opacity: ${props => props.opacity};
  white-space: ${props => props.whiteSpace};
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

Text.defaultProps = {
  color: 'text',
  textAlign: 'left',
  textDecoration: 'none',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 400,
  opacity: 1,
  marginTop: '8px',
  marginBottom: '8px',
  whiteSpace: 'normal',
};

export const SpanText = withTheme(styled.span`
  text-align: ${props => props.textAlign};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => props.textTransform};
  opacity: ${props => props.opacity};
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

SpanText.defaultProps = {
  color: 'text',
  textAlign: 'left',
  textDecoration: 'none',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 400,
  opacity: 1,
  marginTop: '8px',
  marginBottom: '8px',
};

export const Input = withTheme(styled.input`
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
  cursor: ${props => props.cursor};
  ::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  :focus {
    border: solid 1px #29B1CC;
    outline: none;
    outline-style: none;
  }
  :active {
    border: solid 1px #29B1CC;
  }
`);

export const Textarea = withTheme(styled.textarea`
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
  cursor: ${props => props.cursor};
  ::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  :focus {
    border: solid 1px #29B1CC;
    outline: none;
    outline-style: none;
  }
  :active {
    border: solid 1px #29B1CC;
  }
`);

Textarea.defaultProps = {
  borderRadius: '4px',
  borderLeft: 'solid 1px #29B1CC',
  borderTop: 'solid 1px #29B1CC',
  borderRight: 'solid 1px #29B1CC',
  borderDown: 'solid 1px #29B1CC',
  marginTop: '8px',
  marginBottom: '8px',
  paddingTop: '6px',
  paddingBottom: '6px',
  paddingLeft: '4px',
  paddingRight: '2px',
  cursor: 'auto',
};
export const Select = withTheme(styled.select`
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
  cursor: ${props => props.cursor};
  ::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  :focus {
    border: solid 1px #29B1CC;
    outline: none;
    outline-style: none;
  }
  :hover {
    border: solid 1px #29B1CC;
    outline: none;
    outline-style: none;
  }
  :active {
    border: solid 1px #29B1CC;
  }
`);

Select.defaultProps = {
  borderRadius: '4px 0 0 4px',
  background: 'white',
  backgroundColor: 'white',
  border: 'solid 1px #29B1CC',
  marginTop: '8px',
  marginBottom: '8px',
  paddingTop: '6px',
  paddingBottom: '6px',
  paddingLeft: '4px',
  paddingRight: '2px',
  cursor: 'auto',
};

export const SubmitButton = withTheme(styled.button`
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
  cursor: ${props => props.cursor};
  ::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  :focus {
    // border: solid 1px #29B1CC;
    outline: none;
    outline-style: none;
  }
  :active {
    // border: solid 1px #29B1CC;
    transform: translateY(2px);
  }
`);

export const CancelButton = withTheme(styled.button`
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
  border: ${props => props.border};
  cursor: ${props => props.cursor};
  ::placeholder {
     color: #ffffff;
  }
  :focus {
    // border: solid 1px #29B1CC;
    outline: none;
    outline-style: none;
    color: #ffffff;
  }
  :hover {
    outline: none;
    outline-style: none;
    color: #ffffff;
  }
  :active {
    // border: solid 1px #29B1CC;
     color: #ffffff;
    // transform: translateY(2px);
  }
`);

SubmitButton.defaultProps = {
  width: 'auto',
  height: '40px',
  paddingLeft: '28px',
  paddingRight: '28px',
  marginRight: '-8px',
  backgroundColor: '#40A9FF',
  hoverBackgroundColor: '#30A0FF',
  borderRadius: '32px',
  boxShadow: '0 10px 20px 0 rgba(41, 177, 204, 0.3)',
  fontSize: '20px',
  color: 'white',
  cursor: 'pointer',
  marginTop: '32px',
  marginBottom: '20px',
};

CancelButton.defaultProps = {
  width: 'auto',
  height: '40px',
  paddingLeft: '28px',
  paddingRight: '28px',
  marginRight: '10px',
  backgroundColor: '#FFFFFF',
  hoverBackgroundColor: '#30A0FF',
  borderRadius: '32px',
  // boxShadow:"0 10px 20px 0 rgba(41, 177, 204, 0.3)",
  fontSize: '20px',
  color: '#000000',
  cursor: 'pointer',
  marginTop: '32px',
  marginBottom: '20px',
  border: 'solid 1px #29B1CC',
};

export const SpButton = withTheme(styled.button`
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
  cursor: ${props => props.cursor};
  ::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  :focus {
    // border: solid 1px #29B1CC;
    outline: none;
    outline-style: none;
  }
  :active {
    // border: solid 1px #29B1CC;
    transform: translateY(2px);
  }
`);

SpButton.defaultProps = {
  width: 'auto',
  height: '40px',
  paddingLeft: '28px',
  paddingRight: '28px',
  marginRight: '-8px',
  backgroundColor: '#FFFFFF',
  borderColor: '#40A9FF',
  borderRadius: '32px',
  boxShadow: '0 10px 20px 0 rgba(41, 177, 204, 0.3)',
  fontSize: '20px',
  color: 'black',
  cursor: 'pointer',
  marginTop: '32px',
  marginBottom: '20px',
};

export const RoundButton = withTheme(styled.button`
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
  cursor: ${props => props.cursor};
  ::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  :hover {
    outline: none;
    outline-style: none;
    border: 2px solid #A8CAFF !important;
  }
  :active {
    outline: none;
    outline-style: none;
    border: 2px solid #3E8BFF !important;
  }
  :focus {
    outline: none;
    outline-style: none;
    border: 2px solid #3E8BFF !important;
  }
`);

RoundButton.defaultProps = {
  width: '44px',
  height: '44px',
  backgroundColor: '#FFFFFF',
  borderColor: '#EBEBEB',
  border: '2px solid #EBEBEB !important',
  borderRadius: '50px',
  color: 'black',
  cursor: 'pointer',
  marginTop: '32px',
  marginBottom: '20px',
};

export const InputWrapper = withTheme(styled.div`
  ${LayoutMixin}
  display: ${props => props.display};
  border: solid 1px #29B1CC;
  position:${props => props.position};
  border-radius: ${props => props.borderRadius};
`);

InputWrapper.defaultProps = {
  borderRadius: '4px',
  display: 'inline-block',
  position: 'relative',
};

Input.defaultProps = {
  borderRadius: '4px',
  borderLeft: 'solid 1px #29B1CC',
  borderTop: 'solid 1px #29B1CC',
  borderRight: 'solid 1px #29B1CC',
  borderDown: 'solid 1px #29B1CC',
  marginTop: '8px',
  marginBottom: '8px',
  paddingTop: '6px',
  paddingBottom: '6px',
  paddingLeft: '4px',
  paddingRight: '2px',
  width: '298px',
  cursor: 'auto',
};

export const SelectBox = withTheme(styled.select`
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
  width: ${props => props.width};
  cursor: ${props => props.cursor};
  ::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }
  :focus {
    border: solid 1px #29B1CC;
    outline: none;
    outline-style: none;
  }
  :active {
    border: solid 1px #29B1CC;
  }
`);

SelectBox.defaultProps = {
  borderRadius: '4px',
  borderLeft: 'solid 1px #29B1CC',
  borderTop: 'solid 1px #29B1CC',
  borderRight: 'solid 1px #29B1CC',
  borderDown: 'solid 1px #29B1CC',
  marginTop: '8px',
  marginBottom: '8px',
  paddingTop: '6px',
  paddingBottom: '6px',
  paddingLeft: '4px',
  paddingRight: '2px',
  width: '298px',
  cursor: 'auto',
};

type Props = {
  isOpen: Boolean
};

export const Spinner = (props: Props) => (props.isOpen ? (
  <Page
    id="spinner"
    style={{
      position: 'fixed',
      left: 0,
      top: 0,
      backdropFilter: 'blur(.8px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      width: '100%',
    }}
  >
    <StyledSpinner viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="4"
      />
    </StyledSpinner>
  </Page>
) : null);

const StyledSpinner = styled.svg`
  animation: rotate 2s linear infinite;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;
  
  & .path {
    stroke: #5652BF;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;


export const Img = withTheme(styled.img`
  vertical-align: ${props => props.verticalAlign};
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

Img.defaultProps = {
  verticalAlign: 'unset',
};

const ButtonColors = {
  secondary: {
    default: {
      backgroundColor: '#FFF',
      color: '#595959',
      borderColor: '#40A9FF',
    },
    hover: {
      backgroundColor: '#FFF',
      color: '#1791FF',
      borderColor: '#1791FF',
    },
    active: {
      backgroundColor: '#FFF',
      color: '#1F87DC',
      borderColor: '#1F87DC',
    },
  },
  primary: {
    default: {
      backgroundColor: '#40ADFF',
      color: '#FFF',
      borderColor: '#40A9FF',
    },
    hover: {
      backgroundColor: '#67BBFF',
      color: '#FFF',
      borderColor: '#67BFF',
    },
    active: {
      backgroundColor: '#1F87DC',
      color: '#FFF',
      borderColor: '#1F87DC',
    },
  },
  danger: {
    default: {
      backgroundColor: '#FF4D50',
      color: '#FFF',
      borderColor: '#FF4D50',
    },
    hover: {
      backgroundColor: '#FF8A89',
      color: '#FFF',
      borderColor: '#FF8A89',
    },
    active: {
      backgroundColor: '#E2373A',
      color: '#FFF',
      borderColor: '#E2373A',
    },
  },
  dangerSecondary: {
    default: {
      backgroundColor: '#FFF',
      color: '#FF4D50',
      borderColor: '#FF4D50',
    },
    hover: {
      backgroundColor: '#FFF',
      color: '#FF8A89',
      borderColor: '#FF8A89',
    },
    active: {
      backgroundColor: '#FFF',
      color: '#E2373A',
      borderColor: '#E2373A',
    },
  },
};

export const Button = withTheme(styled.button`
  ${LayoutMixin}
  ${colorMixin}
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => ButtonColors[props.type].default.backgroundColor}; 
  color: ${(props) => ButtonColors[props.type].default.color}; 
  border-color: ${(props) => ButtonColors[props.type].default.borderColor}; 
  &:hover {
    background-color: ${(props) => ButtonColors[props.type].hover.backgroundColor}; 
    color: ${(props) => ButtonColors[props.type].hover.color}; 
    border-color: ${(props) => ButtonColors[props.type].hover.borderColor}; 
  }
  &:active {
    background-color: ${(props) => ButtonColors[props.type].active.backgroundColor}; 
    color: ${(props) => ButtonColors[props.type].active.color}; 
    // border-color: ${(props) => ButtonColors[props.type].active.borderColor}; 
    top: 2px;
    outline: none;
    transform: translateY(2px);
  }
  outline:none !important;
  cursor:pointer;
`);

export const Hr = withTheme(styled.hr`
  ${LayoutMixin}
  ${colorMixin}
`);

Hr.defaultProps = {
  width: '100%',
  marginTop: '15px',
  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
};


export const Td = withTheme(styled.td`
  vertical-align: ${props => props.verticalAlign} !important;
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

Td.defaultProps = {
  verticalAlign: 'middle',
};

export const TH = withTheme(styled.th`
  vertical-align: ${props => props.verticalAlign} !important;
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

TH.defaultProps = {
  verticalAlign: 'middle',
};

export const TD = withTheme(styled.td`
  vertical-align: ${props => props.verticalAlign} !important;
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

TD.defaultProps = {
  verticalAlign: 'middle',
};

Button.defaultProps = {
  type: 'primary',
};

const schemes = {
  mastercard: MasterIcon,
  master: MasterIcon,
  visa: VisaIcon,
  discover: DiscoverIcon,
  americanexpress: AmexIcon,
  amex: AmexIcon,
  rupay: RupayIcon,
  ach: AchIcon,
};

type CsProps = {
  scheme: string,
  className: string,
  style: Object
}

export const CardSchemeIcon = ({ scheme, className, style }: CsProps) => {
  const schemeName = scheme && scheme.toString().replace(/[^a-zA-Z ]/g, '')
    .toLowerCase();
  let icon = DefaultCardIcon;
  const iconName = schemes[schemeName];
  if (iconName) {
    icon = iconName;
  }
  return (
    <img
      style={style}
      className={className}
      alt=""
      src={icon}
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = DefaultCardIcon;
      }}
    />
  );
};


// Left Panel Design kits

export const LeftBackButtonText = withTheme(styled.p`
  text-align: ${props => props.textAlign};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => props.textTransform};
  opacity: ${props => props.opacity};
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
  &:before{
  content: "←";
  border: 0px;
  width: 15px;
  height: 13px;
  left: 10px;
  transition: all 500ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
  display: inline-block; 
  }
  &:hover {
    color: #1890ff;
  }
  &:hover::before {
    transform: translateX(-5px);
  }
`);

LeftBackButtonText.defaultProps = {
  color: 'text',
  textAlign: 'left',
  textDecoration: 'none',
  fontWeight: 400,
  opacity: 1,
  marginTop: '8px',
  fontSize: '10px',
  textTransform: 'uppercase',
  marginBottom: '30px',
  cursor: 'pointer',
};

export const H1Text = withTheme(styled.p`
  text-align: ${props => props.textAlign};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => props.textTransform};
  opacity: ${props => props.opacity};
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

H1Text.defaultProps = {
  color: 'text',
  textAlign: 'left',
  textDecoration: 'none',
  fontWeight: 400,
  opacity: 1,
  fontSize: '20px',
  textTransform: 'uppercase',
  marginTop: '0px',
  marginBottom: '24px',
};

export const H2Text = withTheme(styled.p`
  text-align: ${props => props.textAlign};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => props.textTransform};
  opacity: ${props => props.opacity};
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

H2Text.defaultProps = {
  color: 'text',
  textAlign: 'left',
  textDecoration: 'none',
  fontWeight: 400,
  textTransform: 'uppercase',
  fontSize: '14px',
  opacity: '0.7',
  marginTop: '8px',
  marginBottom: '15px',
};

export const DescriptionText = withTheme(styled.p`
  text-align: ${props => props.textAlign};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => props.textTransform};
  opacity: ${props => props.opacity};
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

DescriptionText.defaultProps = {
  color: 'text',
  textAlign: 'left',
  textDecoration: 'none',
  fontSize: '14px',
  marginTop: '8px',
  marginBottom: '15px',
  fontWeight: '600',
};

export const LabelText = withTheme(styled.p`
  text-align: ${props => props.textAlign};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => props.textTransform};
  opacity: ${props => props.opacity};
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

LabelText.defaultProps = {
  textAlign: 'left',
  textDecoration: 'none',
  fontSize: '14px',
  fontWeight: '600',
  color: '#6B6C6F',
  marginBottom: '0px',
  marginTop: '15px',
};

export const PText = withTheme(styled.p`
  text-align: ${props => props.textAlign};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => props.textTransform};
  opacity: ${props => props.opacity};
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

PText.defaultProps = {
  color: 'text',
  textAlign: 'left',
  textDecoration: 'none',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 400,
  opacity: 1,
  marginTop: '8px',
  marginBottom: '8px',
};

export const PH1Text = withTheme(styled.p`
  text-align: ${props => props.textAlign};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => props.textTransform};
  opacity: ${props => props.opacity};
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

PH1Text.defaultProps = {
  color: 'text',
  textAlign: 'left',
  textDecoration: 'none',
  textTransform: 'none',
  fontSize: '20px',
  fontWeight: 900,
  opacity: 1,
  marginTop: '8px',
  marginBottom: '8px',
};

export const PH2Text = withTheme(styled.p`
  text-align: ${props => props.textAlign};
  text-decoration: ${props => props.textDecoration};
  text-transform: ${props => props.textTransform};
  opacity: ${props => props.opacity};
  ${LayoutMixin}
  ${colorMixin}
  ${textMixin}
`);

PH2Text.defaultProps = {
  color: 'text',
  textAlign: 'left',
  textDecoration: 'none',
  textTransform: 'none',
  fontSize: '14px',
  fontWeight: 400,
  opacity: 1,
  marginTop: '8px',
  marginBottom: '8px',
};

// $FlowFixMe
export const UpiPanel = styled.div({
  height: '56px ',
  borderRadius: '6px',
  border: '1px solid #DCDCDC',
});

export const PH3Text = withTheme(styled.p`
  color: ${props => props.color};
  font-size: ${(props) => (props.fontSize)};
  font-weight: ${(props) => (props.fontWeight)};
  font-size: ${(props) => (props.fontSize)};
  font-weight: ${(props) => (props.fontWeight)};
  margin-top:  ${(props) => (props.marginTop)};
  margin-left:  ${(props) => (props.marginLeft)};
  margin-bottom:  ${(props) => (props.marginBottom)};
`);

PH3Text.defaultProps = {
  color: '#2C2C2C',
  fontSize: '15px',
  fontWeight: 600,
  marginTop: '-28px',
  marginLeft: '45px',
};

// $FlowFixMe
export const UpiPanel1 = styled.div({
  height: '371px ',
  Width: '343px',
  borderRadius: '11px',
  border: '1px solid #E3E3E3',
});

// $FlowFixMe
export const UpiPanel2 = styled.div({
  height: '371px ',
  Width: '343px',
  borderRadius: '11px',
  border: '1px solid #E3E3E3',
  textAlign: 'center',
});
