import React from 'react';
import formatNumber from 'utils/formatNumber';
import {
  Row,
  Col,
  Switch,
} from 'antd';
import {
  SpForm,
  SpH5,
} from 'components/DesignKit';

type Props = {
  transferSettings: Object,
  selectedAccount: Object,
};

const View = (props: Props) => {
  const {
    transferSettings,
    selectedAccount,
  } = props;

  const { prefix } = selectedAccount && selectedAccount.currency;
  const currencyType = selectedAccount && selectedAccount.currency;

  return (
    <>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Minimum Limit</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {transferSettings.minLimit
                ? `${prefix}${formatNumber((transferSettings.minLimit / 100).toFixed(2), currencyType)}`
                : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Maximum Limit</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {transferSettings.maxLimit
                ? `${prefix}${formatNumber((transferSettings.maxLimit / 100).toFixed(2), currencyType)}`
                : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Minimum Approval Limit</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {transferSettings.minApprovalLimit
                ? `${prefix}${formatNumber((transferSettings.minApprovalLimit / 100).toFixed(2), currencyType)}`
                : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Approval Email</span>
          </Col>
          <Col span={9}>
            <SpH5>{transferSettings.approvalEmail
              ? transferSettings && transferSettings.approvalEmail
              : <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Smart Routing</span>
          </Col>
          <Col span={9}>
            <Switch
              className="mr-2 ml-2"
              checked={transferSettings?.enableSmartRouting}
              disabled
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Display Active Invoice</span>
          </Col>
          <Col span={9}>
            <Switch
              className="mr-2 ml-2"
              checked={transferSettings?.showActiveInvoice}
              disabled
            />
          </Col>
        </Row>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Default Transfer Type</span>
          </Col>
          <Col span={9}>
            <SpH5>
              {transferSettings?.defaultTransferTypeCode === 'RTP'
                ? 'INSTANT PAYMENT'
                : transferSettings?.defaultTransferTypeCode?.replace(/_/g, ' ') || <>&#8211;</>}
            </SpH5>
          </Col>
        </Row>
      </SpForm>
    </>
  );
};

export default React.memo(View);
