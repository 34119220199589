// @flow
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import debounce from 'lodash.debounce';
import {
  Row,
  Col,
  Card,
  Empty,
} from 'antd';

import {
  accountActions,
  settingsActions,
  acquirerActions,
  issuerActions,
  ratesActions,
  sequenceGeneratorsActions,
} from 'store/actions';

import { SpButton, SpText } from 'components/DesignKit';
import Loading from 'components/Loading';
import {
  SETTINGS,
  ACCOUNT,
} from 'store/actionTypes';

import getSortedList from 'utils/getSortedList';

import RBAC from 'components/rbac';
import { ROLES } from 'appconstants';
import Forbidden from 'components/forbidden';
import Edit from './components/Edit';
import View from './components/View';
import UpdateMerchant from './components/UpdateMerchant';

type Props = {
  selectedAccount: Object,
  internalAccount: Object,
  paymentType: Object,
  allSequence: Object,
  allPOSSequence: Array,
  // fetchAccountById: Function,
  fetchAccountByInternalId: Function,
  fetchAcquirer: Function,
  fetchIssuer: Function,
  fetchPaymentType: Function,
  fetchAllSequence: Function,
  addSequenceGenerators: Function,
  updateSequenceGenerator: Function,
  updateAccountInfo: Function,
  allowCrossTransfer: Function,
  updateInternalUserAccount: Function,
  loading: boolean,
  validator: boolean,
  acquirer: Object,
  issuer: Object,
  role: Object,
  addPOSSequenceGenerators: Function,
  fetchAllPOSSequence: Function,
  updatePOSSequenceGenerator: Function,
};

const AccountSettings = (props: Props) => {
  const {
    selectedAccount,
    allSequence,
    internalAccount,
    paymentType,
    loading,
    validator,
    // fetchAccountById,
    fetchAccountByInternalId,
    fetchAcquirer,
    fetchIssuer,
    fetchPaymentType,
    fetchAllSequence,
    addSequenceGenerators,
    updateSequenceGenerator,
    updateAccountInfo,
    allowCrossTransfer,
    updateInternalUserAccount,
    role,
    acquirer,
    issuer,
    addPOSSequenceGenerators,
    fetchAllPOSSequence,
    allPOSSequence,
    updatePOSSequenceGenerator,
  } = props;
  const gid = selectedAccount && selectedAccount.gid;
  const [editSettings, setEdit] = useState(false);
  const [updateMerchant, setUpdate] = useState(false);
  const [redirect, setRedirect] = useState(false);

  // const debouncedFetchAccountById = debounce(fetchAccountById, 2000, { leading: true });

  const getData = () => {
    if (gid) {
      const sortParams = '&sortBy=updatedAt&direction=DESC';
      const acquireParams = '?page=1&size=1000';
      fetchAllPOSSequence({ sortParams });
      // debouncedFetchAccountById(gid);
      if ((role && role.name) === 'superuser') {
        fetchAccountByInternalId(gid);
        fetchAcquirer({ acquireParams, sortParams });
        fetchIssuer({ sortParams });
        fetchPaymentType({ sortParams });
        fetchAllSequence({ sortParams });
      }
    }
  };

  useEffect(() => {
    getData();
  }, [gid]);

  useEffect(() => {
    setEdit(false);
  }, [selectedAccount]);

  useEffect(() => {
    if (!loading) {
      setEdit(false);
    }
    if (redirect) {
      setUpdate(true);
    }
  }, [loading]);

  useEffect(() => {
    if (!validator) {
      getData();
    }
  }, [validator]);

  const updateCrossBorder = (params) => {
    allowCrossTransfer({
      gid,
      params,
    });
  };

  const updateAccount = (params) => {
    updateAccountInfo({
      gid,
      params,
    });
    if (params.configureSequence) {
      const { dateFormat, currentSequence, prefix } = params;
      const payload = { dateFormat, currentSequence, prefix };
      if (allSequence.length > 0) {
        const accountGid = allSequence[0]?.gid;
        updateSequenceGenerator({ accountGid, ...payload });
      } else {
        addSequenceGenerators({
          gid,
          superUser: (role.name === ROLES.SUPERUSER),
          ...payload,
        });
      }
    }
    if (params.configurePOSSequence) {
      const { maxCount, currentPOSSequence, closeOutTime } = params;
      const payload = {
        type: 'POS',
        currentSequence: Number(currentPOSSequence),
        maxCount: Number(maxCount),
        closeOutTime: Number(closeOutTime),
        disableCloseOutTime: closeOutTime === '',
        disableMaxCount: maxCount === '',
      };
      if (allPOSSequence?.length > 0) {
        const accountGid = allPOSSequence[0]?.gid;
        updatePOSSequenceGenerator({ accountGid, ...payload });
      } else {
        addPOSSequenceGenerators({
          gid,
          superUser: (role.name === ROLES.SUPERUSER),
          ...payload,
        });
      }
    }
  };
  const updateInternalAccount = (params) => {
    updateInternalUserAccount({
      gid,
      params,
    });
    setRedirect(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card>
      <Row type="flex" justify="space-between" className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            {updateMerchant ? 'Update Merchant' : 'Manage Account Settings'}
          </SpText>
        </Col>
        <Col>
          {
            !editSettings && !updateMerchant
            && (
              <RBAC permit={[ROLES.SUPERUSER]}>
                <SpButton
                  className="mr-4"
                  type="secondary"
                  shape="round"
                  onClick={() => setUpdate(true)}
                >
                  Update Merchant Details
                </SpButton>
              </RBAC>
            )
          }
          {
            !editSettings && !updateMerchant
            && (
              <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}>
                <SpButton
                  type="secondary"
                  shape="round"
                  onClick={() => setEdit(true)}
                >
                  Edit Account Settings
                </SpButton>
              </RBAC>
            )
          }
        </Col>
      </Row>
      {
        editSettings
          ? (
            <RBAC fallback={<Forbidden />} permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}>
              <Edit
                selectedAccount={selectedAccount}
                updateAccount={updateAccount}
                allSequence={allSequence}
                allPOSSequence={allPOSSequence && allPOSSequence}
                setEdit={() => setEdit(false)}
                role={role}
                updateCrossBorder={updateCrossBorder}
              />
            </RBAC>
          )
          : updateMerchant ? (
            <RBAC fallback={<Forbidden />} permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}>
              <UpdateMerchant
                internalAccount={internalAccount}
                updateInternalAccount={updateInternalAccount}
                paymentType={getSortedList(paymentType)}
                acquirer={getSortedList(acquirer)}
                issuer={getSortedList(issuer)}
                setUpdate={() => setUpdate(false)}
                selectedAccount={selectedAccount}
              />
            </RBAC>
          )
            : (
              <RBAC
                fallback={<Empty />}
                // eslint-disable-next-line
                permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.POS, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]}
              >
                <View selectedAccount={selectedAccount} allSequence={allSequence && allSequence} allPOSSequence={allPOSSequence && allPOSSequence} />
              </RBAC>
            )
      }
    </Card>
  );
};

const mapStateToProps = state => ({
  selectedAccount: state.account.selectedAccount,
  internalAccount: state.account.internalAccount,
  acquirer: state.acquirer.content,
  issuer: state.issuer.content,
  isSuperUser: state.user.superUser,
  loading: state.loading.loading,
  validator: state.loading.validator,
  paymentType: state.rates.paymenttype.content,
  role: (state.user.internalRole ? state.user.internalRole : state.user.role),
  allSequence: state.sequenceGenerators.content,
  allPOSSequence: state?.sequenceGenerators?.possequence?.content,
});

const mapDispatchToProps = dispatch => ({
  fetchAccountById: gid => dispatch(accountActions.fetchAccountById({
    type: ACCOUNT.FETCH_BY_ID,
    payload: gid,
  })),
  fetchAccountByInternalId: gid => dispatch(accountActions.fetchAccountByInternalId({
    type: ACCOUNT.FETCH_BY_INTERNAL_ID,
    payload: gid,
  })),
  updateAccountInfo: params => dispatch(settingsActions.updateAccountInfo({
    type: SETTINGS.UPDATE,
    payload: params,
  })),
  allowCrossTransfer: params => dispatch(settingsActions.allowCrossTransfer({
    type: SETTINGS.UPDATE,
    payload: params,
  })),
  fetchAcquirer: param => dispatch(acquirerActions.fetchAcquirer({
    payload: param,
  })),
  fetchIssuer: param => dispatch(issuerActions.fetchIssuer({
    payload: param,
  })),
  updateInternalUserAccount: gid => dispatch(accountActions.updateInternalUserAccount({
    type: ACCOUNT.INTERNAL_UPDATE,
    payload: gid,
  })),
  fetchPaymentType: param => dispatch(ratesActions.fetchPaymentType({
    payload: param,
  })),
  fetchAllSequence: param => dispatch(sequenceGeneratorsActions.fetchAllSequence({
    payload: param,
  })),
  fetchAllPOSSequence: param => dispatch(sequenceGeneratorsActions.fetchAllPOSSequence({
    payload: param,
  })),
  addSequenceGenerators: param => dispatch(sequenceGeneratorsActions.addSequence({
    payload: param,
  })),
  addPOSSequenceGenerators: param => dispatch(sequenceGeneratorsActions.addPOSSequence({
    payload: param,
  })),
  updateSequenceGenerator: param => dispatch(sequenceGeneratorsActions.updateSequenceGenerator({
    payload: param,
  })),
  updatePOSSequenceGenerator: param => dispatch(sequenceGeneratorsActions.updatePOSSequenceGenerator({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountSettings);
