import { SEQUENCE_GENERATORS } from '../actionTypes';

const initialState = {
  content: [],
  invoiceSequence: [],
  currentSequence: [],
  possequence: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEQUENCE_GENERATORS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case SEQUENCE_GENERATORS.POS_SUCCESS:
      return {
        ...state,
        possequence: action.payload,
      };
    case SEQUENCE_GENERATORS.INVOICE_SUCCESS:
      return {
        ...state,
        invoiceSequence: action.payload,
      };
    case SEQUENCE_GENERATORS.INVOICE_CURRENT_SUCCESS:
      return {
        ...state,
        currentSequence: action.payload,
      };
    default:
      return state;
  }
};
