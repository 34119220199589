// @flow
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  Card,
  Row,
  Col,
  Switch,
  Input,
  Upload,
  message,
  Icon,
  TimePicker,
  Select,
  Table,
  Tooltip,
  Menu,
  Radio,
  notification,
  Collapse,
  Checkbox,
} from 'antd';
import '../../../../styles/_additembycategory.scss';
import imageOnError from 'utils/imageOnError';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  validators,
} from 'utils/validationMessages';

import {
  SpText,
  SpH5,
  SpButton,
  SpError,
  SpTab,
  SpForm,
} from 'components/DesignKit';

import { NOTIFICATION } from 'store/actionTypes';
import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';
import styled from 'styled-components';
import Loading from 'components/Loading';
import getStateList from 'utils/stateList';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import {
  inventoryShopsDetailsActions,
  inventoryShopsActions,
  inventoryQuickSupportActions,
  inventoryFeedbackActions,
  inventoryItemsActions,
  notificationActions,
  accountSlugActions,
  serviceRatesActions,
  inventoryCategorieDetailsActions,
} from 'store/actions';
import {
  PAGES,
  MESSAGES,
  COUNTRY_CODES,
  RATE_TYPES,
  ORDER_MODES,
  STORE_TYPES,
} from 'appconstants';
import formatNumber from 'utils/formatNumber';
import BannerPreview from 'components/NavIcons/bannerPreview';
import Previewicon from 'components/NavIcons/previewIcon';
import Uploadicon from 'components/NavIcons/uploadIcon';
import debounce from 'lodash.debounce';
import AddNewTip from './components/AddNewTip';
import DeleteTip from './components/DeleteTip';
import AddPromo from './components/AddPromo';
import EditPromoCode from './components/EditPromo';
import DeletePromoCode from './components/DeletePromoCode';
import AddAssosiate from './components/AddAssosiate';
import EditAssosiate from './components/EditAssosiate';
import AddSupport from './components/AddSupport';
import EditSupport from './components/EditSupport';
import DeleteSupport from './components/DeleteSupport';
import AddFeedback from './components/AddFeedback';
import EditFeedback from './components/EditFeedback';
import DeleteFeedback from './components/DeleteFeedback';
import DeleteAssosiate from './components/DeleteAssosiate';
import AddRemoveTaxRates from './components/AddRemoveTaxRates';
import RemoveTaxRates from './components/RemoveTaxRates';
import addItemsImage from '../../../../assets/imgs/addItemsShop.png';
import AddRemoveTaxes from './components/AddRemoveTaxes';
import DeleteShops from './components/DeleteOrderType';

const { Option } = Select;
const { Panel } = Collapse;


const RadioGroup = Radio.Group;


type Props = {
  fetchInventoryShopsDetails: Function,
  deleteInventoryShops: Function,
  fetchItemByShops: Function,
  addItemShops: Function,
  addTips:Function,
  fetchAccountSlug: Function,
  fetchShopsSlug: Function,
  fetchShopRates: Function,
  linkAccountSlug: Function,
  unLinkAccountSlug: Function,
  setNotification: Function,
  getAllItemsByCategory:Function,
  getItemsBySelectedCategory:Function,
  categoryItemsList: Array,
  inventoryShopsDetails: Object,
  accountSlug: Array<Object>,
  ratesList: Array<Object>,
  categoryList: Array<Object>,
  shopPromosList: Array,
  promosList: Array,
  inventoryItems: Array,
  loading: boolean,
  selectedAccount: Object,
  paymentSlug: Array,
  updateInventoryShops: Function,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  submitting: Boolean,
  validator: Boolean,
  shopsItemsList: Array,
  totalElements: Number,
  totalPromoElements: Number,
  totalQuickSupportElements: Number,
  totalInventoryFeedbackElements: Number,
  fetchPromosList: Function,
  fetchItemsList: Function,
  fetchPromosByShop: Function,
  fetchAllPromosByShop: Function,
  addPromosByShop: Function,
  updatePromosByShop: Function,
  deletePromosByShop: Function,
  deleteTipsByShop:Function,
  allShopPromosList: Array,
  addInventoryShopAssociate: Function,
  fetchInventoryShopAssociate: Function,
  updateInventoryShopAssociate: Function,
  deleteInventoryShopAssociate: Function,
  assosiateList: Object,
  inventoryCategorieDetails: Array,
  fetchInventoryQuickSupport: Function,
  addInventoryQuickSupport: Function,
  addInventoryFeedback: Function,
  updateInventoryFeedback: Function,
  deleteInventoryFeedback: Function,
  getInventoryFeedack: Function,
  updateInventoryQuickSupport: Function,
  deleteInventoryQuickSupport: Function,
  inventoryQuickSupport: Array,
  inventoryFeedback: Array,
  fetchServicerates: Function,
  serviceRatesList: Array,
  error: Boolean,
  fetchTablesShops: Function,
}

const StyledDiv = styled.div`
  cursor: pointer;
  &: before {
    font-size: 12px;
    content: "✎ Edit";
    opacity: 0;
    color: #29b1cc;
    position: absolute; 
    top: 50%;
    left: 50%;
    font-weight: 500;
    border: 1px solid #29b1cc;
    padding: 2px 10px;
    border-radius: 20px;
    background-color: #fff;
    transform: translateX(-50%) translateY(-50%);
 }
 &: hover: before {
  opacity: 1;
 }
`;

const ShopsDetails = (props: Props) => {
  const location = useLocation();
  const {
    fetchInventoryShopsDetails,
    deleteInventoryShops,
    fetchItemByShops,
    addItemShops,
    inventoryShopsDetails,
    categoryItemsList,
    inventoryCategorieDetails,
    getAllItemsByCategory,
    getItemsBySelectedCategory,
    fetchAccountSlug,
    fetchShopsSlug,
    fetchShopRates,
    linkAccountSlug,
    unLinkAccountSlug,
    paymentSlug,
    accountSlug,
    ratesList,
    categoryList,
    setNotification,
    updateInventoryShops,
    loading,
    history,
    submitting,
    validator,
    selectedAccount,
    shopsItemsList,
    inventoryItems,
    totalElements,
    totalPromoElements,
    totalQuickSupportElements,
    totalInventoryFeedbackElements,
    fetchPromosList,
    fetchItemsList,
    fetchPromosByShop,
    fetchAllPromosByShop,
    shopPromosList,
    addPromosByShop,
    updatePromosByShop,
    promosList,
    deletePromosByShop,
    deleteTipsByShop,
    allShopPromosList,
    addInventoryShopAssociate,
    fetchInventoryShopAssociate,
    updateInventoryShopAssociate,
    deleteInventoryShopAssociate,
    assosiateList,
    fetchInventoryQuickSupport,
    addInventoryQuickSupport,
    updateInventoryQuickSupport,
    deleteInventoryQuickSupport,
    inventoryQuickSupport,
    inventoryFeedback,
    addInventoryFeedback,
    updateInventoryFeedback,
    deleteInventoryFeedback,
    getInventoryFeedack,
    fetchServicerates,
    serviceRatesList,
    addTips,
    error,
    fetchTablesShops,
  } = props;

  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const path = props.match.url;
  const [openPanelKey, setOpenPanelKey] = useState(null);
  const [editItemsModal, setEditItemsModal] = useState(false);
  const [editHoursModal, setEditHoursModal] = useState(false);
  const [addItemsModal, setAddItemsModal] = useState(false);
  const [addTipModal, setAddTipModal] = useState(false);
  const [editTaxModal, setEditTaxModal] = useState(false);
  const [addPromoModal, setAddPromoModal] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [deleteShopsModal, setDeleteShopsModal] = useState(false);
  const [selectedShops, setSelectedShops] = useState({});
  const [deletePromoModal, setDeletePromoModal] = useState(false);
  const [deleteTip, setDeleteTip] = useState(false);
  const [deleteTipData, setDeleteTipData] = useState([]);
  const [editPromoModal, setEditPromoModal] = useState(false);
  const [selectedPromoCode, setSelectedPromoCode] = useState({});
  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [items, setItems] = useState([]);
  const [logoUrl, setLogo] = useState('');
  const [preview, setPreview] = useState('');
  const [fileName, setFileName] = useState('');
  const [loader, setLoading] = useState(false);
  const [operatingHours, setOperatingHours] = useState([]);
  const [enableOperationHours, setEnableOperationHours] = useState(false);
  const [slugLink, setSlugLink] = useState();
  const [slugGid, setSlugGid] = useState();
  const [enableUnlink, setEnableUnlink] = useState(false);
  const [pagination, setPagination] = useState({});
  const [current, setCurrent] = useState('itemsTab');
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [zipTag, setZipTag] = useState('');
  const [countryCode, setCountryCode] = useState(getCountryAlpha2(selectedAccount && selectedAccount.country && selectedAccount.country.id));
  const [countryName, setCountryName] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [isFetchRequired, setIsFetchRequired] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [statesInCountry, setstatesInCountry] = useState([]);
  const [addAssociateModal, setAddAssociateModal] = useState(false);
  const [editAssociateModal, setEditAssociateModal] = useState(false);
  const [stateList, setSateList] = useState(getStateList(selectedAccount && selectedAccount.country && selectedAccount.country.id));
  const [orderModeData, setOrderMode] = useState('');
  const [addZipRecipient, setAddZipRecipient] = useState(['']);
  const [bannerText, setBannerText] = useState('');
  const [shopTab, setShopTab] = useState('Manage');
  const [addSupportModal, setAddSupportModal] = useState(false);
  const [addFeedbackModal, setAddFeedbackModal] = useState(false);
  const [editSupportModal, setEditSupportModal] = useState(false);
  const [deleteSupportModal, setDeleteSupportModal] = useState(false);
  const [editFeedbackModal, setEditFeedbackModal] = useState(false);
  const [deleteFeedbackModal, setDeleteFeedbackModal] = useState(false);
  const [deleteAssociateModal, setDeleteAssociateModal] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const kdsRole = localStorage.getItem('kdsRole');
  const [editTaxRateModal, setEditTaxRateModal] = useState(false);
  const [deleteTaxRateModal, setDeleteTaxRateModal] = useState(false);
  const [selectedTaxRate, setSelectedTaxRate] = useState({});
  const [tipType, setTipType] = useState('PERCENTAGE');
  const [tipAmount, setTipAmount] = useState(null);
  const [selectAllChecked, setSelectAllChecked] = useState('default');
  const [selectedCount, setSelectedCount] = useState(0);
  const [sendFeedbackNotificationMinutes, setSendFeedbackNotificationMinutes] = useState(5);
  const [bannerTextCount, setBannerTextCount] = useState(0);
  const [validationError, setValidationError] = useState(false);
  const [isCustomerPhoneRequired, setIsCustomerPhoneRequired] = useState();
  // eslint-disable-next-line max-len
  const nameRegex = /\p{RI}\p{RI}|\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?(\u{200D}\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?)+|\p{EPres}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})?|\p{Emoji}(\p{EMod}+|\u{FE0F}\u{20E3}?|[\u{E0020}-\u{E007E}]+\u{E007F})/gu;

  const STRING_LENGTH = 1;
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      bannerTextValid: {
        message: 'Emojis are not supporting',
        rule: val => !val.match(nameRegex),
      },
      minutesValidation: {
        message: 'Minutes range be between 5 and 1440.',
        rule: val => {
          const minutes = parseInt(val);
          return !isNaN(minutes) && minutes >= 5 && minutes <= 1440;
        },
      },

    },
  }));

  const [shopMode, setShopMode] = useState('WEB');
  const [enablePayAtStore, setEnablePayAtStore] = useState('');
  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const direction = parsed.direction || 'DESC';
    const params = `&sortBy=${parsed.sortBy || 'createdAt'}&direction=${direction}`;
    const size = 1000;
    if (!isSearch && !isFetchRequired) {
      fetchInventoryShopsDetails(gid);
      fetchShopsSlug(gid);
      fetchAllPromosByShop(gid);
      fetchInventoryShopAssociate(gid);
      fetchAccountSlug({ currentPage, params, size });
      fetchItemsList('&sortBy=createdAt&direction=DESC');
      fetchTablesShops(gid);
    }


    if (current === 'itemsTab') {
      fetchItemByShops(gid);
    }
    if (current === 'taxesTab') {
      fetchInventoryShopsDetails(gid);
      if (kdsRole !== 'KDS-USER') {
        fetchShopRates({ currentPage, params });
      }
    }
    if (current === 'serviceTab') {
      fetchInventoryShopsDetails(gid);
      if (kdsRole !== 'KDS-USER') {
        fetchServicerates({ currentPage, params, pageSize: 'size=100' });
      }
    }
    if (current === 'promosTab') {
      fetchPromosByShop({ gid, currentPage, params });
      if (kdsRole !== 'KDS-USER') {
        fetchPromosList({ currentPage, params });
      }
    }
    if (shopTab === 'QuickSupport') {
      fetchInventoryQuickSupport({ currentPage, params, gid });
    }
    if (shopTab === 'Feedback') {
      fetchInventoryShopsDetails(gid);

      getInventoryFeedack({ currentPage, params, gid });
    }
  };
  useEffect(() => {
    if (shopsItemsList && shopsItemsList.length > 0) {
      setItems(shopsItemsList.map(option => option.gid));
    } else {
      setItems([]);
    }
  }, [shopsItemsList]);

  useEffect(() => {
    if (paymentSlug && paymentSlug.length > 0) {
      setEnableUnlink(true);
      setSlugLink(paymentSlug[0].slugName);
      setSlugGid(paymentSlug[0].gid);
    } else {
      setEnableUnlink(false);
      setSlugLink('');
      setSlugGid('');
    }
  }, [paymentSlug]);

  useEffect(() => {
    if (categoryItemsList) setSelectedCount(categoryItemsList.filter((item) => item.added === 'true').length);
  }, [categoryItemsList]);

  const getTotalElements = () => {
    if (current === 'itemsTab') {
      return totalElements;
    } if (current === 'promosTab') {
      return totalPromoElements;
    } if (current === 'taxesTab') {
      return (inventoryShopsDetails && inventoryShopsDetails.taxRates) ? inventoryShopsDetails.taxRates.length : '';
    } if (current === 'serviceTab') {
      return (inventoryShopsDetails && inventoryShopsDetails.serviceFees) ? inventoryShopsDetails.serviceFees.length : '';
    }
    return null;
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: shopTab === 'QuickSupport' ? totalQuickSupportElements : shopTab === 'Feedback' ? totalInventoryFeedbackElements : getTotalElements(),
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [inventoryShopsDetails, shopsItemsList, shopPromosList, inventoryQuickSupport, inventoryFeedback]);

  useEffect(() => {
    setValidationError(error === 'Only 5 Questions Allowed');
  }, [error]);


  const handleTableChange = (page, sorter, filters) => {
    setIsSearch(true);
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.SHOPS}/${gid}`,
      search: `?pageNum=${tempPage}${tempParams}`,
    });
  };

  const handleCancelFeedback = () => {
    history.push({
      pathname: `${PAGES.SHOPS}`,
    });
  };

  const linkSlug = () => {
    const params = {
      type: 'SHOP',
      spObjectGid: gid,
      forceSet: false,
    };
    linkAccountSlug({ slugGid, params });
    setRedirect(true);
  };
  const unlinkSlug = async () => {
    unLinkAccountSlug(slugGid);
    setRedirect(true);
  };

  const addSelectedItem = (selectedItems) => {
    // const {
    //   categories,
    // } = customer;
    const listOfItems = (shopsItemsList && shopsItemsList.length !== 0) ? shopsItemsList.map((option) => option.gid) : null;
    const removedValues = (listOfItems && shopsItemsList.length !== 0) ? listOfItems.filter(item => !selectedItems.includes(item)) : null;
    let params;
    if (removedValues && removedValues.length !== 0) {
      params = {
        ADDED: selectedItems,
        REMOVED: removedValues,
      };
    } else {
      params = {
        ADDED: selectedItems,
      };
    }
    addItemShops({
      gid,
      params,
    });
    setRedirect(true);
  };


  const handleTipAmount = (value) => {
    setTipAmount(value);
  };

  const handleTipType = (value) => {
    setTipType(value || 'PERCENTAGE');
  };


  const AddTipsshop = () => {
    let params = [];
    params = {
      shopGid: gid,
      tipType,
      tip: tipAmount,
    };
    if (inventoryShopsDetails.tips?.length >= 5) {
      notification.error({
        message: <span style={{ color: 'red', fontWeight: 100 }}>Only 5 Tips allowed</span>,
      });
    } else {
      addTips({
        params,
      });
    }
    setRedirect(true);
  };

  const deleteTipsshop = (tipGid) => {
    const params = {
      tipGid,
      shopGid: gid,
    };
    deleteTipsByShop(params);
    setRedirect(true);
  };


  const editSelectedTaxes = (taxreates: Object) => {
    const {
      categories,
    } = taxreates;
    let params;
    let type;
    if (current === 'taxesTab') {
      params = {
        name,
        taxRateGids: categories || [],
      };
      type = 'Tax';
    }

    if (current === 'serviceTab') {
      params = {
        name,
        serviceFeeGids: [categories] || [],
      };
      type = 'Service';
    }

    updateInventoryShops({
      gid,
      params,
      type,
    });
    setRedirect(true);
  };

  const deleteSelectedTaxes = () => {
    const params = {
      name,
      serviceFeeGids: [],
    };
    updateInventoryShops({
      gid,
      params,
      type: 'Service Delete',
    });
    setRedirect(true);
  };

  const addSelectedPromos = (taxreates: Object) => {
    const {
      promocode,
      item,
      category,
      pausePromo,
      maximumRedemption,
    } = taxreates;
    let params;
    if (item.length > 0) {
      params = {
        promotionalCodeGid: promocode,
        itemGids: item,
        paused: pausePromo,
        maximumRedemption: maximumRedemption ? parseInt((maximumRedemption).replace(/[_\W]+/g, ''), 10) : null,
      };
    } else if (category.length > 0) {
      params = {
        promotionalCodeGid: promocode,
        inventoryCategoryGids: category,
        paused: pausePromo,
        maximumRedemption: maximumRedemption ? parseInt((maximumRedemption).replace(/[_\W]+/g, ''), 10) : null,
      };
    } else {
      params = {
        promotionalCodeGid: promocode,
        paused: pausePromo,
        maximumRedemption: maximumRedemption ? parseInt((maximumRedemption).replace(/[_\W]+/g, ''), 10) : null,
      };
    }
    addPromosByShop({
      gid,
      params,
    });
    setRedirect(true);
  };

  const deleteSelectedPromos = (promoGid: Object) => {
    deletePromosByShop({
      gid,
      promoGid,
    });
    setRedirect(true);
  };

  const editSelectedPromos = (editCodes: Object) => {
    const {
      item,
      category,
      pausePromo,
      shoppromoGid,
      maximumRedemption,
    } = editCodes;
    let params;
    const maximumRedemptionRequest = maximumRedemption ? parseInt((maximumRedemption).replace(/[_\W]+/g, ''), 10) : null;
    if (item.length > 0) {
      params = {
        itemGids: item,
        paused: pausePromo,
        maximumRedemption: maximumRedemptionRequest,
        removeMaximumRedemption: maximumRedemptionRequest === null,
      };
    } else if (category.length > 0) {
      params = {
        inventoryCategoryGids: category,
        paused: pausePromo,
        maximumRedemption: maximumRedemptionRequest,
        removeMaximumRedemption: maximumRedemptionRequest === null,
      };
    } else {
      params = {
        paused: pausePromo,
        inventoryCategoryGids: [],
        itemGids: [],
        maximumRedemption: maximumRedemptionRequest,
        removeMaximumRedemption: maximumRedemptionRequest === null,
      };
    }
    updatePromosByShop({
      gid,
      promoGid: shoppromoGid,
      params,
    });
    setRedirect(true);
  };

  const editSelectedAssociate = (editAssociateInfo: Object) => {
    const {
      firstName,
      lastName,
      customerEmail,
      customerPhone,
      associateGid,
      associateCountryCode,
    } = editAssociateInfo;
    const assiocateParams = {
      firstName,
      lastName,
      phone: `${associateCountryCode}${customerPhone}`,
      email: customerEmail,
    };
    updateInventoryShopAssociate({
      shopGid: gid,
      assosiateGid: associateGid,
      assiocateParams,
    });
    setRedirect(true);
  };

  const deleteSelectedAssociate = (deleteAssociateInfo: Object) => {
    const {
      associateGid,
    } = deleteAssociateInfo;
    deleteInventoryShopAssociate({
      shopGid: gid,
      assosiateGid: associateGid,
    });
    setRedirect(true);
  };

  const addSelectedAssociate = (editAssociateInfo: Object) => {
    const {
      firstName,
      lastName,
      customerEmail,
      customerPhone,
      associateCountryCode,
    } = editAssociateInfo;
    const assiocateParams = {
      firstName,
      lastName,
      phone: `${associateCountryCode}${customerPhone}`,
      email: customerEmail,
    };
    addInventoryShopAssociate({
      shopGid: gid,
      assiocateParams,
      type: 'Associate',
    });
    setRedirect(true);
  };

  const addSelectedSupport = (editAssociateInfo: Object) => {
    const {
      label,
      logoUrls,
    } = editAssociateInfo;
    const params = {
      shopGid: gid,
      name: label,
      iconUrl: logoUrls || null,
    };
    addInventoryQuickSupport({
      shopGid: gid,
      params,
    });
    setRedirect(true);
  };

  const addSelectedFeedback = (editFeedbackInfo: Object) => {
    const {
      label,
      question,
    } = editFeedbackInfo;
    const params = {
      shopGid: gid,
      enabled: false,
      label,
      question,
    };
    addInventoryFeedback({
      shopGid: gid,
      params,
    });

    setRedirect(true);
  };

  const editSelectedSupport = (editAssociateInfo: Object) => {
    const {
      label,
      logoUrls,
      quickGid,
    } = editAssociateInfo;
    const params = {
      shopGid: gid,
      name: label,
      iconUrl: logoUrls || null,
    };
    updateInventoryQuickSupport({
      shopGid: gid,
      quickGid,
      params,
    });
    setRedirect(true);
  };


  const editSelectedFeedback = (editAssociateInfo: Object) => {
    const {
      label,
      question,
      feedbackQuestionGid,
    } = editAssociateInfo;
    const params = {
      label,
      question,
      feedbackQuestionGid,
      gid,
    };
    updateInventoryFeedback({
      label,
      question,
      feedbackQuestionGid,
      gid,
    });
    setRedirect(true);
  };

  const enableFeedback = (record: Object, value) => {
    updateInventoryFeedback({
      gid,
      feedbackQuestionGid: record.gid,
      enabled: value,
    });
    setRedirect(true);
  };
  const handleenableFeedbackSms = (value) => {
    const params = {
      feedbackEnabled: value,
    };
    updateInventoryShops({
      gid,
      params,
      type: 'Service SMS',
    });
    // setRedirect(true);
  };

  const deleteSelectedSupport = (quickGid: Object) => {
    deleteInventoryQuickSupport({
      shopGid: gid,
      quickGid,
    });
    setRedirect(true);
  };

  const deleteSelectedFeedback = (quickGid: Object) => {
    deleteInventoryFeedback({
      feedbackQuestionGid: quickGid,
      gid,
    });
    setRedirect(true);
  };

  const selectedRecord = (record) => {
    const itemGid = record.gid;
    history.push({
      pathname: `${PAGES.INVENTORY_ITEMS}/${itemGid}`,
      state: location.search,
    });
  };

  useEffect(() => {
    setName((inventoryShopsDetails && inventoryShopsDetails.name));
    setBannerText((inventoryShopsDetails && inventoryShopsDetails.bannerText));
    setEnablePayAtStore(inventoryShopsDetails.enablePayAtStore);
    if (inventoryShopsDetails && inventoryShopsDetails.address) {
      setStreetName(inventoryShopsDetails.address.street);
      setCityName(inventoryShopsDetails.address.city);
      setStateName(inventoryShopsDetails.address.state);
      setZipCode(inventoryShopsDetails.address.postalCode);
    }
    if (inventoryShopsDetails && inventoryShopsDetails.banner) {
      setFileName(inventoryShopsDetails.banner);
      setLogo(inventoryShopsDetails.banner);
      setPreview(
        <img
          src={`${inventoryShopsDetails.banner}?time=${new Date().getTime()}`}
          alt=""
          onError={event => imageOnError(event)}
          style={{ width: '100%' }}
        />,
      );
    } else {
      setPreview('');
      setFileName('');
    }
    // console.log("OrderMode"+JSON.stringify(inventoryShopsDetails));

    setShopMode((inventoryShopsDetails && inventoryShopsDetails.shopType) || '');
    setOrderMode((inventoryShopsDetails && inventoryShopsDetails.orderMode && inventoryShopsDetails.orderMode[0]) || ORDER_MODES[0].value);
    setAddZipRecipient((inventoryShopsDetails && inventoryShopsDetails.zipcodesAvailableForDelivery) || ['']);
    setIsCustomerPhoneRequired(inventoryShopsDetails && inventoryShopsDetails.acceptCustomerPhoneNumber);
    // eslint-disable-next-line
    setEnableOperationHours(inventoryShopsDetails && inventoryShopsDetails.enableHoursOfOperation === true ? false : true);
    if (inventoryShopsDetails && inventoryShopsDetails.hoursOfOperations && inventoryShopsDetails.hoursOfOperations.length !== 0) {
      setOperatingHours([...(inventoryShopsDetails && inventoryShopsDetails.hoursOfOperations)]);
    } else {
      const list = [
        {
          dayOfWeek: 'MONDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'TUESDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'WEDNESDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'THURSDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'FRIDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'SATURDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
        {
          dayOfWeek: 'SUNDAY',
          operatingHours: [
            {
              openingTime: '',
              closingTime: '',
              open: false,
            },
          ],
        },
      ];
      setOperatingHours([...list]);
    }
  }, [inventoryShopsDetails]);

  const selectOperatingHours = (e, type, i, j) => {
    const list = [...operatingHours];
    const time = e.format('hh:mm a');
    const timeStamp = time.split(' ')[1];
    const actualTime = time.split(' ')[0];
    const a = actualTime.split(':');
    let minutes;
    if (a[0] === '12' && timeStamp === 'am') {
      minutes = +a[1];
    } else if (a[0] === '12' && timeStamp === 'pm' && type === 'From') {
      minutes = +a[0] * 60;
    } else {
      minutes = (+a[0] + (timeStamp === 'pm' ? 12 : 0)) * 60 + (+a[1]);
    }
    if (type === 'From') {
      list[i].operatingHours[j].openingTime = minutes;
    } else if (type === 'To') {
      list[i].operatingHours[j].closingTime = minutes;
    }
    setOperatingHours([...list]);
  };

  const updateStatus = (val, i, j) => {
    const list = [...operatingHours];
    list[i].operatingHours[j].open = val;
    setOperatingHours([...list]);
  };

  const getTimeVal = (id, index, type) => {
    if (type === 'From') {
      const fromTime = operatingHours[id].operatingHours[index].openingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          return moment(`${hours}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    } if (type === 'To') {
      const fromTime = operatingHours[id].operatingHours[index].closingTime;
      if (fromTime !== 0) {
        const hours = Math.floor(fromTime / 60);
        const minutes = fromTime % 60;
        if (hours === 12) {
          const hr = 0;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        if (hours === 24) {
          const hr = 12;
          return moment(`${hr}:${minutes}`, 'hh:mm a');
        }
        return moment(`${hours}:${minutes}`, 'hh:mm a');
      }
      return moment(`${24}:${0}`, 'hh:mm a');
    }
    return null;
  };

  const addTimeSlot = (dayIndex) => {
    const newTimeSlot = {
      openingTime: '',
      closingTime: '',
      open: false,
    };
    setOperatingHours((prevData) => {
      const updatedData = [...prevData];
      updatedData[dayIndex].operatingHours.push(newTimeSlot);
      return updatedData;
    });
  };

  const removeTimeSlot = (dayIndex, slotIndex) => {
    setOperatingHours((prevData) => {
      const updatedData = [...prevData];
      updatedData[dayIndex].operatingHours.splice(slotIndex, 1);
      return updatedData;
    });
  };

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <Uploadicon />}
      <div className="ant-upload-text mt-2">Click or drag the file here to upload</div>
      <div className="mt-2"><SpText color="rgba(0, 0, 0, 0.45)">Support extension: .jpg .png</SpText></div>
    </div>
  );

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    const IMAGE = 'image/*';
    const pattern = file.type.match(IMAGE);
    if (!pattern) {
      message.error('File is not image');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 5MB!');
      return false;
    }
    return true;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.SHOP_LOGO}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const imageDetails = {
          fileUrl: entity,
          keyType: 'ACCOUNT_BANNER',
        };
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.SHOP_LOGO}`, imageDetails);
        setLogo(fileUrl);
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview(<img src={imageUrl} alt="..." style={{ width: '115px' }} />);
          });
        } else {
          setPreview(<Previewicon />);
        }
        setFileName(info.file.name);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.SHOPS);
    }
    if (current !== 'hoursOfOperation') {
      setEditHoursModal(false);
    }
  }, [current]);

  useEffect(() => {
    const debouncedGetAllItemsByCategory = debounce(getAllItemsByCategory, 2000, { leading: true });
    if (current === 'itemsTab' && addItemsModal) debouncedGetAllItemsByCategory({});
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [addItemsModal]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData(location.search);
    if ((selectedAccount.country && selectedAccount.country.name) === 'India') {
      setCountryName('INDIA');
    } else if ((selectedAccount.country && selectedAccount.country.name) === 'United States of America') {
      setCountryName('USA');
    }
    if (selectedAccount && selectedAccount.country) {
      setstatesInCountry(getStateList(selectedAccount.country.id));
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [location, selectedAccount]);

  useEffect(() => {
    if (redirect && !validator) {
      setEditItemsModal(false);
      setEditTaxModal(false);
      setEditTaxRateModal(false);
      setDeleteTaxRateModal(false);
      setEditHoursModal(false);
      setEditAssociateModal(false);
      setDeleteAssociateModal(false);
      setAddAssociateModal(false);
      setAddPromoModal(false);
      setDeletePromoModal(false);
      setEditPromoModal(false);
      setAddItemsModal(false);
      setAddSupportModal(false);
      setAddFeedbackModal(false);
      setDeleteFeedbackModal(false);
      setEditSupportModal(false);
      setEditFeedbackModal(false);
      setDeleteSupportModal(false);
      setAddTipModal(false);
      setDeleteTip(false);
      setOpenPanelKey(null);
      if (shopTab !== 'QuickSupport') {
        getData(location.search);
      }
    }
  }, [validator]);

  useEffect(() => {
    if (redirect && !submitting) {
      setDeleteShopsModal(false);
      history.push(PAGES.SHOPS);
    }
  }, [submitting]);

  const editSelectedItem = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    let params;
    const updatedHours = (enableOperationHours === true) ? operatingHours.map(item => ({ ...item, open: false })) : operatingHours;
    if (editHoursModal === true) {
      params = {
        name,
        hoursOfOperations: updatedHours,
        // eslint-disable-next-line
        enableHoursOfOperation: enableOperationHours === true ? false : true,
      };
    } else {
      const compareValue = [''];
      params = {
        name,
        banner: logoUrl || '',
        bannerText,
        enablePayAtStore,
        orderMode: (orderModeData && orderModeData.length) === 0 ? [] : [orderModeData],
        shopType: (shopMode && shopMode.length) === 0 ? null : shopMode,
        zipcodesAvailableForDelivery: JSON.stringify(addZipRecipient) === JSON.stringify(compareValue) ? [] : addZipRecipient,
        acceptCustomerPhoneNumber: isCustomerPhoneRequired,
        address: {
          street: streetName,
          city: cityName,
          state: stateName,
          postalCode: zipCode,
          countryCode,
        },
      };
    }
    updateInventoryShops({
      gid,
      params,
    });
    setRedirect(true);
  };
  const showDeleteShopsModal = () => {
    setSelectedShops(inventoryShopsDetails);
    setDeleteShopsModal(true);
  };

  const deleteSelectedOrderType = (customerGid: Object) => {
    deleteInventoryShops({
      gid: customerGid,
    });
    setRedirect(true);
  };

  const isAvailableChange = (value) => {
    setEnableOperationHours(value);
  };

  const onRadioChange = (e) => {
    setOrderMode(e.target.value);

    if (e.target.value !== 'ONLINE_ORDER') {
      setEnablePayAtStore(false);
    // alert(e.target.value);
    }
  };

  const isEnablePayAtStore = (value) => {
    setEnablePayAtStore(value);
  };

  const onRadioChangeStore = (e) => {
    setShopMode(e.target.value);
  };


  const addNewRecipient = (event) => {
    event.preventDefault();
    setAddZipRecipient([...addZipRecipient, '']);
  };

  const handleRemoveZipCode = (i) => {
    const list = [...addZipRecipient];
    list.splice(i, 1);
    setAddZipRecipient(list);
  };

  const updateCountry = (value) => {
    setCountryCode(value);
    setSateList(getStateList(value));
  };

  const getOperatingTime = (time) => {
    if (time !== 0) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours > 12) {
        const hr = hours - 12;
        return `${hr}:${min} PM`;
      }
      if (hours === 12) {
        return '12:00 PM';
      }
      if (hours === 0) {
        return `${12}:${min} AM`;
      }
      return `${hours}:${min} AM`;
    }
    return '12:00 AM';
  };

  const showDeletePromoModal = (value: Object) => {
    setSelectedPromoCode(value);
    setDeletePromoModal(true);
  };

  const showEditPromoModal = (value: Object) => {
    setSelectedPromoCode(value);
    setEditPromoModal(true);
  };

  const showEditSupportModal = (value: Object) => {
    setSelectedPromoCode(value);
    setEditSupportModal(true);
  };

  const showDeleteSupportModal = (value: Object) => {
    setSelectedPromoCode(value);
    setDeleteSupportModal(true);
  };

  const showDeleteRateModal = (value: Object) => {
    setSelectedTaxRate(value);
    setDeleteTaxRateModal(true);
  };

  const showEditFeedbackModal = (value: Object) => {
    setSelectedPromoCode(value);
    setEditFeedbackModal(true);
  };

  const showDeleteFeedbackModal = (value: Object) => {
    setSelectedPromoCode(value);
    setDeleteFeedbackModal(true);
  };

  const getPromosList = (promosArray: Array) => {
    const activePromos = promosArray.filter(data => data.active === true);
    return activePromos;
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      // eslint-disable-next-line no-shadow
      render: (name) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (price) => {
        const total = (price / 100).toFixed(2);
        return (
          <span> {`${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'COST',
      dataIndex: 'cost',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (cost) => {
        const total = (cost / 100).toFixed(2);
        return (
          <span> {`${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'SORT ORDER',
      dataIndex: 'sortOrder',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (sortOrder) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {sortOrder || 0}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ON POS',
      dataIndex: 'hidden',
      width: '9%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (hidden) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {hidden ? 'No' : 'Yes'}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'AVAILABLE',
      dataIndex: 'available',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (available) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {available ? 'Yes' : 'No'}
          </span>
        </Tooltip>
      ),
    },
  ];

  const taxColumns = [
    {
      title: 'NAME',
      dataIndex: 'displayName',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'REGION',
      dataIndex: 'jurisdiction',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '21%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'RATE',
      dataIndex: 'percentage',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (percentage) => `${percentage} %`,
    },
    {
      title: 'TYPE',
      dataIndex: 'inclusive',
      width: '15%',
      align: 'left',
      render: (inclusive) => {
        let value = '';
        if (inclusive) {
          value = RATE_TYPES.INCLUSIVE;
        } else {
          value = RATE_TYPES.EXCLUSIVE;
        }
        return value;
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const tipColumns = [
    {
      title: 'TYPE',
      dataIndex: 'tipType',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'VALUE',
      dataIndex: 'tip',
      width: '21%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },

  ];

  const serviceColumns = [
    {
      title: 'NAME',
      dataIndex: 'displayName',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      width: '21%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'PERCENTAGE',
      dataIndex: 'percentage',
      width: '12%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (percentage) => `${percentage / 100} %`,
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '18%',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const promoColumns = [
    {
      title: 'PROMO CODE',
      dataIndex: 'promocode',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (promocode, record) => {
        if (record && record.promotionalCodes) {
          return (<span>{record.promotionalCodes && record.promotionalCodes.code}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'TYPE',
      dataIndex: 'promocode',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (promocode, record) => {
        if (record && record.items && (record.items.length > 0)) {
          return (<span>Items</span>);
        }
        if (record && record.inventoryCategories && (record.inventoryCategories.length > 0)) {
          return (<span>Categories</span>);
        }
        if (record && record.promotionalCodes.offer) {
          return (<span>Offer</span>);
        }
        return (
          <>All</>
        );
      },
    },
    {
      title: 'ITEM',
      dataIndex: 'promocode',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        if (record && record.items.length > 0) {
          return (
            <>
              {record.items.map((item, index) => (
                <span key={index} style={{ cursor: 'pointer' }}>
                  {item.name}{index === record.items.length - 1 ? '.' : ', '}
                </span>
              ))}
            </>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'promocode',
      width: '18%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        if (record && record.inventoryCategories.length > 0) {
          return (
            <>
              {record.inventoryCategories.map((category, index) => (
                <span key={index} style={{ cursor: 'pointer' }}>
                  {category.name}{index === record.inventoryCategories.length - 1 ? '.' : ', '}
                </span>
              ))}
            </>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'Promo Pause',
      dataIndex: 'paused',
      width: '15%',
      align: 'left',
      render: (paused) => {
        let value = '';
        if (paused === true) {
          value = 'Yes';
        } else {
          value = 'No';
        }
        return value;
      },
    },
  ];

  const actionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => {
      if (record && record.promotionalCodes && (record.promotionalCodes.active) === true) {
        return (
          <span>
            <Tooltip placement="top" title="Edit">
              <Icon
                type="edit"
                className="ml-3"
                style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); showEditPromoModal(record); }}
              />
            </Tooltip>
            <Tooltip placement="top" title="Delete">
              <Icon
                type="delete"
                className="ml-3"
                style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); showDeletePromoModal(record); }}
              />
            </Tooltip>
          </span>
        );
      }
      return (
        <span>
          <Tooltip placement="top" title="Edit">
            <Icon
              type="edit"
              className="ml-3"
              disabled
              style={{ fontSize: '20px', marginRight: '8px', cursor: 'not-allowed' }}
            />
          </Tooltip>
          <Tooltip placement="top" title="Delete">
            <Icon
              type="delete"
              className="ml-3"
              disabled
              style={{ fontSize: '20px', marginRight: '8px', cursor: 'not-allowed' }}
            />
          </Tooltip>
        </span>
      );
    },
  };

  const actionTipColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) =>
      // if (record && record.promotionalCodes && (record.promotionalCodes.active) === true) {
      (
        <span>
          {/*
            <Tooltip placement="top" title="Edit">
              <Icon
                type="edit"
                className="ml-3"
                style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); showEditPromoModal(record); }}
              />
            </Tooltip>
        */}
          <Tooltip placement="top" title="Delete">
            <Icon
              type="delete"
              className="ml-3"
              style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); setDeleteTip(true); setDeleteTipData(record); }}
            />
          </Tooltip>
        </span>
      )
      // }
    ,
  };

  const quickcolumns = [
    {
      title: '',
      dataIndex: 'iconUrl',
      width: '20%',
      align: 'center',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (iconUrl) => (
        <span>
          {iconUrl ? <img src={iconUrl} alt="item" width="100" height="100" /> : <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'LABEL',
      dataIndex: 'name1',
      width: '20%',
      align: 'left',
      render: (name1, record) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {(record && record.name) || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
  ];

  const supportActionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            className="ml-3"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showEditSupportModal(record); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            className="ml-3"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showDeleteSupportModal(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  const taxRateActionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <span>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            className="ml-3"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showDeleteRateModal(record); }}
          />
        </Tooltip>
      </span>
    ),
  };

  const feedbackcolumns = [
    {
      title: 'LABEL',
      dataIndex: 'label',
      width: '20%',
      align: 'left',
      render: (name1, record) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {(record && record.label) || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'QUESTIONS',
      dataIndex: 'question',
      width: '20%',
      align: 'left',
      render: (name1, record) => (
        <span style={{ cursor: 'pointer' }}>
          {(record && record.question) || <>&#8211;</> }
        </span>
      ),
    },
  ];

  const feedbackActionColumn = {
    title: 'ACTION',
    dataIndex: 'action',
    width: '16%',
    align: 'left',
    render: (text, record) => (
      <div className="d-flex">
        <Tooltip placement="top" title="Edit">
          <Icon
            type="edit"
            className="ml-3"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showEditFeedbackModal(record); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Delete">
          <Icon
            type="delete"
            className="ml-3"
            style={{ fontSize: '20px', marginRight: '8px', cursor: 'pointer' }}
            onClick={(e) => { e.stopPropagation(); showDeleteFeedbackModal(record); }}
          />
        </Tooltip>
        <Tooltip placement="top" title="Enable">
          <Switch
            checked={validationError || record.enabled}
            onChange={(val) => {
              enableFeedback(record, val);
            }}
          />
        </Tooltip>
      </div>
    ),
  };


  const selectedItemView = (gid) => {
    history.push({
      pathname: `${PAGES.INVENTORY_ITEMS}/${gid}`,
      state: location.search,
    });
  };

  const onChangeCollapse = (key, categoryGid) => {
    const debouncedFetchItems = debounce(getItemsBySelectedCategory, 2000, { leading: true });

    debouncedFetchItems({ gid, categoryGid });
    setOpenPanelKey(openPanelKey === key ? null : key);
    setSelectAllChecked('default');
    setSelectedCount(categoryItemsList && categoryItemsList.filter((items) => items.added === 'true').length);
  };
  const handleCancel = () => {
    if (shopTab !== 'QuickSupport') {
      history.push({
        pathname: PAGES.SHOPS,
      });
    }
  };

  const handleItemCheckbox = (itemId, isChecked) => {
    setSelectedCount(prevCount => (isChecked ? prevCount + 1 : prevCount - 1));
    setItems(prevItems => {
      if (isChecked) {
        if (!prevItems.includes(itemId)) {
          return [...prevItems, itemId];
        }
      } else {
        return prevItems.filter(item => item !== itemId);
      }
      return prevItems;
    });
  };
  const handleAllitemSelectCheckbox = (isChecked) => {
    setSelectedCount(isChecked ? categoryItemsList.length : 0);

    setItems(prevItems => {
      if (isChecked) {
        const allItemIds = categoryItemsList.map(item => item.gid);
        return Array.from(new Set([...prevItems, ...allItemIds]));
      }
      return prevItems.filter(item => !categoryItemsList.some(categoryItem => categoryItem.gid === item));
    });

    setSelectAllChecked(isChecked);
  };

  const handleSendFeedbackNotificationMinutes = (event) => {
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const params = {
      sendFeedbackNotificationMinutes,
    };
    updateInventoryShops({
      gid,
      params,
      type: 'Service SMS',
    });
    setRedirect(true);
  };


  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image', 'align',
  ];

  const updateBannerText = (html) => {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    const htmlCount = tmp.innerText;
    if (htmlCount && htmlCount.length <= 300) {
      setBannerText(html);
    }
    setBannerTextCount(htmlCount.length);
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {
        inventoryShopsDetails.gid && !addItemsModal && (
          <>
            <Menu
              onClick={e => { setShopTab(e.key); setIsFetchRequired(true); }}
              selectedKeys={[shopTab]}
              mode="horizontal"
              style={{ fontSize: '20px' }}
            >
              <Menu.Item key="Manage">
                <SpTab
                  to={`${path}`}
                  selected={shopTab === 'Manage'}
                >
                  Manage
                </SpTab>
              </Menu.Item>
              <Menu.Item key="QuickSupport">
                <SpTab
                  to={`${path}`}
                  selected={shopTab === 'QuickSupport'}
                >
                  Quick Support
                </SpTab>
              </Menu.Item>
              <Menu.Item key="Feedback">
                <SpTab
                  to={`${path}`}
                  selected={shopTab === 'Feedback'}
                >
                  Feedback
                </SpTab>
              </Menu.Item>
            </Menu>

            {
              shopTab === 'Manage' ? (
                <>
                  {
                    editItemsModal ? (
                      <Card className="mb-3">
                        <Row className="my-2 mb-1 mt-4">
                          <Col span={5}>
                            <SpH5>Shop Name</SpH5>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-between" className="my-2 mb-3">
                          <Col span={8} className="d-flex align-items-center">
                            <Input
                              placeholder="Shop Name"
                              onChange={(e) => setName(e.currentTarget.value)}
                              value={name}
                            />
                          </Col>
                        </Row>
                        <div>
                          <SpError>
                            {simpleValidator.current.message('shop name', name, 'required|stringLength')}
                          </SpError>
                        </div>
                        <Row className="my-2 mb-1 mt-4">
                          <Col span={5}>
                            <SpH5>Banner Text</SpH5>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-between" className="my-2 mb-3">
                          <Col span={8} className="d-flex align-items-center">
                            <ReactQuill
                              theme="snow"
                              formats={formats}
                              placeholder="Add Banner Text"
                              value={bannerText}
                              maxlength={300}
                              onChange={updateBannerText}
                              className="richTextFormatting"
                              style={{ width: '100%' }}
                            />
                            {/* <Input
                              placeholder="Banner Text"
                              onChange={(e) => setBannerText(e.currentTarget.value)}
                              value={bannerText}
                            /> */}
                          </Col>
                        </Row>
                        <div>
                          <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Max {bannerTextCount} / 300</SpText>
                        </div>
                        <div>
                          <SpError>
                            {simpleValidator.current.message('banner text', bannerText, 'required|bannerTextValid')}
                          </SpError>
                        </div>
                        <Row className="my-2 mb-1 mt-4">
                          <Col span={5}>
                            <SpH5>Order Modes</SpH5>
                          </Col>
                        </Row>
                        <Row className="my-2 mb-2">
                          <RadioGroup onChange={onRadioChange} value={orderModeData}>
                            {ORDER_MODES.map((option) => (
                              <Radio key={option.id} value={option.value}>
                                {option.value.replace(/_/g, ' ')}
                              </Radio>
                            ))}
                          </RadioGroup>
                        </Row>
                        {orderModeData === 'ONLINE_ORDER' && (
                          <div className="mb-4">
                            <label className="d-block" />
                            <Switch
                              className="mr-2"
                              defaultChecked={enablePayAtStore}
                              onChange={isEnablePayAtStore}
                            />
                            Enable Pay At Store
                          </div>

                        )}

                        <Row className="my-2 mb-1 mt-4">
                          <Col span={5}>
                            <SpH5>Shop Mode</SpH5>
                          </Col>
                        </Row>
                        <Row className="my-2 mb-2">
                          <RadioGroup onChange={onRadioChangeStore} value={shopMode}>
                            {STORE_TYPES.map((option) => (
                              <Radio key={option.id} value={option.value}>
                                {option.value.replace(/_/g, ' ')}
                              </Radio>
                            ))}
                          </RadioGroup>
                        </Row>
                        <Row className="my-2 mb-1 mt-4">
                          <Col span={5}>
                            <SpH5>Accept Customer Phone Number</SpH5>
                          </Col>
                        </Row>
                        <Row className="my-2 mb-2">
                          <Switch
                            defaultChecked={isCustomerPhoneRequired}
                            onChange={val => setIsCustomerPhoneRequired(val)}
                          />
                        </Row>
                        <div />
                        {/* <Checkbox.Group style={{ width: '100%' }} value={orderModeData} onChange={onCheckChange}>
                          <Row className="my-2 mb-2">
                            {
                              ORDER_MODES.map(item => (
                                <Col span={3}>
                                  <Checkbox value={item.value}>
                                    {(item.value === 'PRE_ORDER')
                                      ? ORDER_MODE_DISPLAY.PRE_ORDER : item.value}
                                  </Checkbox>
                                </Col>
                              ))
                            }
                          </Row>
                        </Checkbox.Group> */}
                        <Row className="my-2 mb-3">
                          <Col>
                            <SpH5>{`Available ${zipTag} For Delivery`}</SpH5>
                          </Col>
                        </Row>
                        {addZipRecipient.map((item, i) => (
                          <div className="mb-3 d-flex">
                            <Col span={8}>
                              <Input
                                value={item}
                                key={`${zipTag}${i}`}
                                onChange={(e) => {
                                  const list = [...addZipRecipient];
                                  list[i] = e.currentTarget.value;
                                  setAddZipRecipient(list);
                                }}
                                placeholder={zipTag}
                              />
                            </Col>
                            {
                              i !== 0 && (
                                <Col>
                                  <Icon
                                    type="minus-circle"
                                    className="ml-2 mt-2"
                                    style={{
                                      cursor: 'pointer', color: '#ff4d50',
                                    }}
                                    onClick={() => handleRemoveZipCode(i)}
                                  />
                                </Col>
                              )
                            }
                            <SpError className="mb-4">
                              {simpleValidator.current.message(zipTag, item, 'postalcode')}
                            </SpError>
                          </div>
                        ))}
                        <Row className="my-2 mt-3 mb-4">
                          <Col style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={addNewRecipient}
                            >
                              {`Add New ${zipTag}`}
                            </SpText>
                          </Col>
                        </Row>
                        <Row className="my-2 mb-1 mt-4">
                          <Col span={5}>
                            <SpH5>Shop Address</SpH5>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-between" className="my-2 mb-3">
                          <Col span={8} className="d-flex align-items-center">
                            <Input
                              placeholder="Street"
                              value={streetName}
                              onChange={(e) => setStreetName(e.currentTarget.value)}
                            />
                          </Col>
                        </Row>
                        <div>
                          <SpError>
                            {simpleValidator.current.message('street', streetName, 'required')}
                          </SpError>
                        </div>
                        <Row className="my-2 mb-1 mt-4">
                          <Col span={5}>
                            <SpH5>City</SpH5>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-between" className="my-2 mb-3">
                          <Col span={8} className="d-flex align-items-center">
                            <Input
                              placeholder="City"
                              value={cityName}
                              onChange={(e) => setCityName(e.currentTarget.value)}
                            />
                          </Col>
                        </Row>
                        <div>
                          <SpError>
                            {simpleValidator.current.message('city', cityName, 'required')}
                          </SpError>
                        </div>
                        <Row className="my-2 mb-1 mt-4">
                          <Col span={5}>
                            <SpH5>State</SpH5>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-between" className="my-2 mb-3">
                          <Col span={8} className="d-flex align-items-center">
                            <Select
                              className="w-100"
                              placeholder="State"
                              showSearch
                              optionFilterProp="children"
                              value={stateName}
                              onChange={(e) => setStateName(e)}
                            >
                              {
                                stateList.map(s => (
                                  <Option
                                    key={s.id}
                                    value={s.id}
                                    className="text-capitalize"
                                  >
                                    {s.name}
                                  </Option>
                                ))
                              }
                            </Select>
                          </Col>
                        </Row>
                        <div>
                          <SpError>
                            {simpleValidator.current.message('state', stateName, 'required')}
                          </SpError>
                        </div>
                        <Row className="my-2 mb-1 mt-4">
                          <Col span={5}>
                            <SpH5>{zipTag}</SpH5>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-between" className="my-2 mb-3">
                          <Col span={8} className="d-flex align-items-center">
                            <Input
                              placeholder={zipTag}
                              value={zipCode}
                              onChange={(e) => setZipCode(e.currentTarget.value)}
                            />
                          </Col>
                        </Row>
                        <div>
                          <SpError>
                            {simpleValidator.current.message(zipTag, zipCode, 'required|postalcode')}
                          </SpError>
                        </div>
                        <Row className="my-2 mb-1 mt-4">
                          <Col span={5}>
                            <SpH5>Country</SpH5>
                          </Col>
                        </Row>
                        <Row type="flex" justify="space-between" className="my-2 mb-3">
                          <Col span={8} className="d-flex align-items-center">
                            <Select
                              defaultValue={countryName}
                              style={{ width: '100%' }}
                              disabled
                              onChange={updateCountry}
                            >
                              {COUNTRY_CODES.map((item) => (
                                <Option key={item.ID} value={item.ALPHA2}>
                                  {item.NAME}
                                </Option>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                        <hr />
                        <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                          <Col span={3}>
                            <label>
                              <StyledDiv>
                                <Upload
                                  name="avatar"
                                  listType="picture-card upload-card"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  beforeUpload={beforeUpload}
                                  onChange={fileUpload}
                                >
                                  {fileName ? preview : uploadButton}
                                </Upload>
                              </StyledDiv>
                            </label>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col span={24} className="d-flex justify-content-end">
                            <SpButton className="mr-3" onClick={() => setEditItemsModal(false)} type="secondary">
                              Cancel
                            </SpButton>
                            <SpButton onClick={editSelectedItem}>Save</SpButton>
                          </Col>
                        </Row>
                      </Card>
                    ) : (
                      <>
                        <Card className="mb-3">
                          <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                            <Col className="d-flex align-items-center" />
                            <Col>
                              {
                                kdsRole !== 'KDS-USER' && (
                                  <>
                                    <SpButton
                                      shape="round"
                                      type="danger"
                                      className="mr-3"
                                      onClick={() => showDeleteShopsModal(true)}
                                    >
                                      Delete Shop
                                    </SpButton>
                                    {
                                      (assosiateList && assosiateList.gid) ? (
                                        <>
                                          <SpButton
                                            shape="round"
                                            type="danger"
                                            className="mr-3"
                                            onClick={() => setDeleteAssociateModal(true)}
                                          >
                                            Delete Associate
                                          </SpButton>
                                          <SpButton
                                            shape="round"
                                            type="secondary"
                                            className="mr-3"
                                            onClick={() => setEditAssociateModal(true)}
                                          >
                                            Edit Shop Associate
                                          </SpButton>
                                        </>
                                      ) : (
                                        <SpButton
                                          shape="round"
                                          type="secondary"
                                          className="mr-3"
                                          onClick={() => setAddAssociateModal(true)}
                                        >
                                          Create Shop Associate
                                        </SpButton>
                                      )
                                    }

                                    <SpButton
                                      shape="round"
                                      type="secondary"
                                      className="mr-3"
                                      onClick={() => setEditItemsModal(true)}
                                    >
                                      Edit Shop Details
                                    </SpButton>
                                    {
                                        inventoryShopsDetails.orderMode && inventoryShopsDetails.orderMode[0] === 'DINE_IN' && (
                                          <SpButton
                                            shape="round"
                                            type="primary"
                                            className="mr-3"
                                            onClick={() => history.push(`${PAGES.DINING}/${gid}`)}
                                          >
                                            Dining
                                          </SpButton>
                                        )
                                      }
                                  </>
                                )
                              }
                            </Col>
                          </Row>
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={5}>
                                <span>Shop Name</span>
                              </Col>
                              <Col span={6}>
                                <SpH5>
                                  {(inventoryShopsDetails && inventoryShopsDetails.name) || <>&#8211;</>}
                                </SpH5>
                              </Col>
                            </Row>
                          </SpForm>
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={5}>
                                <span>Banner Text</span>
                              </Col>
                              <Col span={6}>
                                <SpH5>
                                  {(inventoryShopsDetails && inventoryShopsDetails.bannerText
                                  && <div dangerouslySetInnerHTML={{ __html: inventoryShopsDetails.bannerText.replaceAll('<br>', '') }} />) || <>&#8211;</>}
                                </SpH5>
                              </Col>
                            </Row>
                          </SpForm>
                          {
                            (inventoryShopsDetails && inventoryShopsDetails.orderMode && inventoryShopsDetails.orderMode.length !== 0) && (
                              <SpForm>
                                <Row type="flex" justify="start" align="middle">
                                  <Col span={5}>
                                    <span>Order Modes</span>
                                  </Col>
                                  <Col span={6}>
                                    {
                                      inventoryShopsDetails.orderMode.map((data, i) => (
                                        i === (inventoryShopsDetails.orderMode.length - 1)
                                          ? (
                                            <SpH5>
                                              {data === 'PRE_ORDER' ? 'PRE ORDER' : data}
                                            </SpH5>
                                          ) : (
                                            <SpH5>
                                              {`${data === 'PRE_ORDER' ? 'PRE ORDER' : data}, `}
                                            </SpH5>
                                          )
                                      ))
                                    }
                                  </Col>
                                </Row>
                              </SpForm>
                            )
                          }
                          {
                            (inventoryShopsDetails && inventoryShopsDetails.zipcodesAvailableForDelivery
                              && inventoryShopsDetails.zipcodesAvailableForDelivery.length !== 0) && (
                              <SpForm>
                                <Row type="flex" justify="start" align="middle">
                                  <Col span={5}>
                                    <span>Zipcodes Available For Delivery</span>
                                  </Col>
                                  <Col span={6}>
                                    {
                                      inventoryShopsDetails.zipcodesAvailableForDelivery.map((data, i) => (
                                        i === (inventoryShopsDetails.zipcodesAvailableForDelivery.length - 1)
                                          ? (
                                            <SpH5>
                                              {data}
                                            </SpH5>
                                          ) : (
                                            <SpH5>
                                              {`${data}, `}
                                            </SpH5>
                                          )
                                      ))
                                    }
                                  </Col>
                                </Row>
                              </SpForm>
                            )
                          }
                          {
                            assosiateList && assosiateList.gid && (
                              <SpForm>
                                <Row type="flex" justify="start" align="middle">
                                  <Col span={5}>
                                    <span>Shop Associate</span>
                                  </Col>
                                  <Col span={6}>
                                    <SpH5>
                                      {(assosiateList && assosiateList.firstName) || <>&#8211;</>}
                                    </SpH5>
                                  </Col>
                                </Row>
                              </SpForm>
                            )
                          }
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={5}>
                                <span>Address</span>
                              </Col>
                              <Col span={10}>
                                <SpH5>
                                  {(inventoryShopsDetails && inventoryShopsDetails.address)
                                    ? `${inventoryShopsDetails.address.street}, 
                            ${inventoryShopsDetails.address.city}, 
                            ${inventoryShopsDetails.address.state}, 
                            ${inventoryShopsDetails.address.postalCode}, 
                            ${inventoryShopsDetails.address.country && inventoryShopsDetails.address.country.alpha3}`
                                    : <>&#8211;</>}
                                </SpH5>
                              </Col>
                            </Row>
                          </SpForm>
                          <SpForm>
                            <Row type="flex" justify="start" align="middle">
                              <Col span={5}>
                                <span>Accept Customer Phone Number</span>
                              </Col>
                              <Col span={10}>
                                <Switch
                                  defaultChecked={inventoryShopsDetails && inventoryShopsDetails.acceptCustomerPhoneNumber}
                                  disabled
                                  // onChange={val => setNotify(val)}
                                />
                              </Col>
                            </Row>
                          </SpForm>
                          <hr />
                          <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                            <Col className="d-flex align-items-center">
                              {
                                inventoryShopsDetails && inventoryShopsDetails.banner ? (
                                  <img src={inventoryShopsDetails.banner} alt="shop" width="100" height="100" />
                                ) : (
                                  <BannerPreview />
                                )
                              }
                            </Col>
                          </Row>
                          {
                            (accountSlug.length > 0) && (
                              <>
                                <hr />
                                <Row type="flex" justify="start" align="middle">
                                  <Col span={6}>
                                    <SpText className="mr-3" fontSize="14px" fontWeight="600">
                                      Link Account Slug
                                    </SpText>
                                    <Select
                                      className="mt-2 w-100"
                                      showSearch
                                      disabled={enableUnlink}
                                      value={slugLink || 'Select slug'}
                                      onChange={(value) => {
                                        setSlugGid(value[0]);
                                        setSlugLink(value[1]);
                                      }}
                                    >
                                      {
                                        accountSlug.map(slug => (
                                          slug.status === 'UNLINKED' && (
                                            <Option
                                              key={1}
                                              value={[slug.gid, slug.slugName]}
                                            >
                                              {slug.slugName}
                                            </Option>
                                          )
                                        ))
                                      }
                                    </Select>
                                  </Col>
                                </Row>
                                <Row className="mt-4" type="flex" justify="start" align="middle">
                                  <Col span={3}>
                                    {
                                      kdsRole !== 'KDS-USER' && (
                                        <>
                                          {
                                            enableUnlink ? (
                                              <SpButton
                                                type="primary"
                                                shape="round"
                                                onClick={unlinkSlug}
                                              >
                                                Unlink
                                              </SpButton>
                                            ) : slugLink ? (
                                              <SpButton
                                                type="primary"
                                                shape="round"
                                                onClick={linkSlug}
                                              >
                                                Link
                                              </SpButton>
                                            ) : (
                                              <SpButton
                                                type="primary"
                                                shape="round"
                                                disabled
                                              >
                                                Link
                                              </SpButton>
                                            )
                                          }
                                        </>
                                      )
                                    }
                                  </Col>
                                </Row>
                              </>
                            )
                          }
                          <Row>
                            <Col span={24} className="d-flex justify-content-end">
                              <SpH5 color="#7f7f7f">{inventoryShopsDetails.gid}</SpH5>
                            </Col>
                          </Row>
                          {/* DELETE SHOP MODAL */}
                          {deleteShopsModal && (
                            <DeleteShops
                              visible={deleteShopsModal}
                              submitting={submitting}
                              inventoryItemDetails={selectedShops}
                              selectedAccount={selectedAccount}
                              close={() => setDeleteShopsModal(false)}
                              submit={deleteSelectedOrderType}
                              // shopTables={shopTables}
                            />
                          )}
                          {/* ADD ASSOCIATE MODAL */}
                          {addAssociateModal && (
                            <AddAssosiate
                              visible={addAssociateModal}
                              submitting={submitting}
                              selectedAccount={selectedAccount}
                              ratesList={ratesList}
                              country={selectedAccount.country.name}
                              cntryId={selectedAccount.country.id}
                              statesInCountry={statesInCountry}
                              zipTag={zipTag}
                              phoneCode={phoneCode}
                              close={() => setAddAssociateModal(false)}
                              submit={addSelectedAssociate}
                            />
                          )}
                          {/* EDIT ASSOCIATE MODAL */}
                          {editAssociateModal && (
                            <EditAssosiate
                              visible={editAssociateModal}
                              submitting={submitting}
                              selectedAccount={selectedAccount}
                              ratesList={ratesList}
                              country={selectedAccount.country.name}
                              cntryId={selectedAccount.country.id}
                              statesInCountry={statesInCountry}
                              zipTag={zipTag}
                              phoneCode={phoneCode}
                              selectedCustomer={assosiateList}
                              close={() => setEditAssociateModal(false)}
                              submit={editSelectedAssociate}
                            />
                          )}
                          {/* DELETE ASSOCIATE MODAL */}
                          {deleteAssociateModal && (
                            <DeleteAssosiate
                              visible={deleteAssociateModal}
                              submitting={submitting}
                              selectedCustomer={assosiateList}
                              close={() => setDeleteAssociateModal(false)}
                              submit={deleteSelectedAssociate}
                            />
                          )}
                        </Card>
                        <Card className="pl-2">
                          <Menu
                            onClick={e => { setCurrent(e.key); setIsFetchRequired(true); }}
                            selectedKeys={[current]}
                            mode="horizontal"
                            style={{ fontSize: '20px' }}
                          >
                            <Menu.Item key="itemsTab">
                              <SpTab
                                to={`${path}`}
                                selected={current === 'itemsTab'}
                              >
                                Items
                              </SpTab>
                            </Menu.Item>
                            <Menu.Item key="promosTab">
                              <SpTab
                                to={`${path}`}
                                selected={current === 'promosTab'}
                              >
                                Promos
                              </SpTab>
                            </Menu.Item>
                            <Menu.Item key="taxesTab">
                              <SpTab
                                to={`${path}`}
                                selected={current === 'taxesTab'}
                              >
                                Taxes
                              </SpTab>
                            </Menu.Item>
                            <Menu.Item key="serviceTab">
                              <SpTab
                                to={`${path}`}
                                selected={current === 'serviceTab'}
                              >
                                Service Charge
                              </SpTab>
                            </Menu.Item>
                            <Menu.Item key="hoursOfOperation">
                              <SpTab
                                to={`${path}`}
                                selected={current === 'hoursOfOperation'}
                              >
                                Operating Hours
                              </SpTab>
                            </Menu.Item>
                            <Menu.Item key="tips">
                              <SpTab
                                to={`${path}`}
                                selected={current === 'tips'}
                              >
                                Tips
                              </SpTab>
                            </Menu.Item>
                          </Menu>
                          {
                            current === 'itemsTab' ? (
                              <>
                                <Row type="flex" justify="space-between" className="my-2">
                                  <Col />
                                  {
                                    kdsRole !== 'KDS-USER' && (
                                      <>
                                        {
                                          (shopsItemsList && shopsItemsList.length !== 0) && (
                                            <Col>
                                              <SpButton
                                                type="secondary"
                                                shape="round"
                                                ghost
                                                className="mr-3"
                                                onClick={() => setAddItemsModal(true)}
                                              >
                                                {'\u002B'}&nbsp;Add or Remove Items
                                              </SpButton>
                                            </Col>
                                          )
                                        }
                                      </>
                                    )
                                  }
                                </Row>
                                {/* Add ITEMS MODAL */}
                                {/* {addItemsModal && (
                                  <AddRemoveItems
                                    visible={addItemsModal}
                                    submitting={submitting}
                                    selectedItemList={inventoryShopsDetails}
                                    itemsList={inventoryItems}
                                    shopsItemsList={shopsItemsList}
                                    selectedAccount={selectedAccount}
                                    close={() => setAddItemsModal(false)}
                                    submit={addSelectedItem}
                                  />
                                )} */}

                                {
                                  shopsItemsList && shopsItemsList.length !== 0 ? (
                                    <>
                                      <Table
                                        rowClassName={() => 'ant-table-clickable-row'}
                                        columns={columns}
                                        onRow={
                                          (record) => ({
                                            onClick: () => {
                                              selectedRecord(record);
                                            },
                                          })
                                        }
                                        rowKey={(record) => record.gid}
                                        className="custom-table"
                                        dataSource={shopsItemsList}
                                        pagination={pagination}
                                        onChange={handleTableChange}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={{
                                          border: '1px solid #CBCBCB',
                                          minHeight: '253px',
                                          width: '1014 px',
                                          marginTop: '10px',
                                          marginBottom: '25px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <div>
                                          <SpH5 color="#333333">
                                            No items assigned yet
                                          </SpH5>
                                          <div
                                            style={{
                                              marginTop: '15px',
                                            }}
                                          >
                                            {
                                              kdsRole !== 'KDS-USER' && (
                                                <SpButton
                                                  type="secondary"
                                                  onClick={() => setAddItemsModal(true)}
                                                >
                                                  {'\u002B'}&nbsp;Add Items
                                                </SpButton>
                                              )
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </>
                            ) : current === 'promosTab' ? (
                              <>
                                <Row type="flex" justify="space-between" className="my-2">
                                  <Col />
                                  {
                                    kdsRole !== 'KDS-USER' && (
                                      <>
                                        {
                                          (shopPromosList && shopPromosList.length !== 0) && (
                                            <Col>
                                              <SpButton
                                                type="secondary"
                                                shape="round"
                                                ghost
                                                className="mr-3"
                                                onClick={() => setAddPromoModal(true)}
                                              >
                                                {'\u002B'}&nbsp;Add Promo
                                              </SpButton>
                                            </Col>
                                          )
                                        }
                                      </>
                                    )
                                  }
                                </Row>
                                {/* ADD PROMO MODAL */}
                                {addPromoModal && (
                                  <AddPromo
                                    visible={addPromoModal}
                                    submitting={submitting}
                                    selectedItemList={inventoryShopsDetails}
                                    promosList={getPromosList(promosList)}
                                    categoryList={categoryList}
                                    shopsItemsList={inventoryItems}
                                    selectedAccount={selectedAccount}
                                    shopPromosList={allShopPromosList}
                                    shopPromo
                                    close={() => setAddPromoModal(false)}
                                    submit={addSelectedPromos}
                                  />
                                )}
                                {/* EDIT PROMO MODAL */}
                                {editPromoModal && (
                                  <EditPromoCode
                                    visible={editPromoModal}
                                    submitting={submitting}
                                    selectedAccount={selectedAccount}
                                    selectedPromoCode={selectedPromoCode}
                                    promosList={getPromosList(promosList)}
                                    categoryList={categoryList}
                                    shopsItemsList={inventoryItems}
                                    shopPromosList={shopPromosList}
                                    shopPromo
                                    close={() => setEditPromoModal(false)}
                                    submit={editSelectedPromos}
                                  />
                                )}
                                {/* DELETE PROMO MODAL */}
                                {deletePromoModal && (
                                  <DeletePromoCode
                                    visible={deletePromoModal}
                                    submitting={submitting}
                                    selectedAccount={selectedAccount}
                                    selectedPromoCode={selectedPromoCode}
                                    close={() => setDeletePromoModal(false)}
                                    submit={deleteSelectedPromos}
                                  />
                                )}
                                {
                                  (shopPromosList && shopPromosList.length !== 0) ? (
                                    <>
                                      <Table
                                        rowClassName={() => 'ant-table-clickable-row'}
                                        columns={kdsRole !== 'KDS-USER' ? [...promoColumns, actionColumn] : promoColumns}
                                        rowKey={(record) => record.gid}
                                        className="custom-table"
                                        dataSource={shopPromosList}
                                        pagination={pagination}
                                        onChange={handleTableChange}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={{
                                          border: '1px solid #CBCBCB',
                                          minHeight: '253px',
                                          width: '1014 px',
                                          marginTop: '10px',
                                          marginBottom: '25px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <div>
                                          <div className="ml-4">
                                            <SpH5 color="#333333">
                                              No data
                                            </SpH5>
                                          </div>
                                          <div
                                            style={{
                                              marginTop: '15px',
                                            }}
                                          >
                                            {
                                              kdsRole !== 'KDS-USER' && (
                                                <SpButton
                                                  type="secondary"
                                                  onClick={() => setAddPromoModal(true)}
                                                >
                                                  {'\u002B'}&nbsp;Add Promo
                                                </SpButton>
                                              )
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </>
                            ) : current === 'taxesTab' ? (
                              <>
                                <Row type="flex" justify="space-between" className="my-2">
                                  <Col />
                                  {
                                    (inventoryShopsDetails && inventoryShopsDetails.taxRates && inventoryShopsDetails.taxRates.length !== 0) && (
                                      <Col>
                                        {
                                          kdsRole !== 'KDS-USER' && (
                                            <>
                                              <SpButton
                                                type="secondary"
                                                shape="round"
                                                ghost
                                                className="mr-3"
                                                onClick={() => setEditTaxModal(true)}
                                              >
                                                {'\u002B'}&nbsp;Add or Remove Taxes
                                              </SpButton>
                                            </>
                                          )
                                        }
                                      </Col>
                                    )
                                  }
                                </Row>
                                {/* Add TAXES MODAL */}
                                {editTaxModal && (
                                  <AddRemoveTaxes
                                    visible={editTaxModal}
                                    submitting={submitting}
                                    selectedItemList={inventoryShopsDetails}
                                    ratesList={ratesList}
                                    shopsRatesList={inventoryShopsDetails && inventoryShopsDetails.taxRates}
                                    selectedAccount={selectedAccount}
                                    close={() => setEditTaxModal(false)}
                                    submit={editSelectedTaxes}
                                  />
                                )}
                                {
                                  (inventoryShopsDetails && inventoryShopsDetails.taxRates && inventoryShopsDetails.taxRates.length !== 0) ? (
                                    <>
                                      <Table
                                        rowClassName={() => 'ant-table-clickable-row'}
                                        columns={taxColumns}
                                        rowKey={(record) => record.gid}
                                        className="custom-table"
                                        dataSource={inventoryShopsDetails && inventoryShopsDetails.taxRates}
                                        pagination={pagination}
                                        onChange={handleTableChange}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={{
                                          border: '1px solid #CBCBCB',
                                          minHeight: '253px',
                                          width: '1014 px',
                                          marginTop: '10px',
                                          marginBottom: '25px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <div>
                                          <div className="ml-4">
                                            <SpH5 color="#333333">
                                              No data
                                            </SpH5>
                                          </div>
                                          <div
                                            style={{
                                              marginTop: '15px',
                                            }}
                                          >
                                            {
                                              kdsRole !== 'KDS-USER' && (
                                                <SpButton
                                                  type="secondary"
                                                  onClick={() => setEditTaxModal(true)}
                                                >
                                                  {'\u002B'}&nbsp;Add Taxes
                                                </SpButton>
                                              )
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </>
                            ) : current === 'tips' ? (
                              <>
                                <Row type="flex" justify="space-between" className="my-2">
                                  <Col />
                                  {
                                  (inventoryShopsDetails && inventoryShopsDetails.tips && inventoryShopsDetails.tips.length !== 0) && (
                                    <Col>
                                      {
                                        kdsRole !== 'KDS-USER' && (
                                          <SpButton
                                            type="secondary"
                                            onClick={() => setAddTipModal(true)}
                                          >
                                            {'\u002B'}&nbsp;Add Tip
                                          </SpButton>
                                        )
                                      }
                                    </Col>
                                  )
                                }
                                </Row>
                                {/* Add TIP MODAL */}
                                {addTipModal && (
                                <AddNewTip

                                  handleTipAmount={handleTipAmount}
                                  handleTipType={handleTipType}
                                  visible={addTipModal}
                                  submitting={submitting}
                                  selectedItemList={inventoryShopsDetails}
                                  ratesList={ratesList}
                                  shopsRatesList={inventoryShopsDetails && inventoryShopsDetails.tips}
                                  selectedAccount={selectedAccount}
                                  close={() => setAddTipModal(false)}
                                  submit={AddTipsshop}
                                />
                                )}
                                {/* DELETE PROMO MODAL */}
                                {deleteTip && (
                                <DeleteTip
                                  visible={deleteTip}
                                  submitting={submitting}
                                  selectedAccount={selectedAccount}
                                  selectedPromoCode={deleteTipData.gid}
                                  close={() => setDeleteTip(false)}
                                  submit={deleteTipsshop}
                                />
                                )}
                                {
                                (inventoryShopsDetails && inventoryShopsDetails.tips && inventoryShopsDetails.tips.length !== 0) ? (
                                  <>
                                    <Table
                                      rowClassName={() => 'ant-table-clickable-row'}
                                      columns={[...tipColumns, actionTipColumn]}
                                      rowKey={(record) => record.gid}
                                      className="custom-table"
                                      dataSource={inventoryShopsDetails && inventoryShopsDetails.tips}
                                      // pagination={pagination} tips pagination not required as requirement,tips taken from shop api.
                                      // onChange={handleTableChange}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        border: '1px solid #CBCBCB',
                                        minHeight: '253px',
                                        width: '1014 px',
                                        marginTop: '10px',
                                        marginBottom: '25px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <div>
                                        <div className="ml-4">
                                          <SpH5 color="#333333">
                                            No data
                                          </SpH5>
                                        </div>
                                        <div
                                          style={{
                                            marginTop: '15px',
                                          }}
                                        >
                                          {
                                            kdsRole !== 'KDS-USER' && (
                                              <SpButton
                                                type="secondary"
                                                onClick={() => setAddTipModal(true)}
                                              >
                                                {'\u002B'}&nbsp;Add Tip
                                              </SpButton>
                                            )
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )
                              }
                              </>
                            ) : current === 'serviceTab' ? (
                              <>
                                <Row type="flex" justify="space-between" className="my-2" />

                                {/* Add SERVICE TAXES MODAL */}
                                {editTaxRateModal && (
                                <AddRemoveTaxRates
                                  visible={editTaxRateModal}
                                  submitting={submitting}
                                  selectedItemList={inventoryShopsDetails}
                                  ratesList={serviceRatesList}
                                  shopsRatesList={inventoryShopsDetails && inventoryShopsDetails.serviceFees}
                                  selectedAccount={selectedAccount}
                                  close={() => setEditTaxRateModal(false)}
                                  submit={editSelectedTaxes}
                                />
                                )}
                                {/* DELETE SERVICE TAXES MODAL */}
                                {deleteTaxRateModal && (
                                <RemoveTaxRates
                                  visible={deleteTaxRateModal}
                                  submitting={submitting}
                                  selectedItemList={inventoryShopsDetails}
                                  ratesList={serviceRatesList}
                                  shopsRatesList={inventoryShopsDetails && inventoryShopsDetails.serviceFees}
                                  selectedTaxRate={selectedTaxRate}
                                  close={() => setDeleteTaxRateModal(false)}
                                  submit={deleteSelectedTaxes}
                                />
                                )}
                                {
                                  (inventoryShopsDetails && inventoryShopsDetails.serviceFees && inventoryShopsDetails.serviceFees.length !== 0) ? (
                                    <>
                                      <Table
                                        rowClassName={() => 'ant-table-clickable-row'}
                                        columns={kdsRole !== 'KDS-USER' ? [...serviceColumns, taxRateActionColumn] : serviceColumns}
                                        rowKey={(record) => record.gid}
                                        className="custom-table"
                                        dataSource={inventoryShopsDetails && inventoryShopsDetails.serviceFees}
                                        pagination={pagination}
                                        onChange={handleTableChange}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={{
                                          border: '1px solid #CBCBCB',
                                          minHeight: '253px',
                                          width: '1014 px',
                                          marginTop: '10px',
                                          marginBottom: '25px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                      >
                                        <div>
                                          <div className="ml-4">
                                            <SpH5 color="#333333">
                                              No data
                                            </SpH5>
                                          </div>
                                          <div
                                            style={{
                                              marginTop: '15px',
                                            }}
                                          >
                                            {
                                              kdsRole !== 'KDS-USER' && (
                                                <SpButton
                                                  type="secondary"
                                                  onClick={() => setEditTaxRateModal(true)}
                                                >
                                                  {'\u002B'}&nbsp;Add Service Charge
                                                </SpButton>
                                              )
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                }
                              </>
                            ) : (
                              editHoursModal ? (
                                <>
                                  <Row className="mt-4">
                                    <Col>
                                      <Row type="flex" justify="start">
                                        <Col span={5}>
                                          <SpText fontSize="20px">Open 24/7</SpText><br />
                                          <SpText fontSize="12px">
                                            You can use the Open 24/7 toggle to select if your shop is open 24 hours a day, 7 days a week.
                                          </SpText>
                                        </Col>
                                        <Col className="mt-4 ml-4">
                                          <Switch
                                            className="mr-2"
                                            checked={enableOperationHours}
                                            onChange={isAvailableChange}
                                          /> {enableOperationHours ? 'Enabled' : 'Disabled'}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  {
                                    !enableOperationHours && (
                                      <>
                                        <Row className="my-2 mt-4 mb-3" type="flex" justify="start">
                                          <Col>
                                            <SpText className="text-uppercase" fontSize="20px">
                                              OPERATING HOURS
                                            </SpText>
                                          </Col>
                                        </Row>
                                        <Row className="my-2 mb-3" type="flex" justify="start">
                                          <Col span={3}>
                                            <span className="text-muted">Days</span>
                                          </Col>
                                          <Col span={3}>
                                            <span className="text-muted">From</span>
                                          </Col>
                                          <Col span={3}>
                                            <span className="text-muted">To</span>
                                          </Col>
                                        </Row>
                                        {operatingHours.map((day, dayIndex) => (
                                          <div key={dayIndex}>
                                            {day.operatingHours.map((timeSlot, slotIndex) => (
                                              <div key={slotIndex}>
                                                <Row className="my-2 mb-3" type="flex" justify="start">
                                                  <Col span={3}>
                                                    {slotIndex === 0 && <span>{day.dayOfWeek}</span>}
                                                  </Col>
                                                  <Col span={3}>
                                                    <TimePicker
                                                      allowClear={false}
                                                      use12Hours
                                                      format="h:mm a"
                                                      defaultValue={getTimeVal(dayIndex, slotIndex, 'From')}
                                                      onChange={(e) => selectOperatingHours(e, 'From', dayIndex, slotIndex)}
                                                    />
                                                  </Col>
                                                  <Col span={3}>
                                                    <TimePicker
                                                      allowClear={false}
                                                      use12Hours
                                                      format="h:mm a"
                                                      defaultValue={getTimeVal(dayIndex, slotIndex, 'To')}
                                                      onChange={(e) => selectOperatingHours(e, 'To', dayIndex, slotIndex)}
                                                    />
                                                  </Col>
                                                  <Col span={3}>
                                                    <Switch
                                                      className="mx-3"
                                                      defaultChecked={timeSlot.open}
                                                      onChange={val => updateStatus(val, dayIndex, slotIndex)}
                                                    /> Open
                                                  </Col>
                                                  <Col span={1}>
                                                    {slotIndex === 0 ? (
                                                      <Icon
                                                        type="plus-circle"
                                                        style={{
                                                          cursor: 'pointer', color: '#3E8BFF',
                                                        }}
                                                        onClick={() => addTimeSlot(dayIndex)}
                                                      />
                                                    )
                                                      : (
                                                        <Icon
                                                          type="minus-circle"
                                                          style={{
                                                            cursor: 'pointer', color: '#FF4B51',
                                                          }}
                                                          onClick={() => removeTimeSlot(dayIndex, slotIndex)}
                                                        />
                                                      )}
                                                  </Col>
                                                </Row>
                                              </div>
                                            ))}
                                          </div>
                                        ))}
                                      </>
                                    )
                                  }
                                  <Row>
                                    <Col span={24} className="d-flex justify-content-end">
                                      <SpButton className="mr-3" onClick={() => setEditHoursModal(false)} type="secondary">
                                        Cancel
                                      </SpButton>
                                      <SpButton onClick={editSelectedItem}>Save</SpButton>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <>
                                  <Row type="flex" justify="space-between" className="my-2 mt-4">
                                    <Col className="d-flex align-items-center" />
                                    <Col>
                                      {
                                        kdsRole !== 'KDS-USER' && (
                                          <SpButton
                                            shape="round"
                                            type="secondary"
                                            onClick={() => setEditHoursModal(true)}
                                          >
                                            Edit Operating Hours
                                          </SpButton>
                                        )
                                      }
                                    </Col>
                                  </Row>
                                  <Row className="mt-4">
                                    <Col>
                                      <Row type="flex" justify="start">
                                        <Col span={5}>
                                          <SpText fontSize="20px">Open 24/7</SpText><br />
                                          <SpText fontSize="12px">
                                            You can use the Open 24/7 toggle to select if your shop is open 24 hours a day, 7 days a week.
                                          </SpText>
                                        </Col>
                                        <Col className="mt-4 ml-4">
                                          <Switch
                                            className="mr-2"
                                            checked={enableOperationHours}
                                            disabled
                                          /> {enableOperationHours ? 'Enabled' : 'Disabled'}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                  {
                                    // eslint-disable-next-line
                                    inventoryShopsDetails && inventoryShopsDetails.hoursOfOperations && inventoryShopsDetails.hoursOfOperations.length !== 0 ? (
                                      <>
                                        {
                                          !enableOperationHours && (
                                            <>
                                              <Row className="my-2 mb-3" type="flex" justify="start">
                                                <Col>
                                                  <SpText className="text-uppercase" fontSize="20px">
                                                    OPERATING HOURS
                                                  </SpText>
                                                </Col>
                                              </Row>
                                              <Row className="my-2 mb-3" type="flex" justify="start">
                                                <Col span={3}>
                                                  <span className="text-muted">Days</span>
                                                </Col>
                                                <Col span={3}>
                                                  <span className="text-muted">From</span>
                                                </Col>
                                                <Col span={3}>
                                                  <span className="text-muted">To</span>
                                                </Col>
                                              </Row>
                                              {inventoryShopsDetails.hoursOfOperations.map((day, dayIndex) => (
                                                <div key={dayIndex}>
                                                  {day.operatingHours.map((timeSlot, slotIndex) => (
                                                    <div key={slotIndex}>
                                                      <Row className="my-2 mb-3" type="flex" justify="start">
                                                        <Col span={3}>
                                                          {slotIndex === 0 && <span>{day.dayOfWeek}</span>}
                                                        </Col>
                                                        <Col span={3}>
                                                          {(timeSlot.openingTime) ? getOperatingTime(timeSlot.openingTime) : <>12:00 AM</>}
                                                        </Col>
                                                        <Col span={3}>
                                                          {(timeSlot.closingTime) ? getOperatingTime(timeSlot.closingTime) : <>12:00 AM</>}
                                                        </Col>
                                                        <Col span={3}>
                                                          <Switch
                                                            className="mx-3"
                                                            disabled
                                                            defaultChecked={timeSlot.open}
                                                          /> Open
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  ))}
                                                </div>
                                              ))}
                                            </>
                                          )
                                        }

                                      </>
                                    ) : (
                                      <></>
                                    )
                                  }
                                </>
                              )
                            )
                          }


                        </Card>
                      </>
                    )
                  }
                </>
              ) : shopTab === 'QuickSupport' ? (
                <>
                  <Card className="pl-2">
                    <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                      <Col className="d-flex align-items-center" />
                      <Col>
                        {
                          kdsRole !== 'KDS-USER' && (
                            <>
                              <SpButton
                                shape="round"
                                type="secondary"
                                className="mr-3"
                                onClick={() => setAddSupportModal(true)}
                              >
                                {'\u002B'}&nbsp;Add New Support
                              </SpButton>
                            </>
                          )
                        }
                      </Col>
                    </Row>
                    <Table
                      rowClassName={() => 'ant-table-clickable-row'}
                      columns={[...quickcolumns, supportActionColumn]}
                      rowKey={(record) => record.gid}
                      className="custom-table"
                      dataSource={inventoryQuickSupport}
                      pagination={pagination}
                      onChange={handleTableChange}
                    />
                    {/* <SpForm>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={5}>
                          <span>Label</span>
                        </Col>
                        <Col span={6}>
                          <SpH5>
                            {
                              (inventoryQuickSupport && inventoryQuickSupport.length !== 0)
                                ? (inventoryQuickSupport && inventoryQuickSupport[0].name)
                                : <>&#8211;</>
                            }
                          </SpH5>
                        </Col>
                      </Row>
                    </SpForm>
                    <SpForm>
                      <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                        <Col className="d-flex align-items-center">
                          {
                            (inventoryQuickSupport && inventoryQuickSupport.length !== 0) ? (
                              inventoryQuickSupport && inventoryQuickSupport[0].iconUrl ? (
                                <img
                                  src={inventoryQuickSupport && inventoryQuickSupport[0].iconUrl}
                                  alt="shop"
                                  width="100"
                                  height="100"
                                />
                              ) : (
                                <BannerPreview />
                              )
                            ) : (
                              <BannerPreview />
                            )
                          }
                        </Col>
                      </Row>
                    </SpForm> */}
                    {/* ADD SUPPORT MODAL */}
                    {addSupportModal && (
                      <AddSupport
                        visible={addSupportModal}
                        submitting={submitting}
                        selectedAccount={selectedAccount}
                        close={() => setAddSupportModal(false)}
                        submit={addSelectedSupport}
                      />
                    )}
                    {/* EDIT SUPPORT MODAL */}
                    {editSupportModal && (
                      <EditSupport
                        visible={editSupportModal}
                        submitting={submitting}
                        selectedAccount={selectedAccount}
                        close={() => setEditSupportModal(false)}
                        inventoryQuickSupport={selectedPromoCode}
                        submit={editSelectedSupport}
                      />
                    )}
                    {/* DELETE SUPPORT MODAL */}
                    {deleteSupportModal && (
                      <DeleteSupport
                        visible={deleteSupportModal}
                        submitting={submitting}
                        selectedAccount={selectedAccount}
                        close={() => setDeleteSupportModal(false)}
                        inventoryQuickSupport={selectedPromoCode}
                        submit={deleteSelectedSupport}
                      />
                    )}
                  </Card>
                </>
              ) : shopTab === 'Feedback' ? (
                <>
                  <Card className="pl-2">
                    <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                      <Col className="d-flex align-items-center" />
                      <Col>
                        {
                      kdsRole !== 'KDS-USER' && (
                        <>
                          <SpButton
                            shape="round"
                            type="secondary"
                            className="mr-3"
                            onClick={() => setAddFeedbackModal(true)}
                          >
                            {'\u002B'}&nbsp;Add More
                          </SpButton>
                        </>
                      )
                    }
                      </Col>
                    </Row>
                    <Table
                      rowClassName={() => 'ant-table-clickable-row'}
                      columns={[...feedbackcolumns, feedbackActionColumn]}
                      rowKey={(record) => record.gid}
                      className="custom-table"
                      dataSource={inventoryFeedback}
                      pagination={pagination}
                      onChange={handleTableChange}
                    />

                    {/* ADD FEEDBACK MOADL */}
                    {addFeedbackModal && (
                    <AddFeedback
                      visible={addFeedbackModal}
                      submitting={submitting}
                      selectedAccount={selectedAccount}
                      close={() => setAddFeedbackModal(false)}
                      submit={addSelectedFeedback}
                    />
                    )}
                    {/* EDIT FEEDBACK MODAL */}
                    {editFeedbackModal && (
                    <EditFeedback
                      visible={editFeedbackModal}
                      submitting={submitting}
                      selectedAccount={selectedAccount}
                      close={() => setEditFeedbackModal(false)}
                      inventoryFeedback={selectedPromoCode}
                      submit={editSelectedFeedback}
                    />
                    )}
                    {/* DELETE FEEDBACK MODAL */}
                    {deleteFeedbackModal && (
                    <DeleteFeedback
                      visible={deleteFeedbackModal}
                      submitting={submitting}
                      selectedAccount={selectedAccount}
                      close={() => setDeleteFeedbackModal(false)}
                      inventoryFeedback={selectedPromoCode}
                      submit={deleteSelectedFeedback}
                    />
                    )}

                    <SpText className="ml-1" fontSize="14px" fontWeight="600">
                      Send feedback  SMS duration
                    </SpText>
                    <Row>
                      <Col span={3}>
                        <Input
                          className="mt-2"
                          type="number"
                          placeholder="Minutes"
            // maxLength={4}
                          min={5}
                          max={1440}
                          defaultValue={(inventoryShopsDetails && inventoryShopsDetails.sendFeedbackNotificationMinutes) || sendFeedbackNotificationMinutes}
                          onChange={e => setSendFeedbackNotificationMinutes(e.currentTarget.value)}
                        />
                        <SpError>
                          {simpleValidator.current.message('Minutes', sendFeedbackNotificationMinutes, 'required|minutesValidation')}
                        </SpError>
                      </Col>
                      <Col span={2} className="mt-2">
                        <SpText className="ml-3 " fontSize="14px">
                          Minutes
                        </SpText>
                      </Col>
                      <Col className="mt-1">
                        <Switch
                          defaultChecked={(inventoryShopsDetails && inventoryShopsDetails.feedbackEnabled) || false}
                          onChange={val => handleenableFeedbackSms(val)}
                        />
                        <SpText className="ml-1" fontSize="14px">
                          Enable feedback  SMS configuration
                        </SpText>
                      </Col>


                    </Row>
                    <SpText className="mt-2" fontSize="12px">
                      <SpText fontWeight="400">
                        Notes:
                      </SpText>
                      Send an SMS within a range of 5 minutes to 24 hours after the payment..
                    </SpText>
                    <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                      <Col className="d-flex align-items-center" />
                      <Col>
                        <SpButton type="secondary" onClick={handleCancelFeedback}>Cancel</SpButton>
                        <SpButton className="ml-2" onClick={(event) => handleSendFeedbackNotificationMinutes(event)}>Save</SpButton>
                      </Col>
                    </Row>

                  </Card>
                </>
              ) : null
            }
          </>
        )
      }

      {inventoryShopsDetails.gid && inventoryCategorieDetails && addItemsModal && (
        <>
          <Card className="mb-3" style={{ backgroundColor: '#214885' }}>
            <Row className=" mt-4">
              <Col span={5}>
                <h4 style={{ color: '#fff', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                  <img src={addItemsImage} className="mb-1" alt="additemsImgae" />Setup your Online Menu
                  <span style={{
                    color: '#fff', fontSize: '17px', fontStyle: 'italic', fontWeight: 400,
                  }}
                  > (Select the items you want to display for online ordering. If you need to edit or reorder them, go to Inventory.)
                  </span>
                </h4>
              </Col>
            </Row>
          </Card>

          <Card className="mb-3" style={{ maxHeight: '60dvh', overflow: 'scroll' }}>
            <Row className="my-2 mt-4">
              <Col>
                {inventoryCategorieDetails.map((category, index) => (
                  <Collapse accordion onChange={(key) => onChangeCollapse(key, category.gid)} activeKey={openPanelKey}>
                    <Panel
                      className="categoryname_panerheader"
                      header={(
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>{`${category.name} (${category.itemCount})`}</span>
                        </div>
                    )}
                      key={index.toString()}
                    >
                      <p className="ml-2">

                        {categoryItemsList && categoryItemsList.filter((item) => item.added === 'true').length === categoryItemsList.length
                          && (
                          <Checkbox
                            onChange={(event) => handleAllitemSelectCheckbox(event.target.checked)}
                            defaultChecked="true"
                            className="m-1"
                          />
                          )}
                        {categoryItemsList && categoryItemsList.filter((item) => item.added === 'true').length !== categoryItemsList.length
                          && (
                          <Checkbox
                            onChange={(event) => handleAllitemSelectCheckbox(event.target.checked)}
                            className="m-1"
                          />
                          )}
                        {selectedCount} selected |{categoryItemsList && categoryItemsList.length} (out of {categoryItemsList && categoryItemsList.length}) items enabled to show online

                      </p>
                      <hr />
                      <div style={{
                        display: 'flex', flexWrap: 'wrap', gap: '10px', marginLeft: '2px',
                      }}
                      >
                        {categoryItemsList && categoryItemsList.map((item, index) => (
                          <div style={{
                            display: 'flex', gap: '5px', position: 'relative', padding: '5px',
                          }}
                          >

                            {item.added === 'true' && selectAllChecked === 'default'
                              && (
                              <Checkbox
                                defaultChecked="true"
                                onChange={(event) => handleItemCheckbox(item.gid, event.target.checked)}
                                style={{ position: 'absolute', left: '-16px' }}
                              />
                              )}
                            {item.added === 'false' && selectAllChecked === 'default'
                              && (
                              <Checkbox
                                onChange={(event) => handleItemCheckbox(item.gid, event.target.checked)}
                                style={{ position: 'absolute', left: '-16px' }}
                              />
                              )}
                            {/* for selectAll checkbox fuction implementation */}
                            {selectAllChecked && selectAllChecked !== 'default' && (
                            <Checkbox
                              defaultChecked="true"

                              onChange={(event) => handleItemCheckbox(item.gid, event.target.checked)}
                              style={{ position: 'absolute', left: '-16px' }}
                            />
                            )}
                            {!selectAllChecked && selectAllChecked !== 'default' && (
                              <Checkbox

                                onChange={(event) => handleItemCheckbox(item.gid, event.target.checked)}
                                style={{ position: 'absolute', left: '-16px' }}
                              />
                            )}

                            <div className="item_box ">
                              <div>
                                <p className="individual_itemname">{item.name}</p>
                                <p className="individual_itemprice">${item.price / 100}</p>
                              </div>
                              <Tooltip placement="top" title="Edit">
                                <Icon
                                  type="edit"
                                  style={{ fontSize: '20px', cursor: 'pointer', margin: '2px' }}
                                  onClick={() => selectedItemView(item.gid)}
                                />
                              </Tooltip>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Panel>
                  </Collapse>
                ))}
              </Col>
            </Row>
          </Card>
          <div style={{ float: 'right' }}>
            <SpButton type="secondary" onClick={() => handleCancel()}>Cancel</SpButton>
            <SpButton style={{ margin: '5px' }} onClick={() => addSelectedItem(items)}>Save</SpButton>
          </div>


        </>
      )}

    </>
  );
};

const mapStateToProps = (state) => ({
  inventoryShopsDetails: state.inventoryShopsDetails,
  inventoryCategorieDetails: state.inventoryCategorieDetails.entity,
  categoryItemsList: state.inventoryCategorieDetails.categoryItemsList.entity,
  inventoryItems: state.inventoryItems.sortItemsList.content,
  shopsItemsList: state.inventoryShopsDetails.shopsItemsList,
  ratesList: state.inventoryShops.ratesList.content,
  categoryList: state.inventoryItems.itemsList.content,
  shopPromosList: state.inventoryShopsDetails.shopPromosList.content,
  totalPromoElements: state.inventoryShopsDetails.shopPromosList ? state.inventoryShopsDetails.shopPromosList.totalElements : '',
  promosList: state.inventoryShops.promosList.content,
  allShopPromosList: state.inventoryShopsDetails.allShopPromosList.content,
  accountSlug: state.accountSlug.content,
  paymentSlug: state.inventoryShopsDetails.shopsSlug,
  totalElements: state.inventoryShopsDetails.shopsItemsList ? state.inventoryShopsDetails.shopsItemsList.length : '',
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  profile: state.subscription.profile,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  assosiateList: state.inventoryShops.assosiateList,
  inventoryQuickSupport: state.inventoryQuickSupport.content,
  serviceRatesList: state.serviceRates.content,
  totalQuickSupportElements: state.inventoryQuickSupport ? state.inventoryQuickSupport.totalElements : '',
  inventoryFeedback: state.inventoyFeedback.content,
  totalInventoryFeedbackElements: state.inventoyFeedback ? state.inventoyFeedback.totalElements : '',
  error: state.notification.error,
  shopTables: state.inventoryShopsDetails.shopTables,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryShopsDetails: gid => dispatch(inventoryShopsDetailsActions.fetchInventoryShopsDetails({
    payload: gid,
  })),
  addItemShops: gid => dispatch(inventoryShopsDetailsActions.addItemShops({
    payload: gid,
  })),
  addPromosByShop: param => dispatch(inventoryShopsDetailsActions.addPromosByShop({
    payload: param,
  })),
  addTips: param => dispatch(inventoryShopsDetailsActions.addTips({
    payload: param,
  })),
  getAllItemsByCategory: param => dispatch(inventoryCategorieDetailsActions.getAllItemsByCategory({
    payload: param,
  })),
  getItemsBySelectedCategory: param => dispatch(inventoryCategorieDetailsActions.getItemsBySelectedCategory({
    payload: param,
  })),

  updatePromosByShop: param => dispatch(inventoryShopsDetailsActions.updatePromosByShop({
    payload: param,
  })),

  deletePromosByShop: param => dispatch(inventoryShopsDetailsActions.deletePromosByShop({
    payload: param,
  })),

  deleteTipsByShop: param => dispatch(inventoryShopsDetailsActions.deleteTipsByShop({
    payload: param,
  })),

  fetchInventorySortItems: param => dispatch(inventoryItemsActions.fetchInventorySortItems({
    payload: param,
  })),
  fetchShopsSlug: param => dispatch(inventoryShopsDetailsActions.fetchShopsSlug({
    payload: param,
  })),
  fetchItemByShops: gid => dispatch(inventoryShopsDetailsActions.fetchItemByShops({
    payload: gid,
  })),
  updateInventoryShops: param => dispatch(inventoryShopsActions.updateInventoryShops({
    payload: param,
  })),
  deleteInventoryShops: param => dispatch(inventoryShopsActions.deleteInventoryShops({
    payload: param,
  })),
  fetchAccountSlug: param => dispatch(accountSlugActions.fetchAccountSlug({
    payload: param,
  })),
  linkAccountSlug: param => dispatch(accountSlugActions.linkAccountSlug({
    payload: param,
  })),
  unLinkAccountSlug: param => dispatch(accountSlugActions.unLinkAccountSlug({
    payload: param,
  })),
  fetchShopRates: param => dispatch(inventoryShopsActions.fetchShopRates({
    payload: param,
  })),
  fetchPromosList: gid => dispatch(inventoryShopsActions.fetchPromosList({
    payload: gid,
  })),
  fetchItemsList: param => dispatch(inventoryItemsActions.fetchItemsList({
    payload: param,
  })),
  fetchPromosByShop: gid => dispatch(inventoryShopsDetailsActions.fetchPromosByShop({
    payload: gid,
  })),
  fetchAllPromosByShop: gid => dispatch(inventoryShopsDetailsActions.fetchAllPromosByShop({
    payload: gid,
  })),
  addInventoryShopAssociate: param => dispatch(inventoryShopsActions.addInventoryShopAssociate({
    payload: param,
  })),
  fetchInventoryShopAssociate: param => dispatch(inventoryShopsActions.fetchInventoryShopAssociate({
    payload: param,
  })),
  fetchInventoryQuickSupport: param => dispatch(inventoryQuickSupportActions.fetchInventoryQuickSupport({
    payload: param,
  })),
  addInventoryQuickSupport: param => dispatch(inventoryQuickSupportActions.addInventoryQuickSupport({
    payload: param,
  })),
  getInventoryFeedack: param => dispatch(inventoryFeedbackActions.fetchInventoryFeedback({
    payload: param,
  })),
  addInventoryFeedback: param => dispatch(inventoryFeedbackActions.addInventoryFeedback({
    payload: param,
  })),
  updateInventoryFeedback: param => dispatch(inventoryFeedbackActions.updateInventoryFeedback({
    payload: param,
  })),
  deleteInventoryFeedback: param => dispatch(inventoryFeedbackActions.deleteInventoryFeedback({
    payload: param,
  })),
  updateInventoryQuickSupport: param => dispatch(inventoryQuickSupportActions.updateInventoryQuickSupport({
    payload: param,
  })),
  deleteInventoryQuickSupport: param => dispatch(inventoryQuickSupportActions.deleteInventoryQuickSupport({
    payload: param,
  })),
  updateInventoryShopAssociate: param => dispatch(inventoryShopsActions.updateInventoryShopAssociate({
    payload: param,
  })),
  deleteInventoryShopAssociate: param => dispatch(inventoryShopsActions.deleteInventoryShopAssociate({
    payload: param,
  })),
  fetchServicerates: param => dispatch(serviceRatesActions.fetchServicerates({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  fetchTablesShops: gid => dispatch(inventoryShopsDetailsActions.fetchTablesShops({
    payload: gid,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ShopsDetails);
