import { VENDORINVOICEDETAILS } from '../actionTypes';

export const fetchVendorInvoiceDetails = (action) => ({
  type: VENDORINVOICEDETAILS.FETCH,
  payload: action.payload,
});

export const updateVendorInvoice = (action) => ({
  type: VENDORINVOICEDETAILS.UPDATE,
  payload: action.payload,
});

export const getContactList = (action) => ({
  type: VENDORINVOICEDETAILS.CONTACT_FETCH,
  payload: action.payload,
});

export const addNewVendorInvoice = (action) => ({
  type: VENDORINVOICEDETAILS.INVOICE_ADD,
  payload: action.payload,
});

export const addContact = (action) => ({
  type: VENDORINVOICEDETAILS.CONTACT_ADD,
  payload: action.payload,
});

export const deleteVendorInvoice = (action) => ({
  type: VENDORINVOICEDETAILS.DELETE,
  payload: action.payload,
});

export const cancelVendorInvoices = (action) => ({
  type: VENDORINVOICEDETAILS.CANCEL,
  payload: action.payload,
});

export const notifyVendorInvoice = (action) => ({
  type: VENDORINVOICEDETAILS.NOTIFY_SUCCESS,
  payload: action.payload,
});

export const payVendorInvoice = (action) => ({
  type: VENDORINVOICEDETAILS.INVOICE_PAY,
  payload: action.payload,
});
export const openPayVendorInvoiceModal = (action) => ({
  type: action.type,
  payload: action.payload,
});
