import React, { useEffect, useState } from 'react';
import { AmountDivideByHundred } from '../../../../../helper';
import outOfStock from '../../../../../imgs/outOfStock.png';

type Props = {
    eventDetails : Object,
    eventPageProduct : Object,
    orderItemIndex : Number,
    lineItems : Array<Object>,
    setOrderItems : Function,
    splitUpApi: Function,
    splitUpResponse: Object,
}
const AddProducts = (props: Props) => {
  const {
    eventDetails,
    eventPageProduct,
    orderItemIndex,
    lineItems,
    setOrderItems,
    splitUpApi,
    splitUpResponse,
  } = props;

  let productCopy = eventPageProduct;
  const [validationErrors, setValidationErrors] = useState([]);
  const [mandatoryItems, setMandatoryItems] = useState([]);

  if (typeof productCopy !== 'undefined' && productCopy !== eventPageProduct) {
    productCopy = eventPageProduct;
  }
  const [activeModifier, setActiveModifier] = useState(null);
  const toggleModifier = (itemGid) => {
    setActiveModifier(activeModifier === itemGid ? null : itemGid);
  };

  const [activeItem, setActiveItem] = useState(null);
  const toggleItem = (index) => {
    setActiveItem(activeItem === index ? null : index);
  };

  const [activeItemModifier, setActiveItemModifier] = useState(null);
  const toggleItemModifier = (index) => {
    setActiveItemModifier(activeItemModifier === index ? null : index);
  };

  const [updates, setUpdates] = useState([]);
  const [updateOrderItem, setUpdateOrderItem] = useState(false);
  const [splitUpCalled, setSplitUpCalled] = useState(false);

  useEffect(() => {
    setUpdates(lineItems);
  }, [lineItems]);

  useEffect(() => {
    setActiveModifier(null);
    setActiveItem(null);
    setActiveItemModifier(null);
    setMandatoryItems([]);
    setUpdateOrderItem(false);
    setSplitUpCalled(false);
  }, [productCopy]);

  const updateCustomItemFunction = (customItem, item, modifier, change, replace) => {
    const updateItems = [...updates];
    const customItemIndex = updateItems.findIndex((update) => update.customItemGid === customItem.gid);
    let updateItem;
    if (customItemIndex === -1) {
      if (change > 0 || replace > 0) {
        updateItem = {
          name: customItem.inputFieldLabel,
          price: customItem.amount,
          totalPrice: customItem.amount * (replace || change),
          customItemGid: customItem.gid,
          quantity: replace || change,
          lineItemModifiers: [],
          lineItemItems: [],
        };
        return [...updateItems, updateItem];
      }
      return updateItems;
    }

    updateItem = { ...updateItems[customItemIndex] };
    if (updateItem && item) {
      const itemIndex = updateItem.lineItemItems.findIndex(
        (update) => update.itemGid === item.gid,
      );

      if (itemIndex === -1) {
        if (change > 0 || replace > 0) {
          updateItem.lineItemItems.push({
            name: item.displayName,
            price: item.price,
            totalPrice: (item.price - (item.coupon ? (item.coupon.percentageOff
              ? Math.round((item.price * item.coupon.percentageOff) / 100)
              : Math.min(item.coupon.amountOff, item.price)) : 0)) * change,
            itemGid: item.gid,
            quantity: item.enableStock ? Math.min(item.onlineStock, replace || change) : replace || change,
            lineItemModifiers: [],
            lineItemDiscounts: item.coupon ? [
              {
                discountGid: item.coupon.gid,
                stackable: item.discountStackable,
                type: 'COUPON',
                amountOff: item.coupon.amountOff ? item.coupon.amountOff * -1 : item.coupon.amountOff,
                percentageOff: item.coupon.percentageOff,
              },
            ] : [],
          });
          const sumModifier = updateItem?.lineItemModifiers?.reduce((sum, lineItemModifier) => sum + (lineItemModifier.quantity * lineItemModifier.price || 0), 0);
          const sumLineItems = updateItem?.lineItemItems?.reduce((sum, lineItem) => {
            const modifierTotal = lineItem.lineItemModifiers?.reduce(
                (modSum, lineItemModifier) => modSum + (lineItemModifier.quantity * (lineItemModifier.price || 0)),
                0
            ) || 0;

            const lineItemTotal = lineItem.price + modifierTotal;

            const discountTotal = lineItem.lineItemDiscounts?.reduce((discSum, discount) => {
              const discountValue = discount.percentageOff
                  ? Math.round((lineItemTotal * discount.percentageOff) / 100) * -1 || 0
                  : Math.min(discount.amountOff * -1, lineItemTotal) * -1;

              return discSum + discountValue;
            }, 0) || 0;

            return sum + (lineItem.quantity * (lineItemTotal + discountTotal));
          }, 0) || 0;
          updateItem.totalPrice = (updateItem.price + sumModifier + sumLineItems);
          return updateItems.map((update, index) => (index === customItemIndex ? updateItem : update));
        }
        return updateItems;
      }
      if (!modifier) {
        updateItem.lineItemItems = updateItem.lineItemItems.map((lineItem, index) => {
          if (index === itemIndex) {
            // eslint-disable-next-line max-len
            return { ...lineItem, quantity: item.enableStock ? Math.min(item.onlineStock, replace || Math.max(0, lineItem.quantity + change)) : replace || Math.max(0, lineItem.quantity + change) };
          }
          return lineItem;
        });

        updateItem.lineItemItems = updateItem.lineItemItems.filter(lineItem => lineItem.quantity > 0);
      } else {
        const modifierIndex = updateItem.lineItemItems[itemIndex].lineItemModifiers.findIndex(
          (update) => update.modifierGid === modifier.gid,
        );

        if (modifierIndex === -1) {
          if (change > 0 || replace > 0) {
            updateItem.lineItemItems[itemIndex].lineItemModifiers.push({
              name: modifier.name,
              price: modifier.price,
              modifierGid: modifier.gid,
              quantity: modifier.maxStackableQuantity
                ? Math.min((replace || change), modifier.maxStackableQuantity)
                : replace || change,
            });
          }
        } else {
          updateItem.lineItemItems[itemIndex].lineItemModifiers = updateItem.lineItemItems[itemIndex].lineItemModifiers.map((mod, index) => {
            if (index === modifierIndex) {
              return {
                ...mod,
                quantity: (modifier.maxStackableQuantity
                  ? Math.min((replace || (mod.quantity + change)), modifier.maxStackableQuantity)
                  : replace || (mod.quantity + change)),
              };
            }
            return mod;
          });
          // eslint-disable-next-line max-len
          updateItem.lineItemItems[itemIndex].lineItemModifiers = updateItem.lineItemItems[itemIndex].lineItemModifiers.filter(mod => mod.quantity > 0);
        }
      }
    }
    if (updateItem && !item && modifier) {
      const modifierIndex = updateItem.lineItemModifiers.findIndex(
        (update) => update.modifierGid === modifier.gid,
      );

      if (modifierIndex === -1) {
        if (change > 0 || replace > 0) {
          updateItem.lineItemModifiers.push({
            name: modifier.name,
            price: modifier.price,
            modifierGid: modifier.gid,
            quantity: modifier.maxStackableQuantity
              ? Math.min((replace || change), modifier.maxStackableQuantity)
              : replace || change,
          });
        }
      } else {
        updateItem.lineItemModifiers = updateItem.lineItemModifiers.map((mod, index) => {
          if (index === modifierIndex) {
            return {
              ...mod,
              quantity: modifier.maxStackableQuantity
                ? Math.min((replace || (mod.quantity + change)), modifier.maxStackableQuantity)
                : replace || (mod.quantity + change),
            };
          }
          return mod;
        });

        updateItem.lineItemModifiers = updateItem.lineItemModifiers.filter(mod => mod.quantity > 0);
      }
    }
    if (!item && !modifier) {
      updateItem.quantity = Math.max(0, replace || ((updateItem.quantity || 0) + change));
      if (updateItem.quantity === 0) {
        return updateItems.filter((_, index) => index !== customItemIndex); // Remove item if quantity is 0
      }
    }

    // eslint-disable-next-line max-len
    const sumModifier = updateItem?.lineItemModifiers?.reduce((sum, lineItemModifier) => sum + (lineItemModifier.quantity * lineItemModifier.price || 0), 0);
    const sumLineItems = updateItem?.lineItemItems?.reduce((sum, lineItem) => {
      const modifierTotal = lineItem.lineItemModifiers?.reduce(
              (modSum, lineItemModifier) => modSum + (lineItemModifier.quantity * (lineItemModifier.price || 0)),
              0
          ) || 0;

      const lineItemTotal = lineItem.price + modifierTotal;

      const discountTotal = lineItem.lineItemDiscounts?.reduce((discSum, discount) => {
        const discountValue = discount.percentageOff
          ? Math.round((lineItemTotal * discount.percentageOff) / 100) * -1 || 0
          : Math.min(discount.amountOff * -1, lineItemTotal) * -1;

        return discSum + discountValue;
      }, 0) || 0;

      return sum + (lineItem.quantity * (lineItemTotal + discountTotal));
    }, 0) || 0;
    updateItem.totalPrice = (updateItem.price + sumModifier + sumLineItems);
    const discountAmount = updateItem.lineItemDiscounts?.reduce((sum, discount) => {
      const discountValue = discount.percentageOff
        ? Math.round((updateItem.totalPrice * discount.percentageOff) / 100) * -1
        : Math.min(discount.amountOff, updateItem.totalPrice);
      return sum + discountValue;
    }, 0) || 0;
    updateItem.totalPrice = (updateItem.totalPrice + discountAmount) * updateItem.quantity;

    return updateItems.map((update, index) => (index === customItemIndex ? updateItem : update));
  };

  const updateCustomItemQuantity = async (customItem, item, modifier, change, replace) => {
    const updatedItems = await updateCustomItemFunction(customItem, item, modifier, change, replace);
    setUpdates(updatedItems);
    setUpdateOrderItem(true);
  };

  const updateItemFunction = (item, modifier, change, replace) => {
    const updateItems = [...updates];
    const itemIndex = updateItems.findIndex((update) => update.itemGid === item.gid);
    let updateItem;
    if (itemIndex === -1) {
      if (change > 0 || replace > 0) {
        updateItem = {
          name: item.displayName,
          price: item.price,
          totalPrice: (item.price - (item.coupon ? (item.coupon.percentageOff
            ? Math.round((item.price * item.coupon.percentageOff) / 100)
            : Math.min(item.coupon.amountOff, item.price)) : 0)) * change,
          itemGid: item.gid,
          quantity: item.enableStock ? Math.min(item.onlineStock, replace || change) : replace || change,
          lineItemModifiers: [],
          lineItemDiscounts: item.coupon ? [
            {
              discountGid: item.coupon.gid,
              stackable: item.discountStackable,
              type: 'COUPON',
              amountOff: item.coupon.amountOff ? item.coupon.amountOff * -1 : item.coupon.amountOff,
              percentageOff: item.coupon.percentageOff,
            },
          ] : [],
        };
        return [...updateItems, updateItem];
      }
      return updateItems;
    }

    updateItem = { ...updateItems[itemIndex] };

    if (!modifier) {
      updateItem.quantity = item.enableStock
        ? Math.min(item.onlineStock, replace || Math.max(0, replace || ((updateItem.quantity || 0) + change)))
        : replace || Math.max(0, replace || ((updateItem.quantity || 0) + change));
      if (updateItem.quantity === 0) {
        return updateItems.filter((_, index) => index !== itemIndex);
      }
    }

    if (updateItem && modifier) {
      const modifierIndex = updateItem.lineItemModifiers.findIndex(
        (update) => update.modifierGid === modifier.gid,
      );

      if (modifierIndex === -1) {
        if (change > 0 || replace > 0) {
          updateItem.lineItemModifiers.push({
            name: modifier.name,
            price: modifier.price,
            modifierGid: modifier.gid,
            quantity: modifier.maxStackableQuantity
              ? Math.min((replace || change), modifier.maxStackableQuantity)
              : replace || change,
          });
        }
      } else {
        updateItem.lineItemModifiers = updateItem.lineItemModifiers.map((mod, index) => {
          if (index === modifierIndex) {
            return {
              ...mod,
              quantity: mod.maxStackableQuantity
                ? Math.min((replace || (Math.max(0, mod.quantity + change))), mod.maxStackableQuantity)
                : replace || (Math.max(0, mod.quantity + change)),
            };
          }
          return mod;
        });

        updateItem.lineItemModifiers = updateItem.lineItemModifiers.filter(mod => mod.quantity > 0);
      }
    }
    // eslint-disable-next-line max-len
    const sumModifier = updateItem?.lineItemModifiers?.reduce((sum, lineItemModifier) => sum + (lineItemModifier.quantity * lineItemModifier.price || 0), 0);
    updateItem.totalPrice = (updateItem.price + sumModifier);
    const discountAmount = updateItem.lineItemDiscounts?.reduce((sum, discount) => {
      const discountValue = discount.percentageOff
        ? Math.round((updateItem.totalPrice * discount.percentageOff) / 100) * -1
        : Math.min(discount.amountOff, updateItem.totalPrice);
      return sum + discountValue;
    }, 0) || 0;
    updateItem.totalPrice = (updateItem.totalPrice + discountAmount) * updateItem.quantity;

    if (JSON.stringify(updateItems[itemIndex]) === JSON.stringify(updateItem)) {
      return updates;
    }

    return updateItems.map((update, index) => (index === itemIndex ? updateItem : update));
  };

  const updateItemQuantity = async (item, modifier, change, replace) => {
    const updatedItems = await updateItemFunction(item, modifier, change, replace);
    setUpdates(updatedItems);
    setUpdateOrderItem(true);
  };

  useEffect(() => {
    if (eventPageProduct?.gid && updates && updateOrderItem) {
      splitUpApi({
        eventPageGid: eventDetails.gid,
        eventPageOrderLineItems: updates,
        eventPageOrderDiscounts: eventDetails.coupon ? [
          {
            discountGid: eventDetails.coupon.gid,
            stackable: eventDetails.discountStackable,
            type: 'COUPON',
            amountOff: eventDetails.coupon.amountOff ? eventDetails.coupon.amountOff * -1 : eventDetails.coupon.amountOff,
            percentageOff: eventDetails.coupon.percentageOff,
          },
        ] : [],
      });
      setSplitUpCalled(true);
    }
  }, [updateOrderItem]);

  useEffect(() => {
    if (splitUpResponse && splitUpCalled) {
      setOrderItems((prevOrderItems) => {
        const updatedOrderItems = [...prevOrderItems];
        const updatedOrderItem = updatedOrderItems[orderItemIndex];
        if (updatedOrderItem) {
          updatedOrderItems[orderItemIndex] = {
            ...updatedOrderItems[orderItemIndex],
            lineItems: updates,
            totalAmount: splitUpResponse?.subTotal || 0,
            totalCount: updates.reduce((sum, update) => sum + (update.quantity || 0), 0),
            errors: [
              ...(validationErrors ? validationErrors
                .filter((validationError) => validationError.productGid === eventPageProduct.gid)
                .map((modifierGroupError) => modifierGroupError.errorMessage) : []),
              ...mandatoryItems
                .filter((customItem) => !updates.some((lineItem) => lineItem.customItemGid === customItem.customItemGid))
                .map((customItem) => (customItem.errorMessage)),
            ],
          };
        } else {
          updatedOrderItems.push({
            productGid: eventPageProduct.gid,
            name: eventPageProduct.eventPageProductName,
            lineItems: updates,
            totalAmount: splitUpResponse?.subTotal || 0,
            totalCount: updates.reduce((sum, update) => sum + (update.quantity || 0), 0),
            errors: [
              ...(validationErrors ? validationErrors.map((modifierGroupError) => modifierGroupError.errorMessage) : []),
              ...mandatoryItems
                .filter((customItem) => !updates.some((lineItem) => lineItem.customItemGid === customItem.customItemGid))
                .map((customItem) => (customItem.errorMessage)),
            ],
          });
        }

        return updatedOrderItems;
      });

      setUpdateOrderItem(false);
      setSplitUpCalled(false);
    }
  }, [splitUpResponse]);

  return (
    <div className="product-screen">
      {eventPageProduct ? (
        <>
          <h4 style={{ textAlign: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
            {eventPageProduct.eventPageProductName}
          </h4>

          {eventPageProduct?.items?.length > 0 ? (
              eventPageProduct.items.map((item, index) => (
                <div
                  key={item.gid}
                  style={{
                    marginBottom: '10px',
                    padding: '10px',
                  }}
                >
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                      borderTop: `${index === 0 ? '' : '2px dashed #B3B3B3'}`,
                    }}
                  >
                    <div>
                      <h3 style={{ margin: '10px 0 0', fontSize: '18px', padding: '5px' }}>
                        {item.displayName}
                      </h3>
                      <p style={{ margin: '5px 0 0', color: '#555', padding: '5px' }}>
                        $ {item.price ? AmountDivideByHundred(item.price) : '0.00'}
                        {item.coupon && (
                          <span className="discount-badge">
                            {item.coupon.percentageOff
                              ? `${item.coupon.percentageOff}%`
                              // eslint-disable-next-line max-len
                              : `${item.price > item.coupon.amountOff ? '$ ' : '100% upto $ '}${AmountDivideByHundred(item.coupon.amountOff)}`} Off
                          </span>
                        )}
                      </p>
                      {item.modifierGroups?.length > 0 && (
                      <div style={{ position: 'relative', margin: '5px 0 0', padding: '5px' }}>
                        <button
                          type="button"
                          className="drop-down-menu"
                          onClick={() => toggleModifier(item.gid)}
                          disabled={item.enableStock && item.onlineStock === 0}
                        >
                          Add Modifications &#9660;
                        </button>
                        {activeModifier === item.gid && (
                        <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                          {item.modifierGroups.map((modifierGroup) => (
                            <div key={modifierGroup.gid} style={{ marginBottom: '5px' }}>
                              {/* eslint-disable-next-line max-len */}
                              <h6>{modifierGroup.name} {modifierGroup?.minRequired || modifierGroup?.maxAllowed ? `(${modifierGroup.minRequired ? `choose ${modifierGroup.minRequired}` : ''} ${modifierGroup.maxAllowed ? `max ${modifierGroup.maxAllowed}` : ''})` : '' }</h6>
                              {modifierGroup?.modifiers.map((mod, modIndex) => {
                                const itemInUpdates = updates.find((u) => u.itemGid === item.gid);

                                const modifiersInGroup = itemInUpdates?.lineItemModifiers.filter(
                                      (lineItemModifier) => modifierGroup.modifiers.some(
                                        (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                      )
                                  ) || [];

                                const modifierQuantityTotal = modifiersInGroup.reduce(
                                  (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                  0,
                                );

                                const modifierInItem = itemInUpdates?.lineItemModifiers.find((m) => m.modifierGid === mod.gid);

                                const disableModifier = !mod.available
                                  || (mod?.maxStackableQuantity && modifierInItem?.quantity >= mod.maxStackableQuantity)
                                  || (modifierGroup?.maxAllowed && modifierQuantityTotal >= modifierGroup.maxAllowed);

                                return (
                                  <div
                                    key={mod.gid}
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      paddingTop: '5px',
                                      paddingBottom: '5px',
                                      marginLeft: '30px',
                                      borderTop: modIndex === 0 ? 'none' : '2px dashed #B3B3B3',
                                    }}
                                  >
                                    {mod.name}

                                    {modifierInItem ? (
                                      <div
                                        className="d-flex align-items-center justify-content-between overflow-hidden"
                                        style={{
                                          backgroundColor: '#FFFFFF',
                                          border: '1px solid #000000',
                                          borderRadius: '5px',
                                          marginLeft: '30px',
                                        }}
                                      >
                                        <button
                                          type="button"
                                          className="increase-decrease-button"
                                          style={{ padding: '6px 12px' }}
                                          onClick={() => updateItemQuantity(item, mod, -1, 0)}
                                        >
                                          –
                                        </button>
                                        <input
                                          type="text"
                                          className="input-item"
                                          value={modifierInItem?.quantity || 0}
                                        />
                                        {!disableModifier && (
                                        <button
                                          type="button"
                                          className="increase-decrease-button"
                                          style={{ padding: '6px 12px' }}
                                          onClick={() => updateItemQuantity(item, mod, 1, 0)}
                                          disabled={disableModifier}
                                        >
                                          +
                                        </button>
                                        )}
                                      </div>
                                    ) : (
                                      <button
                                        type="button"
                                        className="add-button"
                                        style={{
                                          marginLeft: '30px',
                                          padding: '8px 16px',
                                          color: `${!disableModifier ? 'rgba(0,0,0,0.69)' : 'rgba(0,0,0,0.35)'}`,
                                        }}
                                        disabled={disableModifier}
                                        onClick={() => updateItemQuantity(item, mod, 1, 0)}
                                      >
                                        Add
                                      </button>
                                    )}
                                  </div>
                                );
                              })}
                            </div>
                          ))}
                        </div>
                        )}
                        {item.modifierGroups?.map((modifierGroup) => {
                          const itemInUpdates = updates.find((u) => u.itemGid === item.gid);

                          const modifiersInGroup = itemInUpdates?.lineItemModifiers.filter(
                                              (lineItemModifier) => modifierGroup.modifiers.some(
                                                (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                              )
                                          ) || [];

                          const modifierQuantityTotal = modifiersInGroup.reduce(
                            (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                            0,
                          );

                          let updateValidationError = [...validationErrors];
                          const validationErrorIndex = updateValidationError.findIndex(
                            (update) => update.uniqueGid === (`${modifierGroup.gid}&${item.gid}`)
                                                  && update.productGid === eventPageProduct.gid,
                          );
                          if (modifierGroup.minRequired && itemInUpdates?.quantity > 0) {
                            if (modifierQuantityTotal < modifierGroup.minRequired) {
                              if (validationErrorIndex === -1) {
                                updateValidationError.push({
                                  uniqueGid: `${modifierGroup.gid}&${item.gid}`,
                                  itemGid: item.gid,
                                  productGid: eventPageProduct.gid,
                                  errorMessage: `Add Modification -> "${modifierGroup.name}" : for Item -> "${item.displayName}"`,
                                });
                                setValidationErrors(updateValidationError);
                              }
                            } else if (validationErrorIndex !== -1) {
                              updateValidationError = updateValidationError.filter((_, errorIndex) => errorIndex !== validationErrorIndex);
                              setValidationErrors(updateValidationError);
                            }
                          } else if (validationErrorIndex !== -1) {
                            updateValidationError = updateValidationError.filter((_, errorIndex) => errorIndex !== validationErrorIndex);
                            setValidationErrors(updateValidationError);
                          }
                        })}
                      </div>
                              )}
                    </div>
                    <div className="d-flex align-items-center" style={{ gap: '10px' }}>
                      {!updates.some((u) => u.itemGid === item.gid) ? (
                        item.enableStock && item.onlineStock === 0 ? (
                          <img alt="" src={outOfStock} style={{ width: '64px', height: '64px' }} />
                        ) : (
                          <button
                            type="button"
                            className="add-button"
                            style={{
                              color: `${item.enableStock && item.onlineStock === 0 ? 'rgba(0,0,0,0.35)' : 'rgba(0,0,0,0.69)'}`,
                            }}
                            disabled={item.enableStock && item.onlineStock === 0}
                            onClick={() => updateItemQuantity(item, null, 1, 0)}
                          >
                            Add
                          </button>
                        )
                      ) : (
                        <>
                          <div
                            className="d-flex align-items-center justify-content-between overflow-hidden"
                            style={{
                              backgroundColor: '#FFFFFF',
                              border: '1px solid #000000',
                              borderRadius: '5px',
                            }}
                          >
                            <button
                              type="button"
                              className="increase-decrease-button"
                              onClick={() => updateItemQuantity(item, null, -1, 0)}
                            >
                              –
                            </button>
                            <input
                              type="text"
                              className="input-item"
                              value={updates.find((u) => u.itemGid === item.gid)?.quantity || 0}
                            />
                            {!(item.enableStock && updates.find((u) => u.itemGid === item.gid)?.quantity >= item.onlineStock) && (
                              <button
                                type="button"
                                className="increase-decrease-button"
                                onClick={() => updateItemQuantity(item, null, 1, 0)}
                              >
                                +
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              ))
          ) : (
              ''
          )}

          {eventPageProduct?.customItems?.length > 0 ? (
          eventPageProduct.customItems.map((customItem, index) => (
            <div
              key={index}
              style={{
                marginBottom: '10px',
                padding: '10px',
              }}
            >
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  borderTop: index !== 0 || eventPageProduct?.items?.length > 0 ? '2px dashed #B3B3B3' : 'none',
                }}
              >
                <div>
                  <h3 style={{ margin: '10px 0 0', fontSize: '18px', padding: '5px' }}>{customItem.inputFieldLabel}</h3>
                  {/* eslint-disable-next-line max-len */}
                  <p style={{ margin: '5px 0 0', color: '#555', padding: '5px' }}>$ {customItem.amount ? AmountDivideByHundred(customItem.amount) : '0.00'}</p>
                  {(() => {
                    if (!customItem.isOptional) {
                      const updateMandatoryItems = [...mandatoryItems];
                      const itemIndex = updateMandatoryItems.findIndex(
                        (update) => update.customItemGid === (customItem.gid),
                      );
                      if (itemIndex === -1) {
                        updateMandatoryItems.push({
                          customItemGid: customItem.gid,
                          errorMessage: `Add ${customItem.inputFieldLabel} To Proceed`,
                        });
                        setMandatoryItems(updateMandatoryItems);
                        setTimeout(() => {
                          setUpdateOrderItem(true);
                        }, 500);
                      }
                    }
                    return (<></>);
                  })()}
                  {customItem.modifierGroups?.length > 0 && (
                  <div style={{ position: 'relative', margin: '5px 0 0', padding: '5px' }}>
                    <button
                      type="button"
                      className="drop-down-menu"
                      onClick={() => toggleModifier(customItem.gid)}
                    >
                      Add Modifications &#9660;
                    </button>
                    {activeModifier === customItem.gid && (
                    <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                      {customItem.modifierGroups.map((modifierGroup) => (
                        <div key={modifierGroup.gid} style={{ marginBottom: '5px' }}>
                          {/* eslint-disable-next-line max-len */}
                          <h6>{modifierGroup.name} {modifierGroup?.minRequired || modifierGroup?.maxAllowed ? `(${modifierGroup.minRequired ? `choose ${modifierGroup.minRequired}` : ''} ${modifierGroup.maxAllowed ? `max ${modifierGroup.maxAllowed}` : ''})` : '' }</h6>
                          {modifierGroup?.modifiers.map((mod, modIndex) => {
                            const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid);

                            const modifiersInGroup = customItemInUpdates?.lineItemModifiers.filter(
                                                          (lineItemModifier) => modifierGroup.modifiers.some(
                                                            (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                                          )
                                                      ) || [];

                            const modifierQuantityTotal = modifiersInGroup.reduce(
                              (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                              0,
                            );

                            const modifierInItem = customItemInUpdates?.lineItemModifiers.find((m) => m.modifierGid === mod.gid);

                            const disableModifier = !mod.available
                              || (mod?.maxStackableQuantity && modifierInItem?.quantity >= mod.maxStackableQuantity)
                              || (modifierGroup?.maxAllowed && modifierQuantityTotal >= modifierGroup.maxAllowed);

                            return (
                              <div
                                key={mod.gid}
                                className="d-flex align-items-center justify-content-between"
                                style={{
                                  paddingTop: '5px',
                                  paddingBottom: '5px',
                                  marginLeft: '30px',
                                  borderTop: modIndex === 0 ? 'none' : '2px dashed #B3B3B3',
                                }}
                              >
                                {mod.name}

                                {modifierInItem ? (
                                  <div
                                    className="d-flex align-items-center justify-content-between overflow-hidden"
                                    style={{
                                      backgroundColor: '#FFFFFF',
                                      border: '1px solid #000000',
                                      borderRadius: '5px',
                                      marginLeft: '30px',
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="increase-decrease-button"
                                      style={{ padding: '6px 12px' }}
                                      onClick={() => updateCustomItemQuantity(customItem, null, mod, -1, 0)}
                                    >
                                      –
                                    </button>
                                    <input
                                      type="text"
                                      className="input-item"
                                      value={modifierInItem?.quantity || 0}
                                      readOnly
                                    />
                                    {!disableModifier && (
                                    <button
                                      type="button"
                                      className="increase-decrease-button"
                                      style={{ padding: '6px 12px' }}
                                      onClick={() => updateCustomItemQuantity(customItem, null, mod, 1, 0)}
                                      disabled={disableModifier}
                                    >
                                      +
                                    </button>
                                    )}
                                  </div>
                                ) : (
                                  <button
                                    type="button"
                                    className="add-button"
                                    style={{
                                      color: `${!disableModifier ? 'rgba(0,0,0,0.69)' : 'rgba(0,0,0,0.35)'}`,
                                      padding: '6px 12px',
                                      marginLeft: '30px',
                                    }}
                                    disabled={disableModifier}
                                    onClick={() => updateCustomItemQuantity(customItem, null, mod, 1, 0)}
                                  >
                                    Add
                                  </button>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ))}
                    </div>
                    )}
                    {customItem.modifierGroups?.map((modifierGroup) => {
                      const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid);

                      const modifiersInGroup = customItemInUpdates?.lineItemModifiers.filter(
                                          (lineItemModifier) => modifierGroup.modifiers.some(
                                            (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                          )
                                      ) || [];

                      const modifierQuantityTotal = modifiersInGroup.reduce(
                        (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                        0,
                      );
                      let updateValidationError = [...validationErrors];
                      const validationErrorIndex = updateValidationError.findIndex(
                        (update) => update.uniqueGid === (`${modifierGroup.gid}&${customItem.gid}`)
                                              && update.productGid === eventPageProduct.gid,
                      );
                      if (modifierGroup.minRequired && customItemInUpdates?.quantity > 0) {
                        if (modifierQuantityTotal < modifierGroup.minRequired) {
                          if (validationErrorIndex === -1) {
                            updateValidationError.push({
                              uniqueGid: `${modifierGroup.gid}&${customItem.gid}`,
                              customItemGid: customItem.gid,
                              productGid: eventPageProduct.gid,
                              errorMessage: `Add Modifications -> "${modifierGroup.name}" : for Item -> "${customItem.inputFieldLabel}"`,
                            });
                            setValidationErrors(updateValidationError);
                          }
                        } else if (validationErrorIndex !== -1) {
                          updateValidationError = updateValidationError.filter((_, errorIndex) => errorIndex !== validationErrorIndex);
                          setValidationErrors(updateValidationError);
                        }
                      } else if (validationErrorIndex !== -1) {
                        updateValidationError = updateValidationError.filter((_, errorIndex) => errorIndex !== validationErrorIndex);
                        setValidationErrors(updateValidationError);
                      }
                    })}
                  </div>
                          )}
                  {customItem?.items?.length > 0 && (
                  <div style={{ position: 'relative', margin: '5px 0 0', padding: '5px' }}>
                    <button
                      type="button"
                      className="drop-down-menu"
                      onClick={() => toggleItem(index)}
                    >
                      Add Items &#9660;
                    </button>
                    {activeItem === index && (
                    <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                      {customItem.items.map((item, itemIndex) => (
                        <div
                          key={itemIndex}
                          style={{
                            marginBottom: '10px',
                            padding: '10px',
                          }}
                        >
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{
                              borderTop: index === 0 ? 'none' : '2px dashed #B3B3B3',
                            }}
                          >
                            <div>
                              <h3 style={{ margin: '10px 0 0', fontSize: '18px', padding: '5px' }}>{item.displayName}</h3>
                              <p style={{ margin: '5px 0 0', color: '#555', padding: '5px' }}>
                                $ {item.price ? AmountDivideByHundred(item.price) : '0.00'}
                                {item.coupon && (
                                <span className="sc-fbJfA uhGua discount-badge">
                                  {item.coupon.percentageOff
                                    ? `${item.coupon.percentageOff}%`
                                    // eslint-disable-next-line max-len
                                    : `${item.price > item.coupon.amountOff ? '$ ' : '100% upto $ '}${AmountDivideByHundred(item.coupon.amountOff)}`} Off
                                </span>
                                )}
                              </p>
                              {item.modifierGroups?.length > 0 && (
                              <div style={{ position: 'relative', margin: '5px 0 0', padding: '5px' }}>
                                <button
                                  type="button"
                                  onClick={() => toggleItemModifier(item.gid)}
                                  disabled={item.enableStock && item.onlineStock === 0}
                                  className="drop-down-menu"
                                >
                                  Add Modifications &#9660;
                                </button>
                                {activeItemModifier === item.gid && (
                                <div style={{ marginTop: '10px', marginLeft: '20px' }}>
                                  {item.modifierGroups.map((modifierGroup) => (
                                    <div key={modifierGroup.gid} style={{ marginBottom: '5px' }}>
                                      {/* eslint-disable-next-line max-len */}
                                      <h6>{modifierGroup.name} {modifierGroup?.minRequired || modifierGroup?.maxAllowed ? `(${modifierGroup.minRequired ? `choose ${modifierGroup.minRequired}` : ''} ${modifierGroup.maxAllowed ? `max ${modifierGroup.maxAllowed}` : ''})` : '' }</h6>
                                      {modifierGroup?.modifiers.map((mod, modIndex) => {
                                        const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid);
                                        const itemInUpdates = customItemInUpdates?.lineItemItems?.find((u) => u.itemGid === item.gid);

                                        const modifiersInGroup = itemInUpdates?.lineItemModifiers.filter(
                                              (lineItemModifier) => modifierGroup.modifiers.some(
                                                (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                              )
                                          ) || [];

                                        const modifierQuantityTotal = modifiersInGroup.reduce(
                                          (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                          0,
                                        );

                                        const modifierInItem = itemInUpdates?.lineItemModifiers.find((m) => m.modifierGid === mod.gid);

                                        const disableModifier = !mod.available
                                          || (mod?.maxStackableQuantity && modifierInItem?.quantity >= mod.maxStackableQuantity)
                                          || (modifierGroup?.maxAllowed && modifierQuantityTotal >= modifierGroup.maxAllowed);

                                        return (
                                          <div
                                            key={mod.gid}
                                            className="d-flex align-items-center justify-content-between"
                                            style={{
                                              paddingTop: '5px',
                                              paddingBottom: '5px',
                                              marginLeft: '30px',
                                              borderTop: modIndex === 0 ? 'none' : '2px dashed #B3B3B3',
                                            }}
                                          >
                                            {mod.name}

                                            {modifierInItem ? (
                                              <div
                                                className="d-flex align-items-center justify-content-between overflow-hidden"
                                                style={{
                                                  backgroundColor: '#FFFFFF',
                                                  border: '1px solid #000000',
                                                  borderRadius: '5px',
                                                  marginLeft: '30px',
                                                }}
                                              >
                                                <button
                                                  type="button"
                                                  className="increase-decrease-button"
                                                  style={{ padding: '6px 12px' }}
                                                  onClick={() => updateCustomItemQuantity(customItem, item, mod, -1, 0)}
                                                >
                                                  –
                                                </button>
                                                <input
                                                  type="text"
                                                  className="input-item"
                                                  value={modifierInItem?.quantity || 0}
                                                />
                                                {!disableModifier && (
                                                <button
                                                  type="button"
                                                  className="increase-decrease-button"
                                                  style={{ padding: '6px 12px' }}
                                                  onClick={() => updateCustomItemQuantity(customItem, item, mod, 1, 0)}
                                                  disabled={disableModifier}
                                                >
                                                  +
                                                </button>
                                                )}
                                              </div>
                                            ) : (
                                              <button
                                                type="button"
                                                className="add-button"
                                                style={{
                                                  color: `${!disableModifier ? 'rgba(0,0,0,0.69)' : 'rgba(0,0,0,0.35)'}`,
                                                  padding: '6px 12px',
                                                  marginLeft: '30px',
                                                }}
                                                disabled={disableModifier || !customItemInUpdates}
                                                title={!customItemInUpdates ? `Add ${customItem.inputFieldLabel} to proceed` : ''}
                                                onClick={() => updateCustomItemQuantity(customItem, item, mod, 1, 0)}
                                              >
                                                Add
                                              </button>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  ))}
                                </div>
                                )}
                                {item.modifierGroups?.map((modifierGroup) => {
                                  const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid);
                                  const itemInUpdates = customItemInUpdates?.lineItemItems?.find((u) => u.itemGid === item.gid);

                                  const modifiersInGroup = itemInUpdates?.lineItemModifiers.filter(
                                                                      (lineItemModifier) => modifierGroup.modifiers.some(
                                                                        (modifier) => lineItemModifier.modifierGid === modifier.gid,
                                                                      )
                                                                  ) || [];

                                  const modifierQuantityTotal = modifiersInGroup.reduce(
                                    (sum, lineItemModifier) => sum + (lineItemModifier.quantity || 0),
                                    0,
                                  );

                                  let updateValidationError = [...validationErrors];
                                  const validationErrorIndex = updateValidationError.findIndex(
                                    (update) => update.uniqueGid === (`${modifierGroup.gid}&${customItem.gid}&${item.gid}`)
                                                                          && update.productGid === eventPageProduct.gid,
                                  );
                                  if (modifierGroup.minRequired && itemInUpdates?.quantity > 0) {
                                    if (modifierQuantityTotal < modifierGroup.minRequired) {
                                      if (validationErrorIndex === -1) {
                                        updateValidationError.push({
                                          uniqueGid: `${modifierGroup.gid}&${customItem.gid}&${item.gid}`,
                                          customItemGid: customItem.gid,
                                          itemGid: item.gid,
                                          productGid: eventPageProduct.gid,
                                          // eslint-disable-next-line max-len
                                          errorMessage: `Add Modifications -> "${modifierGroup.name}" : for Item -> "${item.displayName}" in -> "${customItem.inputFieldLabel}"`,
                                        });
                                        setValidationErrors(updateValidationError);
                                      }
                                    } else if (validationErrorIndex !== -1) {
                                      updateValidationError = updateValidationError.filter((_, errorIndex) => errorIndex !== validationErrorIndex);
                                      setValidationErrors(updateValidationError);
                                    }
                                  } else if (validationErrorIndex !== -1) {
                                    updateValidationError = updateValidationError.filter((_, errorIndex) => errorIndex !== validationErrorIndex);
                                    setValidationErrors(updateValidationError);
                                  }
                                })}
                              </div>
                              )}
                            </div>
                            {(() => {
                              const customItemInUpdates = updates.find((u) => u.customItemGid === customItem.gid);
                              const itemInUpdates = customItemInUpdates?.lineItemItems?.find((c) => c.itemGid === item.gid);

                              return !itemInUpdates ? (
                                item.enableStock && item.onlineStock === 0 ? (
                                  <img alt="" src={outOfStock} style={{ width: '64px', height: '64px', marginLeft: '40px' }} />
                                ) : (
                                  <button
                                    type="button"
                                    className="add-button"
                                    style={{
                                      color: `${item.enableStock && item.onlineStock === 0 ? 'rgba(0,0,0,0.35)' : 'rgba(0,0,0,0.69)'}`,
                                      marginLeft: '40px',
                                    }}
                                    disabled={(item.enableStock && item.onlineStock === 0) || !customItemInUpdates}
                                    title={!customItemInUpdates ? `Add ${customItem.inputFieldLabel} to proceed` : ''}
                                    onClick={() => updateCustomItemQuantity(customItem, item, null, 1, 0)}
                                  >
                                    Add
                                  </button>
                                )
                              ) : (
                                <div
                                  className="d-flex align-items-center justify-content-between overflow-hidden"
                                  style={{
                                    backgroundColor: '#FFFFFF',
                                    border: '1px solid #000000',
                                    borderRadius: '5px',
                                    marginLeft: '40px',
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="increase-decrease-button"
                                    style={{ padding: '6px 12px' }}
                                    onClick={() => updateCustomItemQuantity(customItem, item, null, -1, 0)}
                                  >
                                    –
                                  </button>
                                  <input
                                    type="text"
                                    className="input-item"
                                    value={itemInUpdates?.quantity || 0}
                                  />
                                  {!(item.enableStock && (itemInUpdates?.quantity * customItemInUpdates.quantity) >= item.onlineStock) && (
                                  <button
                                    type="button"
                                    className="increase-decrease-button"
                                    style={{ padding: '6px 12px' }}
                                    onClick={() => updateCustomItemQuantity(customItem, item, null, 1, 0)}
                                  >
                                    +
                                  </button>
                                  )}
                                </div>
                              );
                            })()}
                          </div>
                        </div>
                      ))}
                    </div>
                    )}
                  </div>
                          )}
                </div>
                {!updates.some((u) => u.customItemGid === customItem.gid) ? (
                  <button
                    type="button"
                    className="add-button"
                    style={{
                      color: 'rgba(0,0,0,0.69)',
                    }}
                    onClick={() => updateCustomItemQuantity(customItem, null, null, 1, 0)}
                  >
                    Add
                  </button>
                ) : (
                  <>
                    <div
                      className="d-flex align-items-center justify-content-between overflow-hidden"
                      style={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #000000',
                        borderRadius: '5px',
                      }}
                    >
                      <button
                        type="button"
                        className="increase-decrease-button"
                        onClick={() => updateCustomItemQuantity(customItem, null, null, -1, 0)}
                      >
                        –
                      </button>
                      <input
                        type="text"
                        className="input-item"
                        value={updates.find((u) => u.customItemGid === customItem.gid)?.quantity || 0}
                      />
                      <button
                        type="button"
                        className="increase-decrease-button"
                        onClick={() => updateCustomItemQuantity(customItem, null, null, 1, 0)}
                      >
                        +
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))) : ('')}
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default AddProducts;
