export const LOADING = {
  SET: 'SET_LOADING',
  UNSET: 'UNSET_LOADING',
  SUBMITTING: 'SET_SUBMITTING',
  SUBMITTED: 'SET_SUBMITTED',
  VALIDATING: 'SET_VALIDATING',
  VALIDATED: 'SET_VALIDATED',
  SUCCESS: 'LOADING_SUCCESS',
  ERROR: 'LOADING_ERROR',
  RESET: 'LOADING_RESET',
  SET_SKELETON: 'SET_SKELETON_LOADING',
  UNSET_SKELETON: 'UNSET_SKELETON_LOADING'};

export const NOTIFICATION = {
  SUCCESS: 'NOTIFICATION_SUCCESS',
  ERROR: 'NOTIFICATION_ERROR',
  RESET: 'NOTIFICATION_RESET',
};

/* AUTH USER ACTIONS */
const AUTH_USER = 'AUTH_USER';
export const AUTH = {
  AUTHENTICATE: `${AUTH_USER}_AUTHENTICATE`,
  SUCCESS: `${AUTH_USER}_SUCCESS`,
  FAILED: `${AUTH_USER}_FAILED`,
  LOGOUT: 'LOGOUT',
  UNLOCK: 'UNLOCK',
};

/* USER PROFILE ACTIONS */
const USER_ACTIONS = 'USER';
export const USER = {
  FETCH: `${USER_ACTIONS}_FETCH`,
  UPDATE: `${USER_ACTIONS}_UPDATE`,
};

/* ACCOUNT ACTIONS */
const USER_ACCOUNT = 'ACCOUNT';
export const ACCOUNT = {
  FETCH: `${USER_ACCOUNT}_FETCH`,
  FETCH_BY_ID: `${USER_ACCOUNT}_FETCH_BY_ID`,
  FETCH_BY_FILTER: `${USER_ACCOUNT}_FETCH_BY_FILTER`,
  SUCCESS_BY_ID: `${USER_ACCOUNT}_SUCCESS_BY_ID`,
  SUCCESS_BY_FILTER: `${USER_ACCOUNT}_SUCCESS_BY_FILTER`,
  SUCCESS: `${USER_ACCOUNT}_SUCCESS`,
  FAILED: `${USER_ACCOUNT}_FAILED`,
  SET_TEST_DATA: `${USER_ACCOUNT}_TEST_DATA`,
  SET_SELECTED_ACCOUNT: `${USER_ACCOUNT}_SELECTED_ACCOUNT`,
  UPDATED: `${USER_ACCOUNT}_UPDATED`,
  UPDATE: `${USER_ACCOUNT}_UPDATE`,
  ADD: `${USER_ACCOUNT}_ADD`,
  FETCH_BY_INTERNAL_ID: `${USER_ACCOUNT}_FETCH_BY_INTERNAL_ID`,
  SUCCESS_BY_INTERNAL_ID: `${USER_ACCOUNT}_SUCCESS_BY_INTERNAL_ID`,
  INTERNAL_UPDATED: `${USER_ACCOUNT}_INTERNAL_UPDATED`,
  INTERNAL_UPDATE: `${USER_ACCOUNT}_INTERNAL_UPDATE`,
  CUSTOM_DASHBOARD_SUCCESS: `${AUTH_USER}_CUSTOM_DASHBOARD_SUCCESS`,
};

/* BUSINESS ACTIONS */
const ACCT_BUSINESS = 'BUSINESS';

export const BUSINESS = {
  FETCH_BUSINESS_BY_ID: `${ACCT_BUSINESS}_FETCH_BUSINESS_BY_ID`,
  FETCH_BUSINESS_SUCCESS: `${ACCT_BUSINESS}_FETCH_BUSINESS_SUCCESS`,
  BUSINESS_REPRESENTATIVE_SUCCESS: `${ACCT_BUSINESS}_BUSINESS_REPRESENTATIVE_SUCCESS`,
  BUSINESS_CATEGORIES_SUCCESS: `${ACCT_BUSINESS}_BUSINESS_CATEGORIES_SUCCESS`,
  BUSINESS_CATEGORIES_FETCH: `${ACCT_BUSINESS}_BUSINESS_CATEGORIES_FETCH`,
  ADD_BUSINESS: `${ACCT_BUSINESS}_ADD_BUSINESS`,
  ADD_BUSINESS_SIGN_UP: `${ACCT_BUSINESS}_ADD_BUSINESS_SIGN_UP`,
  EDIT_BUSINESS: `${ACCT_BUSINESS}_EDIT_BUSINESS`,
  EDIT_BUSINESS_SUCCESS: `${ACCT_BUSINESS}_EDIT_BUSINESS_SUCCESS`,
  BUSINESS_REPRESENTATIVES_EDIT_SUCCESS: `${ACCT_BUSINESS}_BUSINESS_REPRESENTATIVES_EDIT_SUCCESS`,
  BUSINESS_TYPE_SELECTED: `${ACCT_BUSINESS}_BUSINESS_TYPE_SELECTED`,
  FETCH_DOCUSIGN_STATUS: `${ACCT_BUSINESS}_FETCH_DOCUSIGN_STATUS`,
  FETCH_DOCUSIGN_SUCCESS: `${ACCT_BUSINESS}_FETCH_DOCUSIGN_SUCCESS`,
  FETCH_BUSINESS_DRAFT_BY_ID: `${ACCT_BUSINESS}_FETCH_BUSINESS_DRAFT_BY_ID`,
  FETCH_BUSINESS_DRAFT_SUCCESS: `${ACCT_BUSINESS}_FETCH_BUSINESS_DRAFT_SUCCESS`,
  ADD_DRAFT_BUSINESS: `${ACCT_BUSINESS}_ADD_DRAFT_BUSINESS`,
};

/* MEMBERSHIP ACTIONS */
const MEMBERSHIP_PREFIX = 'MEMBERSHIP';
export const MEMBERSHIP = {
  FETCH: `${MEMBERSHIP_PREFIX}_FETCH`,
  ADD: `${MEMBERSHIP_PREFIX}_ADD`,
  UPDATE: `${MEMBERSHIP_PREFIX}_UPDATE`,
  DELETE: `${MEMBERSHIP_PREFIX}_DELETE`,
  SUCCESS: `${MEMBERSHIP_PREFIX}_SUCCESS`,
  FAILED: `${MEMBERSHIP_PREFIX}_FAILED`,
  SELECTED: `${MEMBERSHIP_PREFIX}_SELECTED`,
};

/* INTERNAL MEMBERSHIP ACTIONS */
const INTERNAL_MEMBERSHIP_PREFIX = 'INTERNAL_MEMBERSHIP';
export const INTERNAL_MEMBERSHIP = {
  FETCH: `${INTERNAL_MEMBERSHIP_PREFIX}_FETCH`,
  ADD: `${INTERNAL_MEMBERSHIP_PREFIX}_ADD`,
  UPDATE: `${INTERNAL_MEMBERSHIP_PREFIX}_UPDATE`,
  DELETE: `${INTERNAL_MEMBERSHIP_PREFIX}_DELETE`,
  SUCCESS: `${INTERNAL_MEMBERSHIP_PREFIX}_SUCCESS`,
  FAILED: `${INTERNAL_MEMBERSHIP_PREFIX}_FAILED`,
  SELECTED: `${INTERNAL_MEMBERSHIP_PREFIX}_SELECTED`,
};

/* ROLES ACTIONS */
const ROLES_PREFIX = 'ROLES';
export const ROLES = {
  FETCH: `${ROLES_PREFIX}_FETCH`,
  SUCCESS: `${ROLES_PREFIX}_SUCCESS`,
  FAILED: `${ROLES_PREFIX}_FAILED`,
  INTERNAL_FETCH: `${ROLES_PREFIX}_INTERNAL_FETCH`,
  INTERNAL_SUCCESS: `${ROLES_PREFIX}_INTERNAL_SUCCESS`,
  INTERNAL_FAILED: `${ROLES_PREFIX}_INTERNAL_FAILED`,
};

/* PUBLICKEYS ACTIONS */
const PUBLICKEYS_PREFIX = 'PUBLICKEYS';
export const PUBLICKEYS = {
  FETCH: `${PUBLICKEYS_PREFIX}_FETCH`,
  SUCCESS: `${PUBLICKEYS_PREFIX}_SUCCESS`,
  FAILED: `${PUBLICKEYS_PREFIX}_FAILED`,
  UPDATE: `${PUBLICKEYS_PREFIX}_UPDATE`,
};

/* SECRETKEYS ACTIONS */
const SECRETKEYS_PREFIX = 'SECRETKEYS';
export const SECRETKEYS = {
  FETCH: `${SECRETKEYS_PREFIX}_FETCH`,
  SUCCESS: `${SECRETKEYS_PREFIX}_SUCCESS`,
  FAILED: `${SECRETKEYS_PREFIX}_FAILED`,
  UPDATE: `${SECRETKEYS_PREFIX}_UPDATE`,
};

/* SYMMETRICKEYS ACTIONS */
const SYMMETRICKEYS_PREFIX = 'SYMMETRICKEYS';
export const SYMMETRICKEYS = {
  FETCH: `${SYMMETRICKEYS_PREFIX}_FETCH`,
  SUCCESS: `${SYMMETRICKEYS_PREFIX}_SUCCESS`,
  FAILED: `${SYMMETRICKEYS_PREFIX}_FAILED`,
};

/* PAYMENTS ACTIONS */
const PAYMENTS_PREFIX = 'PAYMENTS';
export const PAYMENTS = {
  FETCH: `${PAYMENTS_PREFIX}_FETCH`,
  REFUND: `${PAYMENTS_PREFIX}_REFUND`,
  SUCCESS: `${PAYMENTS_PREFIX}_SUCCESS`,
  FAILED: `${PAYMENTS_PREFIX}_FAILED`,
  UPDATE: `${PAYMENTS_PREFIX}_UPDATE`,
};

/* CUSTOMERS ACTIONS */
const CUSTOMERS_PREFIX = 'CUSTOMERS';
export const CUSTOMERS = {
  FETCH: `${CUSTOMERS_PREFIX}_FETCH`,
  ADD: `${CUSTOMERS_PREFIX}_ADD`,
  UPDATE: `${CUSTOMERS_PREFIX}_UPDATE`,
  DELETE: `${CUSTOMERS_PREFIX}_DELETE`,
  SUCCESS: `${CUSTOMERS_PREFIX}_SUCCESS`,
  FAILED: `${CUSTOMERS_PREFIX}_FAILED`,
  SELECTED: `${CUSTOMERS_PREFIX}_SELECTED`,
};

/* SETTINGS ACTIONS */
const SETTINGS_PREFIX = 'SETTINGS';
export const SETTINGS = {
  FETCH: `${SETTINGS_PREFIX}_FETCH`,
  SUCCESS: `${SETTINGS_PREFIX}_SUCCESS`,
  FAILED: `${SETTINGS_PREFIX}_FAILED`,
  UPDATE: `${SETTINGS_PREFIX}_UPDATE`,
  UPDATE_CROSS: `${SETTINGS_PREFIX}_UPDATE_CROSS`,
};

/* ACH PAYMENT ACTIONS */
const ACH_PAYMENT_ACTIONS = 'ACH_PAYMENT';
export const ACH_PAYMENT = {
  FETCH: `${ACH_PAYMENT_ACTIONS}_FETCH`,
  SUCCESS: `${ACH_PAYMENT_ACTIONS}_SUCCESS`,
  FAILED: `${ACH_PAYMENT_ACTIONS}_FAILED`,
  CUSTOMER_ADD: `${ACH_PAYMENT_ACTIONS}_CUSTOMER_ADD`,
  CUSTOMER_SUCCESS: `${ACH_PAYMENT_ACTIONS}_CUSTOMER_SUCCESS`,
  UPDATE: `${ACH_PAYMENT_ACTIONS}_UPDATE`,
};

/* PAYOUTS ACTIONS */
const PAYOUTS_PREFIX = 'PAYOUTS';
export const PAYOUTS = {
  FETCH: `${PAYOUTS_PREFIX}_FETCH`,
  SUCCESS: `${PAYOUTS_PREFIX}_SUCCESS`,
  FAILED: `${PAYOUTS_PREFIX}_FAILED`,
  SELECTED: `${PAYOUTS_PREFIX}_SELECTED`,
  UPDATE: `${PAYOUTS_PREFIX}_UPDATE`,
};

/* TRANSACTIONS ACTIONS */
const TRANSACTIONS_PREFIX = 'TRANSACTIONS';
export const TRANSACTIONS = {
  FETCH: `${TRANSACTIONS_PREFIX}_FETCH`,
  SUCCESS: `${TRANSACTIONS_PREFIX}_SUCCESS`,
  FAILED: `${TRANSACTIONS_PREFIX}_FAILED`,
};

/* CASH TRANSACTIONS ACTIONS */
const CASH_TRANSACTIONS_PREFIX = 'CASH_TRANSACTIONS';
export const CASH_TRANSACTIONS = {
  FETCH: `${CASH_TRANSACTIONS_PREFIX}_FETCH`,
  SUCCESS: `${CASH_TRANSACTIONS_PREFIX}_SUCCESS`,
  FAILED: `${CASH_TRANSACTIONS_PREFIX}_FAILED`,
};

/* CASH TRANSACTIONS DETAILS ACTIONS */
const CASH_TRANSACTIONS_DETAILS_PREFIX = 'CASH_TRANSACTIONS_DETAILS';
export const CASH_TRANSACTIONS_DETAILS = {
  FETCH: `${CASH_TRANSACTIONS_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${CASH_TRANSACTIONS_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${CASH_TRANSACTIONS_DETAILS_PREFIX}_FAILED`,
  NOTIFY: `${CASH_TRANSACTIONS_DETAILS_PREFIX}_NOTIFY`,
  VOIDED: `${CASH_TRANSACTIONS_DETAILS_PREFIX}_VOIDED`,
  REFUND: `${CASH_TRANSACTIONS_DETAILS_PREFIX}_REFUND`,
};

/* CHEQUE TRANSACTIONS ACTIONS */
const CHEQUE_TRANSACTIONS_PREFIX = 'CHEQUE_TRANSACTIONS';
export const CHEQUE_TRANSACTIONS = {
  FETCH: `${CHEQUE_TRANSACTIONS_PREFIX}_FETCH`,
  SUCCESS: `${CHEQUE_TRANSACTIONS_PREFIX}_SUCCESS`,
  FAILED: `${CHEQUE_TRANSACTIONS_PREFIX}_FAILED`,
};

/* CHEQUE TRANSACTIONS DETAILS ACTIONS */
const CHEQUE_TRANSACTIONS_DETAILS_PREFIX = 'CHEQUE_TRANSACTIONS_DETAILS';
export const CHEQUE_TRANSACTIONS_DETAILS = {
  FETCH: `${CHEQUE_TRANSACTIONS_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${CHEQUE_TRANSACTIONS_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${CHEQUE_TRANSACTIONS_DETAILS_PREFIX}_FAILED`,
  NOTIFY: `${CHEQUE_TRANSACTIONS_DETAILS_PREFIX}_NOTIFY`,
  VOIDED: `${CHEQUE_TRANSACTIONS_DETAILS_PREFIX}_VOIDED`,
};

/* CASH RECEIPTS ACTIONS */
const CASH_RECEIPTS_PREFIX = 'CASH_RECEIPTS';
export const CASH_RECEIPTS = {
  FETCH: `${CASH_RECEIPTS_PREFIX}_FETCH`,
  DOWNLOAD: `${CASH_RECEIPTS_PREFIX}_DOWNLOAD`,
  SUCCESS: `${CASH_RECEIPTS_PREFIX}_SUCCESS`,
  FAILED: `${CASH_RECEIPTS_PREFIX}_FAILED`,
};

/* CHEQUE RECEIPTS ACTIONS */
const CHEQUE_RECEIPTS_PREFIX = 'CHEQUE_RECEIPTS';
export const CHEQUE_RECEIPTS = {
  FETCH: `${CHEQUE_RECEIPTS_PREFIX}_FETCH`,
  DOWNLOAD: `${CHEQUE_RECEIPTS_PREFIX}_DOWNLOAD`,
  SUCCESS: `${CHEQUE_RECEIPTS_PREFIX}_SUCCESS`,
  FAILED: `${CHEQUE_RECEIPTS_PREFIX}_FAILED`,
};

/* REPORTS ACTIONS */
const REPORTS_PREFIX = 'REPORTS';
export const REPORTS = {
  FETCH: `${REPORTS_PREFIX}_FETCH`,
  DOWNLOAD: `${REPORTS_PREFIX}_DOWNLOAD`,
  ADD: `${REPORTS_PREFIX}_ADD`,
  SUCCESS: `${REPORTS_PREFIX}_SUCCESS`,
  FAILED: `${REPORTS_PREFIX}_FAILED`,
};

/* ADMIN REPORTS ACTIONS */
const ADMIN_REPORTS_PREFIX = 'ADMIN_REPORTS';
export const ADMIN_REPORTS = {
  FETCH: `${ADMIN_REPORTS_PREFIX}_FETCH`,
  DOWNLOAD: `${ADMIN_REPORTS_PREFIX}_DOWNLOAD`,
  ADD: `${ADMIN_REPORTS_PREFIX}_ADD`,
  SUCCESS: `${ADMIN_REPORTS_PREFIX}_SUCCESS`,
  FAILED: `${ADMIN_REPORTS_PREFIX}_FAILED`,
};

/* SCHEDULE ADMIN REPORTS ACTIONS */
const SCHEDULE_ADMIN_REPORTS_PREFIX = 'SCHEDULE_ADMIN_REPORTS';
export const SCHEDULE_ADMIN_REPORTS = {
  FETCH: `${SCHEDULE_ADMIN_REPORTS_PREFIX}_FETCH`,
  DOWNLOAD: `${SCHEDULE_ADMIN_REPORTS_PREFIX}_DOWNLOAD`,
  ADD: `${SCHEDULE_ADMIN_REPORTS_PREFIX}_ADD`,
  SUCCESS: `${SCHEDULE_ADMIN_REPORTS_PREFIX}_SUCCESS`,
  FAILED: `${SCHEDULE_ADMIN_REPORTS_PREFIX}_FAILED`,
  DELETE: `${SCHEDULE_ADMIN_REPORTS_PREFIX}_DELETE`,
};

/* TERMINAL ACTIONS */
const TERMINAL_PREFIX = 'TERMINAL';
export const TERMINAL = {
  FETCH: `${TERMINAL_PREFIX}_FETCH`,
  SUCCESS: `${TERMINAL_PREFIX}_SUCCESS`,
  FAILED: `${TERMINAL_PREFIX}_FAILED`,
  UPDATE: `${TERMINAL_PREFIX}_UPDATE`,
  LOCATIONS_FETCH: `${TERMINAL_PREFIX}_LOCATIONS_FETCH`,
  LOCATIONS_SUCCESS: `${TERMINAL_PREFIX}_LOCATIONS_SUCCESS`,
  LOCATIONS_FAILED: `${TERMINAL_PREFIX}_LOCATIONS_FAILED`,
  SEARCH_LOCATION: `${TERMINAL_PREFIX}_SEARCH_LOCATION`,
};

/* TERMINALDETAILS ACTIONS */
const TERMINALDETAILS_PREFIX = 'TERMINALDETAILS';
export const TERMINALDETAILS = {
  FETCH: `${TERMINALDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${TERMINALDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${TERMINALDETAILS_PREFIX}_FAILED`,
  TERMINALS_UPDATE: `${TERMINALDETAILS_PREFIX}_TERMINALS_UPDATE`,
  TERMINALS_PAYMENTS_FETCH: `${TERMINALDETAILS_PREFIX}_PAYMENTS_FETCH`,
  TERMINALS_PAYMENTS_SUCCESS: `${TERMINALDETAILS_PREFIX}_PAYMENTS_SUCCESS`,
  ADD_PROOF: `${TERMINALDETAILS_PREFIX}_ADD_PROOF`,
};

/* LOCATIONS ACTIONS */
const LOCATIONS_PREFIX = 'LOCATIONS';
export const LOCATIONS = {
  FETCH: `${LOCATIONS_PREFIX}_FETCH`,
  ADD: `${LOCATIONS_PREFIX}_ADD`,
  UPDATE: `${LOCATIONS_PREFIX}_UPDATE`,
  SUCCESS: `${LOCATIONS_PREFIX}_SUCCESS`,
  FAILED: `${LOCATIONS_PREFIX}_FAILED`,
  SELECTED: `${LOCATIONS_PREFIX}_SELECTED`,
};

/* DISPUTES ACTIONS */
const DISPUTES_PREFIX = 'DISPUTES';
export const DISPUTES = {
  FETCH: `${DISPUTES_PREFIX}_FETCH`,
  SUCCESS: `${DISPUTES_PREFIX}_SUCCESS`,
  FAILED: `${DISPUTES_PREFIX}_FAILED`,
};

const LOCATIONSDETAILS_PREFIX = 'LOCATIONSDETAILS';
export const LOCATIONSDETAILS = {
  FETCH: `${LOCATIONSDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${LOCATIONSDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${LOCATIONSDETAILS_PREFIX}_FAILED`,
  DELETE: `${LOCATIONSDETAILS_PREFIX}_DELETE`,
  TERMINALS_SUCCESS: `${LOCATIONSDETAILS_PREFIX}_TERMINALS_SUCCESS`,
  TERMINALS_FETCH: `${LOCATIONSDETAILS_PREFIX}_TERMINALS_FETCH`,
  TERMINALS_UPDATE: `${LOCATIONSDETAILS_PREFIX}_TERMINALS_UPDATE`,
  TERMINALS_DELETE: `${LOCATIONSDETAILS_PREFIX}_TERMINALS_DELETE`,
};

/* CUSTOMERSDETAILS ACTIONS */
const CUSTOMERSDETAILS_PREFIX = 'CUSTOMERSDETAILS';
export const CUSTOMERSDETAILS = {
  FETCH: `${CUSTOMERSDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${CUSTOMERSDETAILS_PREFIX}_SUCCESS`,
  UPDATE: `${CUSTOMERSDETAILS_PREFIX}_UPDATE`,
  FAILED: `${CUSTOMERSDETAILS_PREFIX}_FAILED`,
  DELETE: `${CUSTOMERSDETAILS_PREFIX}_DELETE`,
  CARDS_SUCCESS: `${CUSTOMERSDETAILS_PREFIX}_CARDS_SUCCESS`,
  CARDS_FETCH: `${CUSTOMERSDETAILS_PREFIX}_CARDS_FETCH`,
};

/* PAYOUTSDETAILS ACTIONS */
const PAYOUTSDETAILS_PREFIX = 'PAYOUTSDETAILS';
export const PAYOUTSDETAILS = {
  FETCH: `${PAYOUTSDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${PAYOUTSDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${PAYOUTSDETAILS_PREFIX}_FAILED`,
  DELETE: `${PAYOUTSDETAILS_PREFIX}_DELETE`,
  TRANSACTIONS_FETCH: `${PAYOUTSDETAILS_PREFIX}_TRANSACTIONS_FETCH`,
  TRANSACTIONS_SUCCESS: `${PAYOUTSDETAILS_PREFIX}_TRANSACTIONS_SUCCESS`,
  TRANSACTIONS_UPDATE: `${PAYOUTSDETAILS_PREFIX}_TRANSACTIONS_UPDATE`,
};

/* SUPPORT SETTING ACTIONS */
const SUPPORT_PREFIX = 'SUPPORT';
export const SUPPORT = {
  FETCH: `${SUPPORT_PREFIX}_FETCH`,
  ADD: `${SUPPORT_PREFIX}_ADD`,
  UPDATE: `${SUPPORT_PREFIX}_UPDATE`,
  SUCCESS: `${SUPPORT_PREFIX}_SUCCESS`,
  FAILED: `${SUPPORT_PREFIX}_FAILED`,
};

/* TRANSFER SETTING ACTIONS */
const TRANSFER_SETTINGS_PREFIX = 'TRANSFER_SETTINGS';
export const TRANSFER_SETTINGS = {
  FETCH: `${TRANSFER_SETTINGS_PREFIX}_FETCH`,
  ADD: `${TRANSFER_SETTINGS_PREFIX}_ADD`,
  UPDATE: `${TRANSFER_SETTINGS_PREFIX}_UPDATE`,
  ENABLE_LIVE: `${TRANSFER_SETTINGS_PREFIX}_ENABLE_LIVE`,
  SUCCESS: `${TRANSFER_SETTINGS_PREFIX}_SUCCESS`,
  FAILED: `${TRANSFER_SETTINGS_PREFIX}_FAILED`,
};

/* PAYMENTSDETAILS ACTIONS */
const PAYMENTSDETAILS_PREFIX = 'PAYMENTSDETAILS';
export const PAYMENTSDETAILS = {
  FETCH: `${PAYMENTSDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${PAYMENTSDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${PAYMENTSDETAILS_PREFIX}_FAILED`,
  DELETE: `${PAYMENTSDETAILS_PREFIX}_DELETE`,
  REFUND: `${PAYMENTSDETAILS_PREFIX}_REFUND`,
  REFUND_COMPLETE: `${PAYMENTSDETAILS_PREFIX}_REFUND_COMPLETE`,
  TIMELINE_FETCH: `${PAYMENTSDETAILS_PREFIX}_TIMELINE_FETCH`,
  TIMELINE_SUCCESS: `${PAYMENTSDETAILS_PREFIX}_TIMELINE_SUCCESS`,
  NOTIFY: `${PAYMENTSDETAILS_PREFIX}NOTIFY`,
  SELECTED: `${PAYMENTSDETAILS_PREFIX}_SELECTED`,
  TIMELINE_UPDATE: `${PAYMENTSDETAILS_PREFIX}_TIMELINE_UPDATE`,
  RAISE_DISPUTE: `${PAYMENTSDETAILS_PREFIX}_RAISE_DISPUTE`,
  ACCEPT_EVIDENCE: `${PAYMENTSDETAILS_PREFIX}_ACCEPT_EVIDENCE`,
  RESOLVE_EVIDENCE: `${PAYMENTSDETAILS_PREFIX}_RESOLVE_EVIDENCE`,
  DISPUTE_FETCH: `${PAYMENTSDETAILS_PREFIX}_DISPUTE_FETCH`,
  DISPUTE_SUCCESS: `${PAYMENTSDETAILS_PREFIX}_DISPUTE_SUCCESS`,
  PAGE_NOTIFY: `${PAYMENTSDETAILS_PREFIX}_PAGE_NOTIFY`,
};

/* DISPUTEEVIDENCE ACTIONS */
const DISPUTEEVIDENCE_PREFIX = 'DISPUTEEVIDENCE';
export const DISPUTEEVIDENCE = {
  FETCH: `${DISPUTEEVIDENCE_PREFIX}_FETCH`,
  SUCCESS: `${DISPUTEEVIDENCE_PREFIX}_SUCCESS`,
  FAILED: `${DISPUTEEVIDENCE_PREFIX}_FAILED`,
  SELECTED_EVIDENCES: `${DISPUTEEVIDENCE_PREFIX}_SELECTED_EVIDENCES`,
  SUBMIT_EVIDENCE: `${DISPUTEEVIDENCE_PREFIX}SUBMIT_EVIDENCE`,
};

/* USERPROFILE ACTIONS */
const USERPROFILE_PREFIX = 'USERPROFILE';
export const USERPROFILE = {
  FETCH: `${USERPROFILE_PREFIX}_FETCH`,
  SUCCESS: `${USERPROFILE_PREFIX}_SUCCESS`,
  FAILED: `${USERPROFILE_PREFIX}_FAILED`,
  UPDATE: `${USERPROFILE_PREFIX}_UPDATE`,
};

const RECEIPTS_PREFIX = 'RECEIPTS';
export const RECEIPTS = {
  FETCH: `${RECEIPTS_PREFIX}_FETCH`,
  DOWNLOAD: `${RECEIPTS_PREFIX}_DOWNLOAD`,
  SUCCESS: `${RECEIPTS_PREFIX}_SUCCESS`,
  FAILED: `${RECEIPTS_PREFIX}_FAILED`,
};

const DASHBOARD_PREFIX = 'DASHBOARD';
export const DASHBOARD = {
  FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY`,
  FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY_SUCCESS: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY_SUCCESS`,
  FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY_FAILED: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_ACCOUNT_SUMMARY_FAILED`,
  FETCH_PAYMENT_SESSION_DAILY_SUMMARY: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_DAILY_SUMMARY`,
  FETCH_PAYMENT_SESSION_DAILY_SUMMARY_SUCCESS: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_DAILY_SUMMARY_SUCCESS`,
  FETCH_PAYMENT_SESSION_DAILY_SUMMARY_FAILED: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_DAILY_SUMMARY_FAILED`,
  FETCH_PAYMENT_SESSION_HOURLY_SUMMARY: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_HOURLY_SUMMARY`,
  FETCH_PAYMENT_SESSION_HOURLY_SUMMARY_SUCCESS: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_HOURLY_SUMMARY_SUCCESS`,
  FETCH_PAYMENT_SESSION_HOURLY_SUMMARY_FAILED: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_HOURLY_SUMMARY_FAILED`,
  FETCH_PAYMENT_SESSION_STATUS_SUMMARY: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_STATUS_SUMMARY`,
  FETCH_PAYMENT_SESSION_STATUS_SUMMARY_SUCCESS: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_STATUS_SUMMARY_SUCCESS`,
  FETCH_PAYMENT_SESSION_STATUS_SUMMARY_FAILED: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_STATUS_SUMMARY_FAILED`,
  FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY`,
  FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY_SUCCESS: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY_SUCCESS`,
  FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY_FAILED: `${DASHBOARD_PREFIX}_FETCH_PAYMENT_SESSION_CUSTOMER_SUMMARY_FAILED`,
  FETCH_TOP_PAYMENTS: `${DASHBOARD_PREFIX}_FETCH_TOP_PAYMENTS`,
  FETCH_TOP_PAYMENTS_SUCCESS: `${DASHBOARD_PREFIX}_FETCH_TOP_PAYMENTS_SUCCESS`,
  FETCH_TOP_PAYMENTS_FAILED: `${DASHBOARD_PREFIX}_FETCH_TOP_PAYMENTS_FAILED`,
  FETCH_CUSTOMERS: `${DASHBOARD_PREFIX}_FETCH_CUSTOMERS`,
  FETCH_CUSTOMERS_SUCCESS: `${DASHBOARD_PREFIX}_FETCH_CUSTOMERS_SUCCESS`,
  FETCH_CUSTOMERS_FAILED: `${DASHBOARD_PREFIX}_FETCH_CUSTOMERS_FAILED`,
  SET_DURATION: `${DASHBOARD_PREFIX}_SET_DURATION`,
  SET_SUMMARY_TYPE: `${DASHBOARD_PREFIX}_SET_SUMMARY_TYPE`,
};

/* PAYMENTSLINK ACTIONS */
const PAYMENTSLINK_PREFIX = 'PAYMENTSLINK';
export const PAYMENTSLINK = {
  FETCH: `${PAYMENTSLINK_PREFIX}_FETCH`,
  ADD: `${PAYMENTSLINK_PREFIX}_ADD`,
  SUCCESS: `${PAYMENTSLINK_PREFIX}_SUCCESS`,
  FAILED: `${PAYMENTSLINK_PREFIX}_FAILED`,
  SELECTED: `${PAYMENTSLINK_PREFIX}_SELECTED`,
  DELETE: `${PAYMENTSLINK_PREFIX}_DELETE`,
  ADD_CUSTOMER: `${PAYMENTSLINK_PREFIX}_ADD_CUSTOMER`,
  CLEAR_DATA: `${PAYMENTSLINK_PREFIX}_CLEAR_DATA`,
};

/* PAYMENTSLINK DETAILS ACTIONS */
const PAYMENTSLINKDETAILS_PREFIX = 'PAYMENTSLINKDETAILS';
export const PAYMENTSLINKDETAILS = {
  FETCH: `${PAYMENTSLINKDETAILS_PREFIX}_FETCH`,
  ADD: `${PAYMENTSLINKDETAILS_PREFIX}_ADD`,
  SUCCESS: `${PAYMENTSLINKDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${PAYMENTSLINKDETAILS_PREFIX}_FAILED`,
  DELETE: `${PAYMENTSLINKDETAILS_PREFIX}_DELETE`,
  PAYMENTS_FETCH: `${PAYMENTSLINKDETAILS_PREFIX}_PAYMENTS_FETCH`,
  PAYMENTS_SUCCESS: `${PAYMENTSLINKDETAILS_PREFIX}_PAYMENTS_SUCCESS`,
  UPDATE: `${PAYMENTSLINKDETAILS_PREFIX}_UPDATE`,
};

/* SUBSCRIPTION ACTIONS */
const SUBSCRIPTION_PREFIX = 'SUBSCRIPTION';
export const SUBSCRIPTION = {
  FETCH: `${SUBSCRIPTION_PREFIX}_FETCH`,
  ADD: `${SUBSCRIPTION_PREFIX}_ADD`,
  SEARCH_FETCH: `${SUBSCRIPTION_PREFIX}_SEARCH_FETCH`,
  CUSTOMER_FETCH: `${SUBSCRIPTION_PREFIX}_CUSTOMER_FETCH`,
  PRODUCT_FETCH: `${SUBSCRIPTION_PREFIX}_PRODUCT_FETCH`,
  PRODUCT_SEARCH_FETCH: `${SUBSCRIPTION_PREFIX}_PRODUCT_SEARCH_FETCH`,
  SUCCESS: `${SUBSCRIPTION_PREFIX}_SUCCESS`,
  SEARCH_SUCCESS: `${SUBSCRIPTION_PREFIX}_SEARCH_SUCCESS`,
  CUSTOMER_SUCCESS: `${SUBSCRIPTION_PREFIX}_CUSTOMER_SUCCESS`,
  PRODUCT_SUCCESS: `${SUBSCRIPTION_PREFIX}_PRODUCT_SUCCESS`,
  PRODUCT_SEARCH_SUCCESS: `${SUBSCRIPTION_PREFIX}_PRODUCT_SEARCH_SUCCESS`,
  PROFILE_FETCH: `${SUBSCRIPTION_PREFIX}_PROFILE_FETCH`,
  PROFILE_SUCCESS: `${SUBSCRIPTION_PREFIX}_PROFILE_SUCCESS`,
  FAILED: `${SUBSCRIPTION_PREFIX}_FAILED`,
  SELECTED: `${SUBSCRIPTION_PREFIX}_SELECTED`,
  DELETE: `${SUBSCRIPTION_PREFIX}_DELETE`,
};

/* SUBSCRIPTION DETAILS ACTIONS */
const SUBSCRIPTIONDETAILS_PREFIX = 'SUBSCRIPTIONDETAILS';
export const SUBSCRIPTIONDETAILS = {
  FETCH: `${SUBSCRIPTIONDETAILS_PREFIX}_FETCH`,
  ADD: `${SUBSCRIPTIONDETAILS_PREFIX}_ADD`,
  SUCCESS: `${SUBSCRIPTIONDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${SUBSCRIPTIONDETAILS_PREFIX}_FAILED`,
  DELETE: `${SUBSCRIPTIONDETAILS_PREFIX}_DELETE`,
  PAYMENTS_FETCH: `${SUBSCRIPTIONDETAILS_PREFIX}_PAYMENTS_FETCH`,
  PAYMENTS_SUCCESS: `${SUBSCRIPTIONDETAILS_PREFIX}_PAYMENTS_SUCCESS`,
};

/* PLANS ACTIONS */
const PLANS_PREFIX = 'PLANS';
export const PLANS = {
  FETCH: `${PLANS_PREFIX}_FETCH`,
  ADD: `${PLANS_PREFIX}_ADD`,
  SUCCESS: `${PLANS_PREFIX}_SUCCESS`,
  FAILED: `${PLANS_PREFIX}_FAILED`,
  SELECTED: `${PLANS_PREFIX}_SELECTED`,
  DELETE: `${PLANS_PREFIX}_DELETE`,
};

/* PLANS DETAILS ACTIONS */
const PLANSDETAILS_PREFIX = 'PLANSDETAILS';
export const PLANSDETAILS = {
  FETCH: `${PLANSDETAILS_PREFIX}_FETCH`,
  ADD: `${PLANSDETAILS_PREFIX}_ADD`,
  SUCCESS: `${PLANSDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${PLANSDETAILS_PREFIX}_FAILED`,
  DELETE: `${PLANSDETAILS_PREFIX}_DELETE`,
  PAYMENTS_FETCH: `${PLANSDETAILS_PREFIX}_PAYMENTS_FETCH`,
  PAYMENTS_SUCCESS: `${PLANSDETAILS_PREFIX}_PAYMENTS_SUCCESS`,
};
/* INVOICES ACTIONS */
const INVOICES_PREFIX = 'INVOICES';
export const INVOICES = {
  FETCH: `${INVOICES_PREFIX}_FETCH`,
  SUCCESS: `${INVOICES_PREFIX}_SUCCESS`,
  FAILED: `${INVOICES_PREFIX}_FAILED`,
};

/* PRODUCT ACTIONS */
const PRODUCT_PREFIX = 'PRODUCT';
export const PRODUCT = {
  FETCH: `${PRODUCT_PREFIX}_FETCH`,
  SUCCESS: `${PRODUCT_PREFIX}_SUCCESS`,
  FAILED: `${PRODUCT_PREFIX}_FAILED`,
  ADD: `${PRODUCT_PREFIX}_ADD`,
  UPDATE: `${PRODUCT_PREFIX}_UPDATE`,
  DELETE: `${PRODUCT_PREFIX}_DELETE`,
};

/* INVOICEDETAILS ACTIONS */
const INVOICEDETAILS_PREFIX = 'INVOICEDETAILS';
export const INVOICEDETAILS = {
  FETCH: `${INVOICEDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${INVOICEDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${INVOICEDETAILS_PREFIX}_FAILED`,
  UPDATE: `${INVOICEDETAILS_PREFIX}_UPDATE`,
  DELETE: `${INVOICEDETAILS_PREFIX}_DELETE`,
  CANCEL: `${INVOICEDETAILS_PREFIX}_CANCEL`,
  REFUND: `${INVOICEDETAILS_PREFIX}_REFUND`,
  CUSTOMERS_FETCH: `${INVOICEDETAILS_PREFIX}_CUSTOMERS_FETCH`,
  CUSTOMERS_SUCCESS: `${INVOICEDETAILS_PREFIX}_CUSTOMERS_SUCCESS`,
  CUSTOMERS_FAILED: `${INVOICEDETAILS_PREFIX}_CUSTOMERS_FAILED`,
  INVOICE_ADD: `${INVOICEDETAILS_PREFIX}_INVOICE_ADD`,
  CUSTOMER_ADD: `${INVOICEDETAILS_PREFIX}_CUSTOMER_ADD`,
  CUSTOMER_ADD_SUCCESS: `${INVOICEDETAILS_PREFIX}_CUSTOMER_ADD_SUCCESS`,
  PRODUCTS_FETCH: `${INVOICEDETAILS_PREFIX}_PRODUCTS_FETCH`,
  PRODUCTS_SUCCESS: `${INVOICEDETAILS_PREFIX}_PRODUCTS_SUCCESS`,
  PRODUCT_ADD: `${INVOICEDETAILS_PREFIX}_PRODUCT_ADD`,
  PRODUCT_ADD_SUCCESS: `${INVOICEDETAILS_PREFIX}_PRODUCT_ADD_SUCCESS`,
  NOTIFY_SUCCESS: `${INVOICEDETAILS_PREFIX}_NOTIFY_SUCCESS`,
  COUPON_FETCH: `${INVOICEDETAILS_PREFIX}_COUPON_FETCH`,
  COUPON_SUCCESS: `${INVOICEDETAILS_PREFIX}_COUPON_SUCCESS`,
  COUPON_ADD: `${INVOICEDETAILS_PREFIX}_COUPON_ADD`,
  COUPON_ADD_SUCCESS: `${INVOICEDETAILS_PREFIX}_COUPON_ADD_SUCCESS`,
  TAX_RATE_FETCH: `${INVOICEDETAILS_PREFIX}_TAX_RATE_FETCH`,
  TAX_RATE_SUCCESS: `${INVOICEDETAILS_PREFIX}_TAX_RATE_SUCCESS`,
  TAX_RATE_ADD: `${INVOICEDETAILS_PREFIX}_TAX_RATE_ADD`,
  TAX_RATE_ADD_SUCCESS: `${INVOICEDETAILS_PREFIX}_TAX_RATE_ADD_SUCCESS`,
  TAXES_FETCH: `${INVOICEDETAILS_PREFIX}_TAXES_FETCH`,
  TAXES_SUCCESS: `${INVOICEDETAILS_PREFIX}_TAXES_SUCCESS`,
  ITEMCOUPON_FETCH: `${INVOICEDETAILS_PREFIX}_ITEMCOUPON_FETCH`,
  ITEMCOUPON_SUCCESS: `${INVOICEDETAILS_PREFIX}_ITEMCOUPON_SUCCESS`,
};

/* REMINDERS ACTIONS */
const REMINDERS_PREFIX = 'REMINDERS';
export const REMINDERS = {
  FETCH: `${REMINDERS_PREFIX}_FETCH`,
  SUCCESS: `${REMINDERS_PREFIX}_SUCCESS`,
  FAILED: `${REMINDERS_PREFIX}_FAILED`,
  UPDATE: `${REMINDERS_PREFIX}_UPDATE`,
};

/* PAYMENTPAGES ACTIONS */
const PAYMENTPAGES_PREFIX = 'PAYMENTPAGES';
export const PAYMENTPAGES = {
  FETCH: `${PAYMENTPAGES_PREFIX}_FETCH`,
  SUCCESS: `${PAYMENTPAGES_PREFIX}_SUCCESS`,
  ADD: `${PAYMENTPAGES_PREFIX}_ADD`,
  FAILED: `${PAYMENTPAGES_PREFIX}_FAILED`,
  SELECTED: `${PAYMENTPAGES_PREFIX}_SELECTED`,
};

/* PAYMENTPAGES DETAILS ACTIONS */
const PAYMENTPAGESDETAILS_PREFIX = 'PAYMENTPAGESDETAILS';
export const PAYMENTPAGESDETAILS = {
  FETCH: `${PAYMENTPAGESDETAILS_PREFIX}_FETCH`,
  ADD: `${PAYMENTPAGESDETAILS_PREFIX}_ADD`,
  SUCCESS: `${PAYMENTPAGESDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${PAYMENTPAGESDETAILS_PREFIX}_FAILED`,
  DELETE: `${PAYMENTPAGESDETAILS_PREFIX}_DELETE`,
  PAYMENTS_FETCH: `${PAYMENTPAGESDETAILS_PREFIX}_PAYMENTS_FETCH`,
  PAYMENTS_SUCCESS: `${PAYMENTPAGESDETAILS_PREFIX}_PAYMENTS_SUCCESS`,
  NOTIFY: `${PAYMENTPAGESDETAILS_PREFIX}_NOTIFY`,
  UPDATE: `${PAYMENTPAGESDETAILS_PREFIX}_UPDATE`,
  SOLDOUT: `${PAYMENTPAGESDETAILS_PREFIX}_SOLDOUT`,
  SLUG_FETCH: `${PAYMENTPAGESDETAILS_PREFIX}_SLUG_FETCH`,
  SLUG_SUCCESS: `${PAYMENTPAGESDETAILS_PREFIX}_SLUG_SUCCESS`,
  RESUMESALE: `${PAYMENTPAGESDETAILS_PREFIX}_RESUMESALE`,
  FETCH_ORDERS: `${PAYMENTPAGESDETAILS_PREFIX}_FETCH_ORDERS`,
  ORDERS_SUCCESS: `${PAYMENTPAGESDETAILS_PREFIX}_ORDER_SUCCESS`,
  FETCH_ORDER_BY_ID: `${PAYMENTPAGESDETAILS_PREFIX}_FETCH_ORDER_BY_ID`,
  ORDER_SUCCESS_BY_ID: `${PAYMENTPAGESDETAILS_PREFIX}_ORDER_SUCCESS_BY_ID`,
  FETCH_TIMELINE: `${PAYMENTPAGESDETAILS_PREFIX}_FETCH_TIMELINE`,
  SUCCESS_TIMELINE: `${PAYMENTPAGESDETAILS_PREFIX}_SUCCESS_TIMELINE`,
  SPLIT_UP_SUCCESS: `${PAYMENTPAGESDETAILS_PREFIX}_SPLIT_UP_SUCCESS`,
  UPDATE_ORDER_STATUS: `${PAYMENTPAGESDETAILS_PREFIX}_UPDATE_ORDER_STATUS`,
  UPDATE_ORDER_SUCCESS: `${PAYMENTPAGESDETAILS_PREFIX}_UPDATE_ORDER_SUCCESS`,
  UPDATE_CASH_PAYMENT: `${PAYMENTPAGESDETAILS_PREFIX}_UPDATE_CASH_PAYMENT`,
  FETCH_OFFLINE_PAYMENTS: `${PAYMENTPAGESDETAILS_PREFIX}_FETCH_OFFLINE_PAYMENTS`,
  SUCCESS_OFFLINE_PAYMENTS: `${PAYMENTPAGESDETAILS_PREFIX}_SUCCESS_OFFLINE_PAYMENTS`,
  OFFLINE_DELETE: `${PAYMENTPAGESDETAILS_PREFIX}_OFFLINE_DELETE`,
  FETCH_PAYMENTS_REPORT: `${PAYMENTPAGESDETAILS_PREFIX}_FETCH_PAYMENTS_REPORT`,
  SUCCESS_PAYMENTS_REPORT: `${PAYMENTPAGESDETAILS_PREFIX}_SUCCESS_PAYMENTS_REPORT`,
  FETCH_PAYMENTS_PAGE_ENTRIES: `${PAYMENTPAGESDETAILS_PREFIX}_ FETCH_PAYMENTS_PAGE_ENTRIES`,
  SUCCESS_PAYMENTS_PAGE_ENTRIES: `${PAYMENTPAGESDETAILS_PREFIX}_SUCCESS_PAYMENTS_PAGE_ENTRIES`,
};

/* PAYMENTSBUTTON ACTIONS */
const PAYMENTSBUTTON_PREFIX = 'PAYMENTSBUTTON';
export const PAYMENTSBUTTON = {
  FETCH: `${PAYMENTSBUTTON_PREFIX}_FETCH`,
  ADD: `${PAYMENTSBUTTON_PREFIX}_ADD`,
  SUCCESS: `${PAYMENTSBUTTON_PREFIX}_SUCCESS`,
  FAILED: `${PAYMENTSBUTTON_PREFIX}_FAILED`,
  SELECTED: `${PAYMENTSBUTTON_PREFIX}_SELECTED`,
  DELETE: `${PAYMENTSBUTTON_PREFIX}_DELETE`,
};

/* PAYMENTSBUTTON DETAILS ACTIONS */
const PAYMENTSBUTTONDETAILS_PREFIX = 'PAYMENTSBUTTONDETAILS';
export const PAYMENTSBUTTONDETAILS = {
  FETCH: `${PAYMENTSBUTTONDETAILS_PREFIX}_FETCH`,
  ADD: `${PAYMENTSBUTTONDETAILS_PREFIX}_ADD`,
  SUCCESS: `${PAYMENTSBUTTONDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${PAYMENTSBUTTONDETAILS_PREFIX}_FAILED`,
  DELETE: `${PAYMENTSBUTTONDETAILS_PREFIX}_DELETE`,
  PAYMENTS_FETCH: `${PAYMENTSBUTTONDETAILS_PREFIX}_PAYMENTS_FETCH`,
  PAYMENTS_SUCCESS: `${PAYMENTSBUTTONDETAILS_PREFIX}_PAYMENTS_SUCCESS`,
  UPDATE: `${PAYMENTSBUTTONDETAILS_PREFIX}_UPDATE`,
};

/* SUBSCRIPTIONBUTTON ACTIONS */
const SUBSCRIPTION_BUTTON_PREFIX = 'SUBSCRIPTION_BUTTON';
export const SUBSCRIPTION_BUTTON = {
  FETCH: `${SUBSCRIPTION_BUTTON_PREFIX}_FETCH`,
  ADD: `${SUBSCRIPTION_BUTTON_PREFIX}_ADD`,
  SUCCESS: `${SUBSCRIPTION_BUTTON_PREFIX}_SUCCESS`,
  FAILED: `${SUBSCRIPTION_BUTTON_PREFIX}_FAILED`,
  SELECTED: `${SUBSCRIPTION_BUTTON_PREFIX}_SELECTED`,
  DELETE: `${SUBSCRIPTION_BUTTON_PREFIX}_DELETE`,
};

/* SUBSCRIPTIONBUTTON DETAILS ACTIONS */
const SUBSCRIPTION_BUTTONDETAILS_PREFIX = 'SUBSCRIPTION_BUTTONDETAILS';
export const SUBSCRIPTION_BUTTONDETAILS = {
  FETCH: `${SUBSCRIPTION_BUTTONDETAILS_PREFIX}_FETCH`,
  ADD: `${SUBSCRIPTION_BUTTONDETAILS_PREFIX}_ADD`,
  SUCCESS: `${SUBSCRIPTION_BUTTONDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${SUBSCRIPTION_BUTTONDETAILS_PREFIX}_FAILED`,
  DELETE: `${SUBSCRIPTION_BUTTONDETAILS_PREFIX}_DELETE`,
  PAYMENTS_FETCH: `${SUBSCRIPTION_BUTTONDETAILS_PREFIX}_PAYMENTS_FETCH`,
  PAYMENTS_SUCCESS: `${SUBSCRIPTION_BUTTONDETAILS_PREFIX}_PAYMENTS_SUCCESS`,
  UPDATE: `${SUBSCRIPTION_BUTTONDETAILS_PREFIX}_UPDATE`,
};

/* WEBHOOKS ACTIONS */
const WEBHOOKS_PREFIX = 'WEBHOOKS';
export const WEBHOOKS = {
  FETCH: `${WEBHOOKS_PREFIX}_FETCH`,
  ADD: `${WEBHOOKS_PREFIX}_ADD`,
  SUCCESS: `${WEBHOOKS_PREFIX}_SUCCESS`,
  FAILED: `${WEBHOOKS_PREFIX}_FAILED`,
  DELETE: `${WEBHOOKS_PREFIX}_DELETE`,
  UPDATE: `${WEBHOOKS_PREFIX}_UPDATE`,
  EVENTS_SUCCESS: `${WEBHOOKS_PREFIX}_EVENTS_SUCCESS`,
  EVENTS_FETCH: `${WEBHOOKS_PREFIX}_EVENTS_FETCH`,
};

/* QUICKBOOKS ACTIONS */
const QUICKBOOKS_PREFIX = 'QUICKBOOKS';
export const QUICKBOOKS = {
  FETCH: `${QUICKBOOKS_PREFIX}_FETCH`,
  ADD: `${QUICKBOOKS_PREFIX}_ADD`,
  DETAILS: `${QUICKBOOKS_PREFIX}DETAILS`,
  UPDATE: `${QUICKBOOKS_PREFIX}_UPDATE`,
  DELETE: `${QUICKBOOKS_PREFIX}_DELETE`,
  SUCCESS: `${QUICKBOOKS_PREFIX}_SUCCESS`,
  FAILED: `${QUICKBOOKS_PREFIX}_FAILED`,
  SET_QUICKBOOK: `${QUICKBOOKS_PREFIX}_SET_QUICKBOOK`,
};

/* QUICKBOOK DETAILS ACTIONS */
const QUICKBOOKSDETAILS_PREFIX = 'QUICKBOOKSDETAILS';
export const QUICKBOOKSDETAILS = {
  FETCH: `${QUICKBOOKSDETAILS_PREFIX}_FETCH`,
  DELETE: `${QUICKBOOKSDETAILS_PREFIX}_DELETE`,
  SUCCESS: `${QUICKBOOKSDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${QUICKBOOKSDETAILS_PREFIX}_FAILED`,
};

/* SALESFORCE ACTIONS */
const SALESFORCE_PREFIX = 'SALESFORCE';
export const SALESFORCE = {
  FETCH: `${SALESFORCE_PREFIX}_FETCH`,
  ADD: `${SALESFORCE_PREFIX}_ADD`,
  DETAILS: `${SALESFORCE_PREFIX}DETAILS`,
  UPDATE: `${SALESFORCE_PREFIX}_UPDATE`,
  DELETE: `${SALESFORCE_PREFIX}_DELETE`,
  SUCCESS: `${SALESFORCE_PREFIX}_SUCCESS`,
  FAILED: `${SALESFORCE_PREFIX}_FAILED`,
  CREDENTIALS_UPDATE: `${SALESFORCE_PREFIX}_CREDENTIALS_UPDATE`,
  SPOBJECT_FETCH: `${SALESFORCE_PREFIX}_SPOBJECT_FETCH`,
  SPOBJECT_SUCCESS: `${SALESFORCE_PREFIX}_SPOBJECT_SUCCESS`,
  SPFIELD_FETCH: `${SALESFORCE_PREFIX}_SPFIELD_FETCH`,
  SPFIELD_SUCCESS: `${SALESFORCE_PREFIX}_SPFIELD_SUCCESS`,
  FETCH_MAPPINGLIST: `${SALESFORCE_PREFIX}_FETCH_MAPPINGLIST`,
  FETCH_MAPPINGLIST_SUCCESS: `${SALESFORCE_PREFIX}_FETCH_MAPPINGLIST_SUCCESS`,
  ADD_MAPPINGS: `${SALESFORCE_PREFIX}_ADD_MAPPINGS`,
  DELETE_MAPPINGS: `${SALESFORCE_PREFIX}_DELETE_MAPPINGS`,
  UPDATE_MAPPINGS: `${SALESFORCE_PREFIX}_UPDATE_MAPPINGS`,
  FETCH_MAPPINGDETAILS: `${SALESFORCE_PREFIX}_FETCH_MAPPINGDETAILS`,
  FETCH_MAPPINGDETAILS_SUCCESS: `${SALESFORCE_PREFIX}_FETCH_MAPPINGDETAILS_SUCCESS`,
};

/* REFERRAL ACTIONS */
const REFERRAL_PREFIX = 'REFERRAL';
export const REFERRAL = {
  FETCH: `${REFERRAL_PREFIX}_FETCH`,
  ADD: `${REFERRAL_PREFIX}_ADD`,
  SUCCESS: `${REFERRAL_PREFIX}_SUCCESS`,
  FAILED: `${REFERRAL_PREFIX}_FAILED`,
  NOTIFY: `${REFERRAL_PREFIX}_NOTIFY`,
};

/* RATES ACTIONS */
const RATES_PREFIX = 'RATES';
export const RATES = {
  FETCH: `${RATES_PREFIX}_FETCH`,
  SUCCESS: `${RATES_PREFIX}_SUCCESS`,
  FAILED: `${RATES_PREFIX}_FAILED`,
  ADD: `${RATES_PREFIX}_ADD`,
  DELETE: `${RATES_PREFIX}_DELETE`,
  ACQUIRER_FETCH: `${RATES_PREFIX}_ACQUIRER_FETCH`,
  CATEGORY_FETCH: `${RATES_PREFIX}_CATEGORY_FETCH`,
  PAYMENTTYPE_FETCH: `${RATES_PREFIX}_PAYMENTTYPE_FETCH`,
  ACQUIRER_SUCCESS: `${RATES_PREFIX}_ACQUIRER_SUCCESS`,
  CATEGORY_SUCCESS: `${RATES_PREFIX}_CATEGORY_SUCCESS`,
  PAYMENTTYPE_SUCCESS: `${RATES_PREFIX}_PAYMENTTYPE_SUCCESS`,
};

/* RATES DETAILS ACTIONS */
const RATESDETAILS_PREFIX = 'RATESDETAILS';
export const RATESDETAILS = {
  FETCH: `${RATESDETAILS_PREFIX}_FETCH`,
  ADD: `${RATESDETAILS_PREFIX}_ADD`,
  SUCCESS: `${RATESDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${RATESDETAILS_PREFIX}_FAILED`,
  DELETE: `${RATESDETAILS_PREFIX}_DELETE`,
};

/* ISSUER RATES ACTIONS */
const ISSUER_RATES_PREFIX = 'ISSUER_RATES';
export const ISSUER_RATES = {
  FETCH: `${ISSUER_RATES_PREFIX}_FETCH`,
  SUCCESS: `${ISSUER_RATES_PREFIX}_SUCCESS`,
  FAILED: `${ISSUER_RATES_PREFIX}_FAILED`,
  ADD: `${ISSUER_RATES_PREFIX}_ADD`,
  DELETE: `${ISSUER_RATES_PREFIX}_DELETE`,
  TRANSFERTYPE_FETCH: `${ISSUER_RATES_PREFIX}_TRANSFERTYPE_FETCH`,
  TRANSFERTYPE_SUCCESS: `${ISSUER_RATES_PREFIX}_TRANSFERTYPE_SUCCESS`,
};
/* EXCHANGE RATES ACTIONS */
const EXCHANGE_RATES_PREFIX = 'EXCHANGE_RATES';
export const EXCHANGE_RATES = {
  FETCH: `${EXCHANGE_RATES_PREFIX}_FETCH`,
  FETCH_AMOUNT: `${EXCHANGE_RATES_PREFIX}_FETCH_AMOUNT`,
  FETCH_AMOUNT_SUCCESS: `${EXCHANGE_RATES_PREFIX}_FETCH_AMOUNT_SUCCESS`,
  SUCCESS: `${EXCHANGE_RATES_PREFIX}_SUCCESS`,
  FAILED: `${EXCHANGE_RATES_PREFIX}_FAILED`,
  ADD: `${EXCHANGE_RATES_PREFIX}_ADD`,
  DELETE: `${EXCHANGE_RATES_PREFIX}_DELETE`,
  UPDATE: `${EXCHANGE_RATES_PREFIX}_UPDATE`,

};

/* ISSUER RATES DETAILS ACTIONS */
const ISSUER_RATESDETAILS_PREFIX = 'ISSUER_RATESDETAILS';
export const ISSUER_RATESDETAILS = {
  FETCH: `${ISSUER_RATESDETAILS_PREFIX}_FETCH`,
  ADD: `${ISSUER_RATESDETAILS_PREFIX}_ADD`,
  SUCCESS: `${ISSUER_RATESDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${ISSUER_RATESDETAILS_PREFIX}_FAILED`,
  DELETE: `${ISSUER_RATESDETAILS_PREFIX}_DELETE`,
};

/* ACQUIRER ACTIONS */
const ACQUIRER_PREFIX = 'ACQUIRER';
export const ACQUIRER = {
  FETCH: `${ACQUIRER_PREFIX}_FETCH`,
  SUCCESS: `${ACQUIRER_PREFIX}_SUCCESS`,
  FAILED: `${ACQUIRER_PREFIX}_FAILED`,
  ADD: `${ACQUIRER_PREFIX}_ADD`,
  DELETE: `${ACQUIRER_PREFIX}_DELETE`,
  UPDATE: `${ACQUIRER_PREFIX}_UPDATE`,
  SEARCH_FETCH: `${ACQUIRER_PREFIX}_SEARCH_FETCH`,
  SEARCH_SUCCESS: `${ACQUIRER_PREFIX}_SEARCH_SUCCESS`,
};

/* ISSUER ACTIONS */
const ISSUER_PREFIX = 'ISSUER';
export const ISSUER = {
  FETCH: `${ISSUER_PREFIX}_FETCH`,
  SUCCESS: `${ISSUER_PREFIX}_SUCCESS`,
  FAILED: `${ISSUER_PREFIX}_FAILED`,
  ADD: `${ISSUER_PREFIX}_ADD`,
  DELETE: `${ISSUER_PREFIX}_DELETE`,
  UPDATE: `${ISSUER_PREFIX}_UPDATE`,
  SEARCH_FETCH: `${ISSUER_PREFIX}_SEARCH_FETCH`,
  SEARCH_SUCCESS: `${ISSUER_PREFIX}_SEARCH_SUCCESS`,
};

/* SETTLEMENT ACTIONS */
const SETTLEMENT_PREFIX = 'SETTLEMENT';
export const SETTLEMENT = {
  FETCH: `${SETTLEMENT_PREFIX}_FETCH`,
  SUCCESS: `${SETTLEMENT_PREFIX}_SUCCESS`,
  FAILED: `${SETTLEMENT_PREFIX}_FAILED`,
  IMS_ADD: `${SETTLEMENT_PREFIX}_IMS_ADD`,
  STRIPE_ADD: `${SETTLEMENT_PREFIX}_STRIPE_ADD`,
  AFFINIPAY_ADD: `${SETTLEMENT_PREFIX}_AFFINIPAY_ADD`,
  FETCH_CURRENCY: `${SETTLEMENT_PREFIX}_FETCH_CURRENCY`,
  FETCH_CURRENCY_SUCCESS: `${SETTLEMENT_PREFIX}_FETCH_CURRENCY_SUCCESS`,
  FETCH_CURRENCY_FAILED: `${SETTLEMENT_PREFIX}_FETCH_CURRENCY_FAILED`,
  FETCH_RUN: `${SETTLEMENT_PREFIX}_FETCH_RUN`,
  FETCH_RUN_SUCCESS: `${SETTLEMENT_PREFIX}_FETCH_RUN_SUCCESS`,
  FETCH_RUN_FAILED: `${SETTLEMENT_PREFIX}_FETCH_RUN_FAILED`,
};
/* TAXRATE ACTIONS */
const TAXRATE_PREFIX = 'TAXRATE';
export const TAXRATE = {
  FETCH: `${TAXRATE_PREFIX}_FETCH`,
  ADD: `${TAXRATE_PREFIX}_ADD`,
  SUCCESS: `${TAXRATE_PREFIX}_SUCCESS`,
  FAILED: `${TAXRATE_PREFIX}_FAILED`,
  DELETE: `${TAXRATE_PREFIX}_DELETE`,
  FETCH_TAXES: `${TAXRATE_PREFIX}_FETCH_TAXES`,
  SUCCESS_TAXES: `${TAXRATE_PREFIX}_SUCCESS_TAXES`,
};

/* COUPON ACTIONS */
const COUPON_PREFIX = 'COUPON';
export const COUPON = {
  FETCH: `${COUPON_PREFIX}_FETCH`,
  ADD: `${COUPON_PREFIX}_ADD`,
  SUCCESS: `${COUPON_PREFIX}_SUCCESS`,
  FAILED: `${COUPON_PREFIX}_FAILED`,
  DELETE: `${COUPON_PREFIX}_DELETE`,
  FETCH_PROMOTIONS: `${COUPON_PREFIX}_FETCH_PROMOTIONS`,
  SUCCESS_PROMOTIONS: `${COUPON_PREFIX}_SUCCESS_PROMOTIONS`,
  ADD_PROMOTIONS: `${COUPON_PREFIX}_ADD_PROMOTIONS`,
  FETCH_CUSTOMERS: `${COUPON_PREFIX}_FETCH_CUSTOMERS`,
  SUCCESS_CUSTOMERS: `${COUPON_PREFIX}_SUCCESS_CUSTOMERS`,
};

/* COUPONDETAILS ACTIONS */
const COUPONDETAILS_PREFIX = 'COUPONDETAILS';
export const COUPONDETAILS = {
  FETCH: `${COUPONDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${COUPONDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${COUPONDETAILS_PREFIX}_FAILED`,
  DELETE: `${COUPONDETAILS_PREFIX}_DELETE`,
  UPDATE: `${COUPONDETAILS_PREFIX}_UPDATE`,
  ADD_PROMOTIONS: `${COUPONDETAILS_PREFIX}_ADD_PROMOTIONS`,
  DELETE_PROMOTIONS: `${COUPONDETAILS_PREFIX}_DELETE_PROMOTIONS`,
  FETCH_REDEMPTIONS: `${COUPONDETAILS_PREFIX}_FETCH_REDEMPTIONS`,
  REDEMPTIONS_SUCCESS: `${COUPONDETAILS_PREFIX}_REDEMPTIONS_SUCCESS`,
  FETCH_COUPON_SHOPS: `${COUPONDETAILS_PREFIX}_FETCH_COUPON_SHOPS`,
  COUPON_SHOPS_SUCCESS: `${COUPONDETAILS_PREFIX}_COUPON_SHOPS_SUCCESS`,
  COUPON_SHOPS_FAILED: `${COUPONDETAILS_PREFIX}_COUPON_SHOPS_FAILED`,
  FETCH_COUPONS_MENUS: `${COUPONDETAILS_PREFIX}_FETCH_COUPONS_MENUS`,
  COUPON_MENUS_SUCCESS: `${COUPONDETAILS_PREFIX}_COUPON_MENUS_SUCCESS`,
  FETCH_COUPON_CATEGORIES: `${COUPONDETAILS_PREFIX}_FETCH_COUPON_CATEGORIES`,
  COUPON_CATEGORIES_SUCCESS: `${COUPONDETAILS_PREFIX}_COUPON_CATEGORIES_SUCCESS`,
  FETCH_COUPON_SHOP: `${COUPONDETAILS_PREFIX}_FETCH_COUPON_SHOP`,
  COUPON_SHOP_SUCCESS: `${COUPONDETAILS_PREFIX}_COUPON_SHOP_SUCCESS`,
  FETCH_COUPON_MENU: `${COUPONDETAILS_PREFIX}_FETCH_COUPON_MENU`,
  COUPON_MENU_SUCCESS: `${COUPONDETAILS_PREFIX}_COUPON_MENU_SUCCESS`,
  FETCH_COUPON_CATEGORY: `${COUPONDETAILS_PREFIX}_FETCH_COUPON_CATEGORY`,
  COUPON_CATEGORY_SUCCESS: `${COUPONDETAILS_PREFIX}_COUPON_CATEGORY_SUCCESS`,
  UPDATE_COUPON_SHOP: `${COUPONDETAILS_PREFIX}_UPDATE_COUPON_SHOP`,
  UPDATE_COUPON_MENU: `${COUPONDETAILS_PREFIX}_UPDATE_COUPON_MENU`,
  UPDATE_COUPON_CATEGORY: `${COUPONDETAILS_PREFIX}_UPDATE_COUPON_CATEGORY`,
  FETCH_COUPON_ITEMS: `${COUPONDETAILS_PREFIX}_FETCH_COUPON_ITEMS`,
  COUPON_ITEMS_SUCCESS: `${COUPONDETAILS_PREFIX}_COUPON_ITEMS_SUCCESS`,
  FETCH_ALL_EVENT_PAGES: `${COUPONDETAILS_PREFIX}_FETCH_ALL_EVENT_PAGES`,
  EVENT_PAGES_SUCCESS: `${COUPONDETAILS_PREFIX}_EVENT_PAGES_SUCCESS`,
  FETCH_EVENT_PAGE_ITEMS: `${COUPONDETAILS_PREFIX}_FETCH_EVENT_PAGE_ITEMS`,
  EVENT_PAGE_ITEMS_SUCCESS: `${COUPONDETAILS_PREFIX}_EVENT_PAGE_ITEMS_SUCCESS`,
  FETCH_EVENT_PAGE_CATEGORIES: `${COUPONDETAILS_PREFIX}_FETCH_EVENT_PAGE_CATEGORIES`,
  EVENT_PAGE_CATEGORIES_SUCCESS: `${COUPONDETAILS_PREFIX}_EVENT_PAGE_CATEGORIES_SUCCESS`,
  FETCH_COUPON_EVENT_PAGE: `${COUPONDETAILS_PREFIX}_FETCH_COUPON_EVENT_PAGE`,
  COUPON_EVENT_PAGE_SUCCESS: `${COUPONDETAILS_PREFIX}_COUPON_EVENT_PAGE_SUCCESS`,
  UPDATE_COUPON_EVENT_PAGE: `${COUPONDETAILS_PREFIX}_UPDATE_COUPON_EVENT_PAGE`,

};

/* OFFER ACTIONS */
const OFFER_PREFIX = 'OFFER';
export const OFFER = {
  FETCH: `${OFFER_PREFIX}_FETCH`,
  ADD: `${OFFER_PREFIX}_ADD`,
  SUCCESS: `${OFFER_PREFIX}_SUCCESS`,
  FAILED: `${OFFER_PREFIX}_FAILED`,
  DELETE: `${OFFER_PREFIX}_DELETE`,
  FETCH_PROMOTIONS: `${OFFER_PREFIX}_FETCH_PROMOTIONS`,
  SUCCESS_PROMOTIONS: `${OFFER_PREFIX}_SUCCESS_PROMOTIONS`,
  ADD_PROMOTIONS: `${OFFER_PREFIX}_ADD_PROMOTIONS`,
  FETCH_CUSTOMERS: `${OFFER_PREFIX}_FETCH_CUSTOMERS`,
  SUCCESS_CUSTOMERS: `${OFFER_PREFIX}_SUCCESS_CUSTOMERS`,
};

/* OFFERDETAILS ACTIONS */
const OFFERDETAILS_PREFIX = 'OFFERDETAILS';
export const OFFERDETAILS = {
  FETCH: `${OFFERDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${OFFERDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${OFFERDETAILS_PREFIX}_FAILED`,
  DELETE: `${OFFERDETAILS_PREFIX}_DELETE`,
  UPDATE: `${OFFERDETAILS_PREFIX}_UPDATE`,
  FETCH_REDEMPTIONS: `${OFFERDETAILS_PREFIX}_FETCH_REDEMPTIONS`,
  REDEMPTIONS_SUCCESS: `${OFFERDETAILS_PREFIX}_REDEMPTIONS_SUCCESS`,
};

/* AFFILIATE ACTIONS */
const AFFILIATE_PREFIX = 'AFFILIATE';
export const AFFILIATE = {
  FETCH: `${AFFILIATE_PREFIX}_FETCH`,
  ADD: `${AFFILIATE_PREFIX}_ADD`,
  SUCCESS: `${AFFILIATE_PREFIX}_SUCCESS`,
  FAILED: `${AFFILIATE_PREFIX}_FAILED`,
  NOTIFY: `${AFFILIATE_PREFIX}_NOTIFY`,
};

/* AFFILIATE TRANSACTIONS */
const AFFILIATE_TRANSACTIONS_PREFIX = 'AFFILIATE_TRANSACTIONS';
export const AFFILIATE_TRANSACTIONS = {
  FETCH: `${AFFILIATE_TRANSACTIONS_PREFIX}_FETCH`,
  ADD: `${AFFILIATE_TRANSACTIONS_PREFIX}_ADD`,
  SUCCESS: `${AFFILIATE_TRANSACTIONS_PREFIX}_SUCCESS`,
  FAILED: `${AFFILIATE_TRANSACTIONS_PREFIX}_FAILED`,
};

/* AFFILIATE EARNINGS */
const AFFILIATE_EARNINGS_PREFIX = 'AFFILIATE_EARNINGS';
export const AFFILIATE_EARNINGS = {
  FETCH: `${AFFILIATE_EARNINGS_PREFIX}_FETCH`,
  ADD: `${AFFILIATE_EARNINGS_PREFIX}_ADD`,
  SUCCESS: `${AFFILIATE_EARNINGS_PREFIX}_SUCCESS`,
  FAILED: `${AFFILIATE_EARNINGS_PREFIX}_FAILED`,
};

/* SCHEDULE REPORTS ACTIONS */
const SCHEDULE_REPORTS_PREFIX = 'SCHEDULE_REPORTS';
export const SCHEDULE_REPORTS = {
  FETCH: `${SCHEDULE_REPORTS_PREFIX}_FETCH`,
  ADD: `${SCHEDULE_REPORTS_PREFIX}_ADD`,
  SUCCESS: `${SCHEDULE_REPORTS_PREFIX}_SUCCESS`,
  FAILED: `${SCHEDULE_REPORTS_PREFIX}_FAILED`,
  DELETE: `${SCHEDULE_REPORTS_PREFIX}_DELETE`,
};

/* ADMIN TERMINAL ACTIONS */
const ADMIN_TERMINAL_PREFIX = 'ADMIN_TERMINAL';
export const ADMIN_TERMINAL = {
  FETCH: `${ADMIN_TERMINAL_PREFIX}_FETCH`,
  SUCCESS: `${ADMIN_TERMINAL_PREFIX}_SUCCESS`,
  FAILED: `${ADMIN_TERMINAL_PREFIX}_FAILED`,
  ADD: `${ADMIN_TERMINAL_PREFIX}_ADD`,
  UPDATE: `${ADMIN_TERMINAL_PREFIX}_UPDATE`,
};

/* ADMIN TERMINAL DETAILS ACTIONS */
const ADMIN_TERMINALDETAILS_PREFIX = 'ADMIN_TERMINALDETAILS';
export const ADMIN_TERMINALDETAILS = {
  FETCH: `${ADMIN_TERMINALDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${ADMIN_TERMINALDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${ADMIN_TERMINALDETAILS_PREFIX}_FAILED`,
  DELETE: `${ADMIN_TERMINALDETAILS_PREFIX}_DELETE`,
  UPDATE: `${ADMIN_TERMINALDETAILS_PREFIX}_UPDATE`,
};

/* SPFIELD ACTIONS */
const SPFIELD_PREFIX = 'SPFIELD';
export const SPFIELD = {
  FETCH: `${SPFIELD_PREFIX}_FETCH`,
  ADD: `${SPFIELD_PREFIX}_ADD`,
  UPDATE: `${SPFIELD_PREFIX}_UPDATE`,
  DELETE: `${SPFIELD_PREFIX}_DELETE`,
  SUCCESS: `${SPFIELD_PREFIX}_SUCCESS`,
  FAILED: `${SPFIELD_PREFIX}_FAILED`,
  SELECTED: `${SPFIELD_PREFIX}_SELECTED`,
};

/* SPFIELD ACTIONS */
const SP_OBJECT_PREFIX = 'SP_OBJECT';
export const SP_OBJECT = {
  FETCH: `${SP_OBJECT_PREFIX}_FETCH`,
  FETCH_ALL: `${SP_OBJECT_PREFIX}_FETCH_ALL`,
  ADD: `${SP_OBJECT_PREFIX}_ADD`,
  UPDATE: `${SP_OBJECT_PREFIX}_UPDATE`,
  DELETE: `${SP_OBJECT_PREFIX}_DELETE`,
  SUCCESS: `${SP_OBJECT_PREFIX}_SUCCESS`,
  FAILED: `${SP_OBJECT_PREFIX}_FAILED`,
  SELECTED: `${SP_OBJECT_PREFIX}_SELECTED`,
};

/* ADMIN POSKEY ACTIONS */
const POSKEY_PREFIX = 'POSKEY';
export const POSKEY = {
  FETCH: `${POSKEY_PREFIX}_FETCH`,
  SUCCESS: `${POSKEY_PREFIX}_SUCCESS`,
  FAILED: `${POSKEY_PREFIX}_FAILED`,
  UPDATE: `${POSKEY_PREFIX}_UPDATE`,
};

/* ADMIN GLOBALKEY ACTIONS */
const GLOBAL_RESTRICTED_KEY_PREFIX = 'GLOBAL_RESTRICTED_KEY';
export const GLOBAL_RESTRICTED_KEY = {
  FETCH: `${GLOBAL_RESTRICTED_KEY_PREFIX}_FETCH`,
  SUCCESS: `${GLOBAL_RESTRICTED_KEY_PREFIX}_SUCCESS`,
  FAILED: `${GLOBAL_RESTRICTED_KEY_PREFIX}_FAILED`,
  ADD: `${GLOBAL_RESTRICTED_KEY_PREFIX}_ADD`,
  UPDATE: `${GLOBAL_RESTRICTED_KEY_PREFIX}_UPDATE`,
  DELETE: `${GLOBAL_RESTRICTED_KEY_PREFIX}_DELETE`,
};

/* PAYMENTWIDGETS ACTIONS */
const PAYMENTWIDGETS_PREFIX = 'PAYMENTWIDGETS';
export const PAYMENTWIDGETS = {
  FETCH: `${PAYMENTWIDGETS_PREFIX}_FETCH`,
  ADD: `${PAYMENTWIDGETS_PREFIX}_ADD`,
  SUCCESS: `${PAYMENTWIDGETS_PREFIX}_SUCCESS`,
  FAILED: `${PAYMENTWIDGETS_PREFIX}_FAILED`,
  CURRENT_WIDGET: `${PAYMENTWIDGETS_PREFIX}_CURRENT_WIDGET`,
  SAMPLE_WIDGET: `${PAYMENTWIDGETS_PREFIX}_SAMPLE_WIDGET`,
};

/* PAYMENTWIDGETSDETAILS ACTIONS */
const PAYMENTWIDGETSDETAILS_PREFIX = 'PAYMENTWIDGETSDETAILS';
export const PAYMENTWIDGETSDETAILS = {
  FETCH: `${PAYMENTWIDGETSDETAILS_PREFIX}_FETCH`,
  UPDATE: `${PAYMENTWIDGETSDETAILS_PREFIX}_UPDATE`,
  SUCCESS: `${PAYMENTWIDGETSDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${PAYMENTWIDGETSDETAILS_PREFIX}_FAILED`,
  DELETE: `${PAYMENTWIDGETSDETAILS_PREFIX}_DELETE`,
  FETCH_PAYMENTS: `${PAYMENTWIDGETSDETAILS_PREFIX}_FETCH_PAYMENTS`,
  SUCCESS_PAYMENTS: `${PAYMENTWIDGETSDETAILS_PREFIX}_SUCCESS_PAYMENTS`,
  FETCH_SUBSCRIPTIONS: `${PAYMENTWIDGETSDETAILS_PREFIX}_FETCH_SUBSCRIPTIONS`,
  SUCCESS_SUBSCRIPTIONS: `${PAYMENTWIDGETSDETAILS_PREFIX}_SUCCESS_SUBSCRIPTIONS`,
  SLUG_FETCH: `${PAYMENTWIDGETSDETAILS_PREFIX}_SLUG_FETCH`,
  SLUG_SUCCESS: `${PAYMENTWIDGETSDETAILS_PREFIX}_SLUG_SUCCESS`,
};

/* SERVICES ACTIONS */
const SERVICES_PREFIX = 'SERVICES';
export const SERVICES = {
  FETCH: `${SERVICES_PREFIX}_FETCH`,
  SUCCESS: `${SERVICES_PREFIX}_SUCCESS`,
  FAILED: `${SERVICES_PREFIX}_FAILED`,
};

/* SERVICEDETAILS ACTIONS */
const SERVICEDETAILS_PREFIX = 'SERVICEDETAILS';
export const SERVICEDETAILS = {
  FETCH: `${SERVICEDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${SERVICEDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${SERVICEDETAILS_PREFIX}_FAILED`,
  UPDATE: `${SERVICEDETAILS_PREFIX}_UPDATE`,
  FETCH_TIMELINE: `${SERVICEDETAILS_PREFIX}_FETCH_TIMELINE`,
  SUCCESS_TIMELINE: `${SERVICEDETAILS_PREFIX}_SUCCESS_TIMELINE`,
  FIELDAGENT_FETCH: `${SERVICEDETAILS_PREFIX}_FIELDAGENT_FETCH`,
  FIELDAGENT_SUCCESS: `${SERVICEDETAILS_PREFIX}_FIELDAGENT_SUCCESS`,
  FIELDAGENT_FAILED: `${SERVICEDETAILS_PREFIX}_ FIELDAGENT_FAILED`,
};

/* DOCUMENTS ACTIONS */
const DOCUMENTS_PREFIX = 'DOCUMENTS';
export const DOCUMENTS = {
  FETCH: `${DOCUMENTS_PREFIX}_FETCH`,
  SUCCESS: `${DOCUMENTS_PREFIX}_SUCCESS`,
  FAILED: `${DOCUMENTS_PREFIX}_FAILED`,
  ADD: `${SERVICEDETAILS_PREFIX}_ADD`,
};

/* TRANSFERS ACTIONS */
const TRANSFERS_PREFIX = 'TRANSFERS';
export const TRANSFERS = {
  FETCH: `${TRANSFERS_PREFIX}_FETCH`,
  SUCCESS: `${TRANSFERS_PREFIX}_SUCCESS`,
  FAILED: `${TRANSFERS_PREFIX}_FAILED`,
  CONTACT_FETCH: `${TRANSFERS_PREFIX}_CONTACT_FETCH`,
  CONTACT_SUCCESS: `${TRANSFERS_PREFIX}_CONTACT_SUCCESS`,
  CONTACT_FAILED: `${TRANSFERS_PREFIX}_CONTACT_FAILED`,
  ISSUETRANSFER: `${TRANSFERS_PREFIX}_ISSUETRANSFER`,
  FUNDING_FETCH: `${TRANSFERS_PREFIX}_FUNDING_FETCH`,
  FUNDING_SUCCESS: `${TRANSFERS_PREFIX}_FUNDING_SUCCESS`,
  FUNDING_FAILED: `${TRANSFERS_PREFIX}_FUNDING_FAILED`,
  SEARCH_FUNDING_FETCH: `${TRANSFERS_PREFIX}_SEARCH_FUNDING_FETCH`,
  SEARCH_FUNDING_SUCCESS: `${TRANSFERS_PREFIX}_SEARCH_FUNDING_SUCCESS`,
  SEARCH_FUNDING_FAILED: `${TRANSFERS_PREFIX}_SEARCH_FUNDING_FAILED`,
  CONFIRMTRANSFEROTP: `${TRANSFERS_PREFIX}_CONFIRMTRANSFEROTP`,
  ISSUETRGID: `${TRANSFERS_PREFIX}_ISSUETRGID`,
  UPDATE: `${TRANSFERS_PREFIX}_UPDATE`,
  CROSS_BORDER: `${TRANSFERS_PREFIX}_CROSS_BORDER`,
  CANCELTRANSFERINDETAILS: `${TRANSACTIONS_PREFIX}_CANCEL`,
};

/* TRANSFERLINKS ACTIONS */
const TRANSFERLINKS_PREFIX = 'TRANSFERLINKS';
export const TRANSFERLINKS = {
  FETCH: `${TRANSFERLINKS_PREFIX}_FETCH`,
  SUCCESS: `${TRANSFERLINKS_PREFIX}_SUCCESS`,
  FAILED: `${TRANSFERLINKS_PREFIX}_FAILED`,
  CREATETRANSFERLINK: `${TRANSFERLINKS_PREFIX}_CREATETRANSFERLINK`,
  CONFIRMTRANSFERLINKOTP: `${TRANSFERLINKS_PREFIX}_CONFIRMTRANSFERLINKOTP`,
};

/* ADMIN BIN ACTIONS */
const ADMIN_BIN_PREFIX = 'ADMIN_BIN';
export const ADMIN_BIN = {
  FETCH: `${ADMIN_BIN_PREFIX}_FETCH`,
  SUCCESS: `${ADMIN_BIN_PREFIX}_SUCCESS`,
  FAILED: `${ADMIN_BIN_PREFIX}_FAILED`,
  ADD: `${ADMIN_BIN_PREFIX}_ADD`,
  UPDATE: `${ADMIN_BIN_PREFIX}_UPDATE`,
};

/* PAGE RECEIPTS ACTIONS */
const PAGE_RECEIPTS_PREFIX = 'PAGE_RECEIPTS';
export const PAGE_RECEIPTS = {
  FETCH: `${PAGE_RECEIPTS_PREFIX}_FETCH`,
  SUCCESS: `${PAGE_RECEIPTS_PREFIX}_SUCCESS`,
  FAILED: `${PAGE_RECEIPTS_PREFIX}_FAILED`,
};

/* VENDORS ACTIONS */
const VENDORS_PREFIX = 'VENDORS';
export const VENDORS = {
  FETCH: `${VENDORS_PREFIX}_FETCH`,
  ADD: `${VENDORS_PREFIX}_ADD`,
  UPDATE: `${VENDORS_PREFIX}_UPDATE`,
  DELETE: `${VENDORS_PREFIX}_DELETE`,
  FETCH_CUSTOM_TYPES: `${VENDORS_PREFIX}_FETCH_CUSTOM_TYPES`,
  DELETE_SINGLE_CUSTOM_TYPE: `${VENDORS_PREFIX}_DELETE_SINGLE_CUSTOM_TYPE`,
  DELETE_ALL_CUSTOM_TYPES: `${VENDORS_PREFIX}_DELETE_ALL_CUSTOM_TYPES`,
  SUCCESS: `${VENDORS_PREFIX}_SUCCESS`,
  CUSTOM_TYPE_SUCCESS: `${VENDORS_PREFIX}_CUSTOM_TYPE_SUCCESS`,
  FAILED: `${VENDORS_PREFIX}_FAILED`,
  SELECTED: `${VENDORS_PREFIX}_SELECTED`,
  MULTI_SEND: `${VENDORS_PREFIX}_MULTI_SEND`,
};

/* VENDORS DETAILS ACTIONS */
const VENDORSDETAILS_PREFIX = 'VENDORSDETAILS';
export const VENDORSDETAILS = {
  FETCH: `${VENDORSDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${VENDORSDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${VENDORSDETAILS_PREFIX}_FAILED`,
  DELETE: `${VENDORSDETAILS_PREFIX}_DELETE`,
  TRANSFERS_FETCH: `${VENDORSDETAILS_PREFIX}_TRANSFERS_FETCH`,
  TRANSFERS_SUCCESS: `${VENDORSDETAILS_PREFIX}_TRANSFERS_SUCCESS`,
};

/* TRANSFERSDETAILS ACTIONS */
const TRANSFERSDETAILS_PREFIX = 'TRANSFERSDETAILS';
export const TRANSFERSDETAILS = {
  FETCH: `${TRANSFERSDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${TRANSFERSDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${TRANSFERSDETAILS_PREFIX}_FAILED`,
  DELETE: `${TRANSFERSDETAILS_PREFIX}_DELETE`,
  TIMELINE_FETCH: `${TRANSFERSDETAILS_PREFIX}_TIMELINE_FETCH`,
  TIMELINE_SUCCESS: `${TRANSFERSDETAILS_PREFIX}_TIMELINE_SUCCESS`,
  NOTIFY: `${TRANSFERSDETAILS_PREFIX}_NOTIFY`,
  SELECTED: `${TRANSFERSDETAILS_PREFIX}_SELECTED`,
  RESENDOTP: `${TRANSFERSDETAILS_PREFIX}_RESENDOTP`,
  TIMELINE_UPDATE: `${TRANSFERSDETAILS_PREFIX}_TIMELINE_UPDATE`,
};

/* TRANSFER RECEIPTS ACTIONS */
const TRANSFER_RECEIPTS_PREFIX = 'TRANSFER_RECEIPTS';
export const TRANSFER_RECEIPTS = {
  FETCH: `${TRANSFER_RECEIPTS_PREFIX}_FETCH`,
  DOWNLOAD: `${TRANSFER_RECEIPTS_PREFIX}_DOWNLOAD`,
  SUCCESS: `${TRANSFER_RECEIPTS_PREFIX}_SUCCESS`,
  FAILED: `${TRANSFER_RECEIPTS_PREFIX}_FAILED`,
};

/* FUNDINGSOURCE ACTIONS */
const FUNDINGSOURCE_PREFIX = 'FUNDINGSOURCE';
export const FUNDINGSOURCE = {
  FETCH: `${FUNDINGSOURCE_PREFIX}_FETCH`,
  FETCH_BY_CONTACTGID: `${FUNDINGSOURCE_PREFIX}_FETCH_BY_CONTACTGID`,
  SUCCESS: `${FUNDINGSOURCE_PREFIX}_SUCCESS`,
  SUCCESS_CONTACTGID: `${FUNDINGSOURCE_PREFIX}_SUCCESS_CONTACTGID`,
  FUNDINGSOURCEDETAILS_SUCCESS: `${FUNDINGSOURCE_PREFIX}_FUNDINGSOURCEDETAILS_SUCCESS`,
  FETCH_FUNDINGSOURCEDETAILS: `${FUNDINGSOURCE_PREFIX}_FETCH_FUNDINGSOURCEDETAILS`,
  FAILED: `${FUNDINGSOURCE_PREFIX}_FAILED`,
  CREATE: `${FUNDINGSOURCE_PREFIX}_CREATE`,
  DELETE: `${FUNDINGSOURCE_PREFIX}_DELETE`,
  ADD_FUNDINGSOURCE: `${FUNDINGSOURCE_PREFIX}_ADD_FUNDINGSOURCE`,
};

/* FUNDINGSOURCE LINKS ACTIONS */
const FUNDINGSOURCE_LINKS_PREFIX = 'FUNDINGSOURCE_LINKS';
export const FUNDINGSOURCE_LINKS = {
  FETCH: `${FUNDINGSOURCE_LINKS_PREFIX}_FETCH`,
  SUCCESS: `${FUNDINGSOURCE_LINKS_PREFIX}_SUCCESS`,
  FAILED: `${FUNDINGSOURCE_LINKS_PREFIX}_FAILED`,
  CREATE: `${FUNDINGSOURCE_LINKS_PREFIX}_CREATE`,
};

/* BULK TRANSFER ACTIONS */
const BULKTRANSFER_PREFIX = 'BULKTRANSFER';
export const BULKTRANSFER = {
  FETCH: `${BULKTRANSFER_PREFIX}_FETCH`,
  ADD: `${BULKTRANSFER_PREFIX}_ADD`,
  SUCCESS: `${BULKTRANSFER_PREFIX}_SUCCESS`,
  FAILED: `${BULKTRANSFER_PREFIX}_FAILED`,
  SELECTED: `${BULKTRANSFER_PREFIX}_SELECTED`,
  FETCH_SEARCH: `${BULKTRANSFER_PREFIX}_FETCH_SEARCH`,
  SUCCESS_SEARCH: `${BULKTRANSFER_PREFIX}_SUCCESS_SEARCH`,
};

/* BULK TRANSFER DETAILS ACTIONS */
const BULKTRANSFER_DETAILS_PREFIX = 'BULKTRANSFER_DETAILS';
export const BULKTRANSFER_DETAILS = {
  FETCH: `${BULKTRANSFER_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${BULKTRANSFER_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${BULKTRANSFER_DETAILS_PREFIX}_FAILED`,
  TRANSFERS_FETCH: `${BULKTRANSFER_DETAILS_PREFIX}_TRANSFERS_FETCH`,
  TRANSFERS_SUCCESS: `${BULKTRANSFER_DETAILS_PREFIX}_TRANSFERS_SUCCESS`,
};

/* BANK TRANSFERS ACTIONS */
const BANK_TRANSFERS_PREFIX = 'BANK_TRANSFERS';
export const BANK_TRANSFERS = {
  FETCH: `${BANK_TRANSFERS_PREFIX}_FETCH`,
  SUCCESS: `${BANK_TRANSFERS_PREFIX}_SUCCESS`,
  FAILED: `${BANK_TRANSFERS_PREFIX}_FAILED`,
  CREATE: `${BANK_TRANSFERS_PREFIX}_CREATE`,
  REGISTER: `${BANK_TRANSFERS_PREFIX}_REGISTER`,
  ACCOUNT_BY_ID: `${BANK_TRANSFERS_PREFIX}_ACCOUNT_BY_ID`,
  BALANCE_BY_ID: `${BANK_TRANSFERS_PREFIX}_BALANCE_BY_ID`,
  ACCOUNT_BY_ID_SUCCESS: `${BANK_TRANSFERS_PREFIX}_ACCOUNT_BY_ID_SUCCESS`,
  BALANCE_BY_ID_SUCCESS: `${BANK_TRANSFERS_PREFIX}_BALANCE_BY_ID_SUCCESS`,
  CREATE_FUNDING_SOURCE: `${BANK_TRANSFERS_PREFIX}_CREATE_FUNDING_SOURCE`,
  CREATE_PRIMARY_CONTACT: `${BANK_TRANSFERS_PREFIX}_CREATE_PRIMARY_CONTACT`,
  DELETE_CONTACT: `${BANK_TRANSFERS_PREFIX}_DELETE_CONTACT`,
};

/* PAYMENTCHECKOUT ACTIONS */
const PAYMENTCHECKOUT_PREFIX = 'PAYMENTCHECKOUT';
export const PAYMENTCHECKOUT = {
  FETCH: `${PAYMENTCHECKOUT_PREFIX}_FETCH`,
  ADD: `${PAYMENTCHECKOUT_PREFIX}_ADD`,
  SUCCESS: `${PAYMENTCHECKOUT_PREFIX}_SUCCESS`,
  FAILED: `${PAYMENTCHECKOUT_PREFIX}_FAILED`,
  SELECTED: `${PAYMENTCHECKOUT_PREFIX}_SELECTED`,
  DELETE: `${PAYMENTCHECKOUT_PREFIX}_DELETE`,
};

/* PAYMENTCHECKOUT DETAILS ACTIONS */
const PAYMENTCHECKOUTDETAILS_PREFIX = 'PAYMENTCHECKOUTDETAILS';
export const PAYMENTCHECKOUTDETAILS = {
  FETCH: `${PAYMENTCHECKOUTDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${PAYMENTCHECKOUTDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${PAYMENTCHECKOUTDETAILS_PREFIX}_FAILED`,
  PAYMENTS_FETCH: `${PAYMENTCHECKOUTDETAILS_PREFIX}_PAYMENTS_FETCH`,
  PAYMENTS_SUCCESS: `${PAYMENTCHECKOUTDETAILS_PREFIX}_PAYMENTS_SUCCESS`,
};

/* CUSTOM DOCUMENT REQUEST ACTIONS */
const CUSTOM_DOCUMENT_REQUEST_PREFIX = 'CUSTOM_DOCUMENT_REQUEST';
export const CUSTOM_DOCUMENT_REQUEST = {
  FETCH: `${CUSTOM_DOCUMENT_REQUEST_PREFIX}_FETCH`,
  SUCCESS: `${CUSTOM_DOCUMENT_REQUEST_PREFIX}_SUCCESS`,
  FAILED: `${CUSTOM_DOCUMENT_REQUEST_PREFIX}_FAILED`,
  ADD: `${CUSTOM_DOCUMENT_REQUEST_PREFIX}_ADD`,
  UPDATE: `${CUSTOM_DOCUMENT_REQUEST_PREFIX}_UPDATE`,
};

/* CUSTOM DOCUMENT REQUEST DETAILS ACTIONS */
const CUSTOM_DOCUMENT_REQUEST_DETAILS_PREFIX = 'CUSTOM_DOCUMENT_REQUEST_DETAILS';
export const CUSTOM_DOCUMENT_REQUEST_DETAILS = {
  FETCH: `${CUSTOM_DOCUMENT_REQUEST_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${CUSTOM_DOCUMENT_REQUEST_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${CUSTOM_DOCUMENT_REQUEST_DETAILS_PREFIX}_FAILED`,
  DELETE: `${CUSTOM_DOCUMENT_REQUEST_DETAILS_PREFIX}_DELETE`,
};

/* FILTER ACTIONS */
const FILTER_PARAMS = 'FILTERS_SPECS';
export const FILTERS_SPECS = {
  SET_FILTER_DATA: `${FILTER_PARAMS}_DATA`,
};

/* STATUS PARAM ACTIONS */
const STATUS_PARAMS = 'STATUS_SPECS';
export const STATUS_SPECS = {
  SET_STATUS_DATA: `${STATUS_PARAMS}_DATA`,
};

/* PARTNER BOOLEAN ACTIONS */

const PARTNER_PARAMS = 'PARTNER_SPECS';
export const PARTNER_SPECS = {
  SET_PARTNER_DATA: `${PARTNER_PARAMS}_DATA`,
};

/* ACCOUNT SETLLEMENT ACTIONS */
const ACCOUNT_SETLLEMENT_PREFIX = 'ACCOUNT_SETLLEMENT';
export const ACCOUNT_SETLLEMENT = {
  FETCH: `${ACCOUNT_SETLLEMENT_PREFIX}_FETCH`,
  SUCCESS: `${ACCOUNT_SETLLEMENT_PREFIX}_SUCCESS`,
  FAILED: `${ACCOUNT_SETLLEMENT_PREFIX}_FAILED`,
  ADD: `${ACCOUNT_SETLLEMENT_PREFIX}_ADD`,
  DELETE: `${ACCOUNT_SETLLEMENT_PREFIX}_DELETE`,
};

/* PARTNER SETLLEMENT ACTIONS */
const PARTNER_SETLLEMENT_PREFIX = 'PARTNER_SETLLEMENT';
export const PARTNER_SETLLEMENT = {
  FETCH: `${PARTNER_SETLLEMENT_PREFIX}_FETCH`,
  SUCCESS: `${PARTNER_SETLLEMENT_PREFIX}_SUCCESS`,
  FAILED: `${PARTNER_SETLLEMENT_PREFIX}_FAILED`,
  ADD: `${PARTNER_SETLLEMENT_PREFIX}_ADD`,
  DELETE: `${PARTNER_SETLLEMENT_PREFIX}_DELETE`,
};

/* OCR REQUEST ACTIONS */
const OCR_REQUEST_PREFIX = 'OCR_REQUEST';
export const OCR_REQUEST = {
  FETCH: `${OCR_REQUEST_PREFIX}_FETCH`,
  SUCCESS: `${OCR_REQUEST_PREFIX}_SUCCESS`,
  FAILED: `${OCR_REQUEST_PREFIX}_FAILED`,
  ADD: `${OCR_REQUEST_PREFIX}_ADD`,
  DELETE: `${OCR_REQUEST_PREFIX}_DELETE`,
};

/* BANK STATEMENT ACTIONS */
const BANK_STATEMENT_PREFIX = 'BANK_STATEMENT';
export const BANK_STATEMENT = {
  FETCH: `${BANK_STATEMENT_PREFIX}_FETCH`,
  SUCCESS: `${BANK_STATEMENT_PREFIX}_SUCCESS`,
  FAILED: `${BANK_STATEMENT_PREFIX}_FAILED`,
  ADD: `${BANK_STATEMENT_PREFIX}_ADD`,
  DELETE: `${BANK_STATEMENT_PREFIX}_DELETE`,
};

/* BANK STATEMENT DETAILS ACTIONS */
const BANK_STATEMENT_DETAILS_PREFIX = 'BANK_STATEMENT_DETAILS';
export const BANK_STATEMENT_DETAILS = {
  FETCH: `${BANK_STATEMENT_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${BANK_STATEMENT_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${BANK_STATEMENT_DETAILS_PREFIX}_FAILED`,
  PAYABLES_FETCH: `${BANK_STATEMENT_DETAILS_PREFIX}_PAYABLES_FETCH`,
  PAYABLES_SUCCESS: `${BANK_STATEMENT_DETAILS_PREFIX}_PAYABLES_SUCCESS`,
  ADD: `${BANK_STATEMENT_DETAILS_PREFIX}_ADD`,
};

/* ACCOUNT SLUG ACTIONS */
const ACCOUNT_SLUG_PREFIX = 'ACCOUNT_SLUG';
export const ACCOUNT_SLUG = {
  FETCH: `${ACCOUNT_SLUG_PREFIX}_FETCH`,
  SUCCESS: `${ACCOUNT_SLUG_PREFIX}_SUCCESS`,
  FAILED: `${ACCOUNT_SLUG_PREFIX}_FAILED`,
  ADD: `${ACCOUNT_SLUG_PREFIX}_ADD`,
  LINK: `${ACCOUNT_SLUG_PREFIX}_LINK`,
  UNLINK: `${ACCOUNT_SLUG_PREFIX}_UNLINK`,
};

/* ACCOUNT SLUG DETAILS ACTIONS */
const ACCOUNT_SLUG_DETAILS_PREFIX = 'ACCOUNT_SLUG_DETAILS';
export const ACCOUNT_SLUG_DETAILS = {
  FETCH: `${ACCOUNT_SLUG_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${ACCOUNT_SLUG_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${ACCOUNT_SLUG_DETAILS_PREFIX}_FAILED`,
};

/* WALLET ACTIONS */
const WALLET_PREFIX = 'WALLET';
export const WALLET = {
  FETCH: `${WALLET_PREFIX}_FETCH`,
  SUCCESS: `${WALLET_PREFIX}_SUCCESS`,
  FAILED: `${WALLET_PREFIX}_FAILED`,
  ADD: `${WALLET_PREFIX}_ADD`,
  ADD_CONFIRM: `${WALLET_PREFIX}_ADD_CONFIRM`,
  FETCH_TRANSACTIONS: `${WALLET_PREFIX}_FETCH_TRANSACTIONS`,
  FETCH_TRANSACTIONS_SUCCESS: `${WALLET_PREFIX}_FETCH_TRANSACTIONS_SUCCESS`,
  REST_PIN: `${WALLET_PREFIX}_REST_PIN`,
  REST_PIN_SUCCESS: `${WALLET_PREFIX}_REST_PIN_SUCCESS`,
  VERIFY_PIN: `${WALLET_PREFIX}_VERIFY_PIN`,
  WALLET_AUTH: `${WALLET_PREFIX}_WALLET_AUTH`,
  WALLET_SETUP: `${WALLET_PREFIX}_WALLET_SETUP`,
  WALLET_PIN: `${WALLET_PREFIX}_WALLET_PIN`,
  RESET_WALLET_PIN: `${WALLET_PREFIX}_RESET_WALLET_PIN`,
  CONFIRM_WALLET: `${WALLET_PREFIX}_CONFIRM_WALLET`,
  CONFIRM_PIN: `${WALLET_PREFIX}_CONFIRM_PIN`,
};

/* WALLET ACTIONS */
const WALLET_DETAILS_PREFIX = 'WALLET_DETAILS';
export const WALLET_DETAILS = {
  FETCH: `${WALLET_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${WALLET_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${WALLET_DETAILS_PREFIX}_FAILED`,
  ADD: `${WALLET_DETAILS_PREFIX}_ADD`,
  ADD_BANK: `${WALLET_DETAILS_PREFIX}_ADD_BANK`,
  FETCH_TRANSACTIONS: `${WALLET_DETAILS_PREFIX}_FETCH_TRANSACTIONS`,
  FETCH_WALLETDETAILS_SUCCESS: `${WALLET_DETAILS_PREFIX}_FETCH_WALLETDETAILS_SUCCESS`,
  LOAD_BALANCE: `${WALLET_DETAILS_PREFIX}_LOAD_BALANCE`,
  ADD_CARD: `${WALLET_DETAILS_PREFIX}_ADD_CARD`,
  FETCH_CARDS: `${WALLET_DETAILS_PREFIX}_FETCH_CARDS`,
  FETCH_CARDS_SUCCESS: `${WALLET_DETAILS_PREFIX}_FETCH_CARDS_SUCCESS`,
  WITHDRAW: `${WALLET_DETAILS_PREFIX}_WITHDRAW`,
  DELETE_CARD: `${WALLET_DETAILS_PREFIX}_DELETE_CARD`,
  UPDATE_CARD: `${WALLET_DETAILS_PREFIX}_UPDATE_CARD`,
};

/* WALLET TRANSACTION ACTIONS */
const WALLET_TRANSACTION_PREFIX = 'WALLET_TRANSACTION';
export const WALLET_TRANSACTION = {
  FETCH: `${WALLET_TRANSACTION_PREFIX}_FETCH`,
  SUCCESS: `${WALLET_TRANSACTION_PREFIX}_SUCCESS`,
  FAILED: `${WALLET_TRANSACTION_PREFIX}_FAILED`,
};

/* WALLET TRANSACTION DETAILS ACTIONS */
const WALLET_TRANSACTION_DETAILS_PREFIX = 'WALLET_TRANSACTION_DETAILS';
export const WALLET_TRANSACTION_DETAILS = {
  FETCH: `${WALLET_TRANSACTION_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${WALLET_TRANSACTION_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${WALLET_TRANSACTION_DETAILS_PREFIX}_FAILED`,
  ADD: `${WALLET_TRANSACTION_DETAILS_PREFIX}_ADD`,
};

/* REWARD SETTINGS ACTIONS */
const REWARD_SETTINGS_PREFIX = 'REWARD_SETTINGS';
export const REWARD_SETTINGS = {
  FETCH: `${REWARD_SETTINGS_PREFIX}_FETCH`,
  SUCCESS: `${REWARD_SETTINGS_PREFIX}_SUCCESS`,
  FAILED: `${REWARD_SETTINGS_PREFIX}_FAILED`,
  ADD: `${REWARD_SETTINGS_PREFIX}_ADD`,
  UPDATE: `${REWARD_SETTINGS_PREFIX}_UPDATE`,
};

/* STATIC QR ACTIONS */
const STATIC_QR_PREFIX = 'STATIC_QR';
export const STATIC_QR = {
  FETCH: `${STATIC_QR_PREFIX}_FETCH`,
  SUCCESS: `${STATIC_QR_PREFIX}_SUCCESS`,
  FAILED: `${STATIC_QR_PREFIX}_FAILED`,
  ADD: `${STATIC_QR_PREFIX}_ADD`,
  BULK: `${STATIC_QR_PREFIX}_BULK`,
};

/* STATIC QR DETAILS ACTIONS */
const STATIC_QR_DETAILS_PREFIX = 'STATIC_QR_DETAILS';
export const STATIC_QR_DETAILS = {
  FETCH: `${STATIC_QR_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${STATIC_QR_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${STATIC_QR_DETAILS_PREFIX}_FAILED`,
  DELETE: `${STATIC_QR_DETAILS_PREFIX}_DELETE`,
  UPDATE: `${STATIC_QR_DETAILS_PREFIX}_UPDATE`,
};

/* REPORT TEMPLATE ACTIONS */
const REPORT_TEMPLATE_PREFIX = 'REPORT_TEMPLATE';
export const REPORT_TEMPLATE = {
  FETCH: `${REPORT_TEMPLATE_PREFIX}_FETCH`,
  SUCCESS: `${REPORT_TEMPLATE_PREFIX}_SUCCESS`,
  FAILED: `${REPORT_TEMPLATE_PREFIX}_FAILED`,
  ADD: `${REPORT_TEMPLATE_PREFIX}_ADD`,
};

/* REPORT TEMPLATE DETAILS ACTIONS */
const REPORT_TEMPLATE_DETAILS_PREFIX = 'REPORT_TEMPLATE_DETAILS';
export const REPORT_TEMPLATE_DETAILS = {
  FETCH: `${REPORT_TEMPLATE_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${REPORT_TEMPLATE_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${REPORT_TEMPLATE_DETAILS_PREFIX}_FAILED`,
  DELETE: `${REPORT_TEMPLATE_DETAILS_PREFIX}_DELETE`,
  UPDATE: `${REPORT_TEMPLATE_DETAILS_PREFIX}_UPDATE`,
};

/* CUSTOM REPORTS ACTIONS */
const CUSTOM_REPORT_PREFIX = 'CUSTOM_REPORT';
export const CUSTOM_REPORT = {
  FETCH: `${CUSTOM_REPORT_PREFIX}_FETCH`,
  SUCCESS: `${CUSTOM_REPORT_PREFIX}_SUCCESS`,
  FAILED: `${CUSTOM_REPORT_PREFIX}_FAILED`,
  ADD: `${CUSTOM_REPORT_PREFIX}_ADD`,
};

/* RECAPTCHA DETAILS ACTIONS */
const RECAPTCHA_DETAILS_PREFIX = 'RECAPTCHA_DETAILS';
export const RECAPTCHA_DETAILS = {
  FETCH: `${RECAPTCHA_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${RECAPTCHA_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${RECAPTCHA_DETAILS_PREFIX}_FAILED`,
};

/* US ONBOARDING ACTIONS */
const USONBOARDING_PREFIX = 'USONBOARDING';
export const USONBOARDING = {
  FETCH: `${USONBOARDING_PREFIX}_FETCH`,
  SUCCESS: `${USONBOARDING_PREFIX}_SUCCESS`,
  FAILED: `${USONBOARDING_PREFIX}_FAILED`,
  ADD: `${USONBOARDING_PREFIX}_ADD`,
};

/* VENDOR INVOICES ACTIONS */
const VENDORINVOICES_PREFIX = 'VENDORINVOICES';
export const VENDORINVOICES = {
  FETCH: `${VENDORINVOICES_PREFIX}_FETCH`,
  SUCCESS: `${VENDORINVOICES_PREFIX}_SUCCESS`,
  FAILED: `${VENDORINVOICES_PREFIX}_FAILED`,
};

/* VENDOR INVOICEDETAILS ACTIONS */
const VENDOR_INVOICEDETAILS_PREFIX = 'VENDORINVOICEDETAILS';
export const VENDORINVOICEDETAILS = {
  FETCH: `${VENDOR_INVOICEDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${VENDOR_INVOICEDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${VENDOR_INVOICEDETAILS_PREFIX}_FAILED`,
  UPDATE: `${VENDOR_INVOICEDETAILS_PREFIX}_UPDATE`,
  DELETE: `${VENDOR_INVOICEDETAILS_PREFIX}_DELETE`,
  CANCEL: `${VENDOR_INVOICEDETAILS_PREFIX}_CANCEL`,
  CONTACT_FETCH: `${VENDOR_INVOICEDETAILS_PREFIX}_CONTACT_FETCH`,
  CONTACT_SUCCESS: `${VENDOR_INVOICEDETAILS_PREFIX}_CONTACT_SUCCESS`,
  CONTACT_FAILED: `${VENDOR_INVOICEDETAILS_PREFIX}_CONTACT_FAILED`,
  INVOICE_ADD: `${VENDOR_INVOICEDETAILS_PREFIX}_INVOICE_ADD`,
  CONTACT_ADD: `${VENDOR_INVOICEDETAILS_PREFIX}_CONTACT_ADD`,
  CONTACT_ADD_SUCCESS: `${VENDOR_INVOICEDETAILS_PREFIX}_CONTACT_ADD_SUCCESS`,
  NOTIFY_SUCCESS: `${VENDOR_INVOICEDETAILS_PREFIX}_NOTIFY_SUCCESS`,
  INVOICE_PAY: `${VENDOR_INVOICEDETAILS_PREFIX}_INVOICE_PAY`,
  OPEN_PAY_MODAL: `${VENDOR_INVOICEDETAILS_PREFIX}_INVOICE_PAY_OPEN_PAY_MODAL`,
};

/* BENEFICIARY ACTIONS */
const BENEFICIARY_PREFIX = 'BENEFICIARY';
export const BENEFICIARY = {
  FETCH: `${BENEFICIARY_PREFIX}_FETCH`,
  SUCCESS: `${BENEFICIARY_PREFIX}_SUCCESS`,
  FAILED: `${BENEFICIARY_PREFIX}_FAILED`,
  ADD: `${BENEFICIARY_PREFIX}_ADD`,
  UPDATE: `${BENEFICIARY_PREFIX}_UPDATE`,
  DELETE: `${BENEFICIARY_PREFIX}_DELETE`,
};

/* BENEFICIARY DETAILS ACTIONS */
const BENEFICIARYDETAILS_PREFIX = 'BENEFICIARYDETAILS';
export const BENEFICIARYDETAILS = {
  FETCH: `${BENEFICIARYDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${BENEFICIARYDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${BENEFICIARYDETAILS_PREFIX}_FAILED`,
};

/* WALLET INVOICES ACTIONS */
const WALLETINVOICES_PREFIX = 'WALLETINVOICES';
export const WALLETINVOICES = {
  FETCH: `${WALLETINVOICES_PREFIX}_FETCH`,
  SUCCESS: `${WALLETINVOICES_PREFIX}_SUCCESS`,
  FAILED: `${WALLETINVOICES_PREFIX}_FAILED`,
};

/* WALLET INVOICEDETAILS ACTIONS */
const WALLET_INVOICEDETAILS_PREFIX = 'WALLETINVOICEDETAILS';
export const WALLETINVOICEDETAILS = {
  FETCH: `${WALLET_INVOICEDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${WALLET_INVOICEDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${WALLET_INVOICEDETAILS_PREFIX}_FAILED`,
  UPDATE: `${WALLET_INVOICEDETAILS_PREFIX}_UPDATE`,
  DELETE: `${WALLET_INVOICEDETAILS_PREFIX}_DELETE`,
  CANCEL: `${WALLET_INVOICEDETAILS_PREFIX}_CANCEL`,
  BENEFICIARY_FETCH: `${WALLET_INVOICEDETAILS_PREFIX}_BENEFICIARY_FETCH`,
  BENEFICIARY_SUCCESS: `${WALLET_INVOICEDETAILS_PREFIX}_BENEFICIARY_SUCCESS`,
  BENEFICIARY_FAILED: `${WALLET_INVOICEDETAILS_PREFIX}_BENEFICIARY_FAILED`,
  INVOICE_ADD: `${WALLET_INVOICEDETAILS_PREFIX}_INVOICE_ADD`,
  BENEFICIARY_ADD: `${WALLET_INVOICEDETAILS_PREFIX}_BENEFICIARY_ADD`,
  BENEFICIARY_ADD_SUCCESS: `${WALLET_INVOICEDETAILS_PREFIX}_BENEFICIARY_ADD_SUCCESS`,
  NOTIFY_SUCCESS: `${WALLET_INVOICEDETAILS_PREFIX}_NOTIFY_SUCCESS`,
  INVOICE_PAY: `${WALLET_INVOICEDETAILS_PREFIX}_INVOICE_PAY`,
};

/* WOOCOMMERCE ACTIONS */
const WOOCOMMERCE_PREFIX = 'WOOCOMMERCE';
export const WOOCOMMERCE = {
  FETCH: `${WOOCOMMERCE_PREFIX}_FETCH`,
  ADD: `${WOOCOMMERCE_PREFIX}_ADD`,
  UPDATE: `${WOOCOMMERCE_PREFIX}_UPDATE`,
  SUCCESS: `${WOOCOMMERCE_PREFIX}_SUCCESS`,
  FAILED: `${WOOCOMMERCE_PREFIX}_FAILED`,
  LIST_FETCH: `${WOOCOMMERCE_PREFIX}_LIST_FETCH`,
  LIST_SUCCESS: `${WOOCOMMERCE_PREFIX}_LIST_SUCCESS`,
  LIST_FAILED: `${WOOCOMMERCE_PREFIX}_LIST_FAILED`,
  FETCH_ORDER_BY_ID: `${WOOCOMMERCE_PREFIX}_FETCH_ORDER_BY_ID`,
  ORDER_SUCCESS_BY_ID: `${WOOCOMMERCE_PREFIX}_ORDER_SUCCESS_BY_ID`,
  FETCH_TIMELINE: `${WOOCOMMERCE_PREFIX}_FETCH_TIMELINE`,
  SUCCESS_TIMELINE: `${WOOCOMMERCE_PREFIX}_SUCCESS_TIMELINE`,
};

/* WOOCOMMERCE DETAILS ACTIONS */
const WOOCOMMERCE_DETAILS_PREFIX = 'WOOCOMMERCE_DETAILS';
export const WOOCOMMERCE_DETAILS = {
  FETCH: `${WOOCOMMERCE_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${WOOCOMMERCE_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${WOOCOMMERCE_DETAILS_PREFIX}_FAILED`,
  FETCH_TIMELINE: `${WOOCOMMERCE_DETAILS_PREFIX}_FETCH_TIMELINE`,
  SUCCESS_TIMELINE: `${WOOCOMMERCE_DETAILS_PREFIX}_SUCCESS_TIMELINE`,
  UPDATE_ORDER_STATUS: `${WOOCOMMERCE_DETAILS_PREFIX}_UPDATE_ORDER_STATUS`,
  UPDATE_ORDER_SUCCESS: `${WOOCOMMERCE_DETAILS_PREFIX}_UPDATE_ORDER_SUCCESS`,
};

/* CLOVER RECEIPTS */
const CLOVER_RECEIPTS_PREFIX = 'CLOVER_RECEIPTS';
export const CLOVER_RECEIPTS = {
  FETCH: `${CLOVER_RECEIPTS_PREFIX}_FETCH`,
  SUCCESS: `${CLOVER_RECEIPTS_PREFIX}_SUCCESS`,
  FAILED: `${CLOVER_RECEIPTS_PREFIX}_FAILED`,
};
/* ORDER RECEIPTS */
const ORDER_RECEIPTS_PREFIX = 'ORDER_RECEIPTS';
export const ORDER_RECEIPTS = {
  FETCH: `${ORDER_RECEIPTS_PREFIX}_FETCH`,
  SUCCESS: `${ORDER_RECEIPTS_PREFIX}_SUCCESS`,
  FAILED: `${ORDER_RECEIPTS_PREFIX}_FAILED`,
};

/* CATALOGs */
const CATALOG_PREFIX = 'CATALOG';
export const CATALOG = {
  FETCH: `${CATALOG_PREFIX}_FETCH`,
  SUCCESS: `${CATALOG_PREFIX}_SUCCESS`,
  FAILED: `${CATALOG_PREFIX}_FAILED`,
  ADD: `${CATALOG_PREFIX}_ADD`,
};

/* CATALOG ORDERS */
const CATALOG_ORDERS_PREFIX = 'CATALOG_ORDERS';
export const CATALOG_ORDERS = {
  FETCH: `${CATALOG_ORDERS_PREFIX}_FETCH`,
  SUCCESS: `${CATALOG_ORDERS_PREFIX}_SUCCESS`,
  FAILED: `${CATALOG_ORDERS_PREFIX}_FAILED`,
};

/* ADMIN CATALOGs */
const ADMIN_CATALOG_PREFIX = 'ADMIN_CATALOG';
export const ADMIN_CATALOG = {
  FETCH: `${ADMIN_CATALOG_PREFIX}_FETCH`,
  SUCCESS: `${ADMIN_CATALOG_PREFIX}_SUCCESS`,
  FAILED: `${ADMIN_CATALOG_PREFIX}_FAILED`,
  ADD: `${ADMIN_CATALOG_PREFIX}_ADD`,
  UPDATE: `${ADMIN_CATALOG_PREFIX}_UPDATE`,
  DELETE: `${ADMIN_CATALOG_PREFIX}_DELETE`,
};

/* ADMIN ORDERS */
const ADMIN_ORDERS_PREFIX = 'ADMIN_ORDERS';
export const ADMIN_ORDERS = {
  FETCH: `${ADMIN_ORDERS_PREFIX}_FETCH`,
  SUCCESS: `${ADMIN_ORDERS_PREFIX}_SUCCESS`,
  FAILED: `${ADMIN_ORDERS_PREFIX}_FAILED`,
  UPDATE: `${ADMIN_ORDERS_PREFIX}_UPDATE`,
};

/* INVENTORY ORDERS */
const INVENTORY_ORDERS_PREFIX = 'INVENTORY_ORDERS';
export const INVENTORY_ORDERS = {
  FETCH: `${INVENTORY_ORDERS_PREFIX}_FETCH`,
  SUCCESS: `${INVENTORY_ORDERS_PREFIX}_SUCCESS`,
  FAILED: `${INVENTORY_ORDERS_PREFIX}_FAILED`,
  FETCH_FILTER_ORDERS: `${INVENTORY_ORDERS_PREFIX}_FETCH_FILTER_ORDERS`,
  ORDERS_FILTER_SUCCESS: `${INVENTORY_ORDERS_PREFIX}_ORDERS_FILTER_SUCCESS`,
  ORDERS_SEARCH_SUCCESS: `${INVENTORY_ORDERS_PREFIX}_ORDERS_SEARCH_SUCCESS`,
};

/* INVENTORY ITEMS */
const INVENTORY_ITEMS_PREFIX = 'INVENTORY_ITEMS';
export const INVENTORY_ITEMS = {
  FETCH: `${INVENTORY_ITEMS_PREFIX}_FETCH`,
  SUCCESS: `${INVENTORY_ITEMS_PREFIX}_SUCCESS`,
  ITEM_ADD_SUCCESS: `${INVENTORY_ITEMS_PREFIX}_ITEM_ADD_SUCCESS`,
  FAILED: `${INVENTORY_ITEMS_PREFIX}_FAILED`,
  LIST_FETCH: `${INVENTORY_ITEMS_PREFIX}_LIST_FETCH`,
  LIST_SUCCESS: `${INVENTORY_ITEMS_PREFIX}_LIST_SUCCESS`,
  LIST_FAILED: `${INVENTORY_ITEMS_PREFIX}_LIST_FAILED`,
  MODIFIER_LIST_FETCH: `${INVENTORY_ITEMS_PREFIX}_MODIFIER_LIST_FETCH`,
  MODIFIER_LIST_SUCCESS: `${INVENTORY_ITEMS_PREFIX}_MODIFIER_LIST_SUCCESS`,
  MODIFIER_LIST_FAILED: `${INVENTORY_ITEMS_PREFIX}_MODIFIER_LIST_FAILED`,
  UPDATE: `${INVENTORY_ITEMS_PREFIX}_UPDATE`,
  ADD: `${INVENTORY_ITEMS_PREFIX}_ADD`,
  DELETE: `${INVENTORY_ITEMS_PREFIX}_DELETE`,
  ORDER_LIST_FETCH: `${INVENTORY_ITEMS_PREFIX}_ORDER_LIST_FETCH`,
  ORDER_LIST_SUCCESS: `${INVENTORY_ITEMS_PREFIX}_ORDER_LIST_SUCCESS`,
  ORDER_LIST_FAILED: `${INVENTORY_ITEMS_PREFIX}_ORDER_LIST_FAILED`,
  ORDER_LIST_UPDATE: `${INVENTORY_ITEMS_PREFIX}_ORDER_LIST_UPDATE`,
  BULK_UPDATE_ITEM: `${INVENTORY_ITEMS_PREFIX}_BULK_UPDATE_ITEM`,
  ORDER_LIST_SEARCH: `${INVENTORY_ORDERS_PREFIX}_ORDER_LIST_SEARCH`,
};

/* INVENTORY CATEGORIES */
const INVENTORY_CATEGORIES_PREFIX = 'INVENTORY_CATEGORIES';
export const INVENTORY_CATEGORIES = {
  FETCH: `${INVENTORY_CATEGORIES_PREFIX}_FETCH`,
  SUCCESS: `${INVENTORY_CATEGORIES_PREFIX}_SUCCESS`,
  FAILED: `${INVENTORY_CATEGORIES_PREFIX}_FAILED`,
  UPDATE: `${INVENTORY_CATEGORIES_PREFIX}_UPDATE`,
  ADD: `${INVENTORY_CATEGORIES_PREFIX}_ADD`,
  DELETE: `${INVENTORY_CATEGORIES_PREFIX}_DELETE`,
  ORDER_CATEGORIES_FETCH: `${INVENTORY_ITEMS_PREFIX}_ORDER_CATEGORIES_FETCH`,
  ORDER_CATEGORIES_SUCCESS: `${INVENTORY_ITEMS_PREFIX}_ORDER_CATEGORIES_SUCCESS`,
  ORDER_CATEGORIES_FAILED: `${INVENTORY_ITEMS_PREFIX}_ORDER_CATEGORIES_FAILED`,
  ORDER_CATEGORIES_UPDATE: `${INVENTORY_ITEMS_PREFIX}_ORDER_CATEGORIES_UPDATE`,
  BULK_UPDATE: `${INVENTORY_ITEMS_PREFIX}_BULK_UPDATE`,
  FETCH_OPERATING_HOURS: `${INVENTORY_ITEMS_PREFIX}_FETCH_OPERATING_HOURS`,
  FETCH_OPERATING_HOURS_SUCCESS: `${INVENTORY_ITEMS_PREFIX}_FETCH_OPERATING_HOURS_SUCCESS`,
  CATEGORY_SEARCH_SUCCESS: `${INVENTORY_ITEMS_PREFIX}_CATEGORY_SEARCH_SUCCESS`,
  FETCH_CATEGORY: `${INVENTORY_CATEGORIES_PREFIX}_FETCH_CATEGORY`,
  SUCCESS_CATEGORY: `${INVENTORY_CATEGORIES_PREFIX}_SUCCESS_CATEGORY`,
  FAILED_CATEGORY: `${INVENTORY_CATEGORIES_PREFIX}_FAILED_CATEGORY`,
};

/* INVENTORY MODIFIERS */
const INVENTORY_MODIFIERS_PREFIX = 'INVENTORY_MODIFIERS';
export const INVENTORY_MODIFIERS = {
  FETCH: `${INVENTORY_MODIFIERS_PREFIX}_FETCH`,
  SUCCESS: `${INVENTORY_MODIFIERS_PREFIX}_SUCCESS`,
  FAILED: `${INVENTORY_MODIFIERS_PREFIX}_FAILED`,
  UPDATE: `${INVENTORY_MODIFIERS_PREFIX}_UPDATE`,
  ADD: `${INVENTORY_MODIFIERS_PREFIX}_ADD`,
  DELETE: `${INVENTORY_MODIFIERS_PREFIX}_DELETE`,
  ORDER_MODIFIERS_FETCH: `${INVENTORY_ITEMS_PREFIX}_ORDER_MODIFIERS_FETCH`,
  ORDER_MODIFIERS_SUCCESS: `${INVENTORY_ITEMS_PREFIX}_ORDER_MODIFIERS_SUCCESS`,
  ORDER_MODIFIERS_FAILED: `${INVENTORY_ITEMS_PREFIX}_ORDER_MODIFIERS_FAILED`,
  ORDER_MODIFIERS_UPDATE: `${INVENTORY_ITEMS_PREFIX}_ORDER_MODIFIERS_UPDATE`,
  ORDER_MODIFIERS_DELETE: `${INVENTORY_ITEMS_PREFIX}_ORDER_MODIFIERS_DELETE`,
};

/* INVENTORY ORDER DETAILS */
const INVENTORY_ORDERS_DETAILS_PREFIX = 'INVENTORY_ORDERS_DETAILS';
export const INVENTORY_ORDERS_DETAILS = {
  FETCH: `${INVENTORY_ORDERS_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${INVENTORY_ORDERS_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${INVENTORY_ORDERS_DETAILS_PREFIX}_FAILED`,
  REFUND: `${INVENTORY_ORDERS_DETAILS_PREFIX}_REFUND`,
  REFUND_ITEM: `${INVENTORY_ORDERS_DETAILS_PREFIX}_REFUND_ITEM`,
  DELETE_ITEM: `${INVENTORY_ORDERS_DETAILS_PREFIX}_DELETE_ITEM`,
  DELETE: `${INVENTORY_ORDERS_DETAILS_PREFIX}_DELETE`,
  PAYMENTS_FETCH: `${INVENTORY_ORDERS_DETAILS_PREFIX}_PAYMENTS_FETCH`,
  PAYMENTS_SUCCESS: `${INVENTORY_ORDERS_DETAILS_PREFIX}_PAYMENTS_SUCCESS`,
  ADDITEMS_ORDER: `${INVENTORY_ORDERS_DETAILS_PREFIX}_ADDITEMS_ORDER`,
};

/* INVENTORY ITEM DETAILS */
const INVENTORY_ITEMS_DETAILS_PREFIX = 'INVENTORY_ITEMS_DETAILS';
export const INVENTORY_ITEMS_DETAILS = {
  FETCH: `${INVENTORY_ITEMS_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${INVENTORY_ITEMS_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${INVENTORY_ITEMS_DETAILS_PREFIX}_FAILED`,
};

/* INVENTORY CATEGORIES DETAILS */
const INVENTORY_CATEGORIES_DETAILS_PREFIX = 'INVENTORY_CATEGORIES_DETAILS';
export const INVENTORY_CATEGORIES_DETAILS = {
  FETCH: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_FAILED`,
  FETCH_ALLITEM_CATEGORY: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_ALL_ITEM`,
  CATEGORY_ITEM_FETCH: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_CATEGORY_ITEM_FETCH`,
  CATEGORY_ITEM_SUCCESS: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_CATEGORY_ITEM_SUCCESS`,
  CATEGORY_ITEM_FAILED: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_CATEGORY_ITEM_FAILED`,
  CATEGORY_ITEM_ADD: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_CATEGORY_ITEM_ADD`,
  GET_SELECTED_CATEOGARY_ITEM: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_ GET_SELECTED_CATEOGARY_ITEM`,
  FETCH_OPERATING_HOURS: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_FETCH_OPERATING_HOURS`,
  FETCH_ALL_SHOP: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_FETCH_ALL_SHOP`,
  FETCH_ALL_SHOP_SUCCESS: `${INVENTORY_CATEGORIES_DETAILS_PREFIX}_FETCH_ALL_SHOP_SUCCESS`,
};

/* INVENTORY ITEM DETAILS */
const INVENTORY_MODIFIERS_DETAILS_PREFIX = 'INVENTORY_MODIFIERS_DETAILS';
export const INVENTORY_MODIFIERS_DETAILS = {
  FETCH: `${INVENTORY_MODIFIERS_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${INVENTORY_MODIFIERS_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${INVENTORY_MODIFIERS_DETAILS_PREFIX}_FAILED`,
  ADD_MODIFIER: `${INVENTORY_MODIFIERS_DETAILS_PREFIX}_ADD_MODIFIER`,
  UPDATE_MODIFIER: `${INVENTORY_MODIFIERS_DETAILS_PREFIX}_UPDATE_MODIFIER`,
  DELETE_MODIFIER: `${INVENTORY_MODIFIERS_DETAILS_PREFIX}_DELETE_MODIFIER`,
};

/* EVENTS ACTIONS */
const EVENTS_PREFIX = 'EVENTS';
export const EVENTS = {
  FETCH: `${EVENTS_PREFIX}_FETCH`,
  ADD_EVENT: `${EVENTS_PREFIX}_ADD_EVENT`,
  UPDATE: `${EVENTS_PREFIX}_UPDATE`,
  DELETE: `${EVENTS_PREFIX}_DELETE`,
  SUCCESS: `${EVENTS_PREFIX}_SUCCESS`,
  FAILED: `${EVENTS_PREFIX}_FAILED`,
  SELECTED: `${EVENTS_PREFIX}_SELECTED`,
  FETCH_RECEIPT: `${EVENTS_PREFIX}_FETCH_RECEIPT`,
  RECEIPT_SUCCESS: `${EVENTS_PREFIX}_RECEIPT_SUCCESS`,
};

/* EVENTSDETAILS ACTIONS */
const EVENTSDETAILS_PREFIX = 'EVENTSDETAILS';
export const EVENTSDETAILS = {
  FETCH: `${EVENTSDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${EVENTSDETAILS_PREFIX}_SUCCESS`,
  UPDATE: `${EVENTSDETAILS_PREFIX}_UPDATE`,
  FAILED: `${EVENTSDETAILS_PREFIX}_FAILED`,
  DELETE: `${EVENTSDETAILS_PREFIX}_DELETE`,
  CARDS_SUCCESS: `${EVENTSDETAILS_PREFIX}_CARDS_SUCCESS`,
  CARDS_FETCH: `${EVENTSDETAILS_PREFIX}_CARDS_FETCH`,
  DEACTIVE: `${EVENTSDETAILS_PREFIX}_DEACTIVE`,
  SOLDOUT: `${EVENTSDETAILS_PREFIX}_SOLDOUT`,
  RESUME_SALES: `${EVENTSDETAILS_PREFIX}_RESUME_SALES`,
  OFFLINE_PAYMENTS: `${EVENTSDETAILS_PREFIX}_OFFLINE_PAYMENTS`,
  EVENTS_SLUG_FETCH: `${PAYMENTPAGESDETAILS_PREFIX}_EVENTS_SLUG_FETCH`,
  EVENTS_SLUG_SUCCESS: `${PAYMENTPAGESDETAILS_PREFIX}_EVENTS_SLUG_SUCCESS`,
  FETCH_OFFLINE_PAYMENTS: `${PAYMENTPAGESDETAILS_PREFIX}_FETCH_OFFLINE_PAYMENTS`,
  SUCCESS_OFFLINE_PAYMENTS: `${PAYMENTPAGESDETAILS_PREFIX}_SUCCESS_OFFLINE_PAYMENTS`,
  CLEAR_EVENT_DETAILS: `${EVENTSDETAILS_PREFIX}_CLEAR_EVENT_DETAILS`,
  PAGE_NOTIFY: `${EVENTSDETAILS_PREFIX}_PAGE_NOTIFY`,
  SEATED_NOTIFY: `${EVENTSDETAILS_PREFIX}_SEATED_NOTIFY`,
};

/* INVENTORY ORDER TYPES */
const INVENTORY_ORDERTYPES_PREFIX = 'INVENTORY_ORDERTYPES';
export const INVENTORY_ORDERTYPES = {
  FETCH: `${INVENTORY_ORDERTYPES_PREFIX}_FETCH`,
  SUCCESS: `${INVENTORY_ORDERTYPES_PREFIX}_SUCCESS`,
  FAILED: `${INVENTORY_ORDERTYPES_PREFIX}_FAILED`,
  UPDATE: `${INVENTORY_ORDERTYPES_PREFIX}_UPDATE`,
  ADD: `${INVENTORY_ORDERTYPES_PREFIX}_ADD`,
  DELETE: `${INVENTORY_ORDERTYPES_PREFIX}_DELETE`,
};

/* PRINTER LABEL TYPES */
const PRINTERLABEL_PREFIX = 'PRINTER_LABEL';
export const PRINTER_LABEL = {
  FETCH: `${PRINTERLABEL_PREFIX}_FETCH`,
  SUCCESS: `${PRINTERLABEL_PREFIX}_SUCCESS`,
  FAILED: `${PRINTERLABEL_PREFIX}_FAILED`,
  UPDATE: `${PRINTERLABEL_PREFIX}_UPDATE`,
  ADD: `${PRINTERLABEL_PREFIX}_ADD`,
  DELETE: `${PRINTERLABEL_PREFIX}_DELETE`,
  FETCH_LIST: `${PRINTERLABEL_PREFIX}_FETCH_LIST`,
  SUCCESS_LIST: `${PRINTERLABEL_PREFIX}_SUCCESS_LIST`,
  FAILED_LIST: `${PRINTERLABEL_PREFIX}_FAILED_LIST`,
};

/* PRINTER LABEL DETAILS */
const PRINTERLABEL_DETAILS_PREFIX = 'PRINTER_LABEL_DETAILS';
export const PRINTER_LABEL_DETAILS = {
  FETCH: `${PRINTERLABEL_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${PRINTERLABEL_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${PRINTERLABEL_DETAILS_PREFIX}_FAILED`,
  LABEL_ITEM_FETCH: `${PRINTERLABEL_DETAILS_PREFIX}_LABEL_ITEM_FETCH`,
  LABEL_ITEM_SUCCESS: `${PRINTERLABEL_DETAILS_PREFIX}_LABEL_ITEM_SUCCESS`,
  LABEL_ITEM_FAILED: `${PRINTERLABEL_DETAILS_PREFIX}_LABEL_ITEM_FAILED`,
  LABEL_ITEM_ADD: `${PRINTERLABEL_DETAILS_PREFIX}_LABEL_ITEM_ADD`,
};

/* SHOPS TYPES */
const SHOPS_PREFIX = 'SHOPS';
export const SHOPS = {
  FETCH: `${SHOPS_PREFIX}_FETCH`,
  SUCCESS: `${SHOPS_PREFIX}_SUCCESS`,
  FAILED: `${SHOPS_PREFIX}_FAILED`,
  UPDATE: `${SHOPS_PREFIX}_UPDATE`,
  ADD: `${SHOPS_PREFIX}_ADD`,
  DELETE: `${SHOPS_PREFIX}_DELETE`,
  RATES_FETCH: `${SHOPS_PREFIX}_RATES_FETCH`,
  RATES_SUCCESS: `${SHOPS_PREFIX}_RATES_SUCCESS`,
  RATES_FAILED: `${SHOPS_PREFIX}_RATES_FAILED`,
  PROMOS_FETCH: `${SHOPS_PREFIX}_PROMOS_FETCH`,
  PROMOS_SUCCESS: `${SHOPS_PREFIX}_PROMOS_SUCCESS`,
  PROMOS_FAILED: `${SHOPS_PREFIX}_PROMOS_FAILED`,
  ASSOCIATE_ADD: `${SHOPS_PREFIX}_ASSOCIATE_ADD`,
  ASSOCIATE_FETCH: `${SHOPS_PREFIX}_ASSOCIATE_FETCH`,
  ASSOCIATE_SUCCESS: `${SHOPS_PREFIX}_ASSOCIATE_SUCCESS`,
  ASSOCIATE_FAILED: `${SHOPS_PREFIX}_ASSOCIATE_FAILED`,
  ASSOCIATE_UPDATE: `${SHOPS_PREFIX}_ASSOCIATE_UPDATE`,
  ASSOCIATE_DELETE: `${SHOPS_PREFIX}_ASSOCIATE_DELETE`,
};

/* SHOPS DETAILS */
const SHOPS_DETAILS_PREFIX = 'SHOPS_DETAILS';
export const SHOPS_DETAILS = {
  FETCH: `${SHOPS_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${SHOPS_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${SHOPS_DETAILS_PREFIX}_FAILED`,
  SHOPS_ITEM_FETCH: `${SHOPS_DETAILS_PREFIX}_SHOPS_ITEM_FETCH`,
  SHOPS_ITEM_SUCCESS: `${SHOPS_DETAILS_PREFIX}_SHOPS_ITEM_SUCCESS`,
  SHOPS_ITEM_FAILED: `${SHOPS_DETAILS_PREFIX}_SHOPS_ITEM_FAILED`,
  SHOPS_ITEM_ADD: `${SHOPS_DETAILS_PREFIX}_SHOPS_ITEM_ADD`,
  SHOPS_SLUG_FETCH: `${SHOPS_DETAILS_PREFIX}_SHOPS_SLUG_FETCH`,
  SHOPS_SLUG_SUCCESS: `${SHOPS_DETAILS_PREFIX}_SHOPS_SLUG_SUCCESS`,
  SHOPS_TIPS_SUCCESS: `${SHOPS_DETAILS_PREFIX}_SHOPS_TIPS_SUCCESS`,
  SHOPS_PROMO_FETCH: `${SHOPS_DETAILS_PREFIX}_SHOPS_PROMO_FETCH`,
  SHOPS_PROMO_SUCCESS: `${SHOPS_DETAILS_PREFIX}_SHOPS_PROMO_SUCCESS`,
  SHOPS_PROMO_FAILED: `${SHOPS_DETAILS_PREFIX}_SHOPS_PROMO_FAILED`,
  SHOPS_PROMO_ADD: `${SHOPS_DETAILS_PREFIX}_SHOPS_PROMO_ADD`,
  SHOPS_PROMO_UPDATE: `${SHOPS_DETAILS_PREFIX}_SHOPS_PROMO_UPDATE`,
  SHOPS_PROMO_DELETE: `${SHOPS_DETAILS_PREFIX}_SHOPS_PROMO_DELETE`,
  SHOPS_TIPS_DELETE: `${SHOPS_DETAILS_PREFIX}_SHOPS_TIPS_DELETE`,
  SHOPS_ALLPROMO_FETCH: `${SHOPS_DETAILS_PREFIX}_SHOPS_ALLPROMO_FETCH`,
  SHOPS_ALLPROMO_SUCCESS: `${SHOPS_DETAILS_PREFIX}_SHOPS_ALLPROMO_SUCCESS`,
  SHOPS_ALLPROMO_FAILED: `${SHOPS_DETAILS_PREFIX}_SHOPS_ALLPROMO_FAILED`,
  SHOPS_ITEM_SEARCH_FETCH: `${SHOPS_DETAILS_PREFIX}_SHOPS_ITEM_SEARCH_FETCH`,
  SHOPS_ITEM_SEARCH_SUCCESS: `${SHOPS_DETAILS_PREFIX}_SHOPS_ITEM_SEARCH_SUCCESS`,
  SHOPS_ITEM_SEARCH_FAILED: `${SHOPS_DETAILS_PREFIX}_SHOPS_ITEM_SEARCH_FAILED`,
  SHOPS_TIPS_ADD: `${SHOPS_DETAILS_PREFIX}_SHOPS_TIPS_ADD`,
  SHOPS_TIPS_FETCH: `${SHOPS_DETAILS_PREFIX}_SHOPS_TIPS_FETCH`,
  SHOPS_TIPS_FAILED: `${SHOPS_DETAILS_PREFIX}_SHOPS_TIPS_FAILED`,
  FETCH_SHOPS_TABLES: `${SHOPS_DETAILS_PREFIX}_FETCH_SHOPS_TABLES`,
  SUCCESS_SHOP_TABLES: `${SHOPS_DETAILS_PREFIX}_SUCCESS_SHOP_TABLES`,
  FAILED_SHOP_TABLES: `${SHOPS_DETAILS_PREFIX}_FAILED_SHOP_TABLES`,
};

/* CLOVER WEBHOOK ACTIONS */
const CLOVER_WEBHOOK_PREFIX = 'CLOVER_WEBHOOK';
export const CLOVER_WEBHOOK = {
  FETCH: `${CLOVER_WEBHOOK_PREFIX}_FETCH`,
  UPDATE: `${CLOVER_WEBHOOK_PREFIX}_UPDATE`,
  SUCCESS: `${CLOVER_WEBHOOK_PREFIX}_SUCCESS`,
  FAILED: `${CLOVER_WEBHOOK_PREFIX}_FAILED`,
  INTERNAL_FETCH: `${CLOVER_WEBHOOK_PREFIX}_INTERNAL_FETCH`,
  INTERNAL_UPDATE: `${CLOVER_WEBHOOK_PREFIX}_INTERNAL_UPDATE`,
  INTERNAL_SUCCESS: `${CLOVER_WEBHOOK_PREFIX}_INTERNAL_SUCCESS`,
  INTERNAL_FAILED: `${CLOVER_WEBHOOK_PREFIX}_INTERNAL_FAILED`,
  BULKIMPORT: `${CLOVER_WEBHOOK_PREFIX}_BULKIMPORT`,
};

/* QUICK SUPPORT ACTIONS */
const QUICKSUPPORT_PREFIX = 'QUICKSUPPORT';
export const QUICKSUPPORT = {
  FETCH: `${QUICKSUPPORT_PREFIX}_FETCH`,
  SUCCESS: `${QUICKSUPPORT_PREFIX}_SUCCESS`,
  FAILED: `${QUICKSUPPORT_PREFIX}_FAILED`,
  ADD: `${QUICKSUPPORT_PREFIX}_ADD`,
  UPDATE: `${QUICKSUPPORT_PREFIX}_UPDATE`,
  DELETE: `${QUICKSUPPORT_PREFIX}_DELETE`,
};

/* FEEDBACK ACTIONS */
const FEEDBACK_PREFIX = 'FEEDBACK';
export const FEEDBACK = {
  FETCH: `${FEEDBACK_PREFIX}_FETCH`,
  ORDER_FEEDBACK_FETCH: `${FEEDBACK_PREFIX}_ORDER_FEEDBACK_FETCH`,
  ORDER_FEEDBACK_FETCH_SUCCESS: `${FEEDBACK_PREFIX}_ORDER_FEEDBACK_FETCH_SUCCESS`,
  SUCCESS: `${FEEDBACK_PREFIX}_SUCCESS`,
  FAILED: `${FEEDBACK_PREFIX}_FAILED`,
  ADD: `${FEEDBACK_PREFIX}_ADD`,
  UPDATE: `${FEEDBACK_PREFIX}_UPDATE`,
  DELETE: `${FEEDBACK_PREFIX}_DELETE`,
};

/* SUBSCRIPTION V2 ACTIONS */
const SUBSCRIPTIONV2_PREFIX = 'SUBSCRIPTIONV2';
export const SUBSCRIPTIONV2 = {
  FETCH: `${SUBSCRIPTIONV2_PREFIX}_FETCH`,
  SUCCESS: `${SUBSCRIPTIONV2_PREFIX}_SUCCESS`,
  FAILED: `${SUBSCRIPTIONV2_PREFIX}_FAILED`,
  ADD: `${SUBSCRIPTIONV2_PREFIX}_ADD`,
  SEARCH_FETCH: `${SUBSCRIPTIONV2_PREFIX}_SEARCH_FETCH`,
  SEARCH_SUCCESS: `${SUBSCRIPTIONV2_PREFIX}_SEARCH_SUCCESS`,
  SEARCH_FAILED: `${SUBSCRIPTIONV2_PREFIX}_SEARCH_FAILED`,
};

/* SUBSCRIPTIONV2 DETAILS ACTIONS */
const SUBSCRIPTIONV2DETAILS_PREFIX = 'SUBSCRIPTIONV2DETAILS';
export const SUBSCRIPTIONV2DETAILS = {
  FETCH: `${SUBSCRIPTIONV2DETAILS_PREFIX}_FETCH`,
  ADD: `${SUBSCRIPTIONV2DETAILS_PREFIX}_ADD`,
  SUCCESS: `${SUBSCRIPTIONV2DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${SUBSCRIPTIONV2DETAILS_PREFIX}_FAILED`,
  DELETE: `${SUBSCRIPTIONV2DETAILS_PREFIX}_DELETE`,
  PAYMENTS_FETCH: `${SUBSCRIPTIONV2DETAILS_PREFIX}_PAYMENTS_FETCH`,
  PAYMENTS_SUCCESS: `${SUBSCRIPTIONV2DETAILS_PREFIX}_PAYMENTS_SUCCESS`,
};


/* PLANV2 ACTIONS */
const PLANV2_PREFIX = 'PLANV2';
export const PLANV2 = {
  FETCH: `${PLANV2_PREFIX}_FETCH`,
  ADD: `${PLANV2_PREFIX}_ADD`,
  SUCCESS: `${PLANV2_PREFIX}_SUCCESS`,
  FAILED: `${PLANV2_PREFIX}_FAILED`,
  SELECTED: `${PLANV2_PREFIX}_SELECTED`,
  DELETE: `${PLANV2_PREFIX}_DELETE`,
};

/* PLANV2 DETAILS ACTIONS */
const PLANV2DETAILS_PREFIX = 'PLANV2DETAILS';
export const PLANV2DETAILS = {
  FETCH: `${PLANV2DETAILS_PREFIX}_FETCH`,
  ADD: `${PLANV2DETAILS_PREFIX}_ADD`,
  SUCCESS: `${PLANV2DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${PLANV2DETAILS_PREFIX}_FAILED`,
  DELETE: `${PLANV2DETAILS_PREFIX}_DELETE`,
  PAYMENTS_FETCH: `${PLANV2DETAILS_PREFIX}_PAYMENTS_FETCH`,
  PAYMENTS_SUCCESS: `${PLANV2DETAILS_PREFIX}_PAYMENTS_SUCCESS`,
};

/* SUBSCRIPTIONBUTTONV2 ACTIONS */
const SUBSCRIPTION_BUTTONV2_PREFIX = 'SUBSCRIPTION_BUTTONV2';
export const SUBSCRIPTION_BUTTONV2 = {
  FETCH: `${SUBSCRIPTION_BUTTONV2_PREFIX}_FETCH`,
  ADD: `${SUBSCRIPTION_BUTTONV2_PREFIX}_ADD`,
  SUCCESS: `${SUBSCRIPTION_BUTTONV2_PREFIX}_SUCCESS`,
  FAILED: `${SUBSCRIPTION_BUTTONV2_PREFIX}_FAILED`,
  SELECTED: `${SUBSCRIPTION_BUTTONV2_PREFIX}_SELECTED`,
  DELETE: `${SUBSCRIPTION_BUTTONV2_PREFIX}_DELETE`,
};

/* SUBSCRIPTIONBUTTONV2 DETAILS ACTIONS */
const SUBSCRIPTION_BUTTONV2DETAILS_PREFIX = 'SUBSCRIPTION_BUTTONV2DETAILS';
export const SUBSCRIPTION_BUTTONV2DETAILS = {
  FETCH: `${SUBSCRIPTION_BUTTONV2DETAILS_PREFIX}_FETCH`,
  ADD: `${SUBSCRIPTION_BUTTONV2DETAILS_PREFIX}_ADD`,
  SUCCESS: `${SUBSCRIPTION_BUTTONV2DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${SUBSCRIPTION_BUTTONV2DETAILS_PREFIX}_FAILED`,
  DELETE: `${SUBSCRIPTION_BUTTONV2DETAILS_PREFIX}_DELETE`,
  PAYMENTS_FETCH: `${SUBSCRIPTION_BUTTONV2DETAILS_PREFIX}_PAYMENTS_FETCH`,
  PAYMENTS_SUCCESS: `${SUBSCRIPTION_BUTTONV2DETAILS_PREFIX}_PAYMENTS_SUCCESS`,
  UPDATE: `${SUBSCRIPTION_BUTTONV2DETAILS_PREFIX}_UPDATE`,
};

/* GET SHOP DETAILS ACTIONS */
const GETSHOPDETAILS_PREFIX = 'GETSHOPDETAILS';
export const GETSHOPDETAILS = {
  FETCH: `${GETSHOPDETAILS_PREFIX}_FETCH`,
  SUCCESS: `${GETSHOPDETAILS_PREFIX}_SUCCESS`,
  FAILED: `${GETSHOPDETAILS_PREFIX}_FAILED`,
  ORDER_FETCH: `${GETSHOPDETAILS_PREFIX}_ORDER_FETCH`,
  ORDER_SUCCESS: `${GETSHOPDETAILS_PREFIX}_ORDER_SUCCESS`,
  ORDER_FAILED: `${GETSHOPDETAILS_PREFIX}_ORDER_FAILED`,
  UPDATE: `${GETSHOPDETAILS_PREFIX}_UPDATE`,
  SENDTOKEN: `${GETSHOPDETAILS_PREFIX}_SENDTOKEN`,
  SHOP_FETCH: `${GETSHOPDETAILS_PREFIX}_SHOP_FETCH`,
  SHOP_SUCCESS: `${GETSHOPDETAILS_PREFIX}_SHOP_SUCCESS`,
  SHOP_FAILED: `${GETSHOPDETAILS_PREFIX}_SHOP_FAILED`,
  FULLFILL_FETCH: `${GETSHOPDETAILS_PREFIX}_FULLFILL_FETCH`,
  FULLFILL_SUCCESS: `${GETSHOPDETAILS_PREFIX}_FULLFILL_SUCCESS`,
  FULLFILL_FAILED: `${GETSHOPDETAILS_PREFIX}_FULLFILL_FAILED`,
  UPDATE_FULLFILL: `${GETSHOPDETAILS_PREFIX}_UPDATE_FULLFILL`,
};

/* DINING ACTIONS */
const DINING_PREFIX = 'DINING';
export const DINING = {
  SECTION_FETCH: `${DINING_PREFIX}_SECTION_FETCH`,
  SECTION_ADD: `${DINING_PREFIX}_SECTION_ADD`,
  SECTION_UPDATE: `${DINING_PREFIX}_SECTION_UPDATE`,
  SECTION_DELETE: `${DINING_PREFIX}_SECTION_DELETE`,
  SECTION_SUCCESS: `${DINING_PREFIX}_SECTION_SUCCESS`,
  SECTION_FAILED: `${DINING_PREFIX}_SECTION_FAILED`,
  TABLE_FETCH: `${DINING_PREFIX}_TABLE_FETCH`,
  TABLE_ADD: `${DINING_PREFIX}_TABLE_ADD`,
  TABLE_SUCCESS: `${DINING_PREFIX}_TABLE_SUCCESS`,
  TABLE_FAILED: `${DINING_PREFIX}_TABLE_FAILED`,
  TABLE_UPDATE: `${DINING_PREFIX}_TABLE_UPDATE`,
  TABLE_DELETE: `${DINING_PREFIX}_TABLE_DELETE`,
  TABLE_DETAILS: `${DINING_PREFIX}_TABLE_DETAILS`,
  ASSIGNTABLE_FETCH: `${DINING_PREFIX}_ASSIGNTABLE_FETCH`,
  ASSIGNTABLE_SUCCESS: `${DINING_PREFIX}_ASSIGNTABLE_SUCCESS`,
  ASSIGNTABLE_FAILED: `${DINING_PREFIX}_ASSIGNTABLE_FAILED`,
  ASSIGN_TABLE: `${DINING_PREFIX}_ASSIGN_TABLE`,
  ASSIGN_TABLE_UPDATE: `${DINING_PREFIX}_ASSIGN_TABLE_UPDATE`,
  ASSIGN_TABLE_DELETE: `${DINING_PREFIX}_ASSIGN_TABLE_DELETE`,
};

/* CUSTOM DASHBOARD ACTIONS */
const CUSTOM_DASHBOARD_PREFIX = 'CUSTOM_DASHBOARD';
export const CUSTOM_DASHBOARD = {
  FETCH: `${CUSTOM_DASHBOARD_PREFIX}_FETCH`,
  ADD: `${CUSTOM_DASHBOARD_PREFIX}_ADD`,
  SUCCESS: `${CUSTOM_DASHBOARD_PREFIX}_SUCCESS`,
  FAILED: `${CUSTOM_DASHBOARD_PREFIX}_FAILED`,
  UPDATE: `${CUSTOM_DASHBOARD_PREFIX}_UPDATE`,
};

/* CLOVER SYNC ACTIONS */
const CLOVER_SYNC_PREFIX = 'CLOVER_SYNC';
export const CLOVER_SYNC = {
  FETCH: `${CLOVER_SYNC_PREFIX}_FETCH`,
  SUCCESS: `${CLOVER_SYNC_PREFIX}_SUCCESS`,
  FAILED: `${CLOVER_SYNC_PREFIX}_FAILED`,
  ADD: `${CLOVER_SYNC_PREFIX}_ADD`,
  UPDATE: `${CLOVER_SYNC_PREFIX}_UPDATE`,
};

/* DINING ACTIONS */
const DINING_LIST_PREFIX = 'DININGLIST';
export const DININGLIST = {
  TABLE_FETCH: `${DINING_LIST_PREFIX}_TABLE_FETCH`,
  TABLE_FAILED: `${DINING_LIST_PREFIX}_TABLE_FAILED`,
  TABLE_SUCCESS: `${DINING_LIST_PREFIX}_TABLE_SUCCESS`,
};

/* INVENTORY RESERVATIONS ACTIONS */
const INVENTORY_RESERVATIONS_PREFIX = 'INVENTORY_RESERVATIONS';
export const INVENTORY_RESERVATIONS = {
  FETCH: `${INVENTORY_RESERVATIONS_PREFIX}_FETCH`,
  ADD: `${INVENTORY_RESERVATIONS_PREFIX}_ADD`,
  SUCCESS: `${INVENTORY_RESERVATIONS_PREFIX}_SUCCESS`,
  FAILED: `${INVENTORY_RESERVATIONS_PREFIX}_FAILED`,
  SELECTED: `${INVENTORY_RESERVATIONS_PREFIX}_SELECTED`,
  DELETE: `${INVENTORY_RESERVATIONS_PREFIX}_DELETE`,
  FETCH_FILTER_RESERVATIONS: `${INVENTORY_RESERVATIONS_PREFIX}_FETCH_FILTER_RESERVATIONS`,
  RESERVATIONS_FILTER_SUCCESS: `${INVENTORY_RESERVATIONS_PREFIX}_RESERVATIONS_FILTER_SUCCESS`,
  FETCH_HOLD_RESERVATIONS: `${INVENTORY_RESERVATIONS_PREFIX}_FETCH_HOLD_RESERVATIONS`,
  HOLD_RESERVATIONS_SUCCESS: `${INVENTORY_RESERVATIONS_PREFIX}_HOLD_RESERVATIONS_SUCCESS`,
  FETCH_SEARCH_RESERVATIONS: `${INVENTORY_RESERVATIONS_PREFIX}_FETCH_SEARCH_RESERVATIONS`,
};

/* INVENTORY RESERVATIONS DETAILS */
const INVENTORY_RESERVATIONS_DETAILS_PREFIX = 'INVENTORY_RESERVATIONS_DETAILS';
export const INVENTORY_RESERVATIONS_DETAILS = {
  FETCH: `${INVENTORY_RESERVATIONS_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${INVENTORY_RESERVATIONS_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${INVENTORY_RESERVATIONS_DETAILS_PREFIX}_FAILED`,
  DELETE: `${INVENTORY_RESERVATIONS_DETAILS_PREFIX}_DELETE`,
};

/* INVENTORY PREORDERS ACTIONS */
const INVENTORY_PREORDERS_PREFIX = 'INVENTORY_PREORDERS';
export const INVENTORY_PREORDERS = {
  FETCH: `${INVENTORY_PREORDERS_PREFIX}_FETCH`,
  SUCCESS: `${INVENTORY_PREORDERS_PREFIX}_SUCCESS`,
  FAILED: `${INVENTORY_PREORDERS_PREFIX}_FAILED`,
  FETCH_FILTER_PREORDERS: `${INVENTORY_PREORDERS_PREFIX}_FETCH_FILTER_PREORDERS`,
  PREORDERS_FILTER_SUCCESS: `${INVENTORY_PREORDERS_PREFIX}_PREORDERS_FILTER_SUCCESS`,
};

/* CUSTOMER SUPPORT ACTIONS */
const CUSTOMER_SUPPORT_PREFIX = 'CUSTOMER_SUPPORT';
export const CUSTOMER_SUPPORT = {
  FETCH_SHOPS: `${CUSTOMER_SUPPORT_PREFIX}_FETCH_SHOPS`,
  FETCH_SUPPORTS: `${CUSTOMER_SUPPORT_PREFIX}_FETCH_SUPPORTS`,
  SHOPS_SUCCESS: `${CUSTOMER_SUPPORT_PREFIX}_SHOPS_SUCCESS`,
  SUPPORTS_SUCCESS: `${CUSTOMER_SUPPORT_PREFIX}_SUPPORTS_SUCCESS`,
  SUPPORTS_UPDATE: `${CUSTOMER_SUPPORT_PREFIX}_SUPPORTS_UPDATE`,
  FAILED: `${CUSTOMER_SUPPORT_PREFIX}_FAILED`,
  FETCH_FILTER_SUPPORTS: `${CUSTOMER_SUPPORT_PREFIX}_FETCH_FILTER_SUPPORTS`,
  SUPPORTS_FILTER_SUCCESS: `${CUSTOMER_SUPPORT_PREFIX}_SUPPORTS_FILTER_SUCCESS`,
  QUICKSUPPORT_FETCH: `${CUSTOMER_SUPPORT_PREFIX}_QUICKSUPPORT_FETCH`,
  QUICKSUPPORT_SUCCESS: `${CUSTOMER_SUPPORT_PREFIX}_QUICKSUPPORT_SUCCESS`,
  QUICKSUPPORT_FAILED: `${CUSTOMER_SUPPORT_PREFIX}_QUICKSUPPORT_FAILED`,
};

/* SERVICERATE ACTIONS */
const SERVICERATE_PREFIX = 'SERVICERATE';
export const SERVICERATE = {
  FETCH: `${SERVICERATE_PREFIX}_FETCH`,
  ADD: `${SERVICERATE_PREFIX}_ADD`,
  SUCCESS: `${SERVICERATE_PREFIX}_SUCCESS`,
  FAILED: `${SERVICERATE_PREFIX}_FAILED`,
  DELETE: `${SERVICERATE_PREFIX}_DELETE`,
  UPDATE: `${SERVICERATE_PREFIX}_UPDATE`,
};

/* QUICK SUPPORT SUMMARY ACTIONS */
const QUICK_SUPPORT_SUMMARY_PREFIX = 'QUICK_SUPPORT_SUMMARY';
export const QUICK_SUPPORT_SUMMARY = {
  FETCH: `${QUICK_SUPPORT_SUMMARY_PREFIX}_FETCH`,
  SUCCESS: `${QUICK_SUPPORT_SUMMARY_PREFIX}_SUCCESS`,
  FAILED: `${QUICK_SUPPORT_SUMMARY_PREFIX}_FAILED`,
  UPDATE: `${QUICK_SUPPORT_SUMMARY_PREFIX}_UPDATE`,
  FETCH_SECTION: `${QUICK_SUPPORT_SUMMARY_PREFIX}_FETCH_SECTION`,
  SUCCESS_SECTION: `${QUICK_SUPPORT_SUMMARY_PREFIX}_SUCCESS_SECTION`,
  FAILED_SECTION: `${QUICK_SUPPORT_SUMMARY_PREFIX}_FAILED_SECTION`,
};

/* TABLE DETAILS */
const TABLE_DETAILS_PREFIX = 'TABLE_DETAILS';
export const TABLE_DETAILS = {
  FETCH: `${TABLE_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${TABLE_DETAILS_PREFIX}_SUCCESS`,
  FAILED: `${TABLE_DETAILS_PREFIX}_FAILED`,
  FETCH_STAFF: `${TABLE_DETAILS_PREFIX}_FETCH_STAFF`,
  SUCCESS_STAFF: `${TABLE_DETAILS_PREFIX}_SUCCESS_STAFF`,
  FAILED_STAFF: `${TABLE_DETAILS_PREFIX}_FAILED_STAFF`,
  FETCH_GUEST: `${TABLE_DETAILS_PREFIX}_FETCH_GUEST`,
  SUCCESS_GUEST: `${TABLE_DETAILS_PREFIX}_SUCCESS_GUEST`,
  FAILED_GUEST: `${TABLE_DETAILS_PREFIX}_FAILED_GUEST`,
};

/* MENUS */
const MENUS_PREFIX = 'MENUS';
export const MENUS = {
  FETCH: `${MENUS_PREFIX}_FETCH`,
  SUCCESS: `${MENUS_PREFIX}_SUCCESS`,
  FAILED: `${MENUS_PREFIX}_FAILED`,
  DELETE: `${MENUS_PREFIX}_DELETE`,
  FETCH_OPERATING_HOURS: `${MENUS_PREFIX}_FETCH_OPERATING_HOURS`,
  SUCCESS_OPERATING_HOURS: `${MENUS_PREFIX}_SUCCESS_OPERATING_HOURS`,
  FAILED_OPERATING_HOURS: `${MENUS_PREFIX}_FAILED_OPERATING_HOURS`,
  ADD_MENUS: `${MENUS_PREFIX}_ADD_MENUS`,
  DELETE_MENUS: `${MENUS_PREFIX}_DELETE_MENUS`,
  UPDATE_MENUS: `${MENUS_PREFIX}_UPDATE_MENUS`,
};

/* OPERATING HOURS */
const OPERATINGHOURS_PREFIX = 'OPERATINGHOURS';
export const OPERATINGHOURS = {
  FETCH: `${OPERATINGHOURS_PREFIX}_FETCH`,
  SUCCESS: `${OPERATINGHOURS_PREFIX}_SUCCESS`,
  FAILED: `${OPERATINGHOURS_PREFIX}_FAILED`,
  DELETE: `${OPERATINGHOURS_PREFIX}_DELETE`,
  ADD_OPERATING_HOURS: `${MENUS_PREFIX}_ADD_OPERATING_HOURS`,
  DELETE_OPERATING_HOURS: `${MENUS_PREFIX}_DELETE_OPERATING_HOURS`,
  UPDATE_OPERATING_HOURS: `${MENUS_PREFIX}_UPDATE_OPERATING_HOURS`,
};

/* ADVANCE FEES */
const ADVANCEFEES_PREFIX = 'ADVANCEFEES';
export const ADVANCEFEES = {
  FETCH: `${ADVANCEFEES_PREFIX}_FETCH`,
  SUCCESS: `${ADVANCEFEES_PREFIX}_SUCCESS`,
  FAILED: `${ADVANCEFEES_PREFIX}_FAILED`,
  DELETE: `${ADVANCEFEES_PREFIX}_DELETE`,
  ADD: `${ADVANCEFEES_PREFIX}_ADD`,
  UPDATE: `${ADVANCEFEES_PREFIX}_UPDATE`,
  FETCH_BY_ID: `${ADVANCEFEES_PREFIX}_FETCH_BY_ID`,
  SUCCESS_BY_ID: `${ADVANCEFEES_PREFIX}_SUCCESS_BY_ID`,
  FETCH_ADD_ITEMS: `${ADVANCEFEES_PREFIX}_FETCH_ADD_ITEMS`,
  SUCCESS_ADD_ITEMS: `${ADVANCEFEES_PREFIX}_SUCCESS_ADD_ITEMS`,
};

/* SEQUENCE_GENERATORS */
const SEQUENCE_GENERATORS_PREFIX = 'SEQUENCE';
export const SEQUENCE_GENERATORS = {
  FETCH: `${SEQUENCE_GENERATORS_PREFIX}_FETCH`,
  FETCH_INVOICE: `${SEQUENCE_GENERATORS_PREFIX}_FETCH_INVOICE`,
  FETCH_CURRENT_INVOICE: `${SEQUENCE_GENERATORS_PREFIX}_FETCH_CURRENT_INVOICE`,
  SUCCESS: `${SEQUENCE_GENERATORS_PREFIX}_SUCCESS`,
  INVOICE_SUCCESS: `${SEQUENCE_GENERATORS_PREFIX}_INVOICE_SUCSESS`,
  INVOICE_CURRENT_SUCCESS: `${SEQUENCE_GENERATORS_PREFIX}_INVOICE_CURRENT__SUCSESS`,
  FAILED: `${SEQUENCE_GENERATORS_PREFIX}_FAILED`,
  DELETE: `${SEQUENCE_GENERATORS_PREFIX}_DELETE`,
  ADD_ACCOUNT_SEQUENCE: `${SEQUENCE_GENERATORS_PREFIX}_ADD_SEQUENCE_GENERATORS `,
  ADD_INVOICE_SEQUENCE: `${SEQUENCE_GENERATORS_PREFIX}_ADD_INVOICE_SEQUENCE_GENERATORS `,
  DELETE_ACCOUNT_SEQUENCE: `${SEQUENCE_GENERATORS_PREFIX}_DELETE_SEQUENCE_GENERATORS `,
  UPDATE_ACCOUNT_SEQUENCE: `${SEQUENCE_GENERATORS_PREFIX}_UPDATE_SEQUENCE_GENERATORS `,
  UPDATE_INVOICE_SEQUENCE: `${SEQUENCE_GENERATORS_PREFIX}_UPDATE_INVOICE_SEQUENCE_GENERATORS `,
  ADD_POS_SEQUENCE: `${SEQUENCE_GENERATORS_PREFIX}_ADD_POS_GENERATORS `,
  POS_SUCCESS: `${SEQUENCE_GENERATORS_PREFIX}_POS_SUCCESS`,
  FETCH_POS: `${SEQUENCE_GENERATORS_PREFIX}_FETCH_POS`,
  UPDATE_POS_SEQUENCE: `${SEQUENCE_GENERATORS_PREFIX}_UPDATE_POS_SEQUENCE`,
};

/* MANAGE_ACCOUNTS */
const MANAGE_ACCOUNTS_PREFIX = 'MANAGE_ACCOUNTS';
export const MANAGE_ACCOUNTS = {
  FETCH: `${MANAGE_ACCOUNTS_PREFIX}_FETCH`,
  SUCCESS: `${MANAGE_ACCOUNTS_PREFIX}_SUCCESS`,
  FAILED: `${MANAGE_ACCOUNTS_PREFIX}_FAILED`,
  DELETE: `${MANAGE_ACCOUNTS_PREFIX}_DELETE`,
  ADD_ACCOUNT: `${MANAGE_ACCOUNTS_PREFIX}_ADD_ACCOUNT`,
  UPDATE_ACCOUNT: `${MANAGE_ACCOUNTS_PREFIX}_UPDATE_ACCOUNT`,
  FETCH_BY_ID: `${MANAGE_ACCOUNTS_PREFIX}_FETCH_BY_ID`,
  SUCCESS_BY_ID: `${MANAGE_ACCOUNTS_PREFIX}_SUCCESS_BY_ID`,
  FAILED_BY_ID: `${MANAGE_ACCOUNTS_PREFIX}_FAILED_BY_ID`,
};

/* ACCOUNT_SESSION_GENERATORS */
const ACCOUNT_SESSION_PREFIX = 'ACCOUNT_SESSION';
export const ACCOUNT_SESSION = {
  FETCH: `${ACCOUNT_SESSION_PREFIX}_FETCH`,
  FETCH_INVOICES: `${ACCOUNT_SESSION_PREFIX}_FETCH_INVOICES`,
  FETCH_VIEW_INVOICES: `${ACCOUNT_SESSION_PREFIX}_FETCH_VIEW_INVOICES`,
  FAILED: `${ACCOUNT_SESSION_PREFIX}_FAILED`,
  SUCCESS: `${ACCOUNT_SESSION_PREFIX}_SUCCESS`,
  SUCCESS_INVOICES: `${ACCOUNT_SESSION_PREFIX}_SUCCESS_INVOICES`,
  SUCCESS_VIEW_INVOICES: `${ACCOUNT_SESSION_PREFIX}_SUCCESS_VIEW_INVOICES`,
};


/* MOBILE_VIEW */
const MBLVIEW_PREFIX = 'MBLVIEW';
export const MBLVIEW = {
  FETCH: `${MBLVIEW_PREFIX}_FETCH`,
};

/* DONATION_PAGES */
const DONATION_PAGES_PREFIX = 'DONATION_PAGES';
export const DONATION_PAGES = {
  FETCH: `${DONATION_PAGES_PREFIX}_FETCH`,
  FAILED: `${DONATION_PAGES_PREFIX}_FAILED`,
  SUCCESS: `${DONATION_PAGES_PREFIX}_SUCCESS`,
  DONATION_ADD: `${DONATION_PAGES_PREFIX}_DONATION_ADD`,
  DONATION_ADD_SUCCESS: `${DONATION_PAGES_PREFIX}_DONATION_ADD_SUCCESS`,
  DONATION_UPDATE: `${DONATION_PAGES_PREFIX}_DONATION_UPDATE`,
  DONATION_UPDATE_SUCCESS: `${DONATION_PAGES_PREFIX}_DONATION_UPDATE_SUCCESS`,
};

/* DONATION_PAGES */
const DONATION_DETAILS_PAGES_PREFIX = 'DONATION_DETAILS_PAGES';
export const DONATION_DETAILS_PAGES = {
  FETCH: `${DONATION_DETAILS_PAGES_PREFIX}_FETCH`,
  FAILED: `${DONATION_DETAILS_PAGES_PREFIX}_FAILED`,
  SUCCESS: `${DONATION_DETAILS_PAGES_PREFIX}_SUCCESS`,
  SLUG_FETCH: `${DONATION_DETAILS_PAGES_PREFIX}_SLUG_FETCH`,
  SLUG_SUCCESS: `${DONATION_DETAILS_PAGES_PREFIX}_SLUG_SUCCESS`,
  SLUG_FAILED: `${DONATION_DETAILS_PAGES_PREFIX}_SLUG_FAILED`,
  NOTIFY: `${DONATION_DETAILS_PAGES_PREFIX}_NOTIFY`,
  SOLDOUT: `${DONATION_DETAILS_PAGES_PREFIX}_SOLDOUT`,
  RESUMESALE: `${DONATION_DETAILS_PAGES_PREFIX}_RESUMESALE`,
  DELETE: `${DONATION_DETAILS_PAGES_PREFIX}_DELETE`,
  DONATION_OFFLINE_DONATION: `${DONATION_PAGES_PREFIX}_DONATION_OFFLINE_DONATION`,
  DONATION_OFFLINE_DONATION_SUCCESS: `${DONATION_PAGES_PREFIX}_DONATION_OFFLINE_DONATION_SUCCESS`,
  DONATION_OFFLINE_PAYMENTS_FETCH: `${DONATION_PAGES_PREFIX}_DONATION_OFFLINE_PAYMENTS_FETCH`,
  DONATION_OFFLINE_PAYMENTS_SUCCESS: `${DONATION_PAGES_PREFIX}_DONATION_OFFLINE_PAYMENTS_SUCCESS`,
  DONATION_OFFLINE_PAYMENTS_FAILED: `${DONATION_PAGES_PREFIX}_DONATION_OFFLINE_PAYMENTS_FAILED`,
  DONATION_PAYMENTS_FETCH: `${DONATION_PAGES_PREFIX}_DONATION_PAYMENTS_FETCH`,
  DONATION_PAYMENTS_SUCCESS: `${DONATION_PAGES_PREFIX}_DONATION_PAYMENTS_SUCCESS`,
  DONATION_PAYMENTS_FAILED: `${DONATION_PAGES_PREFIX}_DONATION_PAYMENTS_FAILED`,
  DONATION_PAGE_FETCH: `${DONATION_PAGES_PREFIX}_DONATION_PAGE_FETCH`,
  DONATION_PAGE_SUCCESS: `${DONATION_PAGES_PREFIX}_DONATION_PAGE_SUCCESS`,
  DONATION_PAGE_FAILED: `${DONATION_PAGES_PREFIX}_DONATION_PAGE_FAILED`,
};

/* EVENTS ACTIONS */
const EVENT_PAGES_PREFIX = 'EVENT_PAGES';
export const EVENT_PAGES = {
  FETCH: `${EVENT_PAGES_PREFIX}_FETCH`,
  ADD_EVENT: `${EVENT_PAGES_PREFIX}_ADD_EVENT`,
  ADD_EVENT_SUCCESS: `${EVENT_PAGES_PREFIX}_ADD_EVENT_SUCCESS`,
  UPDATE: `${EVENT_PAGES_PREFIX}_UPDATE`,
  DELETE: `${EVENT_PAGES_PREFIX}_DELETE`,
  SUCCESS: `${EVENT_PAGES_PREFIX}_SUCCESS`,
  FAILED: `${EVENT_PAGES_PREFIX}_FAILED`,
  SELECTED: `${EVENT_PAGES_PREFIX}_SELECTED`,
  FETCH_RECEIPT: `${EVENT_PAGES_PREFIX}_FETCH_RECEIPT`,
  RECEIPT_SUCCESS: `${EVENT_PAGES_PREFIX}_RECEIPT_SUCCESS`,
};

/* EVENTSDETAILS ACTIONS */
const EVENT_PAGE_DETAILS_PREFIX = 'EVENT_PAGE_DETAILS';
export const EVENT_PAGE_DETAILS = {
  FETCH: `${EVENT_PAGE_DETAILS_PREFIX}_FETCH`,
  SUCCESS: `${EVENT_PAGE_DETAILS_PREFIX}_SUCCESS`,
  UPDATE: `${EVENT_PAGE_DETAILS_PREFIX}_UPDATE`,
  FAILED: `${EVENT_PAGE_DETAILS_PREFIX}_FAILED`,
  DELETE: `${EVENT_PAGE_DETAILS_PREFIX}_DELETE`,
  PAYMENT_SUCCESS: `${EVENT_PAGE_DETAILS_PREFIX}_PAYMENT_SUCCESS`,
  PAYMENT_FETCH: `${EVENT_PAGE_DETAILS_PREFIX}_PAYMENT_FETCH`,
  PRODUCT_ITEM_SUCCESS: `${EVENT_PAGE_DETAILS_PREFIX}_PRODUCT_ITEM_SUCCESS`,
  PRODUCT_ITEM_FETCH: `${EVENT_PAGE_DETAILS_PREFIX}_PRODUCT_ITEM_FETCH`,
  CUSTOM_TEMPLATE_SUCCESS: `${EVENT_PAGE_DETAILS_PREFIX}_CUSTOM_TEMPLATE_SUCCESS`,
  CUSTOM_TEMPLATE_FETCH: `${EVENT_PAGE_DETAILS_PREFIX}_CUSTOM_TEMPLATE_FETCH`,
  CUSTOM_TEMPLATE_DELETE: `${EVENT_PAGE_DETAILS_PREFIX}_CUSTOM_TEMPLATE_DELETE`,
  DEACTIVE: `${EVENT_PAGE_DETAILS_PREFIX}_DEACTIVE`,
  SOLDOUT: `${EVENT_PAGE_DETAILS_PREFIX}_SOLDOUT`,
  RESUME_SALES: `${EVENT_PAGE_DETAILS_PREFIX}_RESUME_SALES`,
  OFFLINE_PAYMENTS: `${EVENT_PAGE_DETAILS_PREFIX}_OFFLINE_PAYMENTS`,
  EVENT_PAGE_SLUG_FETCH: `${EVENTSDETAILS_PREFIX}_EVENT_PAGE_SLUG_FETCH`,
  EVENT_PAGE_SLUG_SUCCESS: `${EVENTSDETAILS_PREFIX}_EVENT_PAGE_SLUG_SUCCESS`,
  FETCH_OFFLINE_PAYMENTS: `${PAYMENTPAGESDETAILS_PREFIX}_FETCH_OFFLINE_PAYMENTS`,
  SUCCESS_OFFLINE_PAYMENTS: `${PAYMENTPAGESDETAILS_PREFIX}_SUCCESS_OFFLINE_PAYMENTS`,
  CLEAR_EVENT_DETAILS: `${EVENT_PAGE_DETAILS_PREFIX}_CLEAR_EVENT_DETAILS`,
  PAGE_NOTIFY: `${EVENT_PAGE_DETAILS_PREFIX}_PAGE_NOTIFY`,
  SEATED_NOTIFY: `${EVENT_PAGE_DETAILS_PREFIX}_SEATED_NOTIFY`,
  EVENT_PAGE_PROMOS: `${EVENT_PAGE_DETAILS_PREFIX}_PROMOS`,
  EVENT_PAGE_PROMO_SUCCESS: `${EVENT_PAGE_DETAILS_PREFIX}_EVENT_PAGE_PROMO_SUCCESS`,
  EVENT_PAGE_ALL_PROMOS: `${EVENT_PAGE_DETAILS_PREFIX}_EVENT_PAGE_ALL_PROMOS`,
  EVENT_PAGE_ALL_PROMO_SUCCESS: `${EVENT_PAGE_DETAILS_PREFIX}_EVENT_PAGE_ALL_PROMO_SUCCESS`,
  EVENT_PAGE_PROMO_ADD: `${EVENT_PAGE_DETAILS_PREFIX}_EVENT_PAGE_PROMO_ADD`,
  EVENT_PAGE_PROMO_UPDATE: `${EVENT_PAGE_DETAILS_PREFIX}_EVENT_PAGE_PROMO_UPDATE`,
  EVENT_PAGE_PROMO_DELETE: `${EVENT_PAGE_DETAILS_PREFIX}_EVENT_PAGE_PROMO_DELETE`,
  EVENT_PAGE_OFFLINE_DETAILS_FETCH: `${EVENT_PAGE_DETAILS_PREFIX}_OFFLINE_DETAILS_FETCH`,
  EVENT_PAGE_OFFLINE_DETAILS_SUCCESS: `${EVENT_PAGE_DETAILS_PREFIX}_OFFLINE_DETAILS_SUCCESS`,
  EVENT_PAGE_SPLIT_UP_FETCH: `${EVENT_PAGE_DETAILS_PREFIX}_SPLIT_UP_FETCH`,
  EVENT_PAGE_SPLIT_UP_SUCCESS: `${EVENT_PAGE_DETAILS_PREFIX}_SPLIT_UP_SUCCESS`,
  EVENT_PAGE_OFFLINE_ORDER_SUCCESS: `${EVENT_PAGE_DETAILS_PREFIX}_ORDER_SUCCESS`,
  EVENT_PAGE_OFFLINE_ORDER_FAILED: `${EVENT_PAGE_DETAILS_PREFIX}_ORDER_FAILED`,
  EVENT_PAGE_OFFLINE_CREATE_ORDER: `${EVENT_PAGE_DETAILS_PREFIX}_CREATE_ORDER`,
};
