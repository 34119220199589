// @flow
import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Select,
} from 'antd';

import SimpleReactValidator from 'simple-react-validator';
import { SpH5, SpError } from 'components/DesignKit';
import {
  COUNTRY_CODES,
} from 'appconstants';

const { Option } = Select;

type Props = {
  submitting: boolean,
  searchRateData: Object,
  transferType: Array<Object>,
  issuer: Array<Object>,
  category: Array<Object>,
};

const SearchRate = (props: Props, ref) => {
  const {
    submitting,
    searchRateData,
    transferType,
    issuer,
    category,
  } = props;
  const [, forceUpdate] = useState();
  const [issuerId, setIssuerId] = useState(searchRateData ? searchRateData.issuerId : '');
  const [categoryId, setCategoryId] = useState(searchRateData ? searchRateData.categoryId : '');
  const [transferTypeId, setTransferTypeId] = useState(searchRateData ? searchRateData.transferTypeId : '');
  const [countryId, setCountryId] = useState(searchRateData ? searchRateData.countryId : 'Select a country');
  const [issuerName, setIssuerName] = useState(searchRateData ? searchRateData.issuerName : '');
  const [categoryName, setCategoryName] = useState(searchRateData ? searchRateData.categoryName : '');
  const [transferTypeName, setTransferTypeName] = useState(searchRateData ? searchRateData.transferTypeName : '');
  const [countryName, setCountryName] = useState(searchRateData ? searchRateData.countryName : '');

  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      issuerId,
      categoryId,
      transferTypeId,
      countryId,
      issuerName,
      categoryName,
      transferTypeName,
      countryName,
    }),
  }));

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Issuer Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            defaultValue={issuerName || 'Select Issuer Type'}
            onChange={(value) => {
              setIssuerName(value[0]);
              setIssuerId(value[1]);
            }}
          >
            {
              issuer.map(acquire => (
                <Option
                  key={1}
                  value={[acquire.name, acquire.id]}
                >
                  {acquire.name}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('Issuer Type', issuerId, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Category Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            defaultValue={categoryName || 'Select Category Type'}
            onChange={value => {
              setCategoryName(value[0]);
              setCategoryId(value[1]);
            }}
          >
            {
              category.map(categories => (
                <Option
                  key={1}
                  value={[categories.name, categories.id]}
                >
                  {categories.name}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('Category Type', categoryId, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Transfer Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            defaultValue={transferTypeName || 'Select Transfer Type'}
            onChange={value => {
              setTransferTypeName(value[0]);
              setTransferTypeId(value[1]);
            }}
          >
            {
              transferType.map(transfer => (
                <Option
                  key={1}
                  value={[transfer.value, transfer.id]}
                >
                  {transfer.value.replace(/_/g, ' ')}
                </Option>
              ))
            }
          </Select>
          <SpError>
            {simpleValidator.current.message('Transfer Type', transferTypeId, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Select Country</SpH5></label>
          <Select
            className="w-100"
            showSearch
            defaultValue={countryName || 'Select a country'}
            onChange={value => {
              setCountryName(value[0]);
              setCountryId(value[1]);
            }}
          >
            {COUNTRY_CODES.map((item) => (
              <Option
                key={item.ID}
                value={[item.NAME, item.ID]}
              >
                {item.NAME}
              </Option>
            ))}
          </Select>
          <SpError>
            {simpleValidator.current.message('country', countryId, 'required')}
          </SpError>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(SearchRate);
