// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from 'components/Loading';
import {
  Spin,
} from 'antd';
import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import {
  invoiceDetailsAction,
  notificationActions,
  vendorInvoiceDetailsActions,
} from 'store/actions';
import { NOTIFICATION, VENDORINVOICEDETAILS } from 'store/actionTypes';
import {
  PAGES,
  API_STATUS,
  INVOICE_TYPES,
  MESSAGES,
} from 'appconstants';
import View from './components/ViewInvoice';
import Edit from './components/EditInvoice';

type Props = {
  fetchVendorInvoiceDetails: Function,
  invoiceDetails: Object,
  invoiceDetailsStatus: String,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  loading: boolean,
  updateVendorInvoice: Function,
  getContactList: Function,
  customersList: Array<Object>,
  submitting: boolean,
  validator: boolean,
  addedNewCustomer: Object,
  addContact: Function,
  deleteVendorInvoice: Function,
  getProductsList: Function,
  productsList: Array<Object>,
  addNewProcut: Function,
  setNotification: Function,
  notifyVendorInvoice: Function,
  addedNewProduct: Object,
  addNewCoupon: Function,
  getTaxes: Function,
  taxList: Array<Object>,
  addNewTaxRate: Function,
  newCoupon: Object,
  newTaxrate: Object,
  payVendorInvoice: Function,
  cancelVendorInvoices: Function,
  openPayVendorInvoiceModal: Function,

};

const InvoiceDetails = (props: Props) => {
  const {
    fetchVendorInvoiceDetails,
    invoiceDetails,
    invoiceDetailsStatus,
    selectedAccount,
    history,
    loading,
    updateVendorInvoice,
    getContactList,
    customersList,
    submitting,
    validator,
    addedNewCustomer,
    addContact,
    deleteVendorInvoice,
    getProductsList,
    productsList,
    addNewProcut,
    setNotification,
    notifyVendorInvoice,
    addedNewProduct,
    addNewCoupon,
    getTaxes,
    taxList,
    addNewTaxRate,
    newCoupon,
    newTaxrate,
    payVendorInvoice,
    cancelVendorInvoices,
    openPayVendorInvoiceModal,
  } = props;

  // eslint-disable-next-line react/prop-types
  const { gid } = props.match.params;
  // eslint-disable-next-line react/prop-types
  const gidPath = gid.split('-')[1];
  const [zipTag, setZipTag] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [loader, setLoader] = useState(false);

  const getData = () => {
    fetchVendorInvoiceDetails(gid);
    getContactList();
    getProductsList();
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.VENDORINVOICES);
    }
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.VENDORINVOICES);
    }
  }, [validator]);

  const getProducts = (searchText: string) => {
    getProductsList(searchText);
  };

  const sendInvoice = () => {
    const params = {
      type: 'EMAIL',
    };
    notifyVendorInvoice({
      gid,
      params,
    });
  };

  const invoiceUpdate = (payload: Object) => {
    let endPoint;
    const { params } = payload;
    const { status } = params;

    if (status === 'DRAFT') {
      endPoint = 'draft';
    } else if (status === 'ACTIVE') {
      openPayVendorInvoiceModal({
        type: VENDORINVOICEDETAILS.OPEN_PAY_MODAL,
        payload: true,
      });
      endPoint = 'active';
    }

    updateVendorInvoice({
      gid,
      endPoint,
      params,
    });
  };

  const invoicePay = ({ fundingSourceGid, payerFundingSourceGid }) => {
    payVendorInvoice({
      gid,
      params: { fundingSourceGid, payerFundingSourceGid },
    });
  };

  const createNewInvoice = () => {
    history.push(`${PAGES.NEWVENDOR_INVOICE}?INVOICE.ID=${gid}`);
  };

  const cancelInvoice = () => {
    const params = {
      status: 'CANCELLED',
    };
    cancelVendorInvoices({
      gid,
      params,
    });
    setRedirect(true);
  };

  const deleteInvoices = () => {
    const params = {
      status: 'CANCELLED',
    };
    deleteVendorInvoice({
      gid,
      params,
    });
    setRedirect(true);
  };

  const searchCustomers = (searchParams: string) => {
    getContactList(searchParams);
  };

  const customerAdd = (params: Object) => {
    addContact(params);
  };

  const productAdd = (params: Object) => {
    addNewProcut(params);
  };

  const invoiceDownload = async () => {
    const pdfKey = invoiceDetails.invoicePdfKey;
    setLoader(true);
    try {
      const { data: { entity: { fileUrl } } } = await axiosAuthInstance
        .get(`${API_END_POINTS.VENDORINVOICE_API}/${pdfKey}/download`);
      window.open(fileUrl);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.INVOICE.DOWNLOAD_ERROR,
      });
    } finally {
      setLoader(false);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={loader ? 'OVERLAY' : ''}>
        {
          loader && (
            <Spin style={{ width: '100%', height: '100%', transform: 'translateY(50%)' }} />
          )
        }
      </div>
      {
        invoiceDetails.gid && invoiceDetailsStatus === API_STATUS.SUCCESS && (
          <>
            {
              invoiceDetails.status === INVOICE_TYPES.DRAFT ? (
                <Edit
                  gid={gid}
                  invoiceDetails={invoiceDetails}
                  selectedAccount={selectedAccount}
                  invoiceUpdate={invoiceUpdate}
                  deleteInvoices={deleteInvoices}
                  customersList={customersList}
                  searchCustomers={searchCustomers}
                  submitting={submitting}
                  zipTag={zipTag}
                  validator={validator}
                  addedNewCustomer={addedNewCustomer}
                  customerAdd={customerAdd}
                  getProducts={getProducts}
                  productsList={productsList}
                  productAdd={productAdd}
                  addedNewProduct={addedNewProduct}
                  createNewInvoice={createNewInvoice}
                  addNewCoupon={addNewCoupon}
                  getTaxes={getTaxes}
                  taxList={taxList}
                  addNewTaxRate={addNewTaxRate}
                  newCoupon={newCoupon}
                  newTaxrate={newTaxrate}
                  history={history}
                />
              ) : (
                <View
                  gid={gid}
                  invoiceDetails={invoiceDetails}
                  selectedAccount={selectedAccount}
                  sendInvoice={sendInvoice}
                  cancelInvoice={cancelInvoice}
                  validator={validator}
                  submitting={submitting}
                  invoicePay={invoicePay}
                  invoiceDownload={invoiceDownload}
                  createNewInvoice={createNewInvoice}
                />
              )
            }
          </>

        )
      }
    </>

  );
};

const mapStateToProps = (state) => ({
  invoiceDetails: state.vendorInvoiceDetails.invoice,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  loading: state.loading.loading,
  invoiceDetailsStatus: state.vendorInvoiceDetails.invoiceDetailsStatus,
  customersList: state.vendorInvoiceDetails.customersList,
  addedNewCustomer: state.vendorInvoiceDetails.newCustomer,
  productsList: state.invoiceDetails.productsList,
  addedNewProduct: state.invoiceDetails.newProduct,
  taxList: state.invoiceDetails.taxList,
  newCoupon: state.invoiceDetails.newCoupon,
  newTaxrate: state.invoiceDetails.newTaxrate,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVendorInvoiceDetails: gid => dispatch(vendorInvoiceDetailsActions.fetchVendorInvoiceDetails({
    payload: gid,
  })),
  updateVendorInvoice: param => dispatch(vendorInvoiceDetailsActions.updateVendorInvoice({
    payload: param,
  })),
  getContactList: param => dispatch(vendorInvoiceDetailsActions.getContactList({
    payload: param,
  })),
  addContact: param => dispatch(vendorInvoiceDetailsActions.addContact({
    payload: param,
  })),
  deleteVendorInvoice: param => dispatch(vendorInvoiceDetailsActions.deleteVendorInvoice({
    payload: param,
  })),
  cancelVendorInvoices: param => dispatch(vendorInvoiceDetailsActions.cancelVendorInvoices({
    payload: param,
  })),
  getProductsList: param => dispatch(invoiceDetailsAction.getProductsList({
    payload: param,
  })),
  addNewProcut: param => dispatch(invoiceDetailsAction.addNewProcut({
    payload: param,
  })),
  notifyVendorInvoice: param => dispatch(vendorInvoiceDetailsActions.notifyVendorInvoice({
    payload: param,
  })),
  addNewCoupon: param => dispatch(invoiceDetailsAction.addNewCoupon({
    payload: param,
  })),
  getTaxes: param => dispatch(invoiceDetailsAction.getTaxes({
    payload: param,
  })),
  addNewTaxRate: param => dispatch(invoiceDetailsAction.addNewTaxRate({
    payload: param,
  })),
  payVendorInvoice: param => dispatch(vendorInvoiceDetailsActions.payVendorInvoice({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  openPayVendorInvoiceModal: ({ type, payload }) => dispatch(vendorInvoiceDetailsActions.openPayVendorInvoiceModal({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(InvoiceDetails);
