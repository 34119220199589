import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  Radio,
  DatePicker,
} from 'antd';
import NumberFormat from 'react-number-format';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import KYCFileUpload from 'components/KycFileUpload';

import {
  SpForm,
  SpError,
  SpText,
  SpTextMute,
  SpButton,
} from 'components/DesignKit';
import {
  validators, isVariableNullOrUndefined,
} from 'utils/validationMessages';
import { API_END_POINTS } from 'api';

import {
  MESSAGES,
  PHONE_LENGTH,
  COUNTRY_CODES,
} from 'appconstants';

const { Option } = Select;
const dateFormat = 'MMM DD, YYYY';

type Props = {
  country: string,
  statesInCountry: Array<Object>,
  businessRepresentatives: Object,
  selectedAccount: Object,
  internalRole: Object,
  isIndia: Boolean,
  isUsa: boolean,
  onRemoveRepresentatives: Function,
  show: boolean,
  Num: Number,
}

const EditBusinessRepresentativeDetails = (props: Props, ref) => {
  const {
    Num,
    statesInCountry,
    selectedAccount,
    internalRole,
    isIndia,
    country,
    isUsa,
    onRemoveRepresentatives,
    show,
    businessRepresentatives: {
      gid,
      givenName,
      familyName,
      title: designation,
      dateofbirth,
      phone,
      email,
      hasControl,
      kycAddressProofUrl,
      businessRepPanUrl,
      pan,
      ssn,
      governmentIdUrl,
      ownerShipPercentage: ownerShipPercentageRep,
      address: {
        street,
        city: cityProp,
        state: stateProp,
        postalCode,
        country: {
          id,
          name,
        },
      },
    },
  } = props;

  const [, forceUpdate] = useState();
  const [firstName, setGivenName] = useState(givenName);
  const [lastName, setFamilyName] = useState(familyName);
  const [contactEmail, setContactEmail] = useState(email);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [panNumber, setPanNumber] = useState(pan);
  const [title, setTitle] = useState(designation);
  const [legalAddress, setLegalAddress] = useState(street);
  const [zipCode, setZipCode] = useState(postalCode);
  const [city, setCity] = useState(cityProp);
  const [state, setState] = useState(stateProp);
  const [zipTag, setZipTag] = useState('');
  const [bankStatementUrl, setBankStatementUrl] = useState(kycAddressProofUrl);
  const [businessRepPANUrl, setBusinessRepPanUrl] = useState(businessRepPanUrl || null);
  const [dob, setDob] = useState(dateofbirth);
  const [control, setHasControl] = useState(hasControl);
  const [phoneFormate, setPhoneFormate] = useState('');
  const [ssnNumber, setSsnNumber] = useState(ssn);
  const [countryCode, setCountryCode] = useState(phone.substring(0, 2) === '+1' ? '+1' : '+91');
  const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  const postalCodeRegex = country === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;
  // eslint-disable-next-line max-len
  const [ownerShipPercentage, setOwnerShipPercentage] = useState(isVariableNullOrUndefined(ownerShipPercentageRep) ? ownerShipPercentageRep : '');
  const ifNumber = /^[0-9]+$/;
  const [driverLicenseUrl, setDriverLicenseUrl] = useState(governmentIdUrl);

  const dateIssued = moment(dateofbirth).format(dateFormat);
  const [showRemove, setShowRemove] = useState(false);

  useEffect(() => {
    if (selectedAccount.country.id === 1) {
      setZipTag('Zip code');
    } else if (selectedAccount.country.id === 2) {
      setZipTag('Pin code');
    }
  }, []);

  useEffect(() => {
    if (!isIndia) {
      if (ownerShipPercentage >= 25) {
        setHasControl(true);
      } else {
        setHasControl(false);
      }
    }
  }, [ownerShipPercentage]);

  useEffect(() => {
    const cntryCode = phone.substring(0, 2);
    if (phone.length === 12 && cntryCode === '+1') {
      setCountryCode('+1');
      setPhoneNumber(phone.slice(2));
      setPhoneFormate('(###) ###-####');
    } else if (phone.length === 13 && cntryCode === '+9') {
      setCountryCode('+91');
      setPhoneNumber(phone.slice(3));
      setPhoneFormate('##### #####');
    }
  }, []);

  useEffect(() => {
      if (show) {
        setShowRemove(true);
      } else {
        setShowRemove(false);
      }
    }, [show]);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneLength: {
        message: MESSAGES.ADD_USER.PHONE,
        rule: val => val.length >= PHONE_LENGTH.MIN && val.length <= PHONE_LENGTH.MAX,
      },
      pan: {
        message: MESSAGES.BUSINESS_DETAILS.PAN,
        rule: val => panRegex.test(val),
      },
      nameValidation: {
        message: 'Name must be valid',
        rule: val => /^[A-Za-z0-9&,\s.-]{1,50}$/.test(val),
    },
      validSSN: {
        message: MESSAGES.BUSINESS_DETAILS.SSN,
        rule: val => (new RegExp(/^[0-9]{9}$/).exec(val) !== null),
      },
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      ownerShip: {
        message: 'Percentage ownership should be equal or more than 25% and equal or less than 100%',
        rule: (val) => {
          const value = val && val.replace('%', '');
          return (ifNumber.test(value) && (value >= 25) && (value <= 100));
        },
      },
    },
  }));

  const onCountryCodeChange = (value) => {
    if (value === '+1') {
      setPhoneFormate('(###) ###-####');
      setCountryCode(value);
    } else if (value === '+91') {
      setPhoneFormate('##### #####');
      setCountryCode(value);
    } else {
      setPhoneFormate('##########');
      setCountryCode(value);
    }
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhoneNumber(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if ((!formValid)) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      firstName,
      lastName,
      contactEmail,
      countryCode,
      phoneNumber,
      ownerShipPercentage,
      gid,
      title,
      legalAddress,
      zipCode,
      city,
      state,
      control,
      bankStatementUrl,
      dob,
      panNumber,
      ssnNumber,
      driverLicenseUrl,
      businessRepPanUrl: businessRepPANUrl,
    }),
  }));

  return (
    <>
      <Row type="flex" className="my-2 mb-3">
        <Col className="mr-5">
          <SpText className="text-uppercase" fontSize="20px">
            Business Representative Details
          </SpText>
        </Col>
        <Col>
          {
            showRemove
              ? (
                <SpButton
                  push={18}
                  type="secondary"
                  shape="round"
                  id={id}
                  onClick={() => onRemoveRepresentatives(Num)}
                >
                  Remove
                </SpButton>
              )
              : null
          }
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Given Name</span>
          </Col>
          <Col span={4}>
            <Input
              value={firstName}
              disabled={!internalRole}
              onBlur={() => setGivenName(firstName.trim())}
              onChange={e => setGivenName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Given Name', firstName, 'required|nameValidation')}
            </SpError>
          </Col>

          <Col span={3} className="ml-4">
            <span>Family Name</span>
          </Col>
          <Col span={4}>
            <Input
              value={lastName}
              disabled={!internalRole}
              onBlur={() => setFamilyName(lastName.trim())}
              onChange={e => setFamilyName(e.currentTarget.value)}
            />
          </Col>
          <SpError>
            {simpleValidator.current.message('Family Name', lastName, 'required|nameValidation')}
          </SpError>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Contact Email</span>
          </Col>
          <Col span={6}>
            <Input
              value={contactEmail}
              disabled={!internalRole}
              onBlur={() => setContactEmail(contactEmail.trim())}
              onChange={e => setContactEmail(e.currentTarget.value && e.currentTarget.value.toLowerCase())}
            />
            <SpError>
              {simpleValidator.current.message('Email', contactEmail, 'required|email')}
            </SpError>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Phone Number</span>
          </Col>
          <Col span={6}>
            <div className="d-flex">
              <Select
                style={{ width: '25%' }}
                defaultValue={countryCode}
                onChange={onCountryCodeChange}
                disabled={!internalRole}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.PHONE_CODE}>
                    {item.PHONE_CODE}
                  </Option>
                ))}
              </Select>
              <div style={{ width: '75%' }}>
                <NumberFormat
                  className="InputnumFormate"
                  value={phoneNumber}
                  format={phoneFormate}
                  mask="_"
                  allowEmptyFormatting
                  onChange={onPhoneNumberChange}
                  disabled={!internalRole}
                />
                <SpError>
                  {simpleValidator.current.message('Phone', phoneNumber, 'required|phone')}
                </SpError>
              </div>
            </div>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Title</span>
          </Col>
          <Col span={6}>
            <Input
              value={title}
              placeholder="CEO"
              onBlur={() => setTitle(title.trim())}
              onChange={e => setTitle(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Title', title, 'required|alpha')}
            </SpError>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Legal Address of the Representative</span>
          </Col>
          <Col span={6}>
            <Input
              value={legalAddress}
              placeholder="Street address of the representative"
              onBlur={() => setLegalAddress(legalAddress.trim())}
              onChange={e => setLegalAddress(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Address', legalAddress, 'required')}
            </SpError>
          </Col>
        </Row>

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>{zipTag}</span>
          </Col>
          <Col span={4}>
            <Input
              value={zipCode}
              placeholder={zipTag}
              onBlur={() => setZipCode(zipCode.trim())}
              onChange={e => setZipCode(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message(zipTag, zipCode, 'required|postalcode')}
            </SpError>
          </Col>

          <Col span={2} className="ml-4">
            <span>City</span>
          </Col>
          <Col span={4}>
            <Input
              value={city}
              placeholder="City"
              onBlur={() => setCity(city.trim())}
              onChange={e => setCity(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('City', city, 'required')}
            </SpError>
          </Col>

          <Col span={2} className="ml-4">
            <span>State</span>
          </Col>
          <Col span={4}>
            <Select
              value={state}
              placeholder="Please select"
              showSearch
              optionFilterProp="children"
              className="w-100"
              onChange={value => setState(value)}
            >
              {
                statesInCountry.map(s => (
                  <Option
                    key={1}
                    value={s.id}
                    className="text-capitalize"
                  >
                    {s.name}
                  </Option>
                ))
              }
            </Select>
            <SpError>
              {simpleValidator.current.message('State', state, 'required')}
            </SpError>
          </Col>
        </Row>

        {
          isUsa && (
            <>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Govt. ID (Driver License, Passport etc.)</span>
                </Col>
                <Col span={6}>
                  <KYCFileUpload
                    name="Driver License"
                    endPoint={API_END_POINTS.US_BUSINESS_GOVERNMENT_ID}
                    url={driverLicenseUrl}
                    keyType="US_BUSINESS_GOVERNMENT_ID"
                    setUrl={value => setDriverLicenseUrl(value)}
                    type="Business"
                  />
                  <SpError>
                    {simpleValidator.current.message('govt id url', driverLicenseUrl, 'required')}
                  </SpError>
                </Col>
                <Col span={8} className="ml-4" />
              </Row>
            </>

          )
        }

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Country</span>
          </Col>
          <Col span={4}>
            <Select value={id} style={{ width: '100%' }} disabled>
              <Option key={id} value={id}>{name}</Option>
            </Select>
          </Col>
        </Row>
        {
          internalRole && isIndia && (
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Representative&apos;s PAN</span>
              </Col>
              <Col span={4}>
                <Input
                  placeholder="PAN"
                  value={panNumber}
                  onBlur={() => setPanNumber(panNumber.trim())}
                  onChange={e => setPanNumber((e.currentTarget.value).toUpperCase())}
                />
                <SpError>
                  {simpleValidator.current.message('pan', pan, 'required|pan', { messages: { required: 'The PAN field is required' } })}
                </SpError>
              </Col>
            </Row>
          )
        }
        {
          internalRole && !isIndia && (
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>SSN</span>
              </Col>
              <Col span={4}>
                <Input.Password
                  placeholder="SSN"
                  autoComplete="new-password"
                  value={ssnNumber}
                  onBlur={() => setSsnNumber(ssnNumber.trim())}
                  onChange={e => setSsnNumber(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('ssn', ssnNumber, 'required|validSSN', { messages: { required: 'This SSN field is required' } })}
                </SpError>
              </Col>
            </Row>
          )
        }
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Date of birth</span>
          </Col>
          <Col span={6}>
            <DatePicker
              defaultValue={moment(dateIssued, dateFormat)}
              allowClear={false}
              onChange={(date, dateString) => setDob(dateString)}
              disabledDate={current => current && current > moment().subtract(1, 'days')}
              disabled={!internalRole}
            />
            <SpError>
              {simpleValidator.current.message('Date of birth', dob, 'required')}
            </SpError>
          </Col>
        </Row>
        {!isIndia && (
          <Row type="flex" justify="start" align="middle">
            <Col span={4}>
              <span>Percentage Ownership</span>
            </Col>
            <Col span={6}>
              <Input
                suffix="%"
                value={ownerShipPercentage}
                placeholder="Percentage OwnerShip"
                onBlur={() => setOwnerShipPercentage(ownerShipPercentage && ownerShipPercentage.trim())}
                onChange={e => setOwnerShipPercentage(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('OwnerShip Percentage', ownerShipPercentage, 'required|ownerShip')}
              </SpError>
            </Col>
          </Row>
        )}
        {
          internalRole && (
            <>
              {
                isIndia
                  ? (
                    <>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={4}>
                          <span>Id Proof</span>
                        </Col>
                        <Col span={6}>
                          <KYCFileUpload
                            name="Bank Statement"
                            endPoint={API_END_POINTS.UPLOAD_REP_ADDRESS}
                            url={bankStatementUrl}
                            setUrl={value => setBankStatementUrl(value)}
                          />
                          <SpError>
                            {simpleValidator.current.message('Id proof', bankStatementUrl, 'required')}
                          </SpError>
                        </Col>
                        <Col span={8} className="ml-4">
                          <SpTextMute>
                            Upload an Image or pdf with both sides of a government issued photo ID
                            (Passport, Driving License, Aadhar Card, Election Card). The address on ID
                            should match provided address. Self attested mandatory.
                          </SpTextMute>
                        </Col>
                      </Row>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={4}>
                          <span>PAN Proof</span>
                        </Col>
                        <Col span={6}>
                          <KYCFileUpload
                            name="PAN Proof"
                            endPoint={API_END_POINTS.UPLOAD_REP_PAN}
                            url={businessRepPANUrl}
                            keyType="BUSINESS_REP_PAN"
                            setUrl={value => setBusinessRepPanUrl(value)}
                            type="Business"
                          />
                          <SpError>
                            {simpleValidator.current.message('pan proof', businessRepPANUrl, 'required')}
                          </SpError>
                        </Col>
                        <Col span={8} className="ml-4">
                          <SpTextMute>
                            PAN Card of the Signing Authority. Self attested mandatory.
                          </SpTextMute>
                        </Col>
                      </Row>
                    </>
                  )
                  : null
              }
            </>
          )
        }
        {
          isIndia && (
            <Row>
              <Col className="mb-2">
                Do you own more than 25% of this business, or exercise significant control?
              </Col>
              <Col className="d-flex">
                <Radio.Group
                  onChange={e => setHasControl(e.target.value)}
                  value={control}
                >
                  {/* eslint-disable-next-line */}
                  <Radio value={true} className="ml-2">Yes, I do</Radio>
                  <Radio value={false}>No, I do not</Radio>
                </Radio.Group>
              </Col>
            </Row>
          )
        }
      </SpForm>
    </>
  );
};

// $FlowFixMe
export default forwardRef(EditBusinessRepresentativeDetails);
