// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  DatePicker,
  Radio,
} from 'antd';
import {
  LOWVOLUME_FEE_TYPES,
} from 'appconstants';

import {
  notificationActions,
} from 'store/actions';

import SimpleReactValidator from 'simple-react-validator';
import {
  SpH5, SpError, SpButton,
} from 'components/DesignKit';
import { validators } from 'utils/validationMessages';
import moment from 'moment-timezone';
import { NOTIFICATION } from 'store/actionTypes';


const { Option } = Select;
const dateformat = 'MM-DD-YYYY HH:mm';

type Props = {
  close: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  submit: Function,
  setNotification: Function,
};


const AddExchangeRate = (props: Props) => {
  const {
    visible,
    close,
    submitting,
    selectedAccount,
    submit,
    setNotification,
  } = props;

  const [, forceUpdate] = useState();
  // const [currency, setCurrency] = useState();
  const [lowVolumeLimit, setLowVolumeLimit] = useState(1);
  const [lowVolumeFee, setLowVolumeFee] = useState(1000);
  const [rate, setRate] = useState('');
  const [fromCurrencyCode, setFromCurrencyCode] = useState('USD');
  const [toCurrencyCode, setToCurrencyCode] = useState('INR');
  const [lowVolumeFeeType, setLowVolumeFeeType] = useState('PERCENTAGE');
  const [disableButton, setDisableButton] = useState(false);
  const nameRegex = /^[ ]*([a-zA-Z'-.]{1,}( [a-zA-z'-. ]{1,})?)$/;
  const [apiStartDate, setApiStartDate] = useState();
  const [apiEndDate, setApiEndDate] = useState();
  const [dates, setDates] = useState([]);
  const { prefix } = selectedAccount && selectedAccount.currency;

  const { RangePicker } = DatePicker;
  const RadioGroup = Radio.Group;

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      name: {
        message: validators.integrators.validName,
        rule: val => nameRegex.test(val),
      },
      positiveNumber: {
        message: 'Value must be a positive number or zero',
        rule: val => val >= 0,
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  // useEffect(() => {
  //   const countryId = (selectedAccount && selectedAccount.country && selectedAccount.country.id);
  //   if (countryId === 1) {
  //     setCurrency('USD');
  //   } else if (countryId === 2) {
  //     setCurrency('INR');
  //   }
  // }, []);
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';


  const customFunction = (date) => {
    if (date && date.length === 2) {
      const [start, end] = date;
      const utcStartDate = moment(start).format(MOMENT_FORMAT);
      const utcEndDate = moment(end).format(MOMENT_FORMAT);
      setApiStartDate(utcStartDate);
      setApiEndDate(utcEndDate);
    } else {
      setApiStartDate(null);
      setApiEndDate(null);
    }
  };
  const customStartDateReset = (open) => {
    if (!open && dates.length === 1) {
      setDates([]);
      // setStartDate();
      // setEndDate();
    }
  };
  const onRadioChange = (e) => {
    setLowVolumeFeeType(e.target.value);
  };
  const validate = (event) => {
    setDisableButton(true);
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    if (fromCurrencyCode === toCurrencyCode) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'From Currency and To Currency cannot be the same',
      });
      setDisableButton(false);
      forceUpdate(1);
      return;
    }
    if (apiStartDate === apiEndDate) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Start and End date cannot be the same with the same time',
      });
      setDisableButton(false);
      forceUpdate(1);
      return;
    }
    submit({
      fromCurrencyCode,
      toCurrencyCode,
      rate,
      lowVolumeLimit,
      lowVolumeFeeType,
      lowVolumeFee,
      apiStartDate,
      apiEndDate,
    });
  };


  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Add</SpButton>,
      ]}
      title="Add Exchange Rate"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>From currency</SpH5></label>
          <Select
            className="w-100"
            defaultValue="Select Type"
            onChange={(e) => setFromCurrencyCode(e)}
            value={fromCurrencyCode}
          >
            <Option key={1} value="USD">USD</Option>
            <Option key={2} value="INR">INR</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('Currency Code', fromCurrencyCode, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>To currency</SpH5></label>
          <Select
            className="w-100"
            defaultValue="Select Type"
            onChange={(e) => setToCurrencyCode(e)}
            value={toCurrencyCode}
          >
            <Option key={1} value="USD">USD</Option>
            <Option key={2} value="INR">INR</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('CurrencyCode', toCurrencyCode, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Exchange Rate (1 {fromCurrencyCode} = [?] {toCurrencyCode})</SpH5></label>
          <Input
            placeholder="0.00"
            value={rate}
            min={1}
            onChange={(e) => setRate(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('Exchange Rate', rate, 'required|positiveNumber')}
          </SpError>
        </div>
        <div className="mb-3">
          <div className="d-flex">
            <div className="w-50 mr-3" style={{ marginTop: '29px' }}>
              <label className="d-block"><SpH5>Low Volume Limit</SpH5></label>
              <Input
                placeholder="Quantity"
                prefix={prefix}
                value={lowVolumeLimit}
                min={1}
                onChange={(e) => setLowVolumeLimit(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('lowVolumeLimit', lowVolumeLimit, 'required|positiveNumber')}
              </SpError>
            </div>

            <div className="w-50">
              <label className="d-block"><SpH5>Low Volume Fee</SpH5></label>
              <RadioGroup style={{ marginBottom: '8px' }} onChange={onRadioChange} value={lowVolumeFeeType}>
                {LOWVOLUME_FEE_TYPES.map((option) => (
                  <Radio key={option.id} value={option.value}>
                    {option.display}
                  </Radio>
                ))}
              </RadioGroup>
              <Input
                placeholder="Quantity"
                value={lowVolumeFee}
                prefix={lowVolumeFeeType === 'PERCENTAGE' ? '%' : prefix}
                onChange={(e) => setLowVolumeFee(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('lowVolumeFee', lowVolumeFee, 'required|positiveNumber')}
              </SpError>
            </div>
          </div>

        </div>
        <div>
          <label style={{ display: 'block' }} htmlFor=""><SpH5>Select Dates</SpH5></label>
          <RangePicker
            allowClear={false}
            onChange={customFunction}
            // disabledDate={disabledDate}
            onCalendarChange={value => {
              setDates(value);
            }}
            showTime={{ format: 'HH:mm' }}
            // value={[moment(startDate), moment(endDate)]}
            onOpenChange={customStartDateReset}
            format={dateformat}
          />
          <span style={{ display: 'block' }}>Note: UTC format</span>
          <SpError>
            {simpleValidator.current.message('Date', apiStartDate, 'required')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,

});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddExchangeRate);
