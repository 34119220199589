import { SEQUENCE_GENERATORS } from '../actionTypes';

export const fetchAllSequence = (action) => ({
  type: SEQUENCE_GENERATORS.FETCH,
  payload: action.payload,
});

export const fetchAllPOSSequence = (action) => ({
  type: SEQUENCE_GENERATORS.FETCH_POS,
  payload: action.payload,
});

export const addSequence = (action) => ({
  type: SEQUENCE_GENERATORS.ADD_ACCOUNT_SEQUENCE,
  payload: action.payload,
});

export const addPOSSequence = (action) => ({
  type: SEQUENCE_GENERATORS.ADD_POS_SEQUENCE,
  payload: action.payload,
});

export const updateSequenceGenerator = (action) => ({
  type: SEQUENCE_GENERATORS.UPDATE_ACCOUNT_SEQUENCE,
  payload: action.payload,
});

export const updatePOSSequenceGenerator = (action) => ({
  type: SEQUENCE_GENERATORS.UPDATE_POS_SEQUENCE,
  payload: action.payload,
});

export const deleteSequenceGenerator = (action) => ({
  type: SEQUENCE_GENERATORS.DELETE_ACCOUNT_SEQUENCE,
  payload: action.payload,
});

export const fetchAllInvoiceSequence = (action) => ({
  type: SEQUENCE_GENERATORS.FETCH_INVOICE,
  payload: action.payload,
});
export const fetchCurrentSeqInvoice = (action) => ({
  type: SEQUENCE_GENERATORS.FETCH_CURRENT_INVOICE,
  payload: action.payload,
});

export const addInvoiceSequence = (action) => ({
  type: SEQUENCE_GENERATORS.ADD_INVOICE_SEQUENCE,
  payload: action.payload,
});

export const updateInvoiceSequence = (action) => ({
  type: SEQUENCE_GENERATORS.UPDATE_INVOICE_SEQUENCE,
  payload: action.payload,
});
