import { EVENT_PAGE_ORDERS_REDEEM_STATUSES } from 'appconstants';

export default value => {
  if (value === 'NOT_REDEEMED') {
    return EVENT_PAGE_ORDERS_REDEEM_STATUSES.NOT_REDEEMED;
  } if (value === 'PARTIALLY_REDEEMED') {
    return EVENT_PAGE_ORDERS_REDEEM_STATUSES.PARTIALLY_REDEEMED;
  } if (value === 'REDEEMED') {
    return EVENT_PAGE_ORDERS_REDEEM_STATUSES.REDEEMED;
  }
  return EVENT_PAGE_ORDERS_REDEEM_STATUSES.NOT_REDEEMED;
};
