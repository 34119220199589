import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
} from 'antd';

import { transferSettingsActions } from 'store/actions';
import { useLocation } from 'react-router-dom';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';

import Loading from 'components/Loading';
import { SpButton, SpText } from 'components/DesignKit';

import RBAC from 'components/rbac';
import { ROLES, COUNTRIES } from 'appconstants';
import Add from './Add';
import View from './View';
import Edit from './Edit';

type Props = {
  fetchTransferSetting: Function,
  updateTransferSetting: Function,
  addTransferSetting: Function,
  transferSettings: Object,
  loading: boolean,
  test: boolean,
  selectedAccount: Object,
  validator: boolean,
};

const TransferSettings = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    transferSettings,
    loading,
    test,
    fetchTransferSetting,
    updateTransferSetting,
    addTransferSetting,
    validator,
  } = props;
  const { prefix } = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [editSettings, setEditSettings] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');

  const getData = () => {
    fetchTransferSetting(location.search);
  };

  useEffect(() => {
    getData();
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [location, test]);

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setEditSettings(false);
    }
  }, [validator]);

  const updateSetting = (record: Object) => {
    const gid = transferSettings && transferSettings.gid;
    const {
      email,
      minLimt,
      maxLimt,
      approveLimt,
      defaultTransferTypeCode,
      enableSmartRouting,
      showActiveInvoice,
    } = record;

    const payloadApproveLimt = approveLimt ? approveLimt.replace(/[^\d]/g, '') : '';
    const payloadMinLimt = minLimt ? minLimt.replace(/[^\d]/g, '') : '';
    const payloadMaxLimt = maxLimt ? maxLimt.replace(/[^\d]/g, '') : '';

    const params = {
      approvalEmail: email,
      minLimit: payloadMinLimt ? Number(payloadMinLimt) : null,
      maxLimit: payloadMaxLimt ? Number(payloadMaxLimt) : null,
      minApprovalLimit: payloadApproveLimt ? Number(payloadApproveLimt) : null,
      defaultTransferTypeCode,
      enableSmartRouting,
      showActiveInvoice,
    };

    updateTransferSetting({
      gid,
      params,
    });
  };

  const addSetting = (record: Object) => {
    const {
      email,
      minLimt,
      maxLimt,
      approveLimt,
    } = record;

    const payloadApproveLimt = approveLimt ? approveLimt.replace(/[^\d]/g, '') : '';
    const payloadMinLimt = minLimt ? minLimt.replace(/[^\d]/g, '') : '';
    const payloadMaxLimt = maxLimt ? maxLimt.replace(/[^\d]/g, '') : '';

    addTransferSetting({
      approvalEmail: email,
      minLimit: payloadMinLimt ? Number(payloadMinLimt) : null,
      maxLimit: payloadMaxLimt ? Number(payloadMaxLimt) : null,
      minApprovalLimit: payloadApproveLimt ? Number(payloadApproveLimt) : null,
    });
  };

  const LoadComponent = () => {
    if (Boolean(transferSettings && Object.keys(transferSettings).length) && !editSettings) {
      return (
        <>
          <Row type="flex" justify="space-between" className="my-2 mb-3">
            <Col>
              <SpText className="text-uppercase" fontSize="20px">Manage Transfer Settings</SpText>
            </Col>
            <Col>
              {!editSettings && (
                <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.SUPERUSER]}>
                  <SpButton
                    type="secondary"
                    shape="round"
                    ghost
                    onClick={() => setEditSettings(true)}
                  >
                    Edit Transfer Settings
                  </SpButton>
                </RBAC>
              )}
            </Col>
          </Row>
          <View
            transferSettings={transferSettings}
            selectedAccount={selectedAccount}
          />
        </>
      );
    }
    return editSettings ? (
      <Edit
        transferSettings={transferSettings}
        phoneCode={phoneCode}
        isIndia={isIndia}
        prefix={prefix}
        updateSetting={updateSetting}
        setEditSettings={() => setEditSettings(false)}
        transferTypes={selectedAccount?.supportedTransferTypes ? selectedAccount?.supportedTransferTypes : []}
      />
    ) : (
      <Add
        status={selectedAccount.status}
        isIndia={isIndia}
        prefix={prefix}
        addSetting={addSetting}
      />
    );
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Card className="mb-2">
      <LoadComponent />
    </Card>
  );
};

const mapStateToProps = state => ({
  selectedAccount: state.account.selectedAccount,
  transferSettings: state.transferSettings.content,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  validator: state.loading.validator,
});

const mapDispatchToProps = dispatch => ({
  fetchTransferSetting: gid => dispatch(transferSettingsActions.fetchTransferSetting({
    payload: gid,
  })),
  addTransferSetting: payload => dispatch(transferSettingsActions.addTransferSetting({
    payload,
  })),
  updateTransferSetting: params => dispatch(transferSettingsActions.updateTransferSetting({
    payload: params,
  })),
});

// $FlowFixMe
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransferSettings);
