// @flow
import React from 'react';
import { connect } from 'react-redux';
import {
  Modal,
} from 'antd';
import {
  notificationActions,
} from 'store/actions';
import {
  SpH5,
  SpButton,
} from 'components/DesignKit';

type Props = {
    close: Function,
    submit: Function,
    visible: boolean,
    eventPageCustomTemplate: Object,
};

const RemoveCustomTemplate = (props: Props) => {
  const {
    visible,
    close,
    submit,
    eventPageCustomTemplate,
  } = props;

  const validate = (event) => {
    event.preventDefault();
    submit({
      type: eventPageCustomTemplate.type,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      width="650px"
      style={{
        top: '50px',
      }}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} type="danger">Delete</SpButton>,
      ]}
      title="Remove Custom Template"
    >
      <div className="px-4">
        <div className="mt-1 mb-2">Are you sure you want to delete custom template?</div>
        <SpH5>
          <div>
            {
                (eventPageCustomTemplate && eventPageCustomTemplate.type)
                  ? (eventPageCustomTemplate && eventPageCustomTemplate.type)
                  : <>&#8211;</>
            }
          </div>
        </SpH5>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(RemoveCustomTemplate);
