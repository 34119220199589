import React, {
  useEffect,
  useState,
  useRef,
  createRef,
} from 'react';
import { connect } from 'react-redux';
import type { ContextRouter } from 'react-router-dom';

import ReactDragListView from 'react-drag-listview';

import {
  Row,
  Col,
  Steps,
  Layout,
  Checkbox,
  Input,
  Select,
  Card,
  Switch,
} from 'antd';
import {
  SpButton,
  SpH5,
  SpText,
  SpError,
  PaymentWrapper,
  PaymentFormWrapper,
} from 'components/DesignKit';
import imageOnError from 'utils/imageOnError';
import { formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';

import {
  eventDetailsAction,
  notificationActions,
  eventsActions,
} from 'store/actions';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';

import { NOTIFICATION } from 'store/actionTypes';

import {
  PAGES,
  COUNTRIES,
  MAX_AMOUNT,
  MESSAGES,
  API_STATUS,
} from 'appconstants';
import formatAmount from 'utils/formatAmount';
import SimpleReactValidator from 'simple-react-validator';
import PaymentClose from 'components/NavIcons/paymentClose';

import getPlaceholder from 'utils/getPaymentPagePlaceholder';
import Loading from 'components/Loading';

import reorderIcon from '../../assets/imgs/reorder-icon.png';
import arrowUp from '../../assets/imgs/arrow-upward.png';
import arrowDown from '../../assets/imgs/arrow-down.png';
import arrowUpDisabled from '../../assets/imgs/arrow-upward-disabled.png';
import arrowDownDisabled from '../../assets/imgs/arrow-down-disabled.png';
import removeNonLayout from '../../assets/imgs/removeNonLayout.png';

import PageDetails from './components/PageDetails';
import ReviewSeatedPage from './components/ReviewSeatedPage';

const { Step } = Steps;
const { Header } = Layout;
const { Option } = Select;

type Props = ContextRouter & {
  selectedAccount: Object,
  addNewEvents: Function,
  fetchEventDetails: Function,
  eventDetailsStatus: string,
  eventDetails: Object,
  validator: boolean,
  submitting: boolean,
  loading: boolean,
  history: {
    replace: Function,
  },
  setNotification: Function,
  addSeatedEvent: Boolean,
};

const AddSeatedPage = (props: Props) => {
  const {
    selectedAccount,
    history,
    addNewEvents,
    validator,
    submitting,
    loading,
    eventDetails,
    fetchEventDetails,
    eventDetailsStatus,
    setNotification,
    addSeatedEvent,
  } = props;
  const { gid } = props.match.params;
  const GOOGLE_MAPS_API_KEY = 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds';
  const [, forceUpdate] = useState();
  const pageDetailsRef = createRef();
  const [refArray, setRefArray] = useState([pageDetailsRef]);
  const [current, setCurrent] = useState(0);
  const [title, setTitle] = useState();
  const [content, setContent] = useState();
  const [logo, setLogo] = useState();
  const [termsAndConditions, setPageTerms] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [defaultLocation, setDefaultLocation] = useState(null);
  const [preview, setPreview] = useState();
  const [fileName, setFileName] = useState();
  const [contactUsEmail, setEmail] = useState();
  const [contactPhone, setContactPhone] = useState();
  const [phoneNumberCode, setPhoneCode] = useState();
  // eslint-disable-next-line no-unused-vars
  const [show, setShow] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [venueAddress, setAddress] = useState('');
  const [receiptNote, setReceiptNote] = useState('');
  const [coordinate, setCoordinates] = useState('');
  const [location, setLocation] = useState(null);
  const [phoneFormate, setPhoneFormate] = useState();
  const [primaryColor, setColorCode] = useState('#007bff');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [serviceFeeEnabled, setServiceFeeEnabled] = useState(false);
  const [serviceFeeRate, setServiceFeeRate] = useState(null);
  const [taxPercentage, setTaxPercentage] = useState(null);
  const [serviceFee, setServiceFee] = useState(null);
  const [supportOfflinePayment, setSupportOfflinePayment] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [enableTip, setEnableTip] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isTipOptional, setIsTipOptional] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [firstOption, setFirstOption] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [secondOption, setSecondOption] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [thirdOption, setThirdOption] = useState(null);
  const currencyName = (selectedAccount && (selectedAccount.currency) && (selectedAccount.currency.name));
  const supportedCurrency = (selectedAccount && selectedAccount.supportedCurrencies);
  const {
    name,
    largeLogo,
  } = selectedAccount;
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const [venueDetails, setVenueDetails] = useState();
  const [eventDate, setEventDate] = useState('');
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [duration, setDuration] = useState();
  const [layout, setLayout] = useState();
  const [selectedVenue, setSelectedVenu] = useState('');
  const [minCategory, setMinCategory] = useState(false);
  const [venueName, setVenueName] = useState();
  const [venueName1, setVenueName1] = useState();

  // eslint-disable-next-line no-unused-vars
  const account = { name: name || null, largeLogo: largeLogo || null };

  const pageData = {
    title,
    content,
    logo,
    preview,
    fileName,
    contactUsEmail,
    contactPhone,
    phoneNumberCode,
    termsAndConditions,
    location,
    venueAddress,
    eventDate,
    startTime,
    endTime,
    duration,
    receiptNote,
    coordinate,
    phoneFormate,
    serviceFeeEnabled,
    serviceFeeRate,
    serviceFee,
    taxPercentage,
    supportOfflinePayment,
    layout,
    redirectUrl,
    selectedVenue,
    venueName,
  };

  useEffect(() => {
    if (gid) {
      fetchEventDetails(gid);
    }
  }, []);

  const getVenueList = async () => {
    try {
      const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.SEATED_VENUE}`);
      setVenueDetails(entity.content);
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Failed to load venue details',
      });
    }
  };

  useEffect(() => {
    getVenueList();
  }, []);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      minLimit: {
        message: 'Stock limit should be greater than zero',
        rule: val => val >= 1,
      },
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) && (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) && (typeof val === 'string' ? Number(val && val.replace(/,/g, '')) : val) <= MAX_AMOUNT.LIMIT_USD),
      },
      minAmount: {
        message: 'Min amount should be greater than zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 1,
      },
      optionalMinAmount: {
        message: 'Min amount should be greater than or equal to zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      minValidAmount: {
        message: 'Max amount should be greater than zero',
        rule: val => val && Number(val) >= 1,
      },
      maxAmount: {
        message: 'Max amount should be greater than Min amount',
        rule: (val, params) => val && (Number(val) > Number(params[0])),
      },
      tipAmount: {
        message: 'Tip should be > 0 and <= to 100',
        rule: val => Number(val && val.replace(/,/g, '')) > 0 && Number(val && val.replace(/,/g, '')) <= 100,
      },
      tipValidation1: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
      tipValidation: {
        message: 'Please enter different tip value ',
        rule: (val, params) => val && (Number(params[0] && params[0].replace(/,/g, '')) !== Number(val && val.replace(/,/g, ''))),
      },
    },
  }));

  simpleValidator.current.purgeFields();

  const [productDetailsArray, setProductDetailsArray] = useState([]);
  const [nonLayoutDetailsArray, setNonLayoutArray] = useState([{
    type: 'PRICE_AMOUNT_STOCK_BASED',
    name: '',
    stockQuantity: null,
    price: null,
    currencyCode: currencyName,
    required: false,
    category: '',
  }]);


  useEffect(() => {
    if (addSeatedEvent) {
      let listDetails = [];
      if (venueDetails && venueDetails[0] && venueDetails[0].seatedVenueCategories) {
        listDetails = venueDetails && venueDetails[0].seatedVenueCategories;
        for (let i = 0; i < listDetails.length; i += 1) {
          const listObject = {};
          listObject.currencyCode = currencyName;
          listObject.price = null;
          listObject.name = '';
          listObject.category = listDetails[i].name;
          listObject.seatedVenueCategoryGid = listDetails[i].gid;
          listObject.type = 'TICKET';
          listObject.sequenceNumber = listDetails[i].seatedVenueId;
          listObject.required = true;
          listDetails[i].mataData = [];
          listDetails[i].mataData.push(listObject);
        }
      }
      setProductDetailsArray(venueDetails && venueDetails[0] && venueDetails[0].seatedVenueCategories);
    }
  }, [venueDetails, eventDetails]);

  useEffect(() => {
    if (venueDetails && pageData.layout) {
      const item = venueDetails.filter((data) => data.gid === pageData.layout);
      setSelectedVenu(item[0]);
      if (addSeatedEvent) {
        let listDetails = [];
        if (item[0] && item[0].seatedVenueCategories) {
          listDetails = item[0] && item[0].seatedVenueCategories;
          for (let i = 0; i < listDetails.length; i += 1) {
            const listObject = {};
            listObject.currencyCode = currencyName;
            listObject.price = null;
            listObject.name = '';
            listObject.category = listDetails[i].name;
            listObject.seatedVenueCategoryGid = listDetails[i].gid;
            listObject.type = 'TICKET';
            listObject.sequenceNumber = listDetails[i].seatedVenueId;
            listObject.required = true;
            listDetails[i].mataData = [];
            listDetails[i].mataData.push(listObject);
          }
        }
        setProductDetailsArray(item[0] && item[0].seatedVenueCategories);
      }
    }
  }, [venueDetails, pageData.layout]);

  const [customerDetailsArray, setCustomerDetailsArray] = useState([]);

  const addProductDetails = (event, item, i) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setShow(true);
    const listObject = {
      currencyCode: currencyName,
      price: null,
      name: '',
      category: item.name,
      seatedVenueCategoryGid: item.gid,
      type: 'TICKET',
      sequenceNumber: item.seatedVenueId,
      required: true,
    };
    const listDetails = [...productDetailsArray];
    listDetails[i].addInput = true;
    listDetails[i].mataData.push(listObject);
    setProductDetailsArray(listDetails);
  };

  const removeProductDetails = (i, j) => {
    const listDetails = [...productDetailsArray];
    if (productDetailsArray[i].mataData.length === 2) {
      listDetails[i].addInput = false;
      setShow(false);
    }
    listDetails[i].mataData.splice(j, 1);
    setProductDetailsArray(listDetails);
  };

  const setDisableSection = (val, i) => {
    if (!val) {
      setMinCategory(false);
    }
    const listDetails = [...productDetailsArray];
    listDetails[i].checked = val;
    setProductDetailsArray(listDetails);
  };

  const addCustomerDetails = (event) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setCustomerDetailsArray([...customerDetailsArray, {
      type: '',
      label: '',
      required: true,
      checked: 'checked',
      name: '',
      category: '',
    }]);
  };

  const removeCustomerDetails = (j) => {
    const list = [...customerDetailsArray];
    list.splice(j, 1);
    setCustomerDetailsArray(list);
  };

  const addNonLayOutDetails = (event) => {
    event.preventDefault();
    simpleValidator.current.hideMessages();
    forceUpdate();
    setNonLayoutArray([...nonLayoutDetailsArray, {
      type: 'PRICE_AMOUNT_STOCK_BASED',
      name: '',
      stockQuantity: null,
      price: null,
      currencyCode: currencyName,
      required: false,
      category: '',
    }]);
  };

  const removeNonLayOutDetails = (j) => {
    const list = [...nonLayoutDetailsArray];
    list.splice(j, 1);
    setNonLayoutArray(list);
  };

  useEffect(() => {
    if (!validator && redirect) {
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.EVENTS.ADD_SUCCESS,
      });
      history.push(PAGES.EVENTS);
    }
  }, [validator]);

  const getPlaceDetails = async (place) => {
    if (place) {
      const data = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(place)}&key=${GOOGLE_MAPS_API_KEY}`)
        .then(response => response.json())
        .catch(error => console.error(error));
      // eslint-disable-next-line
      const { place_id } = data.results[0];
      setDefaultLocation({
        label: place,
        value: {
          description: place,
          place_id,
          reference: place_id,
        },
      });
    }
  };

  const getLabel = (type) => {
    if (type === 'INPUT_NUMBER') {
      return 'Number';
    }
    if (type === 'INPUT_ALPHA_NUMERIC') {
      return 'Alpha Numeric';
    }
    if (type === 'INPUT_SINGLE_LINE_TEXT') {
      return 'Single Line Text';
    }
    return null;
  };

  useEffect(() => {
    if (gid && (eventDetails.gid)) {
      setTitle(eventDetails.title);
      setContent(eventDetails.description);
      setLogo(eventDetails.banner);
      setFileName(eventDetails.banner);
      setEmail(eventDetails.contactUsEmail);
      const phoneResult = (eventDetails && (eventDetails.contactUsPhone))
        ? findPhoneNumbersInText(eventDetails && eventDetails.contactUsPhone) : null;
      if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
        setPhoneCode(`+${phoneResult[0].number.countryCallingCode}`);
        setContactPhone(phoneResult[0].number.nationalNumber);
        const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
        const formater = data.substr(data.indexOf(' ') + 1);
        setPhoneFormate(formater);
      }
      setPreview(
        <img
          src={`${eventDetails.banner}?time=${new Date().getTime()}`}
          alt=""
          onError={event => imageOnError(event)}
          style={{ width: '100%' }}
        />,
      );
      setSelectedVenu(eventDetails.seatedVenue ? eventDetails.seatedVenue : 'noLayout');
      setVenueName1(eventDetails.seatedVenue ? eventDetails.seatedVenue : 'noLayout');
      setPageTerms(eventDetails.termsAndConditions);
      setColorCode(eventDetails.primaryColor || '#007bff');
      setAddress(eventDetails.venueAddress);
      getPlaceDetails(eventDetails.venueAddress);
      setCoordinates(eventDetails.coordinate);
      setRedirectUrl(eventDetails.redirectUrl);
      setLayout(eventDetails && eventDetails.seatedVenue && eventDetails.seatedVenue.gid);
      setDuration(eventDetails.eventDuration);
      setEndTime(eventDetails.eventEndTime);
      setStartTime(eventDetails.eventStartTime);
      setEventDate(eventDetails.eventDate);
      setServiceFeeEnabled(eventDetails.serviceFeeEnabled);
      setReceiptNote(eventDetails.receiptNote);
      setSupportOfflinePayment(eventDetails.supportOfflinePayment);
      setServiceFeeRate(eventDetails.serviceFeeRate
        ? ((eventDetails.serviceFeeRate)).toFixed(2) : null);
      setServiceFee(eventDetails.serviceFee
        ? ((eventDetails.serviceFee).toFixed(2)) : null);
      setTaxPercentage(eventDetails.tax
        ? (eventDetails.tax).toFixed(2) : null);
      const tipDetails = eventDetails.paymentPageMetaFields ? eventDetails.paymentPageMetaFields.filter(res => res.type === 'TIP') : '';
      if (tipDetails && tipDetails.length > 0) {
        setEnableTip(true);
        setIsTipOptional((tipDetails[0].required));
        setFirstOption((tipDetails[0].tipPercentages[0] / 100).toFixed(2));
        setSecondOption((tipDetails[0].tipPercentages[1] / 100).toFixed(2));
        setThirdOption((tipDetails[0].tipPercentages[2] / 100).toFixed(2));
      }
      const productDetails = eventDetails?.seatPageMetaTickets;
      const ticketList = productDetails.filter((item) => item.type === 'TICKET');
      const custList = productDetails.filter((item) => item.type !== 'TICKET' && item.type !== 'PRICE_AMOUNT_STOCK_BASED');
      const stockList = productDetails.filter((item) => item.type === 'PRICE_AMOUNT_STOCK_BASED');
      let listDetails = [];
      const stockDetails = [];
      if (venueDetails && venueDetails[0] && eventDetails.seatedVenue && eventDetails.seatedVenue.gid) {
        const findSeatedVenuIndex = venueDetails.findIndex((item) => item.gid === eventDetails.seatedVenue.gid);
        if (findSeatedVenuIndex >= 0) {
          listDetails = venueDetails[findSeatedVenuIndex].seatedVenueCategories;
        }
      }
      if (listDetails && listDetails.length > 0) {
        for (let i = 0; i < listDetails.length; i += 1) {
          for (let j = 0; j < ticketList.length; j += 1) {
            if (listDetails[i].gid === (ticketList[j].seatedVenueCategory && ticketList[j].seatedVenueCategory.gid)) {
              if (!listDetails[i].mataData) {
                const listObject = {};
                listObject.currencyCode = currencyName;
                listDetails[i].checked = false;
                listObject.price = formatAmount(ticketList[j].price / 100, currencyType);
                listObject.name = ticketList[j].name;
                listObject.category = listDetails[i].name;
                listObject.seatedVenueCategoryGid = listDetails[i].gid;
                listObject.type = 'TICKET';
                listObject.sequenceNumber = listDetails[i].seatedVenueId;
                listObject.required = true;
                listDetails[i].labelGid = ticketList[j].gid;
                listDetails[i].mataData = [];
                listDetails[i].mataData.push(listObject);
              } else if (listDetails[i].mataData) {
                const listObject = {};
                listObject.currencyCode = currencyName;
                listDetails[i].checked = false;
                listObject.price = formatAmount(ticketList[j].price / 100, currencyType);
                listObject.name = ticketList[j].name;
                listObject.category = listDetails[i].name;
                listObject.seatedVenueCategoryGid = listDetails[i].gid;
                listObject.type = 'TICKET';
                listObject.sequenceNumber = listDetails[i].seatedVenueId;
                listObject.required = true;
                listDetails[i].labelGid = ticketList[j].gid;
                listDetails[i].mataData.push(listObject);
              }
            } else if (!listDetails[i].mataData) {
              const listObject = {};
              listObject.currencyCode = currencyName;
              listDetails[i].checked = false;
              listObject.price = null;
              listObject.name = '';
              listObject.category = listDetails[i].name;
              listObject.seatedVenueCategoryGid = listDetails[i].gid;
              listObject.type = 'TICKET';
              listObject.sequenceNumber = listDetails[i].seatedVenueId;
              listObject.required = true;
              listDetails[i].labelGid = '';
              listDetails[i].mataData = [];
              listDetails[i].mataData.push(listObject);
            } else if (listDetails[i].mataData) {
              const listObject = {};
              listObject.currencyCode = currencyName;
              listDetails[i].checked = false;
              listObject.price = null;
              listObject.name = '';
              listObject.category = listDetails[i].name;
              listObject.seatedVenueCategoryGid = listDetails[i].gid;
              listObject.type = 'TICKET';
              listObject.sequenceNumber = listDetails[i].seatedVenueId;
              listObject.required = true;
              listDetails[i].labelGid = '';
              listDetails[i].mataData.push(listObject);
            }
          }
        }
      }

      if (ticketList && ticketList.length === 0 && !eventDetails.seatedVenue) {
        for (let i = 0; i < stockList.length; i += 1) {
          const listObject = {};
          listObject.currencyCode = currencyName;
          listObject.checked = false;
          listObject.price = formatAmount(stockList[i].price / 100, currencyType);
          listObject.name = stockList[i].name;
          listObject.category = stockList[i].name;
          listObject.type = stockList[i].type;
          listObject.sequenceNumber = null;
          listObject.required = stockList[i].required;
          listObject.stockQuantity = stockList[i].stockQuantity;
          stockDetails.push(listObject);
        }
      }

      for (let i = 0; i < custList.length; i += 1) {
        custList[i].label = getLabel(custList[i].type);
        custList[i].checked = 'checked';
        custList[i].disableRequired = true;
        if (eventDetails.seatedVenue) {
          custList[i].name = custList[i].type;
        }
        delete custList[i].category;
        delete custList[i].closingDateTime;
        delete custList[i].createdAt;
        delete custList[i].currency;
        delete custList[i].deleted;
        delete custList[i].gid;
        // delete custList[i].name;
        delete custList[i].price;
        delete custList[i].quantityMaxLimit;
        delete custList[i].quantityMinLimit;
        delete custList[i].seatPageMetaId;
        delete custList[i].seatedVenueCategory;
        delete custList[i].seatedVenueCategoryGid;
        delete custList[i].sequenceNumber;
        delete custList[i].updatedAt;
      }
      for (let r = 0; r < listDetails.length; r += 1) {
        const tempArray = [];
        for (let s = 0; s < listDetails[r].mataData.length; s += 1) {
          if (listDetails[r].mataData[s].name) {
            tempArray.push(listDetails[r].mataData[s]);
          }
        }
        listDetails[r].mataData = tempArray;
      }
      setProductDetailsArray(listDetails);
      setCustomerDetailsArray(custList);
      setNonLayoutArray(stockDetails);
    }
  }, [eventDetailsStatus, venueDetails]);

  useEffect(() => {
    if (current === 3 && !submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  useEffect(() => {
    setRefArray(existingElements => (
      [...existingElements]
    ));
  }, []);

  const steps = [
    {
      title: 'Event Details',
    },
    {
      title: 'Payment Details',
    },
    {
      title: 'Customer Details',
    },
    {
      title: 'Review',
    },
  ];

  const next = (event) => {
    const validationArray = [];
    const valuesArray = [];
    if (current === 0) {
      validationArray.push(refArray[0].current.validate());
      valuesArray.push(refArray[0].current.getValues());
      if (!validationArray[0]) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      setVenueName(valuesArray[0].venueName);
      setTitle(valuesArray[0].title);
      setContent(valuesArray[0].content);
      setLogo(valuesArray[0].logo ? valuesArray[0].logo : 'logo_test');
      setPreview(valuesArray[0].preview);
      setFileName(valuesArray[0].fileName);
      setEmail(valuesArray[0].contactUsEmail);
      setContactPhone(valuesArray[0].contactPhone);
      setPhoneFormate(valuesArray[0].phoneFormate);
      setPhoneCode(valuesArray[0].phoneNumberCode);
      setPageTerms(valuesArray[0].termsAndConditions ? valuesArray[0].termsAndConditions : null);
      setAddress(valuesArray[0].venueAddress ? valuesArray[0].venueAddress : null);
      setReceiptNote(valuesArray[0].receiptNote ? valuesArray[0].receiptNote : null);
      setCoordinates(valuesArray[0].coordinate ? valuesArray[0].coordinate : null);
      setRedirectUrl(valuesArray[0].redirectUrl ? valuesArray[0].redirectUrl : null);
      setLayout(valuesArray[0].layout ? valuesArray[0].layout : null);
      setDuration(valuesArray[0].duration ? valuesArray[0].duration : null);
      setEndTime(valuesArray[0].endTime ? valuesArray[0].endTime : null);
      setEventDate(valuesArray[0].eventDate ? valuesArray[0].eventDate : null);
      setStartTime(valuesArray[0].startTime ? valuesArray[0].startTime : null);
      setLocation(valuesArray[0].location ? valuesArray[0].location : null);
      setColorCode(valuesArray[0].primaryColor);
      setServiceFeeEnabled(valuesArray[0].serviceFeeEnabled);
      setSupportOfflinePayment(valuesArray[0].supportOfflinePayment);
      if (valuesArray[0].serviceFeeRate) {
        const feerate = Number((valuesArray[0].serviceFeeRate).replace(/[^\d]/g, ''));
        setServiceFeeRate(feerate);
      }
      if (valuesArray[0].taxPercentage) {
        const taxrate = Number((valuesArray[0].taxPercentage).replace(/[^\d]/g, ''));
        setTaxPercentage(taxrate);
      } else setTaxPercentage(null);
      if (valuesArray[0].serviceFee) {
        const fee = Number((valuesArray[0].serviceFee).replace(/[^\d]/g, ''));
        setServiceFee(fee);
      }
      if (valuesArray[0].serviceFeeEnabled === false) {
        setServiceFeeRate(null);
        setServiceFee(null);
      }
      simpleValidator.current.hideMessages();
    } else if (current === 1) {
      if (gid && !eventDetails.seatedVenue) {
        const metaInfo = nonLayoutDetailsArray.filter((item) => !item.checked);
        if (metaInfo.length < 1) {
          setMinCategory(true);
          return;
        }
      } else {
        const metaInfo = productDetailsArray.filter((item) => !item.checked);
        if (metaInfo.length < 1) {
          setMinCategory(true);
          return;
        }
      }
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
      simpleValidator.current.hideMessages();
      forceUpdate();
    } else if (current === 2) {
      event.preventDefault();
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return;
      }
      setCurrent(current + 1);
    } else if (current === 3) {
      setDisableButton(true);
      const contactNumber = `${pageData.phoneNumberCode}${pageData.contactPhone}`;
      if (serviceFeeEnabled && (serviceFeeRate === null)) {
        pageData.serviceFeeRate = 0;
      }
      if (serviceFeeEnabled && (serviceFee === null)) {
        pageData.serviceFee = 0;
      }
      const regex = /(style=".+?")/gm;
      const subst = '';
      const payload = {
        coordinate: pageData.coordinate,
        title: pageData.title,
        banner: pageData.logo,
        description: ((pageData.content).replaceAll('&nbsp;', '')).replaceAll(regex, subst),
        eventDate: pageData.eventDate,
        eventStartTime: pageData.startTime,
        eventEndTime: pageData.endTime,
        contactUsEmail: pageData.contactUsEmail,
        contactUsPhone: contactNumber,
        serviceFeeRate: pageData.serviceFeeRate,
        serviceFee: pageData.serviceFee,
        tax: pageData.taxPercentage,
        venueAddress: pageData.venueAddress,
        status: 'ACTIVE',
        redirectUrl: 'https://swirepay.com',
        venueName: selectedVenue.venueName,
        eventDuration: pageData.duration,
        serviceFeeEnabled: pageData.serviceFeeEnabled,
        seatedVenueGid: pageData.layout,
        supportOfflinePayment: pageData.supportOfflinePayment,
        seatPageMetaTickets: [
        ],
      };
      const metaInfo = productDetailsArray.filter((item) => !item.checked);
      const finalMeta = [];
      if (venueName === 'noLayout') {
        for (let i = 0; i < nonLayoutDetailsArray.length; i += 1) {
          nonLayoutDetailsArray[i].category = nonLayoutDetailsArray[i].name;
          nonLayoutDetailsArray[i].price = nonLayoutDetailsArray[i].price
            ? Number(((nonLayoutDetailsArray[i].price).replace('.', '')).replace(',', '')) : null;
          finalMeta.push(nonLayoutDetailsArray[i]);
        }
      } else {
        for (let i = 0; i < metaInfo.length; i += 1) {
          for (let j = 0; j < metaInfo[i].mataData.length; j += 1) {
            if (typeof metaInfo[i].mataData[j].price === 'string') {
              metaInfo[i].mataData[j].price = metaInfo[i].mataData[j].price
                ? Number(((metaInfo[i].mataData[j].price).replace('.', '').replace(',', ''))) : null;
            } else {
              metaInfo[i].mataData[j].price = metaInfo[i].mataData[j].price ? metaInfo[i].mataData[j].price : null;
            }
            finalMeta.push(metaInfo[i].mataData[j]);
          }
        }
      }
      const metaData = [...finalMeta, ...customerDetailsArray];
      for (let i = 0; i < metaData.length; i += 1) {
        metaData[i].sequenceNumber = i + 1;
      }
      payload.seatPageMetaTickets = metaData;
      addNewEvents(payload);
      setRedirect(true);
    }
  };

  const prev = (event) => {
    event.preventDefault();
    setDisableButton(false);
    setCurrent(current - 1);
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const dragdata = [...customerDetailsArray];
      const item = dragdata.splice(fromIndex, 1)[0];
      dragdata.splice(toIndex, 0, item);
      setCustomerDetailsArray(dragdata);
    },
    nodeSelector: 'div',
    handleSelector: 'p',
    lineClassName: 'global-drag-line',
  };

  const onArrowUp = (fromIndex) => {
    const dragdata = [...customerDetailsArray];
    const item = dragdata.splice(fromIndex, 1)[0];
    const toIndex = fromIndex - 1;
    dragdata.splice(toIndex, 0, item);
    setCustomerDetailsArray(dragdata);
  };

  const onArrowDown = (fromIndex) => {
    const dragdata = [...customerDetailsArray];
    const item = dragdata.splice(fromIndex, 1)[0];
    const toIndex = fromIndex + 1;
    dragdata.splice(toIndex, 0, item);
    setCustomerDetailsArray(dragdata);
  };

  if (loading && redirect) {
    return <Loading />;
  }

  const updateLayout = (val) => {
    if (!gid) {
      if (val === 'noLayout') {
        setCustomerDetailsArray([{
          type: 'INPUT_EMAIL',
          label: '',
          required: true,
          checked: 'checked',
          disableRequired: true,
          name: '',
          category: '',
        },
        {
          type: 'INPUT_PHONE',
          label: '',
          required: true,
          checked: 'checked',
          disableRequired: true,
          name: '',
          category: '',
        }]);
      } else if (val !== 'noLayout') {
        setCustomerDetailsArray([]);
      }
    }
  };

  return (
    <>
      {
        (gid && eventDetailsStatus === API_STATUS.SUCCESS) && (
          <Layout style={{ marginLeft: '0px', minHeight: '0px' }}>
            <Header style={{ color: '#fff', fontSize: '20px', background: '#18294c !important' }}>
              <Row type="flex" justify="space-between">
                <Col className="d-flex align-items-center">
                  Clone a Seated event
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <span style={{ cursor: 'pointer' }} onClick={() => history.push(PAGES.EVENTS)}>
                    <PaymentClose />
                  </span>
                </Col>
              </Row>
            </Header>
            <PaymentWrapper style={{ background: current === 3 ? primaryColor : '' }}>
              <Steps current={current} className="mb-4 paymentPage" style={{ width: '100%' }}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <PaymentFormWrapper style={{ width: current === 3 ? '964px' : '800px' }}>
                <div>
                  {current === 0 && (
                    <PageDetails
                      ref={refArray[0]}
                      selectedAccount={selectedAccount}
                      pageData={pageData}
                      isIndia={isIndia}
                      isGid={gid}
                      updateLayout={updateLayout}
                      venueName1={venueName1}
                    />
                  )}
                  {current === 1 && (
                    <>
                      <div>
                        {
                              minCategory && (
                                <SpError>
                                  Minimum 1 category required
                                </SpError>
                              )
                            }
                      </div>
                      {
                        venueName !== 'noLayout' ? (
                          <div>
                            <div>
                              {
                                productDetailsArray && productDetailsArray.length !== 0 && productDetailsArray.map((item, i) => (
                                  <Card className="mb-4 pt-4">
                                    <Row>
                                      <Col span={15} push={4}>
                                        <label htmlFor=""><SpH5>Category</SpH5></label>
                                        <div>
                                          <Select
                                            style={{ width: '50%' }}
                                            value={item.name}
                                            disabled
                                          >
                                            <Option key={1} value="PRICE_AMOUNT_FIXED">Fixed Amount</Option>
                                            <Option key={2} value="PRICE_AMOUNT_CUSTOMER_DECIDED">Customer Decided Amount</Option>
                                            <Option key={3} value="PRICE_AMOUNT_QUANTITY_BASED">Quantity Based Amount</Option>
                                            <Option key={4} value="COMBO_BOX">Combo Box</Option>
                                          </Select>
                                        </div>
                                      </Col>
                                      <Col span={8} push={4}>
                                        <Switch
                                          defaultChecked={item.checked}
                                          onChange={val => setDisableSection(val, i)}
                                          className="mr-2"
                                        /><SpText>Disable Section</SpText>
                                      </Col>
                                    </Row>
                                    {
                                      item.mataData && item.mataData.map((data, j) => (
                                        <div>
                                          <Row className="mt-4">
                                            <Col span={16} push={4}>
                                              <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                              <Input
                                                placeholder="Enter the field label"
                                                value={data.name}
                                                disabled={item.checked}
                                                maxlength="100"
                                                onChange={(e) => {
                                                  const list = [...productDetailsArray];
                                                  list[i].mataData[j].name = e.currentTarget.value;
                                                  setProductDetailsArray(list);
                                                }}
                                              />
                                              <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                              {
                                                !item?.checked && (
                                                  <SpError>
                                                    {simpleValidator.current.message(`type${i + 1}`, productDetailsArray[i].mataData[j].name,
                                                      'required', { messages: { required: 'The label field is required' } })}
                                                  </SpError>
                                                )
                                              }
                                            </Col>
                                          </Row>
                                          <Row className="mt-4">
                                            <Col span={16} push={4}>
                                              <label htmlFor=""><SpH5>Amount</SpH5></label>
                                              <div className="d-flex">
                                                <div style={{ width: '15%' }}>
                                                  <Select
                                                    className="w-100"
                                                    defaultValue={data.currencyCode}
                                                    onChange={(value) => {
                                                      const list = [...productDetailsArray];
                                                      list[i].mataData[j].currencyCode = value;
                                                      setProductDetailsArray(list);
                                                    }}
                                                  >
                                                    {supportedCurrency.map((currency) => (
                                                      <Option key={currency} value={currency}>
                                                        {currency}
                                                      </Option>
                                                    ))}
                                                  </Select>
                                                </div>
                                                <div style={{ width: '35%' }}>
                                                  <Input
                                                    placeholder="0.00"
                                                    value={typeof data.price === 'string' ? data.price : (data.price / 100)}
                                                    disabled={item.checked}
                                                    onChange={(e) => {
                                                      const list = [...productDetailsArray];
                                                      const regex = /^\d*\.?\d*$/;
                                                      const { value } = e.currentTarget;
                                                      const amount = value.replace(/\s/g, '');
                                                      if (regex.test(amount) || amount === '') {
                                                        list[i].mataData[j].price = amount;
                                                      } else if (!regex.test(amount)) {
                                                        list[i].mataData[j].price = 0.00;
                                                      }
                                                      setProductDetailsArray(list);
                                                    }}
                                                    onKeyPress={e => {
                                                      const keyCode = e.charCode || e.which;
                                                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                        e.preventDefault();
                                                      }
                                                    }}
                                                    onBlur={e => {
                                                      if (e.currentTarget.value) {
                                                        const list = [...productDetailsArray];
                                                        list[i].mataData[j].price = formatAmount(e.currentTarget.value, currencyType);
                                                        setProductDetailsArray(list);
                                                      }
                                                    }}
                                                  />
                                                  {
                                                    !item?.checked && (
                                                      <SpError>
                                                        {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].mataData[j].price,
                                                          'required|amount', { messages: { required: 'The amount field is required' } })}
                                                      </SpError>
                                                    )
                                                  }

                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                          {item.addInput ? (
                                            <Row className="mt-4 mb-4">
                                              <Col span={16} push={4}>
                                                <SpButton
                                                  type="secondary"
                                                  style={{ width: '50%' }}
                                                  onClick={() => removeProductDetails(i, j)}
                                                >
                                                  {'\u2212'}&nbsp;Remove Input Field
                                                </SpButton>
                                              </Col>
                                            </Row>

                                          ) : ''}
                                        </div>
                                      ))
                                    }

                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <SpButton
                                          type="secondary"
                                          style={{ width: '50%' }}
                                          onClick={(e) => addProductDetails(e, item, i)}
                                          disabled={item.checked}
                                        >
                                          {'\u002B'}&nbsp;Add Another Input Field
                                        </SpButton>
                                      </Col>
                                    </Row>
                                  </Card>

                                ))
                              }

                            </div>
                          </div>
                        ) : (
                          <div>
                            <div>
                              {
                                nonLayoutDetailsArray && nonLayoutDetailsArray.length !== 0 && nonLayoutDetailsArray.map((item, i) => (
                                  <Card className="mb-4 pt-4">
                                    <div>
                                      <Row type="flex" justify="space-between" className="mt-4">
                                        <Col span={16} push={4}>
                                          <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                          <Input
                                            placeholder="Enter text..."
                                            value={item.name}
                                            maxlength="100"
                                            onChange={(e) => {
                                              const list = [...nonLayoutDetailsArray];
                                              list[i].name = e.currentTarget.value;
                                              setNonLayoutArray(list);
                                            }}
                                          />
                                          <SpError>
                                            {simpleValidator.current.message(`type${i + 1}`, nonLayoutDetailsArray[i].name,
                                              'required', { messages: { required: 'The label field is required' } })}
                                          </SpError>
                                        </Col>
                                        <Col className="d-flex align-items-center">
                                          {
                                            (nonLayoutDetailsArray.length > 1) ? (
                                              <div
                                                className="mr-2"
                                                onClick={() => removeNonLayOutDetails(i)}
                                              >
                                                <img src={removeNonLayout} alt="" />
                                              </div>
                                            ) : null
                                          }
                                        </Col>
                                      </Row>
                                      <Row className="mt-4">
                                        <Col span={16} push={4}>
                                          <label htmlFor=""><SpH5>Stock</SpH5></label>
                                          <Input
                                            placeholder="No.of Tickets"
                                            value={item.stockQuantity}
                                            maxlength="100"
                                            onChange={(e) => {
                                              const list = [...nonLayoutDetailsArray];
                                              const regex = /^[0-9]+$/;
                                              const { value } = e.currentTarget;
                                              const limit = value.replace(/\s/g, '');
                                              if (regex.test(limit) || limit === '') {
                                                list[i].stockQuantity = limit;
                                              } else if (!regex.test(limit)) {
                                                list[i].stockQuantity = 1;
                                              }
                                              setNonLayoutArray(list);
                                            }}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {simpleValidator.current.message(`stock${i + 1}`, nonLayoutDetailsArray[i].stockQuantity,
                                              'required|minLimit')}
                                          </SpError>
                                        </Col>
                                      </Row>
                                      <Row className="mt-4">
                                        <Col span={16} push={4}>
                                          <label htmlFor=""><SpH5>Amount</SpH5></label>
                                          <div className="d-flex">
                                            <div style={{ width: '15%' }}>
                                              <Select
                                                className="w-100"
                                                defaultValue={item.currencyCode}
                                                onChange={(value) => {
                                                  const list = [...nonLayoutDetailsArray];
                                                  list[i].currencyCode = value;
                                                  setNonLayoutArray(list);
                                                }}
                                              >
                                                {supportedCurrency.map((currency) => (
                                                  <Option key={currency} value={currency}>
                                                    {currency}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </div>
                                            <div style={{ width: '35%' }}>
                                              <Input
                                                placeholder="0.00"
                                                value={typeof item.price === 'string' ? item.price : (item.price / 100)}
                                                onChange={(e) => {
                                                  const list = [...nonLayoutDetailsArray];
                                                  const regex = /^\d*\.?\d*$/;
                                                  const { value } = e.currentTarget;
                                                  const amount = value.replace(/\s/g, '');
                                                  if (regex.test(amount) || amount === '') {
                                                    list[i].price = amount;
                                                  } else if (!regex.test(amount)) {
                                                    list[i].price = 0.00;
                                                  }
                                                  setNonLayoutArray(list);
                                                }}
                                                onKeyPress={e => {
                                                  const keyCode = e.charCode || e.which;
                                                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                                onBlur={e => {
                                                  if (e.currentTarget.value) {
                                                    const list = [...nonLayoutDetailsArray];
                                                    list[i].price = formatAmount(e.currentTarget.value, currencyType);
                                                    setNonLayoutArray(list);
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <SpError>
                                            {simpleValidator.current.message(`amount${i + 1}`, nonLayoutDetailsArray[i].price, 'required|amount',
                                              { messages: { required: 'The amount field is required' } })}
                                          </SpError>
                                        </Col>
                                      </Row>
                                    </div>

                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <SpButton
                                          type="secondary"
                                          style={{ width: '50%' }}
                                          onClick={addNonLayOutDetails}
                                        >
                                          {'\u002B'}&nbsp;Add Another Input Field
                                        </SpButton>
                                      </Col>
                                    </Row>
                                  </Card>

                                ))
                              }

                            </div>
                          </div>
                        )
                      }
                    </>
                  )}
                  {current === 2 && (
                    <div>
                      <ReactDragListView
                        {...dragProps}
                      >
                        {
                          customerDetailsArray.map((item, j) => (
                            <div
                              style={{
                                backgroundColor: '#fff',
                                padding: '10px',
                                margin: '25px 0',
                                borderRadius: '5px',
                                cursor: 'pointer',
                              }}
                            >
                              <p>
                                <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                                  <Col className="d-flex align-items-center">
                                    <div className="ml-2" />
                                  </Col>
                                  <Col className="d-flex align-items-center">
                                    {
                                      j === 0 ? (
                                        <div className="mr-2">
                                          <img src={arrowUpDisabled} alt="" />
                                        </div>
                                      ) : (
                                        <div
                                          className="mr-2"
                                          onClick={() => onArrowUp(j)}
                                        >
                                          <img src={arrowUp} alt="" />
                                        </div>
                                      )
                                    }
                                    {
                                      j === (customerDetailsArray.length - 1) ? (
                                        <div
                                          className="mr-2"
                                        >
                                          <img src={arrowDownDisabled} alt="" />
                                        </div>
                                      ) : (
                                        <div
                                          className="mr-2"
                                          onClick={() => onArrowDown(j)}
                                        >
                                          <img src={arrowDown} alt="" />
                                        </div>
                                      )
                                    }

                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Input Field Type</SpH5></label>
                                    <div>
                                      <Select
                                        style={{ width: '50%' }}
                                        placeholder="Select field type"
                                        value={item.type || undefined}
                                        disabled={item.disableRequired ? true : ''}
                                        onChange={(e) => {
                                          const list = [...customerDetailsArray];
                                          list[j].type = e;
                                          setCustomerDetailsArray(list);
                                        }}
                                      >
                                        <Option key={1} value="INPUT_EMAIL">Email</Option>
                                        <Option key={2} value="INPUT_PHONE">Phone</Option>
                                        <Option key={3} value="INPUT_NUMBER">Number</Option>
                                        <Option key={4} value="INPUT_ALPHA_NUMERIC">Alpha Numeric</Option>
                                        <Option key={5} value="INPUT_SINGLE_LINE_TEXT">Single Line Text</Option>
                                        <Option key={6} value="PROMOTIONAL_CODES">Promotional Code</Option>
                                      </Select>
                                    </div>
                                    <SpError>
                                      {simpleValidator.current.message(`type${j + 1}`, customerDetailsArray[j].name, 'required',
                                        { messages: { required: 'Please select field type' } })}
                                    </SpError>
                                  </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                    <Input
                                      placeholder={getPlaceholder(item.type || '')}
                                      value={item.name}
                                      maxlength="100"
                                      onChange={(e) => {
                                        const list = [...customerDetailsArray];
                                        list[j].label = e.currentTarget.value;
                                        list[j].name = e.currentTarget.value;
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                    <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                    <SpError>
                                      {simpleValidator.current.message(`Label${j + 1}`, customerDetailsArray[j].name, 'required',
                                        { messages: { required: 'The label field is required' } })}
                                    </SpError>
                                  </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                    <Checkbox
                                      className="ml-4"
                                      disabled={item.disableRequired || false}
                                      checked={!(item.required)}
                                      onChange={({ target: { checked } }) => {
                                        const list = [...customerDetailsArray];
                                        list[j].required = !checked;
                                        if (checked) {
                                          list[j].checked = '';
                                        } else if (!checked) {
                                          list[j].checked = 'checked';
                                        }
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                {
                                  (!item.disableRequired) ? (
                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <SpButton
                                          type="secondary"
                                          style={{ width: '50%' }}
                                          onClick={() => removeCustomerDetails(j)}
                                        >
                                          {'\u2212'}&nbsp;Remove Input Field
                                        </SpButton>
                                      </Col>
                                    </Row>
                                  ) : ''
                                }
                              </p>
                            </div>
                          ))
                        }
                      </ReactDragListView>
                      <Row className="mt-4">
                        <Col span={16} push={8}>
                          <SpButton
                            type="secondary"
                            style={{ width: '50%' }}
                            onClick={addCustomerDetails}
                          >
                            {'\u002B'}&nbsp;Add Another Input Field
                          </SpButton>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {
                    current === 3 && (
                      <ReviewSeatedPage
                        pageData={pageData}
                        selectedAccount={selectedAccount}
                      />
                    )
                  }
                </div>
                <div className="d-flex justify-content-end mt-auto mr-4">
                  {current > 0 && (
                    <SpButton type="secondary" className="mr-4" onClick={prev}>
                      Back
                    </SpButton>
                  )}
                  {current < steps.length - 1 && (
                    <SpButton type="primary" onClick={next}>
                      Next
                    </SpButton>
                  )}
                  {current === steps.length - 1 && (
                    <SpButton type="primary" onClick={next} disabled={disableButton}>
                      Create
                    </SpButton>
                  )}
                </div>
              </PaymentFormWrapper>
            </PaymentWrapper>
          </Layout>
        )
      }
      {
        (!gid) && (
          <Layout style={{ marginLeft: '0px', minHeight: '0px' }}>
            <Header style={{ color: '#fff', fontSize: '20px', background: '#18294c !important' }}>
              <Row type="flex" justify="space-between">
                <Col className="d-flex align-items-center">
                  Create a Seated event
                </Col>
                <Col className="d-flex align-items-center justify-content-end">
                  <span style={{ cursor: 'pointer' }} onClick={() => history.push(PAGES.EVENTS)}>
                    <PaymentClose />
                  </span>
                </Col>
              </Row>
            </Header>
            <PaymentWrapper style={{ background: current === 3 ? primaryColor : '' }}>
              <Steps current={current} className="mb-4 paymentPage" style={{ width: '100%' }}>
                {steps.map(item => (
                  <Step key={item.title} title={item.title} />
                ))}
              </Steps>
              <PaymentFormWrapper style={{ width: current === 3 ? '964px' : '800px' }}>
                <div>
                  {current === 0 && (
                    <PageDetails
                      ref={refArray[0]}
                      selectedAccount={selectedAccount}
                      pageData={pageData}
                      isIndia={isIndia}
                      updateLayout={updateLayout}
                    />
                  )}
                  {current === 1 && (
                    <>
                      {
                        venueName !== 'noLayout' ? (
                          <div>
                            {
                              minCategory && (
                                <SpError>
                                  Minimum 1 category required
                                </SpError>
                              )
                            }
                            <div>
                              {
                                productDetailsArray && productDetailsArray.length !== 0 && productDetailsArray.map((item, i) => (
                                  <Card className="mb-4 pt-4">
                                    <Row>
                                      <Col span={15} push={4}>
                                        <label htmlFor=""><SpH5>Category</SpH5></label>
                                        <div>
                                          <Select
                                            style={{ width: '50%' }}
                                            value={item.name}
                                            disabled
                                          >
                                            <Option key={1} value="PRICE_AMOUNT_FIXED">Fixed Amount</Option>
                                            <Option key={2} value="PRICE_AMOUNT_CUSTOMER_DECIDED">Customer Decided Amount</Option>
                                            <Option key={3} value="PRICE_AMOUNT_QUANTITY_BASED">Quantity Based Amount</Option>
                                            <Option key={4} value="COMBO_BOX">Combo Box</Option>
                                          </Select>
                                        </div>
                                      </Col>
                                      <Col span={8} push={4}>
                                        <Switch
                                          defaultChecked={item.checked}
                                          onChange={val => setDisableSection(val, i)}
                                          className="mr-2"
                                        /><SpText>Disable Section</SpText>
                                      </Col>
                                    </Row>
                                    {
                                      item.mataData.map((data, j) => (
                                        <div>
                                          <Row className="mt-4">
                                            <Col span={16} push={4}>
                                              <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                              <Input
                                                placeholder="Enter the field label"
                                                value={data.name}
                                                disabled={item.checked}
                                                maxlength="100"
                                                onChange={(e) => {
                                                  const list = [...productDetailsArray];
                                                  list[i].mataData[j].name = e.currentTarget.value;
                                                  setProductDetailsArray(list);
                                                }}
                                              />
                                              <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                              {
                                                !item?.checked && (
                                                  <SpError>
                                                    {simpleValidator.current.message(`type${i + 1}`, productDetailsArray[i].mataData[j].name,
                                                      'required', { messages: { required: 'The label field is required' } })}
                                                  </SpError>
                                                )
                                              }
                                            </Col>
                                          </Row>
                                          <Row className="mt-4">
                                            <Col span={16} push={4}>
                                              <label htmlFor=""><SpH5>Amount</SpH5></label>
                                              <div className="d-flex">
                                                <div style={{ width: '15%' }}>
                                                  <Select
                                                    className="w-100"
                                                    defaultValue={data.currencyCode}
                                                    onChange={(value) => {
                                                      const list = [...productDetailsArray];
                                                      list[i].mataData[j].currencyCode = value;
                                                      setProductDetailsArray(list);
                                                    }}
                                                  >
                                                    {supportedCurrency.map((currency) => (
                                                      <Option key={currency} value={currency}>
                                                        {currency}
                                                      </Option>
                                                    ))}
                                                  </Select>
                                                </div>
                                                <div style={{ width: '35%' }}>
                                                  <Input
                                                    placeholder="0.00"
                                                    value={typeof data.price === 'string' ? data.price : (data.price / 100)}
                                                    disabled={item.checked}
                                                    onChange={(e) => {
                                                      const list = [...productDetailsArray];
                                                      const regex = /^\d*\.?\d*$/;
                                                      const { value } = e.currentTarget;
                                                      const amount = value.replace(/\s/g, '');
                                                      if (regex.test(amount) || amount === '') {
                                                        list[i].mataData[j].price = amount;
                                                      } else if (!regex.test(amount)) {
                                                        list[i].mataData[j].price = 0.00;
                                                      }
                                                      setProductDetailsArray(list);
                                                    }}
                                                    onKeyPress={e => {
                                                      const keyCode = e.charCode || e.which;
                                                      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                        e.preventDefault();
                                                      }
                                                    }}
                                                    onBlur={e => {
                                                      if (e.currentTarget.value) {
                                                        const list = [...productDetailsArray];
                                                        list[i].mataData[j].price = formatAmount(e.currentTarget.value, currencyType);
                                                        setProductDetailsArray(list);
                                                      }
                                                    }}
                                                  />
                                                  {
                                                    !item?.checked && (
                                                      <SpError>
                                                        {simpleValidator.current.message(`amount${i + 1}`, productDetailsArray[i].mataData[j].price,
                                                          'required|amount', { messages: { required: 'The amount field is required' } })}
                                                      </SpError>
                                                    )
                                                  }
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                          {item.addInput ? (
                                            <Row className="mt-4 mb-4">
                                              <Col span={16} push={4}>
                                                <SpButton
                                                  type="secondary"
                                                  style={{ width: '50%' }}
                                                  onClick={() => removeProductDetails(i, j)}
                                                >
                                                  {'\u2212'}&nbsp;Remove Input Field
                                                </SpButton>
                                              </Col>
                                            </Row>

                                          ) : ''}
                                        </div>
                                      ))
                                    }

                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <SpButton
                                          type="secondary"
                                          style={{ width: '50%' }}
                                          onClick={(e) => addProductDetails(e, item, i)}
                                          disabled={item.checked}
                                        >
                                          {'\u002B'}&nbsp;Add Another Input Field
                                        </SpButton>
                                      </Col>
                                    </Row>
                                  </Card>

                                ))
                              }

                            </div>
                          </div>
                        ) : (
                          <div>
                            <div>
                              {
                                nonLayoutDetailsArray && nonLayoutDetailsArray.length !== 0 && nonLayoutDetailsArray.map((item, i) => (
                                  <Card className="mb-4 pt-4">
                                    <div>
                                      <Row type="flex" justify="space-between" className="mt-4">
                                        <Col span={16} push={4}>
                                          <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                          <Input
                                            placeholder="Enter text..."
                                            value={item.name}
                                            maxlength="100"
                                            onChange={(e) => {
                                              const list = [...nonLayoutDetailsArray];
                                              list[i].name = e.currentTarget.value;
                                              setNonLayoutArray(list);
                                            }}
                                          />
                                          <SpError>
                                            {simpleValidator.current.message(`type${i + 1}`, nonLayoutDetailsArray[i].name,
                                              'required', { messages: { required: 'The label field is required' } })}
                                          </SpError>
                                        </Col>
                                        <Col className="d-flex align-items-center">
                                          {
                                            (nonLayoutDetailsArray.length > 1) ? (
                                              <div
                                                className="mr-2"
                                                onClick={() => removeNonLayOutDetails(i)}
                                              >
                                                <img src={removeNonLayout} alt="" />
                                              </div>
                                            ) : null
                                          }
                                        </Col>
                                      </Row>
                                      <Row className="mt-4">
                                        <Col span={16} push={4}>
                                          <label htmlFor=""><SpH5>Stock</SpH5></label>
                                          <Input
                                            placeholder="No.of Tickets"
                                            value={item.stockQuantity}
                                            maxlength="100"
                                            onChange={(e) => {
                                              const list = [...nonLayoutDetailsArray];
                                              const regex = /^[0-9]+$/;
                                              const { value } = e.currentTarget;
                                              const limit = value.replace(/\s/g, '');
                                              if (regex.test(limit) || limit === '') {
                                                list[i].stockQuantity = limit;
                                              } else if (!regex.test(limit)) {
                                                list[i].stockQuantity = 1;
                                              }
                                              setNonLayoutArray(list);
                                            }}
                                            onKeyPress={e => {
                                              const keyCode = e.charCode || e.which;
                                              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                e.preventDefault();
                                              }
                                            }}
                                          />
                                          <SpError>
                                            {simpleValidator.current.message(`stock${i + 1}`, nonLayoutDetailsArray[i].stockQuantity,
                                              'required|minLimit')}
                                          </SpError>
                                        </Col>
                                      </Row>
                                      <Row className="mt-4">
                                        <Col span={16} push={4}>
                                          <label htmlFor=""><SpH5>Amount</SpH5></label>
                                          <div className="d-flex">
                                            <div style={{ width: '15%' }}>
                                              <Select
                                                className="w-100"
                                                defaultValue={item.currencyCode}
                                                onChange={(value) => {
                                                  const list = [...nonLayoutDetailsArray];
                                                  list[i].currencyCode = value;
                                                  setNonLayoutArray(list);
                                                }}
                                              >
                                                {supportedCurrency.map((currency) => (
                                                  <Option key={currency} value={currency}>
                                                    {currency}
                                                  </Option>
                                                ))}
                                              </Select>
                                            </div>
                                            <div style={{ width: '35%' }}>
                                              <Input
                                                placeholder="0.00"
                                                value={typeof item.price === 'string' ? item.price : (item.price / 100)}
                                                onChange={(e) => {
                                                  const list = [...nonLayoutDetailsArray];
                                                  const regex = /^\d*\.?\d*$/;
                                                  const { value } = e.currentTarget;
                                                  const amount = value.replace(/\s/g, '');
                                                  if (regex.test(amount) || amount === '') {
                                                    list[i].price = amount;
                                                  } else if (!regex.test(amount)) {
                                                    list[i].price = 0.00;
                                                  }
                                                  setNonLayoutArray(list);
                                                }}
                                                onKeyPress={e => {
                                                  const keyCode = e.charCode || e.which;
                                                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                                                    e.preventDefault();
                                                  }
                                                }}
                                                onBlur={e => {
                                                  if (e.currentTarget.value) {
                                                    const list = [...nonLayoutDetailsArray];
                                                    list[i].price = formatAmount(e.currentTarget.value, currencyType);
                                                    setNonLayoutArray(list);
                                                  }
                                                }}
                                              />
                                            </div>
                                          </div>
                                          <SpError>
                                            {simpleValidator.current.message(`amount${i + 1}`, nonLayoutDetailsArray[i].price, 'required|amount',
                                              { messages: { required: 'The amount field is required' } })}
                                          </SpError>
                                        </Col>
                                      </Row>
                                    </div>

                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <SpButton
                                          type="secondary"
                                          style={{ width: '50%' }}
                                          onClick={addNonLayOutDetails}
                                        >
                                          {'\u002B'}&nbsp;Add Another Input Field
                                        </SpButton>
                                      </Col>
                                    </Row>
                                  </Card>

                                ))
                              }

                            </div>
                          </div>
                        )
                      }
                    </>

                  )}
                  {current === 2 && (
                    <div>
                      <ReactDragListView
                        {...dragProps}
                      >
                        {
                          customerDetailsArray.map((item, j) => (
                            <div
                              style={{
                                backgroundColor: '#fff',
                                padding: '10px',
                                margin: '25px 0',
                                borderRadius: '5px',
                                cursor: 'pointer',
                              }}
                            >
                              <p>
                                <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                                  <Col className="d-flex align-items-center">
                                    <div className="ml-2"><img src={reorderIcon} alt="" /></div>
                                  </Col>
                                  <Col className="d-flex align-items-center">
                                    {
                                      j === 0 ? (
                                        <div className="mr-2">
                                          <img src={arrowUpDisabled} alt="" />
                                        </div>
                                      ) : (
                                        <div
                                          className="mr-2"
                                          onClick={() => onArrowUp(j)}
                                        >
                                          <img src={arrowUp} alt="" />
                                        </div>
                                      )
                                    }
                                    {
                                      j === (customerDetailsArray.length - 1) ? (
                                        <div
                                          className="mr-2"
                                        >
                                          <img src={arrowDownDisabled} alt="" />
                                        </div>
                                      ) : (
                                        <div
                                          className="mr-2"
                                          onClick={() => onArrowDown(j)}
                                        >
                                          <img src={arrowDown} alt="" />
                                        </div>
                                      )
                                    }

                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Input Field Type</SpH5></label>
                                    <div>
                                      <Select
                                        style={{ width: '50%' }}
                                        placeholder="Select field type"
                                        value={item.type || undefined}
                                        disabled={item.disableRequired ? true : ''}
                                        onChange={(e) => {
                                          const list = [...customerDetailsArray];
                                          list[j].type = e;
                                          setCustomerDetailsArray(list);
                                        }}
                                      >
                                        <Option key={1} value="INPUT_EMAIL">Email</Option>
                                        <Option key={2} value="INPUT_PHONE">Phone</Option>
                                        <Option key={3} value="INPUT_NUMBER">Number</Option>
                                        <Option key={4} value="INPUT_ALPHA_NUMERIC">Alpha Numeric</Option>
                                        <Option key={5} value="INPUT_SINGLE_LINE_TEXT">Single Line Text</Option>
                                        <Option key={6} value="PROMOTIONAL_CODES">Promotional Code</Option>
                                      </Select>
                                    </div>
                                    <SpError>
                                      {simpleValidator.current.message(`type${j + 1}`, customerDetailsArray[j].type, 'required',
                                        { messages: { required: 'Please select field type' } })}
                                    </SpError>
                                  </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Input Field Label</SpH5></label>
                                    <Input
                                      placeholder={getPlaceholder(item.type || '')}
                                      value={item.label}
                                      maxlength="100"
                                      onChange={(e) => {
                                        const list = [...customerDetailsArray];
                                        list[j].label = e.currentTarget.value;
                                        list[j].name = e.currentTarget.value;
                                        list[j].category = e.currentTarget.value;
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                    <SpText fontStyle="italic" color="rgba(0, 0, 0, 0.5)">Maximum 100 Characters</SpText>
                                    <SpError>
                                      {simpleValidator.current.message(`Label${j + 1}`, customerDetailsArray[j].label, 'required',
                                        { messages: { required: 'The label field is required' } })}
                                    </SpError>
                                  </Col>
                                </Row>
                                <Row className="mt-4 mb-4">
                                  <Col span={16} push={4}>
                                    <label htmlFor=""><SpH5>Is Optional</SpH5></label>
                                    <Checkbox
                                      className="ml-4"
                                      disabled={item.disableRequired || false}
                                      checked={!(item.required)}
                                      onChange={({ target: { checked } }) => {
                                        const list = [...customerDetailsArray];
                                        list[j].required = !checked;
                                        if (checked) {
                                          list[j].checked = '';
                                        } else if (!checked) {
                                          list[j].checked = 'checked';
                                        }
                                        setCustomerDetailsArray(list);
                                      }}
                                    />
                                  </Col>
                                </Row>
                                {
                                  (!item.disableRequired) ? (
                                    <Row className="mt-4 mb-4">
                                      <Col span={16} push={4}>
                                        <SpButton
                                          type="secondary"
                                          style={{ width: '50%' }}
                                          onClick={() => removeCustomerDetails(j)}
                                        >
                                          {'\u2212'}&nbsp;Remove Input Field
                                        </SpButton>
                                      </Col>
                                    </Row>
                                  ) : ''
                                }
                              </p>
                            </div>
                          ))
                        }
                      </ReactDragListView>
                      <Row className="mt-4">
                        <Col span={16} push={8}>
                          <SpButton
                            type="secondary"
                            style={{ width: '50%' }}
                            onClick={addCustomerDetails}
                          >
                            {'\u002B'}&nbsp;Add Another Input Field
                          </SpButton>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {
                    current === 3 && (
                      <ReviewSeatedPage
                        pageData={pageData}
                        selectedAccount={selectedAccount}
                      />
                    )
                  }
                </div>
                <div className="d-flex justify-content-end mt-auto mr-4">
                  {current > 0 && (
                    <SpButton type="secondary" className="mr-4" onClick={prev}>
                      Back
                    </SpButton>
                  )}
                  {current < steps.length - 1 && (
                    <SpButton type="primary" onClick={next}>
                      Next
                    </SpButton>
                  )}
                  {current === steps.length - 1 && (
                    <SpButton type="primary" onClick={next} disabled={disableButton}>
                      Create
                    </SpButton>
                  )}
                </div>
              </PaymentFormWrapper>
            </PaymentWrapper>
          </Layout>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  selectedAccount: state.account.selectedAccount,
  eventDetails: state.eventDetails,
  addSeatedEvent: state.eventDetails.addSeatedEvent,
  eventDetailsStatus: state.eventDetails.eventDetailsStatus,
});

const mapDispatchToProps = (dispatch) => ({
  addNewEvents: param => dispatch(eventsActions.addNewEvents({
    payload: param,
  })),
  fetchEventDetails: gid => dispatch(eventDetailsAction.fetchEventDetails({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSeatedPage);
