import { EVENT_PAGES } from '../actionTypes';

export const fetchEventPages = (action) => ({
  type: EVENT_PAGES.FETCH,
  payload: action.payload,
});

export const addEventPage = (action) => ({
  type: EVENT_PAGES.ADD_EVENT,
  payload: action.payload,
});

export const updateEventPage = (action) => ({
  type: EVENT_PAGES.UPDATE,
  payload: action.payload,
});

export const deleteEventPage = (action) => ({
  type: EVENT_PAGES.DELETE,
  payload: action.payload,
});

export const fetchEventPageReceipts = (action) => ({
  type: EVENT_PAGES.FETCH_RECEIPT,
  payload: action.payload,
});
