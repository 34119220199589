import { INVENTORY_ORDERS_PAYMENT_STATUSES } from 'appconstants';

export default value => {
  if (value === 'UN_PAID') {
    return INVENTORY_ORDERS_PAYMENT_STATUSES.UN_PAID;
  } if (value === 'PARTIALLY_PAID') {
    return INVENTORY_ORDERS_PAYMENT_STATUSES.PARTIALLY_PAID;
  } if (value === 'PAID') {
    return INVENTORY_ORDERS_PAYMENT_STATUSES.PAID;
  } if (value === 'REFUND_PROCESSING') {
    return INVENTORY_ORDERS_PAYMENT_STATUSES.REFUND_PROCESSING;
  } if (value === 'PARTIALLY_REFUNDED') {
    return INVENTORY_ORDERS_PAYMENT_STATUSES.PARTIALLY_REFUNDED;
  } if (value === 'REFUNDED') {
    return INVENTORY_ORDERS_PAYMENT_STATUSES.REFUNDED;
  } if (value === 'VOID') {
    return INVENTORY_ORDERS_PAYMENT_STATUSES.VOID;
  }
  return INVENTORY_ORDERS_PAYMENT_STATUSES.UN_PAID;
};
