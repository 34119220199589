import React, { useEffect, useRef, useState } from 'react';
import '../../../../assets/css/eventPageStyle.css';
import { connect } from 'react-redux';
import {
  Input,
  Modal, Select,
} from 'antd';
import type { ContextRouter } from 'react-router-dom';
import {
  eventPageDetailsActions,
  notificationActions,
} from 'store/actions';
import {
  findPhoneNumbersInText,
  formatNumber,
  isValidPhoneNumber,
} from 'libphonenumber-js';
import SimpleReactValidator from 'simple-react-validator';
import supportEmailLogo from '../../../../imgs/SupportEmail.svg';
import supportPhoneLogo from '../../../../imgs/SupportPhone.svg';
import info from '../../../../imgs/info.svg';
import backArrow from '../../../../imgs/backArrowBlack.svg';
import ProductReview from './components/productReview';
import AddProducts from './components/addProducts';
import { AmountDivideByHundred } from '../../../../helper';
import { SpError, SpH5 } from '../../../../components/DesignKit';
import { COUNTRY_PHONE_CODE, PAGES } from '../../../../appconstants';
import { NOTIFICATION } from '../../../../store/actionTypes';
import Loading from '../../../../components/Loading';
import { countryCodeValidation, validationRegex, validators } from '../../../../utils/validationMessages';
import getCountryPhoneCode from '../../../../utils/getCountryPhoneCode';

const { Option } = Select;

type Props = ContextRouter & {
  gid: String,
  phoneCode: String,
  selectedAccount: Object,
  eventDetails: Object,
  eventPageOrderDetails: Object,
  eventPageOrderDetailsStatus: boolean,
  eventPageOrderSplitUp: Object,
  fetchEventPageSecureDetails: Function,
  createEventPageOfflineOrder: Function,
  fetchEventPageSplitUp: Function,
  history: {
    push: Function,
  },
  setNotification: Function,
  loading: boolean,
}


const EventPageOfflinePayment = (props: Props) => {
  const {
    phoneCode,
    selectedAccount,
    eventDetails,
    eventPageOrderDetails,
    eventPageOrderDetailsStatus,
    eventPageOrderSplitUp,
    fetchEventPageSecureDetails,
    createEventPageOfflineOrder,
    fetchEventPageSplitUp,
    history,
    setNotification,
    loading,
  } = props;
  const { gid } = props.match.params;
  const [mobileView, setMobileView] = useState(false);
  const [payload, setPayload] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [current, setCurrent] = useState(0);
  const [matchedProduct, setMatchedProduct] = useState({});
  const [orderItemIndex, setOrderItemIndex] = useState(0);
  const [payNow, setPayNow] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [steps, setSteps] = useState([]);

  const [orderItems, setOrderItems] = useState(() => {
    const storedItems = sessionStorage.getItem(`placedItems-${gid.replace('eventpage-', '')}-offline`);
    return storedItems ? JSON.parse(storedItems) : [];
  });
  const [stockItems, setStockItems] = useState([]);
  const [customer, setCustomer] = useState(() => {
    const customerObject = sessionStorage.getItem(`placedItems-${gid.replace('eventpage-', '')}-customer`);
    return customerObject ? JSON.parse(customerObject) : {};
  });
  const [phoneNumberCode, setPhoneCode] = useState(phoneCode || getCountryPhoneCode(selectedAccount?.country?.id));
  const [phoneFormate, setPhoneFormate] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const phoneResult = ((customer?.phoneNumber)) ? findPhoneNumbersInText(customer.phoneNumber) : null;

  if (!contactPhone && (phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
    setPhoneCode(`+${phoneResult[0].number.countryCallingCode}`);
    setContactPhone(phoneResult[0].number.nationalNumber);
    const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  }

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: 'The phone number is invalid',
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.email.test(val),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 480) {
      setMobileView(true);
    }
  }, []);

  useEffect(() => {
    fetchEventPageSecureDetails(gid);
  }, [gid]);

  const close = () => {
    sessionStorage.removeItem(`placedItems-${gid.replace('eventpage-', '')}-offline`);
    sessionStorage.removeItem(`placedItems-${gid.replace('eventpage-', '')}-customer`);
    if (gid) {
      history.push(`${PAGES.EVENT_PAGES}/${gid}`);
    } else {
      history.push(PAGES.EVENT_PAGE_DASHBOARD);
    }
  };

  const notify = (error) => {
    setNotification({
      type: NOTIFICATION.RESET,
    });
    setNotification({
      type: NOTIFICATION.ERROR,
      payload: error,
    });
  };

  useEffect(() => {
    if (errorMessage) {
      notify(errorMessage);
      setErrorMessage('');
    }
  }, [errorMessage]);

  const productEntry = (product, index) => {
    orderItems.push({
      index,
      productGid: product.gid,
      name: product.eventPageProductName,
      title: product.eventPageProductType === 'TICKET'
        ? 'Ticket' : product.eventPageProductType === 'FOOD'
          ? 'Food' : product.eventPageProductName,
      lineItems: [],
      totalAmount: 0,
      totalCount: 0,
    });
  };

  const stockEntry = (product) => {
    const enabledStockItems = product.items.filter((item) => item.enableStock);

    const customEnabledItems = product.customItems?.flatMap((customItem) => customItem.items?.filter((item) => item.enableStock) || []
    ) || [];

    const updatedStockItems = [...stockItems, ...enabledStockItems, ...customEnabledItems].reduce(
      (unique, item) => {
        if (!unique.some((existingItem) => existingItem.gid === item.gid)) {
          unique.push(item);
        }
        return unique;
      },
      [],
    );

    setStockItems(updatedStockItems);
  };

  useEffect(() => {
    if (eventDetails?.primaryColor) {
      document.documentElement.style.setProperty('--primary-color', eventDetails.primaryColor);
    }
    if (eventDetails?.eventPageProducts) {
      const newSteps = [];
      let index = 0;

      newSteps.push({ index, title: 'Customer Info', productGid: '' });
      index += 1;

      // Check for TICKET and add it to steps
      const ticketProduct = eventDetails.eventPageProducts.find(
        (product) => product.eventPageProductType === 'TICKET',
      );
      if (ticketProduct) {
        newSteps.push({ title: 'Tickets', productGid: ticketProduct.gid });
        const ticketIndex = orderItems.findIndex((update) => update.productGid === ticketProduct.gid);
        if (ticketIndex === -1) {
          productEntry(ticketProduct, index);
        }
        stockEntry(ticketProduct);
        index += 1;
      }

      // Check for FOOD and add it to steps
      const foodProduct = eventDetails.eventPageProducts.find(
        (product) => product.eventPageProductType === 'FOOD',
      );
      if (foodProduct) {
        newSteps.push({ title: 'Foods', productGid: foodProduct.gid });
        const foodIndex = orderItems.findIndex((update) => update.productGid === foodProduct.gid);
        if (foodIndex === -1) {
          productEntry(foodProduct, index);
        }
        stockEntry(foodProduct);
        index += 1;
      }

      // Check for CUSTOM and add it to steps
      const customProduct = eventDetails.eventPageProducts.find(
        (product) => product.eventPageProductType === 'CUSTOM',
      );
      if (customProduct) {
        newSteps.push({ title: customProduct.eventPageProductName, productGid: customProduct.gid });
        const customIndex = orderItems.findIndex((update) => update.productGid === customProduct.gid);
        if (customIndex === -1) {
          productEntry(customProduct, index);
        }
        stockEntry(customProduct);
        index += 1;
      }

      // Add the final step
      newSteps.push({ index, title: 'Review & Proceed', productGid: '' });

      // Update steps and set the first matched product
      setSteps(newSteps);
      setMatchedProduct(
        eventDetails.eventPageProducts.find((product) => product.gid === newSteps[0]?.productGid),
      );
      setOrderItemIndex(orderItems.findIndex((orderItem) => orderItem.productGid === newSteps[0]?.productGid));
    }
  }, [eventDetails]);

  const updateCustomerInfo = (value, type) => {
    if (type === 'NAME') {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        name: value,
      }));
    } else if (type === 'EMAIL') {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        email: value,
      }));
    } else {
      setCustomer((prevCustomer) => ({
        ...prevCustomer,
        phoneNumber: `${phoneNumberCode}${value}`,
      }));
      setContactPhone(value.replace(/[^0-9+]/g, ''));
      setPhoneFormate(value.replace(/[^0-9+]/g, ''));
    }
  };

  const onCountryCodeChange = (value) => {
    setPhoneCode(value);
    const data = formatNumber(`${value}${contactPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
    updateCustomerInfo(contactPhone, 'PHONE');
  };

  const handleNext = () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      updateCustomerInfo(contactPhone, 'PHONE');
      return;
    }
    if (orderItems?.length > 0) {
      const orderItem = orderItems.find((orderItem1) => orderItem1.productGid === steps[current].productGid);
      if (orderItem?.errors?.length > 0) {
        orderItem.errors.forEach((error) => {
          notify(error);
        });
        return;
      }
    }

    if ((current + 1 === steps.length - 1
        && orderItems.flatMap(item => item.lineItems).length === 0)) {
      setErrorMessage('Add Items To Proceed...');
    } else if (current < steps.length - 1) {
      if (stockItems.some((stockItem) => (stockItem.stockUsed ? stockItem.stockUsed > stockItem.onlineStock : false))) {
        stockItems.filter((stockItem) => (stockItem.stockUsed ? stockItem.stockUsed > stockItem.onlineStock : false))
          .forEach((stockItem) => {
            // eslint-disable-next-line max-len
            notify(<span>Available stock for Item : <strong>{stockItem.displayName}</strong> is : <strong>{stockItem.onlineStock}</strong>, but placed : <strong>{stockItem.stockUsed}</strong></span>);
          });
        return;
      }
      setCurrent(current + 1);
      setMatchedProduct(eventDetails?.eventPageProducts.find((product) => product.gid === (steps[current + 1].productGid)));
      setOrderItemIndex(orderItems.findIndex((orderItem) => orderItem.productGid === steps[current + 1]?.productGid));
      sessionStorage.setItem(`placedItems-${gid.replace('eventpage-', '')}-offline`, JSON.stringify(orderItems));
      sessionStorage.setItem(`placedItems-${gid.replace('eventpage-', '')}-customer`, JSON.stringify(customer));
    }
  };

  const handleBack = () => {
    if (current > 0) {
      setCurrent(current - 1);
      setMatchedProduct(eventDetails?.eventPageProducts.find((product) => product.gid === (steps[current - 1].productGid)));
      setOrderItemIndex(orderItems.findIndex((orderItem) => orderItem.productGid === steps[current - 1]?.productGid));
    } else {
      close();
    }
  };

  const handlePayNow = () => {
    setPayNow(true);
  };

  const createOrderAndPay = async (orderPayload) => {
    try {
      createEventPageOfflineOrder(orderPayload);
    } catch (e) {
      setPayNow(false);
      if (e?.data?.message) {
        setErrorMessage(e.data.message);
      }
    }
  };

  useEffect(() => {
    if (eventPageOrderDetails?.paymentStatus === 'PAID') {
      setPayNow(false);
      close();
    }
  }, [eventPageOrderDetails]);

  useEffect(() => {
    if (eventPageOrderDetailsStatus
        && eventPageOrderDetailsStatus === 'FAILED') {
      setPayNow(false);
    }
  }, [eventPageOrderDetailsStatus]);

  useEffect(() => {
    if (payNow && payload.total !== 0) {
      createOrderAndPay(payload);
    }
  }, [payload]);

  useEffect(() => {
    if (orderItems?.length > 0 && stockItems?.length > 0) {
      let updatedStockItems = [...stockItems];
      let mergedLineItems = [];
      orderItems.forEach((orderItem) => {
        if (orderItem.lineItems?.length > 0) {
          orderItem.lineItems
            .filter((lineItem) => lineItem.itemGid)
            .forEach((lineItem) => {
              const mergedItemIndex = mergedLineItems.findIndex((mergeLineItem) => mergeLineItem.itemGid === lineItem.itemGid);
              if (mergedItemIndex === -1) {
                mergedLineItems.push(lineItem);
              } else {
                mergedLineItems = mergedLineItems.map((item, index) => (index === mergedItemIndex
                  ? { ...item, quantity: item.quantity + lineItem.quantity }
                  : item));
              }
            });

          orderItem.lineItems
            .filter((lineItem) => lineItem.lineItemItems?.length > 0)
            .forEach((lineItem) => lineItem.lineItemItems.forEach((lineItemItem) => {
              const mergedItemIndex = mergedLineItems.findIndex((mergeLineItem) => mergeLineItem.itemGid === lineItemItem.itemGid);
              if (mergedItemIndex === -1) {
                mergedLineItems.push(lineItemItem);
              } else {
                mergedLineItems = mergedLineItems.map((item, index) => (index === mergedItemIndex
                  ? { ...item, quantity: item.quantity + (lineItem.quantity * lineItemItem.quantity) }
                  : item));
              }
            }));
        }
      });
      mergedLineItems.forEach((lineItem) => {
        const stockIndex = updatedStockItems.findIndex((item) => item.gid === lineItem.itemGid);
        if (stockIndex !== -1) {
          updatedStockItems[stockIndex] = {
            ...updatedStockItems[stockIndex],
            stockUsed: lineItem.quantity,
          };
        }
      });

      updatedStockItems = updatedStockItems.map((stockItem) => ({
        ...stockItem,
        stockUsed: mergedLineItems.some((lineItem) => lineItem.itemGid === stockItem.gid)
          ? stockItem.stockUsed
          : 0,
      }));

      setStockItems(updatedStockItems);
    }
  }, [orderItems]);

  if (loading && !eventDetails) {
    return <Loading />;
  }

  return (
    <>
      <div className="background">
        {/* Fixed Top Section */}
        <div className="fixed-top-section">
          {(() => {
            const primarySupport = eventDetails?.eventPageSupports?.find(
                  (list) => list.isPrimary
              );

            return (
              <>
                {/* Heading (optional) */}
                <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
                  {eventDetails?.title}
                  {eventDetails?.coupon && (
                  <span
                    className="sc-fbJfA uhGua discount-badge"
                  >
                    {eventDetails.coupon.percentageOff
                      ? `${eventDetails.coupon.percentageOff}%`
                      : `$ ${AmountDivideByHundred(eventDetails.coupon.amountOff)}`} Off
                  </span>
                  )}
                </div>
                {/* Email and Phone Section */}
                <div className="d-flex align-items-center gap-20">
                  {primarySupport?.email && (
                  <div className="d-flex align-items-center gap-8">
                    <img
                      alt="Email Icon"
                      src={supportEmailLogo}
                      style={{ height: '20px' }}
                    />
                    <span>{primarySupport.email}</span>
                  </div>
                      )}
                  {primarySupport?.phoneNumber && (
                  <div className="d-flex align-items-center gap-8">
                    <img
                      alt="Phone Icon"
                      src={supportPhoneLogo}
                      style={{ height: '20px' }}
                    />
                    <span>{primarySupport.phoneNumber}</span>
                  </div>
                      )}
                  {eventDetails?.eventPageSupports?.length > 0 && (
                  <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                    <img
                      alt="More Info"
                      src={info}
                      style={{ height: '20px' }}
                      onClick={() => setIsExpanded(!isExpanded)}
                    />
                  </div>
                      )}
                </div>
                {/* Event Page Support List */}
                {isExpanded && (
                <div className="support-list">
                  {eventDetails?.eventPageSupports?.map((support, index) => (
                    <div key={index}>
                      {/* Support Details */}
                      <div className="support-item">
                        { support.name ? <div style={{ fontWeight: 'bold' }}>{support.name}</div> : '' }
                        { support.email ? <div>{support.email}</div> : '' }
                        { support.phoneNumber ? <div>{support.phoneNumber}</div> : '' }
                        { support.designation ? <div>{support.designation}</div> : '' }
                      </div>

                      {/* Separator Line */}
                      {index < eventDetails.eventPageSupports.length - 1 && (
                      <div className="support-separator" />
                      )}
                    </div>
                  ))}
                </div>
                )}
              </>
            );
          })()}
        </div>
        <div className="fixed-logo-section">
          <div className="back-button" onClick={handleBack}>
            <img
              alt="Back Icon"
              src={backArrow}
              style={{ height: '15px', width: '25px', cursor: 'pointer' }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center" style={{ width: '80%' }}>
            {steps.map((item, index) => (
              <div
                key={index}
                className="d-flex align-items-center"
                style={{
                  gap: '10px',
                  flex: `${index < steps.length - 1 ? 1 : ''}`,
                }}
              >
                {/* Step Circle */}
                <div
                  className="stepper"
                  style={{
                    backgroundColor: current === index ? '#1890ff' : current > index ? '#52c41a' : '#d9d9d9',
                    color: current === index || current > index ? '#fff' : '#000',
                  }}
                >
                  {current > index ? '✔' : index + 1}
                </div>
                {/* Step Title */}
                <div
                  style={{
                    color: current === index ? '#1890ff' : current > index ? '#52c41a' : '#8c8c8c',
                    fontWeight: current === index ? 'bold' : 'normal',
                  }}
                >
                  {item.title}
                </div>
                {/* Connector Line */}
                {index < steps.length - 1 && (
                <div
                  style={{
                    flex: 1,
                    height: '2px',
                    backgroundColor: current > index ? '#52c41a' : '#d9d9d9',
                    margin: '0 10px',
                  }}
                />
                )}
              </div>
            ))}
          </div>
        </div>

        <div className="scrollable-content product-section">
          {current === steps.length - 1
            ? (
              <>
                <ProductReview
                  eventDetails={eventDetails}
                  customer={customer}
                  orderItems={orderItems}
                  setPayload={setPayload}
                  setPayNow={setPayNow}
                  payNow={payNow}
                  setErrorMessage={setErrorMessage}
                  splitUpApi={fetchEventPageSplitUp}
                  splitUpResponse={eventPageOrderSplitUp}
                />
                {payNow && (
                  <Modal
                    centered
                    closable={false}
                    visible
                    footer={[]}
                    open
                  >
                    <>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          flexDirection: 'column',
                          textAlign: 'center',
                          padding: '20px',
                          marginTop: '20px',
                        }}
                      >
                        {/* Loader */}
                        <div className="spinner-border loader" />

                        {/* Loading Message */}
                        <div className="loader-message">
                          Your order is being processed... <br />
                          Please wait and avoid closing or reloading this page. <br />
                          Thank you for your patience!
                        </div>
                      </div>
                    </>
                  </Modal>
                )}
              </>
            ) : current === 0 ? (
              <div className="product-screen">
                <h4 style={{ textAlign: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
                  Customer Details
                </h4>
                <div
                  className="mb-2"
                  style={{
                    padding: '0 12.5%',
                  }}
                >
                  <label htmlFor=""><SpH5>Name</SpH5></label>
                  <Input
                    type="text"
                    className="input-box d-flex align-items-center"
                    style={{ width: '100%' }}
                    placeholder="Name"
                    value={customer?.name}
                    onChange={(e) => updateCustomerInfo((e.target.value), 'NAME')}
                  />
                  <SpError>
                    {simpleValidator.current.message('name', customer?.name, 'required')}
                  </SpError>
                </div>
                <div
                  className="mb-2"
                  style={{
                    padding: '0 12.5%',
                  }}
                >
                  <label htmlFor=""><SpH5>Email</SpH5></label>
                  <Input
                    type="email"
                    className="input-box d-flex align-items-center"
                    style={{ width: '100%' }}
                    placeholder="Email"
                    value={customer?.email}
                    onChange={(e) => updateCustomerInfo((e.target.value), 'EMAIL')}
                  />
                  <SpError>
                    {simpleValidator.current.message('email', customer?.email, 'required|emailId|checkLowerCase')}
                  </SpError>
                </div>
                <div
                  style={{
                    padding: '0 12.5%',
                    marginBottom: '20px',
                  }}
                >
                  <label htmlFor=""><SpH5>Phone Number</SpH5></label>
                  <div className="d-flex">
                    <div
                      style={{ width: '20%' }}
                      onKeyPress={e => countryCodeValidation(e)}
                    >
                      <Select
                        className="w-100"
                        showSearch
                        value={phoneNumberCode}
                        onChange={onCountryCodeChange}
                      >
                        {COUNTRY_PHONE_CODE.map((item) => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div style={{ width: '80%' }}>
                      <Input
                        className="InputnumFormate"
                        placeholder="Phone Number"
                        value={phoneFormate}
                        onChange={(e) => updateCustomerInfo(e.currentTarget.value, 'PHONE')}
                        onBlur={e => {
                          if (e.currentTarget.value) {
                            updateCustomerInfo(e.currentTarget.value, 'PHONE');
                            const value = formatNumber(`${phoneNumberCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                            const formater = value.substr(value.indexOf(' ') + 1);
                            setPhoneFormate(formater);
                          }
                        }}
                      />
                      <SpError>
                        {/* eslint-disable-next-line max-len */}
                        {simpleValidator.current.message('phone number', contactPhone, `required|phoneNumValidation:${phoneNumberCode}${contactPhone}`)}
                      </SpError>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <AddProducts
                eventDetails={eventDetails}
                eventPageProduct={matchedProduct}
                orderItemIndex={orderItemIndex}
                lineItems={orderItems[orderItemIndex] ? orderItems[orderItemIndex].lineItems : []}
                setOrderItems={setOrderItems}
                setErrorMessage={setErrorMessage}
                splitUpApi={fetchEventPageSplitUp}
                splitUpResponse={eventPageOrderSplitUp}
              />
            )}
        </div>


        {/* Fixed Footer */}
        <div className="fixed-footer">
          {current < steps.length - 1 && (
            <div className="d-flex align-items-center justify-content-center" style={{ width: '100%' }}>
              {current !== 0 && (
              // eslint-disable-next-line max-len
              <div className="d-flex align-items-center gap-8" style={{ marginRight: `${current === 1 ? '28%' : current === 2 ? '22%' : current === 3 ? '16%' : '28%'}`, color: '#fff' }}>
                {orderItems.map((orderItem, index) => {
                  if (index > orderItemIndex) return '';

                  return (
                    <React.Fragment key={index}>
                      <div style={{ fontSize: '22px', fontWeight: 'bold' }}>
                        ${parseFloat((orderItem.totalAmount ? orderItem.totalAmount : 0) / 100)
                        .toFixed(2)
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}
                        <div style={{ fontSize: '10px' }}>
                          {orderItem.totalCount} {orderItem.title}{orderItem.totalCount > 1 ? 's' : ''}
                        </div>
                      </div>

                      {/* Add "+" separator between items, but not after the last one */}
                      {index !== current - 1 && <div style={{ fontSize: '22px', padding: '3px' }}>+</div>}
                    </React.Fragment>
                  );
                })}
              </div>
              )}
              {/* eslint-disable-next-line react/button-has-type */}
              <button
                className="book-now-btn"
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          )}
          {current === steps.length - 1 && (
            // eslint-disable-next-line react/button-has-type
            <button
              className="book-now-btn"
              disabled={(current + 1 === steps.length - 1 && orderItems.flatMap(item => item.lineItems).length === 0) && payNow}
              onClick={handlePayNow}
            >
              Add Payment
            </button>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  eventDetails: state.eventPageDetails.eventPageOfflineDetails,
  eventPageOrderSplitUp: state.eventPageDetails.eventPageOrderSplitUp,
  eventPageOrderDetails: state.eventPageDetails.eventPageOrderDetails,
  eventPageOrderDetailsStatus: state.eventPageDetails.eventPageOrderDetailsStatus,
  eventDetailsStatus: state.eventPageDetails.eventDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventPageSecureDetails: gid => dispatch(eventPageDetailsActions.fetchEventPageSecureDetails({
    payload: gid,
  })),
  createEventPageOfflineOrder: gid => dispatch(eventPageDetailsActions.createOrderForOfflinePayment({
    payload: gid,
  })),
  fetchEventPageSplitUp: param => dispatch(eventPageDetailsActions.fetchEventPageSplitUp({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventPageOfflinePayment);
