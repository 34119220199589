import { call, put } from 'redux-saga/effects';


import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  SEQUENCE_GENERATORS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchAllSequence = async () => axiosAuthInstance.get(`${API_END_POINTS.GET_SEQUENCE_GENERATORS}`);
const fetchAllInvoiceSequence = async (payload) => axiosAuthInstance.get(`${API_END_POINTS.GET_INVOICE_SEQUENCE}?type.EQ=${payload.type ?? payload}`);
const fetchCurrentSeqInvoice = async (payload) => axiosAuthInstance.get(`${API_END_POINTS.GET_CURRENT_INVOICE_SEQUENCE}/${payload}`);

const addSequence = async (params) => {
  if (params && params.superUser) {
    return axiosAuthInstance.post(`${API_END_POINTS.GET_POS_SEQUENCE_GENERATORS}`, params);
  }
  return axiosAuthInstance.post(`${API_END_POINTS.SEQUENCE_GENERATORS}`, params);
};

const fetchAllPOSSequence = async () => axiosAuthInstance.get(`${API_END_POINTS.POS_SEQUENCE_GENERATORS}?type.EQ=POS`);
const addPOSSequence = async (params) => {
  if (params && params.superUser) {
    return axiosAuthInstance.post(`${API_END_POINTS.GET_POS_SEQUENCE_GENERATORS}`, params);
  }
  return axiosAuthInstance.post(`${API_END_POINTS.POS_SEQUENCE_GENERATORS}`, params);
};

const addInvoiceSequence = async (params) => axiosAuthInstance.post(`${API_END_POINTS.GET_INVOICE_SEQUENCE}`, params);

const patchSequence = async (payload) => axiosAuthInstance.patch(`${API_END_POINTS.GET_SEQUENCE_GENERATORS}/${payload.accountGid}`, payload);
const patchInvoiceSequence = async (payload) => axiosAuthInstance.patch(`${API_END_POINTS.GET_INVOICE_SEQUENCE}/${payload.invoiceTypeGid}`, payload);
const patchPOSSequence = async (payload) => axiosAuthInstance.patch(`${API_END_POINTS.POS_SEQUENCE_GENERATORS}/${payload.accountGid}`, payload);

export function* getSequenceGenerators(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchAllSequence, action.payload);

    yield put({ type: SEQUENCE_GENERATORS.SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SEQUENCE_GENERATORS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getPOSSequenceGenerators(action) {
  try {
    yield put({ type: LOADING.SET });
    const { data: { entity } } = yield call(fetchAllPOSSequence, action.payload);

    yield put({ type: SEQUENCE_GENERATORS.POS_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SEQUENCE_GENERATORS.FAILED, payload: e.response.data });
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getInvoiceSequenceGenerators(action) {
  try {
    const { data: { entity } } = yield call(fetchAllInvoiceSequence, action.payload);

    yield put({ type: SEQUENCE_GENERATORS.INVOICE_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SEQUENCE_GENERATORS.FAILED, payload: e.response.data });
  }
}
export function* getCurrentInvoiceSequence(action) {
  try {
    const { data: { entity } } = yield call(fetchCurrentSeqInvoice, action.payload);

    yield put({ type: SEQUENCE_GENERATORS.INVOICE_CURRENT_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
    yield put({ type: SEQUENCE_GENERATORS.FAILED, payload: e.response.data });
  }
}

export function* addSequenceGenerators(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(addSequence, action.payload);
    const { data: { entity } } = yield call(fetchAllSequence, action.payload);
    yield put({ type: SEQUENCE_GENERATORS.SUCCESS, payload: entity });
    // yield put({
    //   type: NOTIFICATION.SUCCESS,
    //   payload: 'Added Sequence successfully',
    // });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addPOSSequenceGenerators(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(addPOSSequence, action.payload);
    const { data: { entity } } = yield call(fetchAllPOSSequence, action.payload);
    yield put({ type: SEQUENCE_GENERATORS.POS_SUCCESS, payload: entity });
    // yield put({
    //   type: NOTIFICATION.SUCCESS,
    //   payload: 'Added Sequence successfully',
    // });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* addInvoiceSequenceGenerators(action) {
  try {
    yield call(addInvoiceSequence, action.payload);
    const { data: { entity } } = yield call(fetchAllInvoiceSequence, action.payload);
    yield put({ type: SEQUENCE_GENERATORS.INVOICE_SUCCESS, payload: entity });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateSequenceGenerator(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchSequence, action.payload);
    const { data: { entity } } = yield call(fetchAllSequence, action.payload);
    yield put({ type: SEQUENCE_GENERATORS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Sequence Generator updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updatePOSSequenceGenerator(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchPOSSequence, action.payload);
    const { data: { entity } } = yield call(fetchAllPOSSequence, action.payload);
    yield put({ type: SEQUENCE_GENERATORS.POS_SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'POS Sequence Generator updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
export function* updateInvoiceSequence(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchInvoiceSequence, action.payload);
    const { data: { entity } } = yield call(fetchAllInvoiceSequence, action.payload);
    yield put({ type: SEQUENCE_GENERATORS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: 'Invoice Generator updated successfully',
    });
    yield put({ type: LOADING.VALIDATED });
    yield put({ type: LOADING.SUBMITTED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}
