import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react';

import {
  Row,
  Col,
  Input,
  Select,
  Checkbox,
  DatePicker,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
  validationRegex,
  validators,
} from 'utils/validationMessages';

import {
  SpForm,
  SpError,
  SpTextMute,
  SpText,
  SpCaption,
} from 'components/DesignKit';
import formatAmount from 'utils/formatAmount';

import { API_END_POINTS } from 'api';
import { MESSAGES } from 'appconstants';
import KYCFileUpload from 'components/KycFileUpload';
import moment from 'moment-timezone';

let inpersonValue = 0;
const getInpersonValue = () => inpersonValue;

const setInpersonValue = (value) => {
  inpersonValue = value;
};

const { Option } = Select;
const dateFormat = 'MMM DD, YYYY';

type Props = {
  business: Array<Object>,
  isIndia: boolean,
  internalRole: Object,
  selectedAccount: Object,
}

const EditBusinessBankDetails = (props: Props, ref) => {
  const {
    internalRole,
    isIndia,
    selectedAccount,
    business: {
      bankName,
      bankBeneficiaryName,
      bankAccountLastFour,
      bankIfscCode,
      bankRoutingNumber: routingNumber,
      bankAccountNumber,
      bankAccountType,
      kycBankStatementUrl,
      averageMonthlyVolume: companyAverageMonthlyVolume,
      averageTicketAmount: companyAverageTicketAmount,
      whenIsCardCharged: companyWhenIsCardCharged,
      servicesDeliveredIn: companyServicesDeliveredIn,
      seasonalBusiness: companySeasonalBusiness,
      refundPolicy: companyRefundPolicy,
      modeOfTransactionPerson,
      modeOfTransactionOnline,
      voidCheckUrl: companyVoidCheckUrl,
      requestedServiceDate: companyRequestedServiceDate,
      isServiceDateFlexible: companyIsServiceDateFlexible,
    },
  } = props;

  const [, forceUpdate] = useState();
  const [nameOfBank, setBankName] = useState(bankName);
  const [accountHolderName, setAccountHolderName] = useState(bankBeneficiaryName);
  const [accountNumber, setAccountNumber] = useState(internalRole ? bankAccountNumber : `************${bankAccountLastFour}`);
  const [bankRoutingNumber, setBankRoutingNumber] = useState(routingNumber);
  const [bankStatementUrl, setBankStatementUrl] = useState(kycBankStatementUrl);
  const [ifscNumber, setIfscNumber] = useState(bankIfscCode);
  const [accountType, setAccountType] = useState(bankAccountType);
  const averageMonthlyValue = companyAverageMonthlyVolume ? (companyAverageMonthlyVolume / 100).toFixed(2) : '';
  const averageMonthlyAmtVal = companyAverageTicketAmount ? (companyAverageTicketAmount / 100).toFixed(2) : '';
  const [averageMonthlyVolume, setAverageMonthlyVolume] = useState(averageMonthlyValue);
  const [averageTicketAmount, setAverageTicketAmount] = useState(averageMonthlyAmtVal);
  const [whenIsCardCharged, setWhenIsCardCharged] = useState(companyWhenIsCardCharged);
  const [servicesDeliveredIn, setServicesDeliveredIn] = useState(companyServicesDeliveredIn);
  const [seasonalBusiness, setSeasonalBusiness] = useState(companySeasonalBusiness);
  const [refundPolicy, setRefundPolicy] = useState(companyRefundPolicy);
  const [isServiceDateFlexible, setIsServiceDateFlexible] = useState(companyIsServiceDateFlexible || null);
  const [inPerson, setInPerson] = useState(modeOfTransactionPerson ? [
    `${modeOfTransactionPerson}%`,
    modeOfTransactionPerson,
  ] : '');
  const [online, setOnline] = useState(modeOfTransactionOnline ? [
    `${modeOfTransactionOnline}%`,
    modeOfTransactionOnline,
  ] : '');
  const [voidCheckUrl, setVoidCheckUrl] = useState(companyVoidCheckUrl);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [requestedServiceDate, setRequestedServiceDate] = useState(companyRequestedServiceDate);
  const [EBTCheckBox, setEBTCheckBoxChange] = useState(false);
  const routingRegex = /[0-9]{9}/;
  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  const averagemonthlyVolumeHandler = useRef();

  const dateIssued = moment(companyRequestedServiceDate).format(dateFormat);

  const defaultDate = () => (companyRequestedServiceDate ? moment(dateIssued, dateFormat) : '');

  useEffect(() => {
    setInpersonValue(modeOfTransactionPerson);
  }, []);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      bankRoutingNumber: {
        message: MESSAGES.BANK_DETAILS.ROUTING,
        rule: val => routingRegex.test(val),
      },
      bankNameValidation: {
        message: validators.bussiness.validBankName,
        rule: val => /^[A-Za-z0-9&,\s.-]{1,50}$/.test(val),
      },
      accountName: {
        message: validators.bussiness.validBankAccountName,
        rule: val => /^[A-Za-z0-9&,\s.-]{1,50}$/.test(val),
      },
      bankIFSC: {
        message: MESSAGES.BANK_DETAILS.IFSC,
        rule: val => ifscRegex.test(val),
      },
      modeOfTransactionValditation: {
        message: 'Mode of transaction Online and In Person must add up to 100%',
        rule: (val) => (Number(getInpersonValue()) + Number(val[1]) === 100),
      },
      averageTicketAmountCheck: {
        message: 'Average ticket amount should be less than average monthly amount',
        rule: (val) => Number(averagemonthlyVolumeHandler?.current?.input?.value.replace(',', '')) > Number(val ? val.replace(',', '') : 0.00),
      },
    },
  }));
  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      accountHolderName,
      nameOfBank,
      accountNumber,
      bankRoutingNumber,
      bankStatementUrl,
      bankAccountNumber,
      accountType,
      ifscNumber,
      averageMonthlyVolume: averageMonthlyVolume ? averageMonthlyVolume.replace(/[^\d]/g, '') / 100 : '',
      averageTicketAmount: averageTicketAmount ? averageTicketAmount.replace(/[^\d]/g, '') / 100 : '',
      whenIsCardCharged,
      servicesDeliveredIn,
      refundPolicy,
      seasonalBusiness,
      modeOfTransactionOnline: online[1],
      modeOfTransactionPerson: inPerson[1],
      voidCheckUrl,
      EBTCheckBox,
      requestedServiceDate,
      isServiceDateFlexible,
    }),
  }));

  const EBTCheckBoxChange = (e) => {
    setEBTCheckBoxChange(e.target.checked);
  };

  const getPercentage = (inPersn, onLine) => {
    let result = 0;
    if (inPersn && onLine) {
      result = Number(inPersn[1]) + Number(onLine[1]);
    } if (inPersn && !onLine) {
      result = Number(inPersn[1]) + 0;
    } if (!inPersn && onLine) {
      result = 0 + Number(onLine[1]);
    }
    return `${result}%`;
  };

  return (
    <>
      <Row className="my-2 mb-3">
        <Col>
          <SpText className="text-uppercase" fontSize="20px">
            Manage Bank Details
          </SpText>
        </Col>
      </Row>
      <SpForm>
        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Account Holder Name</span>
          </Col>
          <Col span={6}>
            <Input
              value={accountHolderName}
              placeholder="Name of the account holder"
              onBlur={() => setAccountHolderName(accountHolderName.trim())}
              onChange={e => setAccountHolderName(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Account Holder Name', accountHolderName, 'required|accountName')}
            </SpError>
          </Col>
          <Col span={8} className="ml-4">
            <SpTextMute>
              Name should match the legal business name or legal
              name of the individual or sole proprietor
            </SpTextMute>
          </Col>
        </Row>

        {
          internalRole && (
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Bank Name</span>
              </Col>
              <Col span={6}>
                <Input
                  placeholder="Bank Name"
                  value={nameOfBank}
                  onBlur={() => setBankName(nameOfBank.trim())}
                  onChange={e => setBankName(e.currentTarget.value)}
                />
                <SpError>
                  {simpleValidator.current.message('Bank name', nameOfBank, 'required|bankNameValidation')}
                </SpError>
              </Col>
            </Row>
          )
        }

        {
          !isIndia && (
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Account Type</span>
              </Col>
              <Col span={6}>
                <Select
                  placeholder="Select Account Type"
                  optionFilterProp="children"
                  className="w-100"
                  showSearch
                  value={accountType || 'Select Account Type'}
                  onChange={(value) => {
                    setAccountType(value);
                  }}
                >
                  <Option key={1} value="SAVINGS">SAVINGS</Option>
                  <Option key={2} value="CHECKING">CHECKING</Option>
                </Select>
                <SpError>
                  {simpleValidator.current.message('account Type', accountType, 'required')}
                </SpError>
              </Col>
            </Row>
          )
        }

        <Row type="flex" justify="start" align="middle">
          <Col span={4}>
            <span>Account Number</span>
          </Col>
          <Col span={6}>
            <Input
              value={accountNumber}
              placeholder="Bank account number"
              onBlur={() => setAccountNumber(accountNumber.trim())}
              onChange={e => setAccountNumber(e.currentTarget.value)}
            />
            <SpError>
              {simpleValidator.current.message('Account Number', accountNumber, 'required')}
            </SpError>
          </Col>
        </Row>

        {
          isIndia
            ? (
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>IFSC Number</span>
                </Col>
                <Col span={6}>
                  <Input
                    value={ifscNumber}
                    placeholder="IFSC number"
                    onBlur={() => setIfscNumber(ifscNumber.trim())}
                    onChange={e => setIfscNumber((e.currentTarget.value).toUpperCase())}
                  />
                  <SpError>
                    {simpleValidator.current.message('IFSC Number', ifscNumber, 'required|bankIFSC',
                      { messages: { required: 'The IFSC Number field is required.' } })}
                  </SpError>
                </Col>
              </Row>
            )
            : (
              <>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Routing Number</span>
                  </Col>
                  <Col span={6}>
                    <Input
                      value={bankRoutingNumber}
                      placeholder="Bank routing number"
                      onBlur={() => setBankRoutingNumber(bankRoutingNumber.trim())}
                      onChange={e => setBankRoutingNumber(e.currentTarget.value)}
                    />
                    <SpError>
                      {simpleValidator.current.message('Bank Routing Number', bankRoutingNumber, 'required|bankRoutingNumber')}
                    </SpError>
                  </Col>
                </Row>
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Void Check or Bank Letter/Statement</span>
                  </Col>
                  <Col span={6}>
                    <KYCFileUpload
                      name="Void Check"
                      endPoint={API_END_POINTS.US_BUSINESS_VOID_CHECK}
                      url={voidCheckUrl}
                      keyType="US_BUSINESS_VOID_CHECK"
                      setUrl={value => setVoidCheckUrl(value)}
                      type="Business"
                    />
                    {
                      (inPerson && inPerson[1] !== 0) ? (
                        <SpError>
                          {simpleValidator.current.message('Void Check', voidCheckUrl, 'required')}
                        </SpError>
                      ) : ''
                    }

                  </Col>
                  <Col span={8} className="ml-4" />
                </Row>
              </>
            )
        }

        {
          isIndia && (
            <Row type="flex" justify="start" align="middle">
              <Col span={4}>
                <span>Bank Account Statement</span>
              </Col>
              <Col span={6}>
                <KYCFileUpload
                  name="Bank Statement"
                  endPoint={API_END_POINTS.UPLOAD_BANK_STATEMENT}
                  url={bankStatementUrl}
                  setUrl={value => setBankStatementUrl(value)}
                />
                <SpError>
                  {simpleValidator.current.message('Bank Statement Url', bankStatementUrl, 'required')}
                </SpError>
              </Col>
              <Col span={8} className="ml-4">
                <SpTextMute>
                  Upload an Image or pdf with bank account number, IFSC code,
                  company name all in one document. Self attested mandatory.
                </SpTextMute>
              </Col>
            </Row>
          )
        }
        {!isIndia && (
          <>
            <Row className="my-2 mb-3" style={{ marginLeft: '-16px' }}>
              <Col>
                <SpText className="text-uppercase" fontSize="20px">
                  Processing Information
                </SpText>
              </Col>
            </Row>

            <SpForm>
              {!isIndia && (
                <>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Average Monthly Volume ($)</span>
                    </Col>
                    <Col span={6}>
                      <Input
                        value={averageMonthlyVolume}
                        placeholder="0.00"
                        ref={averagemonthlyVolumeHandler}
                        onChange={(e) => {
                          const regex = /^\d*\.?\d*$/;
                          const { value } = e.currentTarget;
                          if (regex.test(value) || value === '') {
                            setAverageMonthlyVolume(e.currentTarget.value);
                          } else if (!regex.test(value)) {
                            setAverageMonthlyVolume(0.00);
                          }
                        }}
                        onKeyPress={e => {
                          const keyCode = e.charCode || e.which;
                          if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                            e.preventDefault();
                          }
                        }}
                        onBlur={e => {
                          if (e.currentTarget.value) {
                            const value = formatAmount(e.currentTarget.value, currencyType);
                            setAverageMonthlyVolume(value);
                          }
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('average monthly volume', averageMonthlyVolume, 'required')}
                      </SpError>
                    </Col>
                  </Row>

                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Average Ticket Amount ($)</span>
                    </Col>
                    <Col span={6}>
                      <Input
                        value={averageTicketAmount}
                        placeholder="0.00"
                        onChange={(e) => {
                          const regex = /^\d*\.?\d*$/;
                          const { value } = e.currentTarget;
                          if (regex.test(value) || value === '') {
                            setAverageTicketAmount(e.currentTarget.value);
                          } else if (!regex.test(value)) {
                            setAverageTicketAmount(0.00);
                          }
                        }}
                        onKeyPress={e => {
                          const keyCode = e.charCode || e.which;
                          if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                            e.preventDefault();
                          }
                        }}
                        onBlur={e => {
                          if (e.currentTarget.value) {
                            const value = formatAmount(e.currentTarget.value, currencyType);
                            setAverageTicketAmount(value);
                          }
                        }}
                      />
                      <SpError>
                        {simpleValidator.current.message('Average ticket amount', averageTicketAmount, 'required|averageTicketAmountCheck')}
                      </SpError>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Do Customers Typically Receive Goods/Services at the Time of Purchase?</span>
                    </Col>
                    <Col span={6}>
                      <Select
                        placeholder="When card is charged"
                        optionFilterProp="children"
                        className="w-100"
                        showSearch
                        value={whenIsCardCharged}
                        onChange={(value) => {
                          setWhenIsCardCharged(value);
                        }}
                      >
                        <Option key={1} value="WHEN_SERVICE_PROVIDED">Yes</Option>
                        <Option key={2} value="IN_ADVANCE">No</Option>
                      </Select>
                      <SpError>
                        {simpleValidator.current.message('when is card charged', whenIsCardCharged, 'required')}
                      </SpError>
                    </Col>
                  </Row>

                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>When Are Goods and Services Typically Delivered?</span>
                    </Col>
                    <Col span={6}>
                      <Select
                        placeholder="Services delivered in"
                        optionFilterProp="children"
                        className="w-100"
                        showSearch
                        value={servicesDeliveredIn}
                        onChange={(value) => {
                          setServicesDeliveredIn(value);
                        }}
                      >
                        <Option key={1} value="ZERO_TO_SEVEN_DAYS">0-7 Days</Option>
                        <Option key={2} value="EIGHT_TO_FOURTEEN_DAYS">8-14 Days</Option>
                        <Option key={3} value="FIFTEEN_TO_THIRTY_DAYS">15-30 Days</Option>
                        <Option key={4} value="OVER_THIRTY_DAYS">Over 30 Days</Option>
                      </Select>
                      <SpError>
                        {simpleValidator.current.message('services delivered in', servicesDeliveredIn, 'required')}
                      </SpError>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>What is Your Current Refund Policy?</span>
                    </Col>
                    <Col span={6}>
                      <Select
                        placeholder="Select refund policy"
                        optionFilterProp="children"
                        className="w-100"
                        showSearch
                        value={refundPolicy}
                        onChange={(value) => {
                          setRefundPolicy(value);
                        }}
                      >
                        <Option key={1} value="EXCHANGE_ONLY">Exchange Only</Option>
                        <Option key={2} value="NO_REFUND_OR_EXCHANGE">No Refund or Exchange</Option>
                        <Option key={3} value="MORE_THAN_THIRTY_DAYS">More than 30 Days</Option>
                        <Option key={4} value="THIRTY_DAYS_OR_LESS">30 Days or Less</Option>
                      </Select>
                      <SpError>
                        {simpleValidator.current.message('refund policy', refundPolicy, 'required')}
                      </SpError>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Is Your Business Seasonal or do you Process Payments Throughout the Year?</span>
                    </Col>
                    <Col span={6}>
                      <Select
                        placeholder="Select seasonal business"
                        optionFilterProp="children"
                        className="w-100"
                        showSearch
                        value={seasonalBusiness}
                        onChange={(value) => {
                          setSeasonalBusiness(value);
                        }}
                      >
                        <Option key={1} value>SEASONAL</Option>
                        <Option key={2} value={false}>ALL YEAR</Option>
                      </Select>
                      <SpError>
                        {simpleValidator.current.message('seasonal business', seasonalBusiness, 'required')}
                      </SpError>
                    </Col>
                  </Row>
                  <Row type="flex" justify="start" align="middle">
                    <Col span={4}>
                      <span>Mode of Transaction</span>
                    </Col>
                    <Col span={6} />
                  </Row>
                  <Row type="flex" className="mt-2" justify="start" align="middle">
                    <Col span={4} />
                    <Col span={2}>
                      <span>In Person</span>
                    </Col>
                    <Col span={4}>
                      <Select
                        placeholder="Select in person"
                        optionFilterProp="children"
                        className="w-100"
                        showSearch
                        value={inPerson[0]}
                        onChange={(value) => {
                          setInPerson(value);
                          setInpersonValue(value[1]);
                        }}
                      >
                        <Option key={1} value={['0%', 0]}>0%</Option>
                        <Option key={2} value={['25%', 25]}>25% </Option>
                        <Option key={3} value={['50%', 50]}>50%</Option>
                        <Option key={4} value={['75%', 75]}>75%</Option>
                        <Option key={5} value={['100%', 100]}>100%</Option>
                      </Select>
                      <SpError>
                        {simpleValidator.current.message('in person', inPerson, 'required')}
                      </SpError>
                    </Col>
                    {(inPerson[1] > 0) && (
                      <Col span={8} className="ml-4">
                        <Checkbox onChange={EBTCheckBoxChange}>Accept Electronic Benefits Transfer (EBT) Payments</Checkbox>
                      </Col>
                    )}
                  </Row>
                  <Row type="flex" className="mt-2" justify="start" align="middle">
                    <Col span={4} />
                    <Col span={2}>
                      <span>Online</span>
                    </Col>
                    <Col span={4}>
                      <Select
                        placeholder="Select online"
                        optionFilterProp="children"
                        className="w-100"
                        showSearch
                        value={online[0]}
                        onChange={(value) => {
                          setOnline(value);
                        }}
                      >
                        <Option key={1} value={['0%', 0]}>0%</Option>
                        <Option key={2} value={['25%', 25]}>25% </Option>
                        <Option key={3} value={['50%', 50]}>50%</Option>
                        <Option key={4} value={['75%', 75]}>75%</Option>
                        <Option key={5} value={['100%', 100]}>100%</Option>
                      </Select>
                      <SpError>
                        {simpleValidator.current.message('online', online, 'required|modeOfTransactionValditation')}
                      </SpError>
                    </Col>
                  </Row>
                  <Row type="flex" className="mt-2" justify="start" align="middle">
                    <Col span={4} />
                    <Col span={2}>
                      <span>Total</span>
                    </Col>
                    <Col span={4} align="right" justify="end">
                      <span>
                        {getPercentage(inPerson, online)}
                      </span>
                    </Col>
                  </Row>
                  {(inPerson[1] > 0) && (
                    <Row type="flex" justify="start" align="middle">
                      <Col span={4}>
                        <span>Requested Service Date</span>
                      </Col>
                      <Col span={6}>
                        <DatePicker
                          defaultValue={defaultDate()}
                          allowClear={false}
                          onChange={(date, dateString) => setRequestedServiceDate(dateString)}
                          disabledDate={current => current && current < moment().subtract(1, 'days')}
                        />
                      </Col>
                      <Col>
                        <SpCaption className="pl-4">(Optional)</SpCaption>
                      </Col>
                    </Row>
                  )}
                  {(inPerson[1] > 0) && (
                    <Row type="flex" justify="start" align="middle">
                      <Col span={4}>
                        <span>Is Service Date Flexible</span>
                      </Col>
                      <Col span={6}>
                        <Select
                          placeholder="Is Service Date Flexible"
                          optionFilterProp="children"
                          className="w-100"
                          showSearch
                          value={isServiceDateFlexible}
                          onChange={(value) => {
                            setIsServiceDateFlexible(value);
                          }}
                        >
                          <Option key={1} value>Yes</Option>
                          <Option key={2} value={false}>No</Option>
                        </Select>
                      </Col>
                      <Col>
                        <SpCaption className="pl-4">(Optional)</SpCaption>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </SpForm>
          </>
        )}
      </SpForm>
    </>
  );
};

// $FlowFixMe
export default forwardRef(EditBusinessBankDetails);
