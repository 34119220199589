// @flow
import React, { useEffect, useState } from 'react';
import { Icon } from 'antd';
import { GoogleApiWrapper } from 'google-maps-react';
import moment from 'moment-timezone';
import supportEmailLogo from '../../../imgs/SupportEmail.svg';
import supportPhoneLogo from '../../../imgs/SupportPhone.svg';
import info from '../../../imgs/info.svg';

type Props = {
  pageData: Object,
};

const ReviewEventPage = (props: Props) => {
  const {
    pageData,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);

  const [mobileView, setMobileView] = useState(false);

  const getOperatingTime = (time) => {
    if (time !== 0) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let min;
      if (minutes < 10) {
        min = `0${minutes}`;
      } else if (minutes >= 10) {
        min = minutes;
      }
      if (hours > 12) {
        const hr = hours - 12;
        return `${hr}:${min} PM`;
      }
      if (hours === 0) {
        return `${12}:${min} AM`;
      }
      return `${hours}:${min} AM`;
    }
    return '12:00 AM';
  };

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 480) {
      setMobileView(true);
    }
  }, []);

  return (
    <>
      <style>
        {`
          .scrollable-content::-webkit-scrollbar {
            width: 8px;
          }

          .scrollable-content::-webkit-scrollbar-thumb {
            background: #888; /* Scrollbar thumb color */
            border-radius: 4px; /* Rounded corners */
          }

          .scrollable-content::-webkit-scrollbar-thumb:hover {
            background: #00000; /* Thumb color on hover */
          }

          .scrollable-content::-webkit-scrollbar-track {
            background: linear-gradient(
              to bottom,
              ${pageData?.primaryColor});
          }
        `}
      </style>
      <div
        className="d-flex"
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          marginBottom: '20px',
          background: `linear-gradient(to bottom, transparent, ${pageData?.primaryColor})`,
        }}
      >
        {/* Fixed Top Section */}
        <div
          style={{
            background: '#1C1C1C',
            color: '#fff',
            padding: '7px 20px',
            top: '0',
            height: '5%',
            zIndex: 10,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {/* Heading (optional) */}
          <div style={{ fontWeight: 'bold', fontSize: '18px' }}>
            {/* Add any text or heading if needed */}
          </div>
          {/* Email and Phone Section */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
            {pageData?.contactUsEmail && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img
                alt="Email Icon"
                src={supportEmailLogo}
                style={{ height: '20px' }}
              />
              <span>{pageData.contactUsEmail}</span>
            </div>
                        )}
            {pageData?.contactPhone && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img
                alt="Phone Icon"
                src={supportPhoneLogo}
                style={{ height: '20px' }}
              />
              <span>{pageData.contactPhone}</span>
            </div>
                        )}
            {pageData && (
            <div style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
              <img
                alt="More Info"
                src={info} // Replace this with the URL or import path for your 3-dot or info icon
                style={{ height: '20px' }}
                onClick={() => setIsExpanded(!isExpanded)}
              />
            </div>
            )}
          </div>
          {/* Event Page Support List */}
          {isExpanded && (
          <div
            style={{
              position: 'absolute',
              top: '6%',
              right: 0,
              backgroundColor: '#fff',
              boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
              borderRadius: '4px',
              padding: '10px 0',
              width: '250px',
              zIndex: 10,
            }}
          >
            {/* Support Details */}
            <div
              style={{
                padding: '10px 20px',
                cursor: 'pointer',
                color: '#000',
                fontSize: '16px',
                whiteSpace: 'nowrap',
                lineHeight: '20px',
              }}
            >
              { pageData.contactName ? <div style={{ fontWeight: 'bold' }}>{pageData.contactName}</div> : '' }
              { pageData.contactUsEmail ? <div>{pageData.contactUsEmail}</div> : '' }
              { pageData.contactPhone ? <div>{pageData.contactPhone}</div> : '' }
              { pageData.designation ? <div>{pageData.designation}</div> : '' }
            </div>
          </div>
          )}
        </div>
        <div
          style={{
            background: 'rgba(255, 255, 255, 0.7)',
            padding: '10px',
            height: '12%',
            display: 'flex',
            alignItems: 'center',
            top: '5%',
            zIndex: 9,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
            {/* Logo */}
            <img
              alt=""
              src={pageData?.logo}
              height="70px"
              width="70px"
              style={{ borderRadius: '10%' }}
            />
            {/* Text */}
            <div>
              {/* eslint-disable-next-line max-len */}
              {pageData ? (pageData.bannerText ? <div dangerouslySetInnerHTML={{ __html: pageData.bannerText.replaceAll('<br>', '') }} /> : pageData.title) : ''}
            </div>
          </div>
        </div>

        {/* Gradient Background */}
        <div
          style={{
            margin: 0,
            top: '17%',
            bottom: '11%',
            padding: '10px',
            display: 'flex',
            flexDirection: 'row',
            overflow: 'hidden', // Prevent scrolling
          }}
        >
          {/* Left side: Image */}
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '65%',
              overflow: 'hidden', // Prevent image overflow
            }}
          >
            <img
              id="dynamic-img"
              src={pageData?.banner}
              alt=""
              style={{
                maxWidth: '100%',
                maxHeight: '64vh',
                objectFit: 'contain',
                boxShadow: '0 4px 15px rgba(0, 0, 0, 0.5)',
              }}
            />
          </div>

          {/* Right side: Event details */}
          <div
            style={{
              flex: 1,
              display: 'flex',
              height: '100%', // Full viewport height
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '1%',
              width: '35%',
              color: '#000000',
              fontFamily: 'Arial, sans-serif',
              overflow: 'hidden', // Prevent content overflow
            }}
          >
            <h2 style={{ margin: 0, fontSize: '2.5rem', lineHeight: '1.2' }}>
              {pageData?.title}
            </h2>
            <p style={{ marginTop: '1rem', fontSize: '1.2rem', lineHeight: '1.5' }}>
              {pageData && pageData.description && (
              <div
                className="scrollable-content"
                style={{
                  maxHeight: '11rem', // Assuming each line is ~1.5rem height (7 lines * 1.5rem)
                  overflowY: 'auto',
                  whiteSpace: 'pre-wrap', // Ensures line breaks are respected
                  wordWrap: 'break-word',
                }}
                dangerouslySetInnerHTML={{
                  __html: pageData.description.replaceAll('<br>', ''),
                }}
              />
              )}
            </p>

            {
                pageData && pageData.eventDate && (
                <>
                  <br />
                  <div className="mr-2 mt-2">
                    <h1 style={{
                      margin: 0, fontSize: '2.5rem', lineHeight: '1.2', fontStyle: 'italic',
                    }}
                    >
                      <Icon type="calendar" style={{ marginRight: '10px', fontSize: '20px', color: '#000000' }} />
                      Date and time
                    </h1>
                    {
                        pageData && pageData.endTime ? (
                        // eslint-disable-next-line max-len
                          <p style={{ fontSize: '1.25rem', marginLeft: '30px' }}>{pageData && moment.utc(pageData.eventDate).format('MMM DD, YYYY')}, {getOperatingTime(pageData && pageData.startTime)} - {getOperatingTime(pageData && pageData.endTime)}</p>
                        ) : (
                        // eslint-disable-next-line max-len
                          <p style={{ fontSize: '1.25rem', marginLeft: '30px' }}>{pageData && moment.utc(pageData.eventDate).format('MMM DD, YYYY')}, {getOperatingTime(pageData && pageData.startTime)}</p>
                        )
                    }
                  </div>
                </>
                )
            }
            {
                pageData && pageData.street && pageData.city && pageData.state && pageData.zipcode && pageData.country && (
                <>
                  <br />
                  <div className="mr-2 mt-2">
                    <h1 style={{
                      margin: 0, fontSize: '2.5rem', lineHeight: '1.2', fontStyle: 'italic',
                    }}
                    >
                      <Icon type="pushpin" style={{ marginRight: '10px', fontSize: '20px', color: '#000000' }} />
                      Location
                    </h1>
                    <p style={{ fontSize: '1.25rem', marginLeft: '30px' }}>{(pageData)
                      ? `${pageData.street}, 
                        ${pageData.city}, 
                        ${pageData.state}, 
                        ${pageData.zipcode}, 
                        ${pageData.country === 1 ? 'USA' : pageData.country === 2 ? 'IN' : <>&#8211;</>}`
                      : <>&#8211;</>}
                    </p>
                  </div>
                </>
                )
            }
          </div>
        </div>


        {/* Fixed Footer */}
        <div
          style={{
            backgroundColor: pageData?.primaryColor,
            padding: '20px 0',
            textAlign: 'center',
            bottom: '0',
            height: '11%',
            zIndex: 10,
          }}
        >
          <button
            style={{
              padding: '10px 90px',
              backgroundColor: '#fff',
              color: '#000000',
              border: 'none',
              fontSize: '18px',
              fontWeight: 'bold',
              borderRadius: '20px',
              cursor: 'pointer',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.5)',
              transition: 'background-color 0.3s ease',
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = '#f0f0f0')}
            onMouseOut={(e) => (e.target.style.backgroundColor = '#fff')}
          >
            Book Now
          </button>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default GoogleApiWrapper({
  apiKey: 'AIzaSyCLR2kMaQ9AHIa_tqsG48hxA9CZ7ullXds',
})(ReviewEventPage);
