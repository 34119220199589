// @flow
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Modal, Icon, message, Upload, Radio,
} from 'antd';
import { SpH5, SpButton } from 'components/DesignKit';
import { API_END_POINTS, axiosAuthInstance, axiosEmptyInstance } from 'api';
import { NOTIFICATION } from 'store/actionTypes';
import { MESSAGES } from 'appconstants';
import { notificationActions } from 'store/actions';
import purpleFile from '../../../../../imgs/purpleFile.svg';

type Props = {
  close: Function,
  visible: boolean,
  setNotification: Function,
  eventPageGid: String,
  selectedAccount: Object,
  eventPageCustomTemplates: Array<Object>,
};

const CustomTemplateUploadModal = (props: Props) => {
  const {
    visible,
    close,
    setNotification,
    eventPageGid,
    eventPageCustomTemplates,
    selectedAccount,
  } = props;
  const [loading, setLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const isUploading = useState(false);
  const [disablePdfRadio, setDisablePdfRadio] = useState(
    eventPageCustomTemplates
      && eventPageCustomTemplates.length !== 0
      && eventPageCustomTemplates.some((customTemplate) => customTemplate && customTemplate.type === 'EVENT_PAGE_QR_PDF_RECEIPT'),
  );

  const [disableEmailRadio, setDisableEmailRadio] = useState(
    eventPageCustomTemplates
      && eventPageCustomTemplates.length !== 0
      && eventPageCustomTemplates.some((customTemplate) => customTemplate?.type === 'EVENT_PAGE_EMAIL_RECEIPT'),
  );


  let [templateType, setTemplateType] = useState(!disablePdfRadio ? 'PDF Receipt' : !disableEmailRadio ? 'Email Receipt' : '');

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });
  const beforeUpload = (file) => {
    setLoading(true);
    const isLt2M = file.size / 1024 / 1024 < 2;
    const HTML_MIME_TYPE = 'text/html';
    const isHtmlFile = file.type === HTML_MIME_TYPE;

    if (templateType === '') {
      message.error('No template type is selected');
      setLoading(false);
      return false;
    }

    if (!isHtmlFile) {
      message.error('Please select a valid HTML file');
      setLoading(false);
      return false;
    }

    if (isHtmlFile && !isLt2M) {
      message.error('File must smaller than 2MB!');
      setLoading(false);
      return false;
    }
    setLoading(false);
    return true;
  };

  const fileUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);

      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.EVENT_PAGE_CUSTOM_TEMPLATE}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.EVENT_PAGE_CUSTOM_TEMPLATE}`, { fileUrl: entity, keyType: 'EMAIL_TEMPlATE' });
        const { data } = await axiosAuthInstance.post(`${API_END_POINTS.GET_EVENT_PAGE}/${eventPageGid}/custom-template`, { templateUrl: fileUrl, type: templateType === 'PDF Receipt' ? 'EVENT_PAGE_QR_PDF_RECEIPT' : templateType === 'Email Receipt' ? 'EVENT_PAGE_EMAIL_RECEIPT' : '' });
        console.log(data);
        setLoading(false);
        setUploadSuccess(true);
        if (templateType === 'PDF Receipt') {
          setDisablePdfRadio(true);
          templateType = !disableEmailRadio ? 'Email Receipt' : '';
        } else if (templateType === 'Email Receipt') {
          setDisableEmailRadio(true);
          templateType = !disablePdfRadio ? 'PDF Receipt' : '';
        }
        setNotification({
          type: NOTIFICATION.SUCCESS,
          payload: MESSAGES.FILE_UPLOAD.SUCCESS,
        });
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      centered
      closable={false}
      width="600px"
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">
          Cancel
        </SpButton>,
        <SpButton
          onClick={() => close()}
          disabled={!uploadSuccess}
        >
          Done
        </SpButton>,
      ]}
      title="Template Upload"
    >
      <Row
        type="flex"
        justify="space-between"
        style={{
          height: '100px',
          border: '1px solid #CBCBCB',
          marginTop: '10px',
          marginBottom: '25px',
          margin: '2rem',
        }}
      >
        <Col
          span={8}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#fff',
            borderRight: '1px solid #CBCBCB',
            cursor: 'pointer',
          }}
        >
          <img src={purpleFile} alt="file" width="60px" height="60px" style={{ marginRight: '8px' }} />
        </Col>
        <Col span={16} className="p-2">
          <div className="ml-2">
            <SpH5>Do You Want To Upload ? </SpH5>
          </div>
          <div className="ml-2">
            <Radio.Group
              value={templateType}
              onChange={e => setTemplateType(e.target.value)}
              style={{ marginTop: '8px' }}
            >
              <Radio value="PDF Receipt" disabled={disablePdfRadio}>PDF Receipt</Radio>
              <Radio value="Email Receipt" disabled={disableEmailRadio}>Email Receipt</Radio>
            </Radio.Group>
          </div>
        </Col>
      </Row>
      <Row
        type="flex"
        style={{ marginLeft: '2rem' }}
      >
        <SpH5>Upload .html file</SpH5>
        <Upload
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={fileUpload}
        >
          <SpButton
            disabled={disableEmailRadio && disablePdfRadio}
            shape="round"
            ghost
            className="ml-3"
            style={{ borderColor: '#B3B3B3' }}
          >
            <div style={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '25px', paddingRight: '25px',
            }}
            >
              <Icon
                type="upload"
                style={{ color: '#292929', marginRight: '5px' }}
              /> <span style={{ color: '#292929' }}>Upload</span>
            </div>
          </SpButton>
        </Upload>
        { loading && <span className="spinner-border text-muted mt-2 ml-2"> </span>}
        { uploadSuccess && (
        <Icon
          type="check-circle"
          style={{ color: 'green', marginLeft: '7px', fontSize: '22px' }}
        />
        )}
      </Row>
      <span className="mt-1" style={{ color: '#999999', fontSize: '12px', marginLeft: '10rem' }}>
        File size should be within 2 MB
      </span>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CustomTemplateUploadModal);
