import React, {
  useState,
  createRef,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  message,
} from 'antd';
import styled from 'styled-components';

import { SpButton, SpText, SpError } from 'components/DesignKit';

import getStatus from 'utils/getStatus';

import { businessActions } from 'store/actions';

import {
  BUSINESS_TYPES,
  COUNTRIES,
} from 'appconstants';

import Loading from 'components/Loading';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getStateList from 'utils/stateList';
import {
  isVariableNullOrUndefined,
} from 'utils/validationMessages';
import editGeneratePayload from '../helper/editGeneratePayload';

import EditBusinessDetails from './components/EditBusinessDetails';
import EditBusinessBankDetails from './components/EditBusinessBankDetails';
import EditBusinessRepresentativeDetails from './components/EditBusinessRepresentativeDetails';

const StatusTag = styled('div')({
  display: 'inline-block',
  paddingLeft: '10px',
  paddingRight: '10px',
  borderRadius: '10px',
  height: '21px',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  editBusiness: Function,
  cancelEdit: Function,
  editDone: Function,
  business: Object,
  representatives: Array<Object>,
  loading: boolean,
  selectedAccount: Object,
  businessCategories: Array<Object>,
  internalRole: Object,
};

const Edit = (props: Props) => {
  const {
    selectedAccount,
    business,
    representatives,
    cancelEdit,
    editDone,
    loading,
    businessCategories,
    internalRole,
  } = props;

  const businessDetailRef = createRef();
  const businessBankAccountRef = createRef();

  const [refArray, setRefArray] = useState([businessDetailRef, businessBankAccountRef]);
  const statesInCountry = getStateList((selectedAccount && selectedAccount.country && selectedAccount.country.id));
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const isUsa = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.USA.id;
  const [businessTypes, setBusinessTypes] = useState([]);
  const [fixErrors, setFixErrors] = useState(false);
  const [businessRepresnts, setBusinessRepresents] = useState([]);

  useEffect(() => {
    representatives.forEach(() => {
      const businessRepresentativesRef = createRef();
      setRefArray(existingElements => (
        [...existingElements, businessRepresentativesRef]
      ));
    });
    setBusinessRepresents(representatives);
  }, []);

  useEffect(() => {
    setBusinessTypes(BUSINESS_TYPES[selectedAccount.country.id]);
  }, []);

  useEffect(() => {
    setBusinessTypes(BUSINESS_TYPES[selectedAccount.country.id]);
  }, [selectedAccount]);

  const handleEdit = () => {
    const validationArray = [];
    const BussinessInfo = refArray.filter((item) => item?.current !== null);
    console.log(BussinessInfo, 'BussinessInfoBussinessInfoBussinessInfoBussinessInfo');
    BussinessInfo.forEach(arr => validationArray.push(arr?.current?.validate()));
    const formValid = validationArray.every(elem => elem === true);
    const getRepresentativeInfo = BussinessInfo.slice(2).map(ref => ref.current?.getValues());
    const filterRepresentative = getRepresentativeInfo.filter(obj => (obj?.givenName !== '') && (obj?.contactEmail !== ''));
    const validateHasControl = filterRepresentative && filterRepresentative.some(obj => obj?.control === true);
    let ownership = 0;
    filterRepresentative.forEach(obj => {
      if (isVariableNullOrUndefined(obj?.ownerShipPercentage)) {
        ownership += Number(obj?.ownerShipPercentage.replace('%', ''));
      }
    });
    if (!formValid) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setFixErrors(true);
      return;
    }
    if (isIndia && !validateHasControl) {
      message.error('At least one director with control is needed. You should not be able to save a business with everybody saying No.');
      return;
    }
    if (!isIndia) {
      if (ownership > 100) {
        message.error('Cumulative Ownership of all Representatives should not exceed 100%');
        return;
      }
      if (ownership <= 50) {
        message.error('Cumulative Ownership of all Representatives should be atleast 51%');
        return;
      }
    }
    // submit form here
    const payload = editGeneratePayload({
      refArray: BussinessInfo,
      phoneCode: getCountryPhoneCode(selectedAccount.country.id),
      countryId: selectedAccount.country.id,
    });
    props.editBusiness({
      accountGid: selectedAccount.gid,
      businessGid: business.gid,
      payload,
    });
    editDone();
  };

  const onRemoveRepresentatives = (id) => {
    const listItems = [...businessRepresnts];
    listItems.splice(id, 1);
    setBusinessRepresents(listItems);
  };

  if (loading) {
    return <Loading />;
  }

  const { text, color } = getStatus(selectedAccount && selectedAccount.status);

  return (
    <>
      <Row type="flex" justify="space-between" className="mt-2 my-2 mb-3">
        <Col className="d-flex align-items-center">
          <SpText className="mr-4 text-uppercase" fontSize="20px">Manage Business Settings</SpText>
          <StatusTag style={{ background: color }}>
            {text}
          </StatusTag>
        </Col>
      </Row>
      {
        fixErrors && (
          <Row>
            <Col className="ml-3 mb-3">
              <SpError>
                Please fix one or more errors below.
              </SpError>
            </Col>
          </Row>
        )
      }
      <EditBusinessDetails
        ref={refArray[0]}
        business={business}
        statesInCountry={statesInCountry}
        country={selectedAccount.country.name}
        isIndia={isIndia}
        selectedAccount={selectedAccount}
        businessTypes={businessTypes}
        businessCategories={businessCategories}
        internalRole={internalRole}
      />
      <EditBusinessBankDetails
        ref={refArray[1]}
        isIndia={isIndia}
        business={business}
        selectedAccount={selectedAccount}
        internalRole={internalRole}
      />
      {
        businessRepresnts.map((Component, index) => (
          <EditBusinessRepresentativeDetails
            key={index}
            ref={refArray[2 + index]}
            statesInCountry={statesInCountry}
            country={selectedAccount.country.name}
            countryId={selectedAccount.country.id}
            business={business}
            businessRepresentatives={representatives[index]}
            isIndia={isIndia}
            isUsa={isUsa}
            selectedAccount={selectedAccount}
            internalRole={internalRole}
            onRemoveRepresentatives={onRemoveRepresentatives}
            show={(internalRole?.name === 'superuser') && businessRepresnts.length > 1}
            Num={index}
          />
        ))
      }
      <div className="d-flex justify-content-end">
        <SpButton
          type="secondary"
          shape="round"
          className="mr-4"
          onClick={() => cancelEdit()}
        >
          Cancel
        </SpButton>
        <SpButton
          type="primary"
          shape="round"
          onClick={handleEdit}
        >
          Save
        </SpButton>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  selectedAccount: state.account.selectedAccount,
  business: state.business.business,
  representatives: state.business.businessRepresentatives,
  businessCategories: state.business.businessCategories,
  loading: state.loading.loading,
  submitting: state.loading.submitting,
  internalRole: state.user.internalRole,
});

const mapDispatchToProps = dispatch => ({
  editBusiness: param => dispatch(businessActions.editBusiness(param)),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(Edit);
