import React, { useEffect, useRef, useState } from 'react';
import { Button, Input, Select } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { isValidPhoneNumber } from 'libphonenumber-js';
import brand from '../../../../../imgs/brand.svg';
import { AmountDivideByHundred } from '../../../../../helper';
import { SpCaption, SpError, SpH5 } from '../../../../../components/DesignKit';

const { Option } = Select;

type Props = {
    customer : Object,
    eventDetails : Object,
    orderItems : Array<Object>,
    setPayload: Function,
    setPayNow: Function,
    payNow: Boolean,
    setErrorMessage: Function,
    splitUpApi: Function,
    splitUpResponse: Object,
}

const ProductReview = (props: Props) => {
  const {
    customer,
    eventDetails,
    orderItems,
    setPayload,
    setPayNow,
    payNow,
    setErrorMessage,
    splitUpApi,
    splitUpResponse,
  } = props;

  const [customInfoValues, setCustomInfoValues] = useState(
        eventDetails?.eventPageCustomInfos?.map((customInfo) => ({
          ...customInfo,
          value: customInfo.value || '',
        })),
  );

  const [promoCode, setPromoCode] = useState('');
  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [message, setMessage] = useState('');

  const [subtotal, setSubtotal] = useState(0.00);
  const [orderDiscount, setOrderDiscount] = useState(0.00);
  const [serviceCharge, setServiceCharge] = useState(0.00);
  const [serviceFeePercentage, setServiceFeePercentage] = useState(0.00);
  const [taxes, setTaxes] = useState(0.00);
  const [tipAmount, setTipAmount] = useState(0.00);
  const [total, setTotal] = useState(0.00);

  const [tip, setTip] = useState(null);
  const [tipType, setTipType] = useState('AMOUNT');
  const [customTip, setCustomTip] = useState(false);

  const [issuedBy, setIssuedBy] = useState('');
  const [offlinePaymentType, setOfflinePaymentType] = useState('');
  const [offlinePaymentNote, setOfflinePaymentNote] = useState('');
  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneNumValidation: {
        message: 'The phone number is invalid',
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  const calculateOrder = (tip1, tipType1, customTip1, promoCode1) => {
    if (eventDetails) {
      const payload = {
        eventPageGid: eventDetails.gid,
        promoCode: promoCode1 || null,
        tip: tip1 ? (customTip1 ? tip1.replace(/,/g, '').replace('.', '') : tip1) : null,
        tipType: tipType1,
        eventPageOrderLineItems: orderItems.flatMap(item => item.lineItems),
        eventPageOrderDiscounts: eventDetails.coupon ? [
          {
            discountGid: eventDetails.coupon.gid,
            stackable: eventDetails.discountStackable,
            type: 'COUPON',
            amountOff: eventDetails.coupon.amountOff ? eventDetails.coupon.amountOff * -1 : eventDetails.coupon.amountOff,
            percentageOff: eventDetails.coupon.percentageOff,
          },
        ] : [],
      };

      splitUpApi(payload);
    }
  };

  useEffect(() => {
    if (splitUpResponse) {
      setSubtotal(splitUpResponse.subTotal ? AmountDivideByHundred(splitUpResponse.subTotal) : 0);
      setOrderDiscount(splitUpResponse.orderDiscount ? AmountDivideByHundred(splitUpResponse.orderDiscount * -1) : 0);
      setServiceCharge(splitUpResponse.serviceFee ? AmountDivideByHundred(splitUpResponse.serviceFee) : 0);
      setServiceFeePercentage(splitUpResponse.serviceFeePercentage ? AmountDivideByHundred(splitUpResponse.serviceFeePercentage) : 0);
      setTaxes(splitUpResponse.tax ? AmountDivideByHundred(splitUpResponse.tax) : 0);
      setTipAmount(splitUpResponse.tipAmount ? splitUpResponse.tipAmount : 0);
      setTotal(splitUpResponse.netPayable ? AmountDivideByHundred(splitUpResponse.netPayable) : 0);
      setMessage(splitUpResponse.message ? splitUpResponse.message : '');
    }
  }, [splitUpResponse]);

  useEffect(() => {
    calculateOrder();
  }, [orderItems]);

  useEffect(() => {
    if (!promoCodeValue) {
      setMessage('');
      calculateOrder(tip, tipType, customTip, null);
    }
  }, [promoCodeValue]);

  useEffect(() => {
    if (payNow && total > 0) {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        const list = [...customInfoValues];
        setCustomInfoValues(list);
        setPayNow(false);
        return;
      }
      setPayload({
        customer,
        eventPageGid: eventDetails.gid,
        promoCode: promoCode && message && message === 'Promo-Code applied' ? promoCode : null,
        totalTip: tipAmount,
        eventPageOrderLineItems: orderItems.flatMap(item => item.lineItems.map(lineItem => ({
          ...lineItem,
          lineItemDiscounts: [],
        }))),
        eventPageOrderLabels: customInfoValues
          .filter(customInfo => customInfo.value)
          .map(customInfo => ({ label: customInfo.inputFieldLabel, value: customInfo.value })),
        orderMode: 'OFFLINE',
        issuedBy,
        offlinePaymentType,
        offlinePaymentNote,
      });
    } else {
      if (payNow && total === 0) {
        setErrorMessage("Total can't be zero...");
      }
      setPayNow(false);
    }
  }, [payNow]);

  const handleInputChange = (index, newValue) => {
    const updatedCustomInfos = [...customInfoValues];
    updatedCustomInfos[index].value = newValue; // Update the value at the given index
    setCustomInfoValues(updatedCustomInfos); // Update the state
  };


  const handleApplyPromo = () => {
    setPromoCode(promoCodeValue);
    calculateOrder(tip, tipType, customTip, promoCodeValue);
  };

  return (
    <div className="product-screen">
      {/* Header */}
      <h4 style={{ textAlign: 'center', marginBottom: '20px', fontWeight: 'bold' }}>
        Review & Proceed
      </h4>

      {/* Product Details */}
      {orderItems.map((item, index) => ((item.lineItems && item.lineItems.length > 0) ? (
        <div key={index} style={{ marginBottom: '20px' }}>
          <h6 style={{ marginBottom: '10px', fontWeight: 'bold', color: '#676767' }}>{item.name}</h6>
          {item.lineItems.map((lineItem, lineIndex) => (
            <div
              key={lineIndex}
              className="d-flex align-items-center justify-content-between"
              style={{ marginLeft: '10px', padding: '10px 0' }}
            >
              <div style={{ textAlign: 'left', width: '60%' }}>
                <h6 style={{ margin: '0' }}>{lineItem.name}</h6>
                <p style={{ margin: '5px 0', color: '#555' }}>
                  ${lineItem.price ? AmountDivideByHundred(lineItem.price) : '0.00'}
                  {lineItem.lineItemDiscounts?.length > 0 && (
                    <span className="sc-fbJfA uhGua discount-badge">
                      {lineItem.lineItemDiscounts[0].percentageOff
                        ? `${lineItem.lineItemDiscounts[0].percentageOff}%`
                        // eslint-disable-next-line max-len
                        : `${lineItem.price > lineItem.lineItemDiscounts[0].amountOff ? '$ ' : '100% upto $ '}${AmountDivideByHundred(lineItem.lineItemDiscounts[0].amountOff * -1)}`} Off
                    </span>
                                        )}
                </p>
                {lineItem.lineItemModifiers && lineItem.lineItemModifiers.length > 0 && (
                  <>
                    <p className="additional-info">Modifiers:</p>
                    {lineItem.lineItemModifiers.map((lineItemModifier, lineItemModifierIndex) => (
                      <p
                        key={lineItemModifierIndex}
                        style={{ margin: '7.5px 20px', fontSize: '14px', color: '#555' }}
                      >
                        {lineItemModifier.name} ($
                        {lineItemModifier.price ? AmountDivideByHundred(lineItemModifier.price) : '0.00'} x
                        {lineItemModifier.quantity})
                      </p>
                    ))}
                  </>
                )}
                {lineItem.lineItemItems && lineItem.lineItemItems.length > 0 && (
                  <>
                    <p className="additional-info">
                      Items:
                    </p>
                    {lineItem.lineItemItems.map((lineItemItem, lineItemItemIndex) => (
                      <div key={lineItemItemIndex}>
                        <p
                          style={{ margin: '7.5px 20px', fontSize: '14px', color: '#555' }}
                        >
                          {lineItemItem.name}
                          ({lineItemItem.lineItemModifiers && lineItemItem.lineItemModifiers.length > 0
                            // eslint-disable-next-line max-len
                            ? `($ ${lineItemItem.price ? AmountDivideByHundred(lineItemItem.price) : '0.00'} + (M - $ ${AmountDivideByHundred(lineItemItem.lineItemModifiers.reduce(
                              (sum, modifier) => sum + (modifier.quantity * modifier.price || 0), 0,
                            ))}))`
                            : `$ ${lineItemItem.price ? AmountDivideByHundred(lineItemItem.price) : '0.00'}`} x {lineItemItem.quantity})
                          {lineItemItem.lineItemDiscounts?.length > 0 && (
                          <span className="sc-fbJfA uhGua discount-badge">
                            {lineItemItem.lineItemDiscounts[0].percentageOff
                              ? `${lineItemItem.lineItemDiscounts[0].percentageOff}%`
                              // eslint-disable-next-line max-len
                              : `${lineItemItem.price > (lineItemItem.lineItemDiscounts[0].amountOff * -1) ? '$ ' : '100% upto $ '}${AmountDivideByHundred(lineItemItem.lineItemDiscounts[0].amountOff * -1)}`} Off
                          </span>
                                                        )}
                        </p>

                        {lineItemItem.lineItemModifiers && lineItemItem.lineItemModifiers.length > 0 && (
                        <>
                          <p className="additional-info" style={{ margin: '7.5px 20px' }}>
                            Modifiers(M):
                          </p>
                          {lineItemItem.lineItemModifiers.map((lineItemModifier, lineItemIndex) => (
                            <p
                              key={`${lineIndex}-${lineItemIndex}`}
                              style={{ margin: '7.5px 40px', fontSize: '14px', color: '#555' }}
                            >
                              {lineItemModifier.name} ($
                              {lineItemModifier.price ? AmountDivideByHundred(lineItemModifier.price) : '0.00'} x
                              {lineItemModifier.quantity})
                            </p>
                          ))}
                        </>
                        )}
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div style={{ textAlign: 'center', width: '10%' }}>
                <p style={{ margin: '0' }}>{lineItem.quantity}</p>
              </div>
              <div style={{ textAlign: 'right', width: '30%' }}>
                <p style={{ margin: '0' }}>${lineItem.totalPrice ? AmountDivideByHundred(lineItem.totalPrice) : '0.00'}</p>
              </div>
            </div>
          ))}
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ borderBottom: '2px dashed #B3B3B3', padding: '10px 0' }}
          />
        </div>
      ) : ('')))}

      {/* Subtotal */}
      <div
        style={{
          borderBottom: '2px dashed #B3B3B3',
          marginBottom: '20px',
          paddingBottom: '10px',
        }}
      >
        <h6 className="d-flex justify-content-between font-weight-bold">
          <span>Sub Total</span> <span>${subtotal}</span>
        </h6>
        <div style={{ marginTop: '7.5px' }}>
          <Input
            type="text"
            className="input-box"
            style={{ width: 'calc(60% - 10px)' }}
            placeholder="Enter Promo Code"
            value={promoCodeValue}
            onChange={(e) => setPromoCodeValue((e.target.value).toUpperCase())}
          />
          <Button
            className="tip-button"
            onClick={handleApplyPromo}
          >
            Apply
          </Button>
        </div>
        {message ? (
          <div style={{ margin: '5px' }}>
            <span style={{ color: (message === 'Promo-Code applied' ? '#00B04A' : '#FF0000') }}>{message}</span>
          </div>
        ) : (
          <div style={{ margin: '5px' }} />
        )}
      </div>

      {/* Bill Details */}
      <div
        style={{
          borderBottom: '2px dashed #B3B3B3',
          marginBottom: '20px',
          paddingBottom: '10px',
        }}
      >
        <h6 style={{ marginBottom: '20px', fontWeight: 'bold' }}>Bill details</h6>
        <p className="d-flex justify-content-between" style={{ marginBottom: '10px' }}>
          <span>Sub total</span> <span>${subtotal}</span>
        </p>
        {orderDiscount !== 0.00 && (
        <p className="d-flex justify-content-between" style={{ marginBottom: '10px' }}>
          <span>Discount</span> <span style={{ color: '#FF0000' }}>- ${orderDiscount}</span>
        </p>
        )}
        {taxes !== 0.00 && (
        <p className="d-flex justify-content-between" style={{ marginBottom: '10px' }}>
          <span>Taxes</span> <span>${taxes}</span>
        </p>
        )}
        {serviceCharge !== 0.00 && (
        <p className="d-flex justify-content-between" style={{ marginBottom: '10px' }}>
          <span>Service Charge ({serviceFeePercentage}%)</span> <span>${serviceCharge}</span>
        </p>
        )}
        <p className="d-flex justify-content-between" style={{ marginBottom: '10px' }}>
          <span>Tip</span> <span>${AmountDivideByHundred(tipAmount)}</span>
        </p>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          gap: '8px',
          flexWrap: 'wrap',
        }}
        >
          <Button
            className="tip-button"
            onClick={() => {
              setCustomTip(false);
              setTip(0);
              calculateOrder(0, 'AMOUNT', null, promoCode);
            }}
          >
            None
          </Button>

          {eventDetails?.eventPageTips?.map((tips) => (
            <Button
              key={tips.tip}
              className="tip-button"
              onClick={() => {
                setCustomTip(false);
                setTip(Math.round(tips.tip * 100));
                setTipType(tips.tipType);
                calculateOrder(Math.round(tips.tip * 100), tips.tipType, null, promoCode);
              }}
            >
              {tips.tipType === 'AMOUNT' ? `$${tips.tip}` : `${tips.tip}%`}
            </Button>
          ))}

          <Button
            className="tip-button"
            onClick={() => {
              setCustomTip(true);
              setTipAmount();
              setTip();
              calculateOrder(0, 'AMOUNT', null, promoCode);
            }}
          >
            Custom
          </Button>
        </div>
        {customTip && (
        <div
          className="d-flex justify-content-start align-items-center gap-8"
          style={{
            marginTop: '1%',
            flexWrap: 'wrap',
          }}
        >
          <Input
            className="input-box"
            style={{ backgroundColor: '#FFFFFF', width: '30%', margin: 0, border: 0 }}
            value={tip}
            prefix="$ "
            placeholder="0.00"
            onChange={(e) => {
              const regex = /^\d*\.?\d*$/;
              const { value } = e.currentTarget;
              if (regex.test(value) || value === '') {
                setTip(e.currentTarget.value);
              } else if (!regex.test(value)) {
                setTip(0.00);
              }
            }}
            onKeyPress={e => {
              const keyCode = e.charCode || e.which;
              if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                e.preventDefault();
              }
            }}
            onBlur={e => {
              if (e.currentTarget.value) {
                const value = parseFloat(e.currentTarget.value).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
                setTip(value);
              }
            }}
          />
          <Button
            className="tip-button"
            onClick={() => {
              setTipType('AMOUNT');
              calculateOrder(tip, 'AMOUNT', true, promoCode);
            }}
          >
            Add
          </Button>
        </div>
        )}
      </div>

      {/* Total */}
      <h6
        className="d-flex justify-content-between font-weight-bold"
        style={{
          marginBottom: '20px',
          paddingBottom: '25px',
          borderBottom: '2px solid #B3B3B3',
        }}
      >
        <span>Total</span> <span>${total}</span>
      </h6>

      {/* User Information */}
      <div
        style={{
          marginBottom: '20px',
          paddingBottom: '25px',
          borderBottom: '2px solid #B3B3B3',
        }}
      >
        <h6 style={{ marginBottom: '10px' }}>Your Information</h6>
        {customInfoValues?.map((customInfo, index) => (
          <>
            <input
              className="input-box"
              type={customInfo?.inputFieldType === 'INPUT_EMAIL' ? 'email' : 'text'}
              placeholder={customInfo?.inputFieldLabel}
              onChange={(e) => handleInputChange(index, e.target.value)}
              value={customInfo.value}
              style={{
                width: '100%',
                padding: '10px',
                marginRight: '0',
                marginTop: '5px',
                marginBottom: '5px',
              }}
            />
            <SpError fontSize="12px" color="#ff1e1e">
              {customInfo && !customInfo.isOptional && simpleValidator.current.message(customInfo?.inputFieldLabel, customInfo.value, 'required')}
            </SpError>
          </>
        ))}
      </div>

      {/* Issuer Information */}
      <div>
        <h6 style={{ marginBottom: '10px' }}>Issuer Information</h6>
        <>
          <div className="mb-2">
            <label htmlFor=""><SpH5>Name</SpH5></label>
            <input
              type="text"
              className="input-box d-flex align-items-center"
              style={{
                width: '100%',
                padding: '10px',
                marginRight: '0',
                marginBottom: '5px',
              }}
              value={issuedBy}
              onChange={(e) => setIssuedBy((e.target.value))}
            />
            <SpError fontSize="12px" color="#ff1e1e">
              {simpleValidator.current.message('Name', issuedBy, 'required')}
            </SpError>
          </div>
          <div className="mb-2">
            <label htmlFor=""><SpH5>Payment Type</SpH5></label>
            <Select
              style={{
                width: '100%',
                marginRight: '0',
                marginBottom: '5px',
              }}
              placeholder="Select Payment type"
              showSearch
              optionFilterProp="children"
              value={offlinePaymentType}
              onChange={(e) => setOfflinePaymentType(e)}
            >
              <Option value="CASH" className="text-capitalize">
                CASH
              </Option>
              <Option value="CHECK" className="text-capitalize">
                CHECK
              </Option>
              <Option value="CUSTOM" className="text-capitalize">
                CUSTOM
              </Option>
            </Select>
            <SpError>
              {simpleValidator.current.message('Payment Type', offlinePaymentType, 'required')}
            </SpError>
          </div>
          <div>
            <label htmlFor="">
              <SpH5>Note</SpH5>
              <SpCaption className="pl-2">(Optional)</SpCaption>
            </label>
            <input
              type="text"
              className="input-box d-flex align-items-center"
              style={{
                width: '100%',
                padding: '10px',
                marginRight: '0',
                marginBottom: '5px',
              }}
              value={offlinePaymentNote}
              onChange={(e) => setOfflinePaymentNote((e.target.value))}
            />
          </div>
        </>
      </div>

      {/* Footer */}
      <div style={{
        textAlign: 'center', marginTop: '20px', fontSize: '12px', color: '#888',
      }}
      >
        <img alt="" src={brand} style={{ height: '40px' }} />
      </div>
    </div>
  );
};

export default ProductReview;
