import { combineReducers } from 'redux';
import {
  PAGES,
  TOKENS,
} from 'appconstants';

import persistor from './persistor';
import auth from './auth';
import account from './account';
import business from './business';
import membership from './membership';
import roles from './roles';
import user from './user';
import payments from './payments';
import loading from './loading';
import customers from './customers';
import settings from './settings';
import notification from './notification';
import payouts from './payouts';
import transactions from './transactions';
import reports from './reports';
import adminreports from './adminreports';
import adminschedulereports from './adminschedulereports';
import terminals from './terminal';
import locations from './locations';
import disputes from './disputes';
import locationDetails from './locationDetails';
import customerDetails from './customerDetails';
import publickeys from './publickeys';
import secretkeys from './secretkeys';
import symmetrickeys from './symmetrickeys';
import payoutDetails from './payoutDetails';
import support from './support';
import paymentDetails from './paymentDetails';
import userProfile from './userProfile';
import receipts from './receipts';
import disputeEvidence from './disputeEvidence';
import dashboard from './dashboard';
import paymentsLink from './paymentsLink';
import paymentsLinkDetails from './paymentsLinkDetails';
import subscription from './subscription';
import subscriptionDetails from './subscriptionDetails';
import plans from './plans';
import plansDetails from './plansDetails';
import invoices from './invoices';
import products from './products';
import invoiceDetails from './invoiceDetails';
import paymentPages from './paymentPages';
import paymentPagesDetails from './paymentPagesDetails';
import reminders from './reminders';
import paymentsButton from './paymentsButton';
import paymentsButtonDetails from './paymentsButtonDetails';
import subscriptionButton from './subscriptionButton';
import subscriptionButtonDetails from './subscriptionButtonDetails';
import webhooks from './webhooks';
import quickbooks from './quickbooks';
import referral from './referral';
import rates from './rates';
import ratesDetails from './ratesDetails';
import acquirer from './acquirer';
import settlement from './settlement';
import taxrates from './taxRates';
import coupons from './coupons';
import couponDetails from './couponDetails';
import offers from './offers';
import offerDetails from './offerDetails.js';
import affiliateAccounts from './affiliate';
import affiliateTransctions from './affiliateTransactions';
import affiliateEarnings from './affiliateEarnings';
import quickbookDetails from './quickbookDetails';
import salesforce from './salesforce';
import scheduleReports from './scheduleReports';
import adminTerminal from './adminTerminal';
import adminTerminalDetails from './adminTerminalDetails';
import spField from './spField';
import spObject from './spObject';
import poskey from './poskey';
import globalRestrictedKey from './globalRestrictedKey';
import paymentWidgets from './paymentWidgets';
import paymentWidgetsDetails from './paymentWidgetsDetails';
import issuer from './issuer';
import issuerrate from './issuerrate';
import issuerrateDetails from './issuerrateDetails';
import cashtransactions from './cashtransactions';
import chequetransactions from './chequetransactions';
import cashtransactionsDetails from './cashtransactionsDetails';
import chequetransactionsDetails from './chequetransactionsDetails';
import cashreceipts from './cashreceipts';
import chequereceipts from './chequereceipts';
import internalMembership from './internalMembership';
import terminalDetails from './terminalDetails';
import services from './services';
import serviceDetails from './serviceDetails';
import documents from './documents';
import transfers from './transfers';
import adminBin from './adminBin';
import pageRecipts from './pageRecipts';
import vendors from './vendors';
import achPayments from './achPayments';
import vendorsDetails from './vendorsDetails';
import transfersDetails from './transfersDetails';
import fundingSource from './fundingSource';
import fundingSourceLinks from './fundingSourceLinks';
import transferlinks from './transferlinks';
import bulkTransfer from './bulkTransfer';
import bulkTransferDetails from './bulkTransferDetails';
import bankTransfers from './bankTransfers';
import transferReceipts from './transferReceipts';
import paymentCheckout from './paymentCheckout';
import paymentCheckoutDetails from './paymentCheckoutDetails';
import customDocumentRequest from './customDocumentRequest';
import customDocumentRequestDetails from './customDocumentRequestDetails';
import filterParams from './filterParams';
import statusParams from './statusParams';
import accountSettlement from './accountSettlement';
import partnerSettlement from './partnerSettlement';
import ocrRequest from './ocrRequest';
import bankStatement from './bankStatement';
import bankStatementDetails from './bankStatementDetails';
import partnerParams from './partnerParams';
import accountSlug from './accountSlug';
import accountSlugDetails from './accountSlugDetails';
import wallet from './wallet';
import walletTransaction from './walletTransaction';
import walletTransactionDetails from './walletTransactionDetails';
import walletDetails from './walletDetails';
import transferSettings from './transferSettings';
import rewardSettings from './rewardSettings';
import staticqr from './staticqr';
import staticqrDetails from './staticqrDetails';
import reportTemplates from './reportTemplates';
import reportTemplatesDetails from './reportTemplatesDetails';
import customReports from './customReports';
import { AUTH, PAYMENTSLINK } from '../actionTypes';
import recaptchaDetails from './recaptchaDetails';
import usonboarding from './usonboarding';
import vendorInvoices from './vendorInvoices';
import vendorInvoiceDetails from './vendorInvoiceDetails';
import beneficiary from './beneficiary';
import beneficiaryDetails from './beneficiaryDetails';
import walletInvoices from './walletInvoices';
import walletInvoiceDetails from './walletInvoiceDetails';
import woocommerce from './woocommerce';
import wooCommerceDetails from './wooCommerceDetails';
import cloverReceipts from './cloverReceipts';
import orderReceipt from './orderReceipt';
import catalog from './catalog';
import catalogOrders from './catalogOrders';
import adminCatalog from './adminCatalog';
import adminOrders from './adminOrders';
import inventoryOrders from './inventoryOrders';
import inventoryItems from './inventoryItems';
import inventoryCategories from './inventoryCategories';
import inventoryModifiers from './inventoryModifiers';
import inventoryOrderDetails from './inventoryOrderDetails';
import inventoryItemDetails from './inventoryItemDetails';
import inventoryCategorieDetails from './inventoryCategorieDetails';
import inventoryModifierDetails from './inventoryModifierDetails';
import events from './events';
import eventDetails from './eventDetails';
import inventoryOrderTypes from './inventoryOrderTypes';
import inventoryPrinterLabel from './inventoryPrinterLabel';
import inventoryPrinterLabelDetails from './inventoryPrinterLabelDetails';
import inventoryShops from './inventoryShops';
import inventoryShopsDetails from './inventoryShopsDetails';
import cloverWebhook from './cloverWebhook';
import inventoryQuickSupport from './inventoryQuickSupport';
import subscriptionv2 from './subscriptionv2';
import subscriptionV2Details from './subscriptionV2Details';
import planv2 from './planv2';
import planv2Details from './planv2Details';
import subscriptionButtonV2 from './subscriptionButtonV2';
import subscriptionButtonV2Details from './subscriptionButtonV2Details';
import shopDetails from './shopDetails';
import dining from './dining';
import customdashboard from './customdashboard';
import cloverSync from './cloverSync';
import inventoryReservations from './inventoryReservations';
import inventoryReservationsDetails from './inventoryReservationsDetails';
import customerSupport from './customerSupport';
import serviceRates from './serviceRates';
import quickSupportSummary from './quickSupportSummary';
import tableDetails from './tableDetails';
import menus from './menus';
import hoursOfOperations from './operatingHours';
import inventoryPreorders from './inventoryPreorders';
import sequenceGenerators from './sequenceGenerators';
import inventoyFeedback from './inventoryFeedback';
import advanceFees from './advanceFee';
import mobileView from './mobileView';
import manageAccounts from './manageAccounts';
import accountSession from './accountSession';
import donationPages from './donationPages';
import donationpageDetails from './donationpageDetails';
import eventPages from './eventPages';
import eventPageDetails from './eventPageDetails';
import exchangeRate from './exchangeRate';

const appReducer = combineReducers({
  persistor,
  auth,
  account,
  business,
  membership,
  roles,
  user,
  payments,
  loading,
  customers,
  settings,
  notification,
  payouts,
  transactions,
  reports,
  adminreports,
  adminschedulereports,
  terminals,
  locations,
  disputes,
  locationDetails,
  customerDetails,
  publickeys,
  secretkeys,
  symmetrickeys,
  payoutDetails,
  support,
  paymentDetails,
  userProfile,
  receipts,
  disputeEvidence,
  dashboard,
  paymentsLink,
  paymentsLinkDetails,
  subscription,
  subscriptionDetails,
  plans,
  plansDetails,
  invoices,
  products,
  invoiceDetails,
  paymentPages,
  paymentPagesDetails,
  reminders,
  paymentsButton,
  paymentsButtonDetails,
  subscriptionButton,
  subscriptionButtonDetails,
  webhooks,
  quickbooks,
  referral,
  rates,
  ratesDetails,
  acquirer,
  settlement,
  taxrates,
  coupons,
  couponDetails,
  offers,
  offerDetails,
  affiliateAccounts,
  affiliateTransctions,
  affiliateEarnings,
  quickbookDetails,
  salesforce,
  scheduleReports,
  adminTerminal,
  adminTerminalDetails,
  spField,
  spObject,
  poskey,
  globalRestrictedKey,
  paymentWidgets,
  paymentWidgetsDetails,
  issuer,
  issuerrate,
  issuerrateDetails,
  cashtransactions,
  chequetransactions,
  cashtransactionsDetails,
  chequetransactionsDetails,
  cashreceipts,
  chequereceipts,
  internalMembership,
  terminalDetails,
  services,
  serviceDetails,
  documents,
  transfers,
  adminBin,
  pageRecipts,
  vendors,
  achPayments,
  vendorsDetails,
  transfersDetails,
  fundingSource,
  fundingSourceLinks,
  transferlinks,
  bulkTransfer,
  bulkTransferDetails,
  bankTransfers,
  transferReceipts,
  paymentCheckout,
  paymentCheckoutDetails,
  customDocumentRequest,
  customDocumentRequestDetails,
  filterParams,
  statusParams,
  accountSettlement,
  partnerSettlement,
  ocrRequest,
  bankStatement,
  bankStatementDetails,
  partnerParams,
  accountSlug,
  accountSlugDetails,
  wallet,
  walletTransaction,
  walletTransactionDetails,
  walletDetails,
  transferSettings,
  rewardSettings,
  staticqr,
  staticqrDetails,
  reportTemplates,
  reportTemplatesDetails,
  customReports,
  recaptchaDetails,
  usonboarding,
  vendorInvoices,
  vendorInvoiceDetails,
  beneficiary,
  beneficiaryDetails,
  walletInvoices,
  walletInvoiceDetails,
  woocommerce,
  wooCommerceDetails,
  cloverReceipts,
  orderReceipt,
  catalog,
  catalogOrders,
  adminCatalog,
  adminOrders,
  inventoryOrders,
  inventoryItems,
  inventoryCategories,
  inventoryModifiers,
  inventoryOrderDetails,
  inventoryItemDetails,
  inventoryCategorieDetails,
  inventoryModifierDetails,
  events,
  eventDetails,
  inventoryOrderTypes,
  inventoryPrinterLabel,
  inventoryPrinterLabelDetails,
  inventoryShops,
  inventoryShopsDetails,
  cloverWebhook,
  inventoryQuickSupport,
  subscriptionv2,
  subscriptionV2Details,
  planv2,
  planv2Details,
  subscriptionButtonV2,
  subscriptionButtonV2Details,
  shopDetails,
  dining,
  customdashboard,
  cloverSync,
  inventoryReservations,
  inventoryReservationsDetails,
  customerSupport,
  serviceRates,
  quickSupportSummary,
  tableDetails,
  menus,
  hoursOfOperations,
  inventoryPreorders,
  sequenceGenerators,
  inventoyFeedback,
  advanceFees,
  mobileView,
  manageAccounts,
  accountSession,
  donationPages,
  donationpageDetails,
  eventPages,
  eventPageDetails,
  exchangeRate,
});

const rootReducer = (state, action) => {
  if (action.type === PAYMENTSLINK.CLEAR_DATA) {
    /* eslint-disable */
    const {
      persistor,
      auth,
      account,
      roles,
      user,
      settings,
      support,
      loading,
      partnerParams,
      userProfile,
    } = state;
    state = {
      persistor,
      auth,
      userProfile,
      account,
      roles,
      user,
      loading,
      partnerParams,
      settings,
      support,
    };
  }
  if (action.type === AUTH.LOGOUT) {
    localStorage.removeItem(TOKENS.ACCESS_TOKEN);
    localStorage.removeItem(TOKENS.REFRESH_TOKEN);
    localStorage.removeItem('email');
    localStorage.removeItem(TOKENS.REFERRAL_CODE);
    localStorage.removeItem(TOKENS.AFFILIATE_CODE);
    localStorage.removeItem('dynamicStaticQr');
    localStorage.removeItem('fcmToken');
    // eslint-disable-next-line no-param-reassign
    state = undefined;
    window.location.assign(PAGES.LOGIN);
  } else if (action.type === AUTH.UNLOCK) {
    localStorage.removeItem(TOKENS.ACCESS_TOKEN);
    localStorage.removeItem(TOKENS.REFRESH_TOKEN);
    localStorage.removeItem(TOKENS.REFERRAL_CODE);
    localStorage.removeItem(TOKENS.AFFILIATE_CODE);
    localStorage.removeItem('dynamicStaticQr');
    localStorage.removeItem('fcmToken');
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
