import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Switch,
  Route,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { Menu } from 'antd';
import { SpTab } from 'components/DesignKit';
import {
  COUNTRIES,
  ACQUIRER_TYPES,
} from 'appconstants';
import { cloverWebhookActions } from 'store/actions';

import AccountSettings from './components/AccountSettings';
import BusinessSettings from './components/BusinessSettings';
import SupportSettings from './components/SupportSettings';
import Referrals from './components/Referrals';
import AchPayments from './components/AchPayments';
import BankTransfer from './components/BankTransfer';
import TransferSettings from './components/TransferSettings';
import CustomDocuments from './components/CustomDocuments';
// import USOnboarding from './components/USOnboarding';
import CloverWebhook from './components/CloverWebhook';
import CloverSync from './components/CloverSync';
import VirtualTerminal from './components/VirtualTerminal';

type Props = {
  location: {
    pathname: string
  },
};

const Settings = (props: Props) => {
  const [current, setCurrent] = useState('account');
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { selectedAccount } = useSelector(state => state.account);
  const { internalRole } = useSelector(state => state.user);
  const { cloverInternalCredentials } = useSelector(state => state.cloverWebhook);
  const [acquirerType, setAcquirerType] = useState(selectedAccount && selectedAccount.acquirer && selectedAccount.acquirer.name);
  const isUSA = ((selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.USA.id);
  const {
    location,
  } = props;

  const setMenu = (query) => {
    switch (query) {
      case '/settings': {
        setCurrent('account');
        break;
      }
      case '/settings/business': {
        setCurrent('business');
        break;
      }
      case '/settings/support': {
        setCurrent('support');
        break;
      }
      case '/settings/referrals': {
        setCurrent('referrals');
        break;
      }
      case '/settings/ach-payments': {
        setCurrent('ach-payments');
        break;
      }
      case '/settings/bank-transfer': {
        setCurrent('bank-transfer');
        break;
      }
      case '/settings/customdocuments': {
        setCurrent('customdocuments');
        break;
      }
      case '/settings/transfer': {
        setCurrent('transfer');
        break;
      }
      // case '/settings/usonboard': {
      //   setCurrent('usonboard');
      //   break;
      // }
      case '/settings/cloverwebhook': {
        setCurrent('cloverwebhook');
        break;
      }
      case '/settings/cloversync': {
        setCurrent('cloversync');
        break;
      }
      case '/settings/terminal': {
        setCurrent('terminal');
        break;
      }
      default:
        setCurrent('account');
    }
  };

  useEffect(() => {
    const { pathname } = location;
    setMenu(pathname);
    dispatch(cloverWebhookActions.fetchInternalCloverWebhook());
  }, [location]);

  useEffect(() => {
    setAcquirerType(selectedAccount && selectedAccount.acquirer && selectedAccount.acquirer.name);
  }, [selectedAccount]);

  return (
    <div>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
        <Menu.Item key="account">
          <SpTab
            to={path}
            selected={current === 'account'}
          >
            Account
          </SpTab>
        </Menu.Item>
        <Menu.Item key="business">
          <SpTab
            to={`${path}/business`}
            selected={current === 'business'}
          >
            Business
          </SpTab>
        </Menu.Item>
        <Menu.Item key="support">
          <SpTab
            to={`${path}/support`}
            selected={current === 'support'}
          >
            Support
          </SpTab>
        </Menu.Item>
        <Menu.Item key="referrals">
          <SpTab
            to={`${path}/referrals`}
            selected={current === 'referrals'}
          >
            Referrals
          </SpTab>
        </Menu.Item>
        <Menu.Item key="cloversync">
          <SpTab
            to={`${path}/cloversync`}
            selected={current === 'cloversync'}
          >
            Clover Sync
          </SpTab>
        </Menu.Item>
        {
          isUSA && (
            <Menu.Item key="terminal">
              <SpTab
                to={`${path}/terminal`}
                selected={current === 'terminal'}
              >
                Virtual Terminal
              </SpTab>
            </Menu.Item>
          )
        }
        {
          isUSA && (acquirerType === ACQUIRER_TYPES.STRIPE) && (
            <Menu.Item key="ach-payments">
              <SpTab
                to={`${path}/ach-payments`}
                selected={current === 'ach-payments'}
              >
                ACH Payments
              </SpTab>
            </Menu.Item>
          )
        }
        <Menu.Item key="transfer">
          <SpTab
            to={`${path}/transfer`}
            selected={current === 'transfer'}
          >
            Transfer
          </SpTab>
        </Menu.Item>
        {
          cloverInternalCredentials && cloverInternalCredentials.gid && (
            <Menu.Item key="cloverwebhook">
              <SpTab
                to={`${path}/cloverwebhook`}
                selected={current === 'cloverwebhook'}
              >
                Clover Webhook
              </SpTab>
            </Menu.Item>
          )
        }
        {
          (!isUSA) && (
            <Menu.Item key="bank-transfer">
              <SpTab
                to={`${path}/bank-transfer`}
                selected={current === 'bank-transfer'}
              >
                Bank Transfers
              </SpTab>
            </Menu.Item>
          )
        }
        <Menu.Item key="customdocuments">
          <SpTab
            to={`${path}/customdocuments`}
            selected={current === 'customdocuments'}
          >
            Documents
          </SpTab>
        </Menu.Item>
        {/* {
          isUSA && internalRole && (
            <Menu.Item key="usonboard">
              <SpTab
                to={`${path}/usonboard`}
                selected={current === 'usonboard'}
              >
                US Onboarding
              </SpTab>
            </Menu.Item>
          )
        } */}
      </Menu>

      <Switch>
        <Route exact path={path} component={AccountSettings} />
        <Route exact path={`${path}/business`} component={BusinessSettings} />
        <Route exact path={`${path}/support`} component={SupportSettings} />
        <Route exact path={`${path}/referrals`} component={Referrals} />
        <Route exact path={`${path}/ach-payments`} component={AchPayments} />
        <Route exact path={`${path}/bank-transfer`} component={BankTransfer} />
        <Route exact path={`${path}/transfer`} component={TransferSettings} />
        <Route exact path={`${path}/customdocuments`} component={CustomDocuments} />
        {/* <Route exact path={`${path}/usonboard`} component={USOnboarding} /> */}
        <Route exact path={`${path}/cloverwebhook`} component={CloverWebhook} />
        <Route exact path={`${path}/cloverSync`} component={CloverSync} />
        <Route exact path={`${path}/terminal`} component={VirtualTerminal} />
      </Switch>
    </div>
  );
};

export default withRouter(Settings);
